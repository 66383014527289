import React from "react";

import styles from "./DataBoostRetentionCampaign.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";

interface DataBoostRetentionCampaignProps {
  className?: string;
  cardType: CardType;
}

const DataBoostRetentionCampaign: React.FC<DataBoostRetentionCampaignProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {};

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(
        styles["container"],
        className,
        styles["product-demo-invitations-content"]
      )}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Data Boost Retention Campaign
        </TextElement>
        <TextElement>
          This offer provides customers with an additional 10GB of data at no
          extra cost for one month. It’s designed to help customers stay
          connected and satisfied, especially if they’ve been experiencing data
          limitations. This offer will be automatically applied to the
          customer's account, and they will be notified via email and SMS.
        </TextElement>
      </div>
    </Card>
  );
};

export default DataBoostRetentionCampaign;
