// src/hooks/useFetchLogs.ts
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Log {
  id: string;
  datetime: string;
}

interface FetchLogsResponse {
  logs: Log[];
}

const API_URL = "https://dev-pgrag.eriko.ai/model_logs";

export const useFetchLogs = (modelName: string) => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [log, setLog] = useState<JSON | null>(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get<FetchLogsResponse>(
          `${API_URL}?model_name=market_sentiments`
        );
        console.log(response?.data);
        setLogs(response?.data?.logs || []);
      } catch (err) {
        setError((err as Error).message);
        toast.error("Failed to fetch logs");
      } finally {
        setLoading(false);
      }  
    };

    fetchLogs();
  }, [modelName]);

  const fetchLogById = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const { data } = await axios.get<JSON>(`${API_URL}/${id}`);
      setLog(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  return { logs, loading, error, log, fetchLogById };
};
