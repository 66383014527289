import { useState } from "react";
import TextElement from "../TextElement/TextElement";

export const useSalesQualifiedLeadsQueryChat = () => {
  const objections = [
    {
      heading: "Scepticism about ROI",
      description:
        "Showcase proven impact with data and analytics. Present KPI dashboards. Offer a trial period or performance-based incentives.",
      id: 4,
    },
    {
      heading: "Security Concerns",
      description:
        "Emphasise advanced security features and compliance with industry standards like GDPR and HIPAA. Provide client testimonials.",
      id: 3,
    },
    {
      heading: "Implementation Challenges",
      description:
        "Provide a clear implementation roadmap and detailed project plan. Ensure robust technical support and training programs.",
      id: 2,
    },
    {
      heading: "Cost Concerns",
      description:
        "Highlight long-term cost savings and efficiency gains. Offer flexible payment terms and financing options. Show cost-benefit analysis.",
      id: 1,
    },
  ];

  const suggestions = [
    {
      heading: "Pilot Program",
      description: (
        <TextElement>
          Offer live demonstrations and pilot programs. Let them experience the
          benefits firsthand with a{" "}
          <b>
            30-day pilot where they can test the 5G network in their own
            environment.
          </b>
        </TextElement>
      ),
      id: 1,
    },
    {
      heading: "Share Case Studies",
      description: (
        <TextElement>
          Share case studies and success stories that highlight the proven
          impact of your 5G solutions. For instance,{" "}
          <b>
            a detailed case study of a financial institution that saw a 50%
            increase in transaction speeds.
          </b>
        </TextElement>
      ),
      id: 2,
    },
  ];

  const timelineEvents = [
    {
      period: "Month 1 to 2",
      description:
        "Initial outreach and introduction through personalised emails and LinkedIn connections",
    },
    {
      period: "Month 3 to 4",
      description:
        "Follow-up with tailored content such as industry-specific white papers and case studies",
    },
    {
      period: "Month 5 to 6",
      description:
        "Schedule one-on-one meetings and demos, attend industry events and invite leads to exclusive roundtable discussions",
    },
    {
      period: "Month 7 to 8",
      description:
        "Conduct pilot programs and provide live demonstrations to showcase the benefits of your solutions",
    },
    {
      period: "Month 9 to 10",
      description:
        "Maintain regular communication, share success stories, and offer continued support to nurture relationships",
    },
  ];

  const strategies = [
    {
      id: 1,
      strategyType: "Finance",
      strategies: [
        "Slow transaction speeds",
        "Data security and compliance issues",
        "High infrastructure costs",
      ],
      offer:
        "Implement a high-speed, secure transaction processing system with compliance features.",
    },
    {
      id: 2,
      strategyType: "Manufacturing",
      strategies: [
        "Inefficient supply chain management",
        "Lack of real-time monitoring",
        "High downtime costs",
      ],
      offer:
        "Provide a real-time monitoring system with predictive maintenance capabilities to reduce downtime.",
    },
    {
      id: 3,
      strategyType: "Healthcare",
      strategies: [
        "Limited bandwidth for telemedicine and remote patient monitoring",
        "Data security concerns",
        "High operational costs",
      ],
      offer:
        "Offer a comprehensive telemedicine solution with secure data transmission and low latency.",
    },
  ];

  const customizedSolutions = [
    {
      id: 1,
      leadType: "Leads with Confirmed Budgets",
      items: [
        {
          heading: "Rapid Deployment Kits",
          content: "Provide pre-configured 5G deployment kits.",
          example:
            "'5G in 5 Days' kit with all necessary hardware and software.",
        },
        {
          heading: "Priority Support",
          content:
            "Establish a dedicated support team with a 24/7 hotline and a 4-hour on-site response time for critical issues.",
        },
      ],
    },
    {
      id: 2,
      leadType: "Leads with Confirmed Budgets",
      items: [
        {
          heading: "Financial Solutions",
          content:
            "Offer flexible financing options such as CAPEX to OPEX transitions, leasing, and subscription models.",
          example: "Leasing option spreading the cost over three years",
        },
        {
          heading: "Tailored Packages",
          content:
            "Develop bespoke 5G packages that match their specific business requirements and budget constraints.",
          example:
            "Custom package including enhanced security features and network management tools for enterprises.",
        },
      ],
    },
  ];

  const leads = [
    {
      leadType: "High-Priority Leads with Confirmed Budgets",
      leadsText: [
        "Robust and scalable 5G connectivity",
        "Reduce operational costs by up to 20%",
        "Increase productivity by 30%",
        "Seamless integration with existing infrastructure",
        "Minimise disruptions during transition to 5G",
      ],
      id: 1,
    },
    {
      leadType: "Leads with Immediate Needs",
      leadsText: [
        "Quick implementation with rapid deployment kits",
        "Fully operational 5G network within five days",
        "Reduce downtime",
        "Accelerate time-to-market for products and services",
        "Stay competitive and responsive to market demands",
      ],
      id: 2,
    },
  ];

  return {
    objections,
    suggestions,
    timelineEvents,
    strategies,
    customizedSolutions,
    leads,
  };
};
