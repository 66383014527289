import React from "react";

import styles from "./AddBANTQualificationQuestions.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface AddBANTQualificationQuestionsProps {
  className?: string;
  cardType: CardType;
}

const AddBANTQualificationQuestions: React.FC<
  AddBANTQualificationQuestionsProps
> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  
  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        bantQualification: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          BANT Qualification Questions
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Budget</TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            Question: “Have you set aside a budget for implementing 5G solutions
            this year?”
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Objective:</span> Determine
              if the lead has the financial capacity to invest in your 5G
              solutions.
            </li>
            <li>
              <span className={styles["highlight"]}>Follow-up Strategy:</span>{" "}
              If the lead is unsure, provide information on ROI and cost savings
              associated with 5G implementation.
            </li>
            <li>
              <span className={styles["highlight"]}>Example Response:</span>{" "}
              “Implementing 5G solutions can initially seem costly, but our
              clients typically see a return on investment within the first year
              due to increased efficiency and reduced operational costs.”
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Authority
        </TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            Question: “Who is the primary decision-maker for technology
            investments in your organisation?”
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Objective:</span> Identify
              if the lead has the authority to make purchasing decisions or if
              they can connect you with the decision-maker.
            </li>
            <li>
              <span className={styles["highlight"]}>Follow-up Strategy:</span>{" "}
              If the lead is not the decision-maker, request an introduction to
              the appropriate person.
            </li>
            <li>
              <span className={styles["highlight"]}>Example Response:</span>{" "}
              “It’s great to understand your perspective. Could you introduce me
              to the person who oversees technology investments so I can provide
              them with detailed information?”
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>Need</TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            Question: “What specific challenges are you currently facing that
            you believe 5G solutions can help solve?”
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Objective:</span> Understand
              the lead’s pain points and determine if your solutions meet their
              needs.
            </li>
            <li>
              <span className={styles["highlight"]}>Follow-up Strategy:</span>{" "}
              Tailor your response to highlight how your 5G solutions can
              address their specific challenges.
            </li>
            <li>
              <span className={styles["highlight"]}>Example Response:</span>{" "}
              “Many of our clients in the healthcare sector have found that our
              5G solutions greatly enhance telemedicine capabilities and improve
              patient care through faster data transmission.”
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Timeline
        </TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            Question: “What is your timeline for implementing 5G solutions?”
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Objective:</span> Assess the
              urgency and timeframe for the lead’s project.
            </li>
            <li>
              <span className={styles["highlight"]}>Follow-up Strategy:</span>{" "}
              If the timeline is unclear, help them define a realistic
              implementation schedule based on their needs and budget.
            </li>
            <li>
              <span className={styles["highlight"]}>Example Response:</span>{" "}
              “Based on our experience, a typical 5G implementation can be
              completed within 3-6 months, depending on your specific
              requirements. Does this timeline align with your planning?”
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default AddBANTQualificationQuestions;
