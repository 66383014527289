import React from "react";

const HighRiskIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="469"
      height="332"
      viewBox="0 0 469 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M405.005 183.726C401.769 172.896 394.135 162.996 383.571 159.085C385.88 169.34 386.515 183.123 376.853 187.171C367.192 191.218 357.532 180.58 354.753 170.45C350.897 156.383 353.02 141.469 352.712 126.884C352.405 112.298 348.731 96.3085 336.934 87.7925C326.798 80.4755 313.336 80.5196 301.31 77.1587C276.959 70.3507 259.24 49.8451 243.044 30.3574C244.073 39.6736 241.969 49.0679 237.066 57.0439C231.888 38.0774 218.232 21.6206 200.59 13.0846C182.947 4.54871 161.621 4.08609 143.622 11.8337C152.14 25.5468 160.804 39.6132 164.21 55.4087C167.616 71.2042 164.788 89.3426 153.021 100.369C144.642 108.221 132.174 112.324 127.39 122.783C122.332 133.846 128.026 147.99 121.857 158.47C116.788 167.082 105.128 169.819 95.4456 167.468C85.7633 165.116 77.5862 158.808 69.7291 152.659C60.3019 165.554 64.6395 183.997 72.5263 197.896C80.4131 211.795 91.5346 224.203 95.7128 239.639C100.056 255.69 96.2735 272.678 96.1746 289.31C96.0756 305.943 101.598 324.959 116.816 331.527L380.643 332.001C376.517 284.005 395.644 264.485 405.287 217.293C407.547 206.218 408.241 194.553 405.005 183.726Z"
        fill="#EB2701"
      />
      <g opacity="0.6">
        <path
          d="M405.005 183.726C401.769 172.896 394.135 162.996 383.571 159.085C385.88 169.34 386.515 183.123 376.853 187.171C367.192 191.218 357.532 180.58 354.753 170.45C350.897 156.383 353.02 141.469 352.712 126.884C352.405 112.298 348.731 96.3085 336.934 87.7925C326.798 80.4755 313.336 80.5196 301.31 77.1587C276.959 70.3507 259.24 49.8451 243.044 30.3574C244.073 39.6736 241.969 49.0679 237.066 57.0439C231.888 38.0774 218.232 21.6206 200.59 13.0846C182.947 4.54871 161.621 4.08609 143.622 11.8337C152.14 25.5468 160.804 39.6132 164.21 55.4087C167.616 71.2042 164.788 89.3426 153.021 100.369C144.642 108.221 132.174 112.324 127.39 122.783C122.332 133.846 128.026 147.99 121.857 158.47C116.788 167.082 105.128 169.819 95.4456 167.468C85.7633 165.116 77.5862 158.808 69.7291 152.659C60.3019 165.554 64.6395 183.997 72.5263 197.896C80.4131 211.795 91.5346 224.203 95.7128 239.639C100.056 255.69 96.2735 272.678 96.1746 289.31C96.0756 305.943 101.598 324.959 116.816 331.527L380.643 332.001C376.517 284.005 395.644 264.485 405.287 217.293C407.547 206.218 408.241 194.553 405.005 183.726Z"
          fill="white"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M123.729 310.037C120.419 306.686 118.059 298.373 118.112 289.443C118.138 285.008 118.489 280.3 118.863 275.311C119.799 262.766 120.857 248.548 116.884 233.858C113.105 219.901 105.622 208.643 99.0179 198.712C96.8914 195.51 94.8573 192.453 93.0453 189.465C97.4487 190.273 101.939 190.496 106.4 190.127C121.293 188.912 133.81 181.452 140.743 169.674C147.058 158.946 147.01 147.895 146.973 139.825C146.962 137.232 146.946 133.354 147.328 131.997C148.288 130.681 152.287 128.045 154.954 126.286C159.007 123.611 163.603 120.581 167.99 116.469C184.326 101.163 191.094 75.9768 185.652 50.7423C183.98 42.9903 181.394 35.755 178.325 28.9492C182.743 29.6446 187.037 30.9837 191.07 32.924C202.915 38.6555 212.435 50.1262 215.911 62.861L229.188 111.495L252.77 73.3987C264.559 84.1407 278.562 93.667 295.431 98.3783C300.182 99.7032 304.879 100.567 309.024 101.325C315.285 102.473 321.199 103.558 324.131 105.673C328.07 108.517 330.557 116.618 330.784 127.346C330.861 131.028 330.756 134.891 330.647 138.982C330.343 150.299 329.996 163.127 333.606 176.292C338.425 193.873 354.648 211.652 375.222 209.968C378.465 209.7 381.651 208.953 384.677 207.751C384.45 209.398 384.157 211.103 383.798 212.866C380.408 229.454 372.784 245.906 364.714 263.324C354.928 284.438 362.915 284.192 359.556 307.963L123.729 310.037Z"
          fill="white"
        />
      </g>
      <path
        d="M373.624 125.003C371.457 125.46 369.173 127.363 369.766 129.505C370.047 130.519 370.897 131.253 371.679 131.954C377.426 137.121 381.616 143.802 383.774 151.239L384.697 150.832C387.2 145.562 388.635 139.434 386.86 133.874C385.085 128.313 379.314 123.804 373.624 125.003Z"
        fill="#EB2701"
      />
      <g opacity="0.6">
        <path
          d="M373.624 125.003C371.457 125.46 369.173 127.363 369.766 129.505C370.047 130.519 370.897 131.253 371.679 131.954C377.426 137.121 381.616 143.802 383.774 151.239L384.697 150.832C387.2 145.562 388.635 139.434 386.86 133.874C385.085 128.313 379.314 123.804 373.624 125.003Z"
          fill="white"
        />
      </g>
      <path
        d="M117.401 93.0856C113.796 90.5638 108.879 91.0452 106.406 96.0259C104.906 100.077 105.611 104.262 106.982 108.358C108.353 112.454 112.735 115.765 116.887 114.626C120.965 113.506 123.057 108.765 123 104.521C122.944 100.109 121.006 95.6085 117.401 93.0856Z"
        fill="#EB2701"
      />
      <g opacity="0.6">
        <path
          d="M117.401 93.0856C113.796 90.5638 108.879 91.0452 106.406 96.0259C104.906 100.077 105.611 104.262 106.982 108.358C108.353 112.454 112.735 115.765 116.887 114.626C120.965 113.506 123.057 108.765 123 104.521C122.944 100.109 121.006 95.6085 117.401 93.0856Z"
          fill="white"
        />
      </g>
      <path
        d="M447.575 0L401.956 40.1633L417.973 41.4286L399.283 68.5214L437.027 33.0274L422.954 32.8298L447.575 0Z"
        fill="#EB2701"
      />
      <path
        d="M468.964 69.9727L436.477 72.3333L442.927 78.0846L426.186 83.6074L453.795 80.6848L447.832 76.0221L468.964 69.9727Z"
        fill="#EB2701"
      />
      <path
        d="M369.563 12.3359L369.5 45.0443L375.689 39.0114L379.933 56.1848L379.077 28.3202L374.005 33.9457L369.563 12.3359Z"
        fill="#EB2701"
      />
      <path
        d="M27.7288 28L52.4238 64.5418L55.0923 53.3008L73.0594 69.3712L50.9581 38.9043L49.2869 48.8755L27.7288 28Z"
        fill="#EB2701"
      />
      <path
        d="M0.5 92.2405L27.9581 103.01L24.9542 96.0452L40.7982 98.2546L17.1026 89.8125L20.1362 95.7427L0.5 92.2405Z"
        fill="#EB2701"
      />
      <g opacity="0.5">
        <path
          d="M145.443 210.183C145.493 209.974 145.543 209.764 145.596 209.553C145.596 209.553 145.568 209.474 145.518 209.332C145.489 209.62 145.466 209.902 145.443 210.183Z"
          fill="white"
        />
      </g>
      <path
        d="M156.11 270.691C161.524 267.548 164.062 261.859 161.863 260.157C159.664 258.456 153.995 264.236 152.297 264.915L151.367 264.406C151.367 264.406 156.781 255.145 155.765 253.446C154.749 251.747 151.704 251.407 150.773 252.257C149.842 253.107 144.485 258.034 143.949 257.949C143.414 257.864 142.489 256.93 142.489 256.93C142.489 256.93 144.942 251.492 146.043 247.84C146.043 247.84 145.933 244.045 141.925 244.497C141.925 244.497 142.715 241.156 139.782 240.364C136.85 239.571 134.368 242.722 133.014 248.027C132.576 246.016 132.331 243.967 132.281 241.909C132.224 238.664 129.123 237.702 127.318 239.627C125.514 241.553 122.186 252.936 129.517 262.791C136.849 272.647 143.956 273.098 143.956 273.098C143.956 273.098 150.695 273.835 156.11 270.691Z"
        fill="#F2C5A7"
      />
      <path
        d="M353.309 242.457C353.309 242.457 354.268 237.076 351.504 236.17C348.74 235.263 349.249 237.019 348.516 238.718C347.782 240.417 345.865 240.705 345.582 239.851C345.3 238.996 347.5 237.076 347.561 234.528C347.623 231.98 346.151 227.957 344.346 227.957C342.542 227.957 341.526 229.486 341.921 230.959C342.315 232.432 342.429 233.168 341.639 233.904C341.07 234.408 340.445 234.845 339.778 235.207C339.778 235.207 340.511 230.901 339.668 229.486C338.825 228.072 337.018 225.126 335.438 225.18C333.858 225.235 332.167 226.596 332.45 227.616C332.732 228.636 333.69 231.864 333.295 232.486C332.9 233.109 327.148 236.903 327.148 236.734C327.148 236.565 327.768 232.373 326.697 231.354C325.626 230.334 323.538 230.843 323.538 230.843C323.538 230.843 319.534 235.149 319.36 237.13C319.186 239.111 319.95 245.003 319.95 245.003C319.95 245.003 326.126 256.67 334.698 264.372L333.909 263.579C333.909 263.579 342.801 260.295 347.577 254.234C347.577 254.234 352.577 251.232 353.987 248.061C355.396 244.89 353.309 242.457 353.309 242.457Z"
        fill="#F2C5A7"
      />
      <path
        d="M319.95 245.002C319.95 245.002 313.944 251.798 308.867 248.287C303.791 244.776 295.783 232.43 286.987 229.824C278.191 227.218 266.696 228.609 266.696 228.609C266.696 228.609 250.596 233.786 241.1 235.994C241.1 235.994 212.371 225.232 191.052 229.989C169.734 234.747 171.171 244.176 161.358 246.471C151.545 248.765 146.046 247.83 146.046 247.83C144.947 251.482 142.493 256.92 142.493 256.92C142.493 256.92 143.423 257.854 143.959 257.939C144.496 258.024 149.853 253.096 150.783 252.247C151.713 251.398 154.76 251.737 155.775 253.436C156.789 255.136 151.377 264.396 151.377 264.396L152.307 264.905C153.999 264.226 159.667 258.449 161.873 260.147C164.078 261.845 161.534 267.538 156.12 270.682C150.706 273.825 143.966 273.089 143.966 273.089C143.966 273.089 151.523 278.639 164.156 278.413C176.79 278.186 196.465 267.728 196.465 267.728C196.465 267.728 196.728 286.033 198.42 295.208C200.112 304.384 202.329 311.304 202.329 311.304C202.329 311.304 198.77 321.523 199.446 331.717C216.928 330.812 310.131 331.57 326.403 331.865C326.403 331.865 323.765 295.969 314.178 291.325C314.178 291.325 314.798 284.473 314.403 279.998C314.403 279.998 329.293 274.448 334.707 264.367C326.125 256.671 319.95 245.002 319.95 245.002Z"
        fill="#02285E"
      />
      <path
        d="M201.831 310.135C201.831 310.135 303.756 291.17 314.363 288.619C314.363 288.619 315.067 283.306 314.096 275.766C314.096 275.766 221.378 291.782 199.094 298.259L201.831 310.135Z"
        fill="#EB2701"
      />
      <g opacity="0.5">
        <path
          d="M125.177 249.809C125.177 249.809 127.713 241.606 129.066 240.984C130.42 240.361 131.435 241.465 131.435 241.465C131.435 241.465 131.215 238.832 129.517 238.775C127.82 238.719 126.387 239.823 125.541 242.484C124.696 245.145 125.177 249.809 125.177 249.809Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M132.845 249.809C132.845 249.809 136.933 242.06 139.049 242.202C141.164 242.343 140.963 245.256 140.963 245.256C140.963 245.256 141.981 244.297 141.756 243.221C141.53 242.144 140.69 239.959 138.276 240.302C135.861 240.644 133.72 244.58 133.691 244.718C133.662 244.856 132.451 248.639 132.845 249.809Z"
          fill="white"
        />
      </g>
      <g opacity="0.26">
        <path
          d="M224.468 292.352C224.468 292.352 231.262 292.916 237.691 292.352C244.121 291.787 314.168 278.535 314.168 278.535L314.365 277.067L313.209 275.766L224.468 292.352Z"
          fill="white"
        />
      </g>
      <path
        d="M255.483 237.156L263.26 231.008L263.93 229.867L263.38 229.57L246.926 234.413L244.261 235.432V236.239L250.691 238.32L253.495 238.811L255.483 237.156Z"
        fill="#F2C5A7"
      />
      <path
        d="M301.422 277.845C296.736 272.803 221.559 230.73 221.117 230.638C220.297 230.467 240.585 234.34 241.406 234.51C241.859 234.605 300.837 256.894 312.27 276.025C312.32 276.107 310.011 276.32 307.4 276.81C304.641 277.333 301.65 278.091 301.422 277.845Z"
        fill="#012551"
      />
      <path
        d="M265.885 227.326C266.594 226.878 275.581 227.767 274.871 228.215C270.29 231.105 259.588 241.977 259.494 242.054C259.209 242.297 251.266 238.709 251.907 238.165"
        fill="#012551"
      />
      <path
        d="M286.98 283.629C286.98 283.629 297.023 292.58 293.188 304.246C289.353 315.912 275.839 326.674 275.839 326.674L268.711 319.652C268.711 319.652 286.339 305.077 282.037 296.113"
        fill="#EB2701"
      />
      <path
        d="M268.086 296.945C268.086 296.945 263.409 308.438 254.272 313.649C245.135 318.859 236.79 318.746 236.79 318.746L232.955 307.845C232.955 307.845 245.024 305.72 249.762 301.416C254.5 297.112 258.804 286.961 258.804 286.961"
        fill="#EB2701"
      />
      <path
        d="M272.321 282.327C272.321 282.327 273.956 275.984 281.4 276.947C288.845 277.91 287.86 290.583 284.123 294.582C280.386 298.581 275.592 295.524 275.592 295.524C275.592 295.524 269.895 299.035 264.085 295.468C258.275 291.9 256.923 281.761 261.717 280.01C266.511 278.259 270.121 280.686 272.321 282.327Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M236.878 306.98C236.878 306.98 239.609 313.138 242.316 313.251C245.023 313.363 254.836 309.287 258.559 304.245C262.282 299.204 257.036 290.707 257.092 290.595C257.148 290.484 258.396 287.876 258.396 287.876C258.396 287.876 258.803 286.482 258.804 285.932C258.805 285.383 258.883 284.184 258.883 284.184C258.883 284.184 264.763 291.717 270.177 292.601C275.591 293.486 276.923 290.544 276.923 290.544L276.263 288.426C276.263 288.426 278.715 289.861 281.382 289.804C284.048 289.746 286.359 286.958 286.359 286.958C286.359 286.958 287.97 288.967 285.652 292.138C283.334 295.309 281.216 296.665 278.853 296.604C276.49 296.543 275.814 295.319 274.689 295.98C273.564 296.641 270.525 297.149 269.411 297.063C268.298 296.977 267.804 297.578 267.804 297.578C267.804 297.578 262.526 309.683 253.66 313.987C244.793 318.291 236.502 318.745 236.502 318.745L232.949 307.844L236.878 306.98Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M271.797 316.513C271.907 316.742 274.52 319.82 277.396 319.367C280.272 318.914 287.153 310.534 289.748 304.641C292.343 298.749 290.255 295.637 288.845 295.524C287.436 295.41 282.697 298.559 282.665 298.332C282.633 298.106 282.217 296.523 282.217 296.523L283.06 295.52C283.06 295.52 285.408 292.895 286.157 290.811C286.906 288.728 286.98 285.726 286.98 285.726L288.416 285.125C288.416 285.125 293.388 288.558 293.683 298.072C293.978 307.586 284.876 317.826 284.876 317.826L276.101 326.673L268.713 319.367L271.797 316.513Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M270.1 283.208C271.821 284.422 272.601 286.097 272.229 286.63C271.858 287.164 270.477 286.348 268.755 285.133C267.033 283.919 265.62 282.766 265.992 282.235C266.363 281.704 268.377 281.993 270.1 283.208Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M281.387 279.259C282.977 279.737 283.916 280.984 283.733 281.606C283.551 282.227 282.304 281.989 280.715 281.511C279.127 281.033 277.792 280.494 277.978 279.872C278.163 279.25 279.798 278.78 281.387 279.259Z"
          fill="white"
        />
      </g>
      <path
        d="M167.617 162.696C167.617 162.696 170.523 173.929 158.761 180.781C146.998 187.633 135.671 183.358 135.671 183.358L131.626 166.424C131.626 166.424 142.632 168.897 148.331 167.144C154.03 165.391 164.189 153.191 164.189 153.191"
        fill="#EB2701"
      />
      <path
        d="M259.777 234.042C242.075 238.369 212.279 239.542 202.615 235.114C189.785 229.229 177.688 217.143 173.038 208.042C168.566 199.273 154.456 165.644 173.855 134.299C191.982 105.014 243.838 84.0356 284.59 128.952C295.38 143.195 302.319 180.497 294.896 200.983C290.082 214.277 276.959 229.839 259.777 234.042Z"
        fill="#02285E"
      />
      <path
        d="M195.52 166.759C189.405 168.787 185.432 174.418 185.712 180.577C186.139 189.982 191.21 203.433 199.927 208.419C211.882 215.255 222.266 198.021 244.655 195.791C267.836 193.482 270.109 199.807 282.678 193.252C290.28 189.29 289.055 156.76 280.693 153.762C268.159 149.261 262.576 161.662 240.325 164.814C225.465 166.917 205.655 163.401 195.52 166.759Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M164.893 154.121C159.432 145.306 150.855 147.224 143.903 148.357C136.951 149.49 130.693 141.422 130.693 141.422C126.966 146.129 130.319 163.474 130.319 163.474C130.319 163.474 147.135 160.21 151.38 160.44C155.625 160.671 164.16 165.694 164.16 165.694L164.84 165.488"
        fill="#EB2701"
      />
      <path
        d="M291.951 145.3C291.951 145.3 221.179 167.127 162.654 165.974C162.654 165.974 158.396 165.992 158.707 158.407C159.018 150.821 168.138 150.819 168.138 150.819C168.138 150.819 217.157 147.859 234.602 144.956C252.047 142.054 286.103 133.199 287.24 133.266C289.701 133.412 291.834 140.527 291.951 145.3Z"
        fill="#EB2701"
      />
      <path
        d="M240.654 180.032C235.266 186.302 227.722 189.987 219.751 190.234C212.559 190.02 209.179 188.095 205.347 181.668C202.181 174.468 202.675 172.582 202.675 172.582L240.654 180.032Z"
        fill="#EB2701"
      />
      <path
        d="M274.834 174.074C272.771 179.153 268.047 182.418 262.772 182.394C257.136 182.804 251.512 181.456 246.667 178.537L272.691 158.371C272.691 158.371 275.814 170.938 274.834 174.074Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default HighRiskIcon;
