import React from "react";

import styles from "./PricingAndDiscounts.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateNegotiateStrategyData } from "../../../../redux/negotiateStrategySlice";

interface Props {
  className?: string;
  cardType: CardType;
}

const PricingAndDiscounts: React.FC<Props> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateNegotiateStrategyData({
        ...includeData,
        pricingAndDiscounts: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <TextElement className={styles["heading"]}>
        Pricing and Discounts
      </TextElement>
      <TextElement>
        <span>Objective:</span> Offer flexible and competitive pricing options
        to close deals effectively.
      </TextElement>
    </Card>
  );
};

export default PricingAndDiscounts;
