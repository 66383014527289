import React, { useState } from "react";
import styles from "./AddFieldModal.module.scss";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import Input from "../../../MarketingBriefFormCard/components/Input/Input";

interface AddFieldModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (label: string, value: string) => void;
}

const AddFieldModal: React.FC<AddFieldModalProps> = ({
  visible,
  onClose,
  onSubmit,
}) => {
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");

  const handleOk = () => {
    onSubmit(label, value);
    setLabel("");
    setValue("");
    onClose();
  };

  const handleCancel = () => {
    setLabel("");
    setValue("");
    onClose();
  };

  return (
    <Modal
      isOpen={visible}
      closeModal={handleCancel}
      className={styles["container"]}
      contentClassName={styles["content"]}
    >
      <form>
        <Input
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
        <Input
          label="Value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={styles["modal-footer"]}>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk}>Add</Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddFieldModal;
