import React from "react";

import styles from "./UsercaseStory.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import FieldWithIcon from "../FieldWithIcon/FieldWithIcon";

interface UsercaseStoryProps {
  className?: string;
  cardType: CardType;
}

const UsercaseStory: React.FC<UsercaseStoryProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    // nurtureStrategyData &&
    //   dispatch(
    //     setNurtureStrategyData({
    //       ...nurtureStrategyData,
    //       UsercaseStory: isPinned,
    //     })
    //   );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={styles["content"]}>
        <TextElement className={styles["heading"]}>
          Title: Revolutionising Financial Services with 5G: A Success Story
        </TextElement>
        <FieldWithIcon
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7857 14.2143H0.357178V16.5H11.7857V14.2143ZM18.6429 5.07143H0.357178V7.35714H18.6429V5.07143ZM0.357178 11.9286H18.6429V9.64286H0.357178V11.9286ZM0.357178 0.5V2.78571H18.6429V0.5H0.357178Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Outline"
          text={
            <TextElement className={styles["body-text"]}>
              <ol>
                <li className={styles["list-item"]}>
                  <strong>Background</strong>
                  <ul>
                    <li>Overview of the client</li>
                    <li>Initial challenges and objectives</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Challenges</strong>
                  <ul>
                    <li>Slow transaction processing</li>
                    <li>High operational costs</li>
                    <li>Compliance with data security regulations</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Solution</strong>
                  <ul>
                    <li>Implemented 5G transaction processing system</li>
                    <li>Enhanced data security measures</li>
                    <li>Seamless integration with existing infrastructure</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Results</strong>
                  <ul>
                    <li>50% increase in transaction speeds</li>
                    <li>30% reduction in operational costs</li>
                    <li>Improved compliance and data security</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Conclusion</strong>
                  <ul>
                    <li>Overall impact on the client’s operations</li>
                    <li>Client testimonial and satisfaction</li>
                  </ul>
                </li>
              </ol>
            </TextElement>
          }
        />
      </div>
    </Card>
  );
};

export default UsercaseStory;
