import React from "react";

import styles from "./SuggestedDiscounts.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import TextElement from "../../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateNegotiateStrategyData } from "../../../../redux/negotiateStrategySlice";

interface SuggestedDiscountsProps {
  className?: string;
  cardType: CardType;
}

const SuggestedDiscounts: React.FC<SuggestedDiscountsProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateNegotiateStrategyData({
        ...includeData,
        suggestedDiscounts: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={classNames(styles["content-container"])}>
        <TextElement className={styles["heading"]}>
          Suggested Discounts
        </TextElement>
        <div className={styles["follow-item"]}>
          <div className={styles["icon-container"]}>
            <svg
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.048 15.1107L0.236084 8.29883V9.69044C0.236084 10.0592 0.382203 10.4141 0.646608 10.6715L6.06692 16.0918C6.60964 16.6346 7.49331 16.6346 8.03604 16.0918L12.357 11.7709C12.8997 11.2282 12.8997 10.3445 12.357 9.80176L7.048 15.1107Z"
                fill="#EB2701"
              />
              <path
                d="M6.06692 12.6139C6.60964 13.1567 7.49331 13.1567 8.03604 12.6139L12.357 8.293C12.8997 7.75027 12.8997 6.8666 12.357 6.32387L6.93667 0.903566C6.67227 0.646119 6.31741 0.5 5.94863 0.5H1.62769C0.862307 0.5 0.236084 1.12622 0.236084 1.89161V6.21255C0.236084 6.58132 0.382203 6.93618 0.646608 7.19363L6.06692 12.6139ZM3.19325 2.58741C3.67335 2.58741 4.063 2.97706 4.063 3.45716C4.063 3.93727 3.67335 4.32692 3.19325 4.32692C2.71314 4.32692 2.32349 3.93727 2.32349 3.45716C2.32349 2.97706 2.71314 2.58741 3.19325 2.58741Z"
                fill="#EB2701"
              />
            </svg>
          </div>
          <TextElement className={styles["text"]}>
            <span>Early-Bird Discount: </span>
            10% off if the contract is signed within 14 days of the proposal.
            <br />
            <span>Referral Discount:</span> 5% off for clients who refer another
            business that signs a contract.
            <br />
            <span>Volume Discount:</span> 10% off for bulk purchases of
            additional units or services.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default SuggestedDiscounts;
