import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import { ROUTE_DEFINITIONS } from "../pages/AppRoutes";

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

class HttpService {
  private http: AxiosInstance;
  private baseURL = process.env.REACT_APP_API_URL;

  constructor(baseUrl?: string) {
    this.http = axios.create({
      baseURL: baseUrl || this.baseURL,
      withCredentials: false,
      headers: this.setupHeaders(),
    });

    this.injectInterceptors();
  }

  private getAuthorization(): Record<string, string> {
    // Implement your logic to get the authorization token
    const accessToken = getAccessToken();
    return accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
          "X-ACCESS-KEY":
            "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",
        }
      : {};
  }

  private setupHeaders(hasAttachment = false): Record<string, string> {
    return hasAttachment
      ? { "Content-Type": "multipart/form-data", ...this.getAuthorization() }
      : { "Content-Type": "application/json", ...this.getAuthorization() };
  }

  private async request<T>(
    method: string,
    url: string,
    options: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.http.request<T>({
        method,
        url,
        ...options,
      });

      return response;
    } catch (error) {
      return this.normalizeError(error as AxiosError<T>);
    }
  }

  public async get<T>(
    url: string,
    params?: any,
    hasAttachment = false
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("get", url, {
      params,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  public async post<T, P>(
    url: string,
    payload: P,
    params?: any,
    hasAttachment = false
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("post", url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  public async update<T, P>(
    url: string,
    payload: P,
    params?: any,
    hasAttachment = false
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("patch", url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  public async put<T, P>(
    url: string,
    payload: P,
    params?: any,
    hasAttachment = false
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("put", url, {
      params,
      data: payload,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  public async remove<T>(
    url: string,
    params?: any,
    hasAttachment = false
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("delete", url, {
      params,
      headers: this.setupHeaders(hasAttachment),
    });
  }

  private injectInterceptors() {
    this.http.interceptors.request.use((request) => {
      // Implement your request interceptor logic here
      return request;
    });

    this.http.interceptors.response.use(
      (response) => {
        // Implement your response interceptor logic here
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );
  }

  private normalizeError<T>(error: AxiosError<T>): Promise<AxiosResponse<T>> {
    if (axios.isAxiosError(error)) {
      // Handle Axios errors here, you can extract more details from the error object
      return Promise.reject(error.response || error.message);
    }

    // Handle non-Axios errors (e.g., network errors)
    return Promise.reject(error);
  }
}

export default HttpService;
