import React from "react";
import UpRightarrowIcon from "../../../icons/UpRightarrowIcon";

type Props = {
  data: {
    id: number;
    title: string;
    description: string;
  };
};

const OfferCard = ({ data: { id, title = "", description = "" } }: Props) => {
  return (
    <div key={id} className="border-[3px] border-[#EB2701] p-5 rounded-xl">
      <div className="flex items-start">
        <div className="bg-[#EB2701] p-3 rounded-full">
          <UpRightarrowIcon />
        </div>
        <div className="ms-4">
          <h6 className="font-bold text-base">{title}</h6>
          <p className="font-normal text-base mt-2">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
