import React, { useState, useRef, useEffect } from "react";

import styles from "./NegotiateStrategyQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FollowUpProtocol from "./componenets/FollowUpProtocol/FollowUpProtocol";

import { updateCampaign } from "../../redux/campaignPinningSlice";
import FollowUp from "./componenets/FollowUp/FollowUp";
import PricingAndDiscounts from "./componenets/PricingAndDiscounts/PricingAndDiscounts";
import TieredPricing from "./componenets/TieredPricing/TieredPricing";
import SuggestedDiscounts from "./componenets/SuggestedDiscounts/SuggestedDiscounts";
import CustomBundles from "./componenets/CustomBundles/CustomBundles";
import ExtendedPaymentTerms from "./componenets/ExtendedPaymentTerms/ExtendedPaymentTerms";
import PilotProgram from "./componenets/PilotProgram/PilotProgram";
import EmailContentTemplate from "./componenets/EmailContentTemplate/EmailContentTemplate";
import Button from "../Button/Button";
import classNames from "classnames";
import { useNegotiateStrategyQueryChat } from "./useNegotiateStrategyQueryChat";
import { SyncLoader } from "react-spinners";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface NegotiateStrategyQueryChatProps {
  className?: string;
}

const NegotiateStrategyQueryChat: React.FC<NegotiateStrategyQueryChatProps> = (
  props
) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { emailsContent, followUpsContent } = useNegotiateStrategyQueryChat();

  const addEmailContent = () => {
    setConversation((pC) => [
      ...emailsContent?.map((email) => ({
        type: MessageType.ANSWER,
        content: (
          <EmailContentTemplate
            key={email.id}
            heading={email.subject}
            body={email?.body}
            subject={email.subject}
            cardType="pinable"
            className={styles["card"]}
            id={email.id}
          />
        ),
      })),
      ...pC,
    ]);
  };

  const addFollowUps = () => {
    setConversation((pC) => [
      ...followUpsContent?.map((followUp) => ({
        type: MessageType.ANSWER,
        content: (
          <FollowUp
            cardType="pinable"
            className={styles["card"]}
            id={followUp.id}
            heading={followUp.heading}
            timeline={followUp.timeline}
            action={followUp.action}
            detail={followUp.detail}
            key={followUp.id}
          />
        ),
      })),
      ...pC,
    ]);
  };

  const addExtendedPaymentTerms = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ExtendedPaymentTerms cardType="pinable" className={styles["card"]} />
        ),
      },

      ...pC,
    ]);
  };

  const addPilotProgram = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: <PilotProgram cardType="pinable" className={styles["card"]} />,
      },
      ...pC,
    ]);
  };

  const addCustomBundles = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <CustomBundles cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addSuggestedDiscount = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <SuggestedDiscounts cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addPricingAndDiscount = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <PricingAndDiscounts cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addFollowUpProtocol = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <FollowUpProtocol cardType="pinable" className={styles["card"]} />
        ),
      },

      ...pC,
    ]);
  };

  const addTieredPricing = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <TieredPricing cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };
  
  const addStrategyFollowupProtocols = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs.strategyFollowupProtocols}
            message={
              <>
                <TextElement className={styles["chat-text"]}>
                  {" "}
                  Following up is more than just a courtesy—it's a critical step
                  in maintaining momentum and showing clients that their
                  business matters to us. By implementing a structured follow-up
                  protocol, we can keep the conversation going, address any
                  concerns in a timely manner, and move closer to closing the
                  deal. Here’s how you can implement the follow-up protocol:
                </TextElement>
              </>
            }
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message={
                "Based on our recent analyses, we've identified several key areas where we can focus our efforts to accelerate the sales process and maximise our success. Here’s a detailed strategy that outlines how we can tackle the next steps, streamline our approach, and make sure we're offering the best possible solutions to our clients. "
              }
            />
          ),
        },
        ...pC,
      ]);

      addFollowUpProtocol();
      addFollowUps();
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["continue-button"], styles["card"])}
              onClick={addClosingStrategyPricing}
            >
              Continue
            </Button>
          ),
        },
        ...pC,
      ]);
      handleScroll("strategyFollowupProtocols");
    }, 2000);
  };

  const addClosingStrategyPricing = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs.closingStrategyPricing}
            message={
              <>
                <TextElement className={styles["chat-text"]}>
                  Competitive pricing can be the deciding factor for many
                  clients. By offering tiered pricing, strategic discounts, and
                  flexible payment terms, we can make our 5G Corporate Solution
                  more accessible and appealing to a broader range of
                  businesses. Here are some pricing strategies you can
                  implement:
                </TextElement>
              </>
            }
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      addPricingAndDiscount();
      addTieredPricing();
      addSuggestedDiscount();
      addCustomBundles();
      addPilotProgram();
      addExtendedPaymentTerms();

      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message={
                "By implementing these strategies immediately, your sales team can effectively address client concerns and streamline the closing process for the 5G Corporate Solution."
              }
            />
          ),
        },
        ...pC,
      ]);
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["continue-button"], styles["card"])}
              onClick={addClosingStrategyContent}
            >
              Generate Closing Content?
            </Button>
          ),
        },
        ...pC,
      ]);
      handleScroll("closingStrategyPricing");
    }, 2000);
  };

  const addClosingStrategyContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message={
              "As we move toward finalizing our Sales Qualified Opportunities (SQOs), having a concise and effective closing strategy is key. The following content is designed to support our sales team in converting these opportunities into signed deals."
            }
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              ref={scrollRefs.closingStrategyContent}
              messageClass={styles["chat-message"]}
              message={
                "These follow-up communications are designed to keep the conversation moving forward, addressing any concerns and ensuring that the client feels supported at each stage of the decision-making process."
              }
            />
          ),
        },
        ...pC,
      ]);

      addEmailContent();

      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message={
                "This content includes essential communication pieces, proposal summaries, personalized offers, and a finalization checklist, all crafted to streamline the closing process and drive successful outcomes."
              }
            />
          ),
        },
        ...pC,
      ]);

      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["continue-button"], styles["card"])}
            >
              Implement Closing Strategy?
            </Button>
          ),
        },
        ...pC,
      ]);
      handleScroll("closingStrategyContent");
    }, 2000);
  };

  const [conversation, setConversation] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<HTMLDivElement>(null);

  const scrollRefs = {
    strategyFollowupProtocols: useRef<HTMLDivElement>(null),
    closingStrategyPricing: useRef<HTMLDivElement>(null),
    closingStrategyContent: useRef<HTMLDivElement>(null),
  };

  const handleScroll = (id: keyof typeof scrollRefs) => {
    const ref = scrollRefs[id];
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  useEffect(() => {
    addStrategyFollowupProtocols();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}
          {conversation?.map((item: Question, index: number) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default NegotiateStrategyQueryChat;
