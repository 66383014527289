import React, { useState, useEffect } from "react";

import styles from "./MarketingPipelineConfiguration.module.scss";

import Tabs from "../../componenets/Tabs/Tabs";
import { TextElement } from "../../componenets";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { Disclosure } from "@headlessui/react";
import DisclosureComponent from "../../componenets/Disclosure/Disclosure";
import MarketingSentiments from "./componenets/MarketingSentiments/MarketingSentiments";
import ContentGeneration from "./componenets/ContentGeneration/ContentGeneration";
import PromptEngineering from "./componenets/PromptEngineering/PromptEngineering";
import MarketingBriefFormCard from "../../componenets/MarketingBriefFormCard/MarketingBriefFormCard";
import { JSONTree } from "react-json-tree";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";

type Props = {};

const MarketingPipelineConfiguration: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [campaignActiveTab, setCampaingActiveTab] = useState(1);
  const [commentSideBarOpen, setCommentSideBarOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setCampaingActiveTab(
      parseInt(new URLSearchParams(window.location.search).get("tab") || "1")
    );
  }, [window.location.search]);

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Marketing Sentiments",
      content: (
        <div>
          <PromptEngineering
            apiPath={process.env.REACT_APP_PGRAG_API_URL as string}
            modelKey="market_sentiments"
            heading="Marketing Sentiments"
          />
        </div>
      ),
    },
    {
      id: 2,
      label: "Audience Segmentation",
      content: (
        <div>
          <PromptEngineering
            apiPath={process.env.REACT_APP_PGRAG_API_URL as string}
            modelKey="audience_segmentation"
            heading="Audience Segmentation"
          />
        </div>
      ),
    },
    {
      id: 3,
      label: "SWOT Analysis",
      content: (
        <div>
          <PromptEngineering
            apiPath={process.env.REACT_APP_PGRAG_API_URL as string}
            modelKey="swot_competitor_analysis"
            heading="SWOT Analysis"
          />
        </div>
      ),
    },
    {
      id: 4,
      label: "Content Generation",
      content: (
        <div>
          <PromptEngineering
            apiPath={process.env.REACT_APP_PGRAG_API_URL as string}
            modelKey="content_generation"
            heading="Content Generation"
          />
        </div>
      ),
    },
    {
      id: 5,
      label: "PCA",
      content: (
        <div>
          <PromptEngineering
            apiPath={process.env.REACT_APP_PGRAG_API_URL as string}
            modelKey="past_campaign_analysis"
            heading="Past Campaign Analysis"
          />
        </div>
      ),
    },
    // {
    //   id: 2,
    //   label: "Marketing Sentiments",
    //   content: (
    //     <div>
    //       <DisclosureComponent
    //         header={
    //           <TextElement className={styles["header-text"]}>
    //             Marketing Sentiments
    //           </TextElement>
    //         }
    //         content={<MarketingSentiments type="Marketing Sentiments" />}
    //         headerClassName={styles["disclosure-header"]}
    //         openHeaderClassName={styles["disclosure-header-open"]}
    //       />
    //       <DisclosureComponent
    //         header={
    //           <TextElement className={styles["header-text"]}>
    //             Marketing Sentiments Generation
    //           </TextElement>
    //         }
    //         content={
    //           <MarketingSentiments type="Marketing Sentiments Generation" />
    //         }
    //         headerClassName={styles["disclosure-header"]}
    //         openHeaderClassName={styles["disclosure-header-open"]}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   id: 3,
    //   label: "Competitor Analysis",
    //   content: (
    //     <div>
    //       <DisclosureComponent
    //         header={
    //           <TextElement className={styles["header-text"]}>
    //             Competitor Analysis
    //           </TextElement>
    //         }
    //         content={<MarketingSentiments type="Marketing Sentiments" />}
    //         headerClassName={styles["disclosure-header"]}
    //         openHeaderClassName={styles["disclosure-header-open"]}
    //       />
    //       <DisclosureComponent
    //         header={
    //           <TextElement className={styles["header-text"]}>
    //             Competitor Analysis Generation
    //           </TextElement>
    //         }
    //         content={
    //           <MarketingSentiments type="Marketing Sentiments Generation" />
    //         }
    //         headerClassName={styles["disclosure-header"]}
    //         openHeaderClassName={styles["disclosure-header-open"]}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   id: 4,
    //   label: "Content Generation",
    //   content: <ContentGeneration />,
    // },
  ]);

  const getUserLayoutWrapper = (children: React.ReactNode) => {
    return (
      <MarkerterViewLayout
        header={
          <TextElement className={styles["header-text"]}>
            <button
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.HOME.path);
              }}
              className={styles["back-link"]}
            >
              Back to Main Funnel
            </button> 
            <br />
            Marketing Pipeline Configuration
          </TextElement>
        }
      >
        {children}
      </MarkerterViewLayout>
    );
  };

  return getUserLayoutWrapper(
    <Tabs
      className={styles["tabs"]}
      tabs={tabs}
      basePath={`${ROUTE_DEFINITIONS.MARKETING_PIPELINE_CONFIGURATION.path}?`}
      tabContentClassName={styles["tab-content"]}
      onTabContentClick={() => {
        dispatch(setSelectedCampaign(""));
      }}
      
    />
  );
};

export default MarketingPipelineConfiguration;
