import React from "react";
import styles from "./ConfidenceScoreBar.module.scss";
import { TextElement } from "../../../../componenets";

interface ConfidenceScoreBarProps {
  score: number; // Range 0-100
  onSeeMoreDetails: () => void;
}

const ConfidenceScoreBar: React.FC<ConfidenceScoreBarProps> = ({
  score,
  onSeeMoreDetails,
}) => {
  // Calculate the position of the marker based on score
  const markerPosition = Math.min(Math.max(score / 5, 0), 20); // Maps score (0-100) to 20 sections

  return (
    <div className={styles.container}>
      <TextElement className={styles["score"]}>{score}</TextElement>
      <div className={styles["bar-container"]}>
        <div className={styles.icons}>
          <span className={styles.icon}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47778 20.5 0 16.0228 0 10.5C0 4.97778 4.47778 0.5 10 0.5C15.5228 0.5 20 4.97778 20 10.5Z"
                fill="#EB2701"
                fill-opacity="0.8"
              />
              <path
                d="M14.1582 17.099C14.1332 16.9996 13.5093 14.6662 9.99985 14.6662C6.48985 14.6662 5.86652 16.9996 5.84152 17.099C5.81096 17.2196 5.86541 17.3446 5.97319 17.4068C6.08152 17.4673 6.21707 17.4507 6.30652 17.364C6.31708 17.3535 7.39207 16.3329 9.99985 16.3329C12.6076 16.3329 13.6832 17.3535 13.6932 17.3635C13.7465 17.4162 13.8176 17.444 13.8887 17.444C13.9354 17.444 13.9826 17.4323 14.0254 17.4085C14.1343 17.3462 14.1887 17.2201 14.1582 17.099ZM8.72596 10.1068C6.91763 8.29845 4.01152 8.27734 3.88874 8.27734C3.58207 8.27734 3.33374 8.52568 3.33374 8.83179C3.33319 9.13845 3.58152 9.38734 3.88819 9.38845C3.9043 9.38845 4.95763 9.40068 6.10096 9.7979C5.77152 10.1535 5.55541 10.7057 5.55541 11.3329C5.55541 12.4073 6.17707 13.2773 6.9443 13.2773C7.71152 13.2773 8.33319 12.4073 8.33319 11.3329C8.33319 11.2362 8.32263 11.144 8.31263 11.0512C8.31985 11.0512 8.32652 11.0551 8.33319 11.0551C8.47541 11.0551 8.61763 11.0007 8.72596 10.8923C8.94319 10.6751 8.94319 10.324 8.72596 10.1068ZM16.111 8.27734C15.9882 8.27734 13.0826 8.29845 11.2737 10.1068C11.0565 10.324 11.0565 10.6751 11.2737 10.8923C11.3821 11.0007 11.5243 11.0551 11.6665 11.0551C11.6737 11.0551 11.6799 11.0512 11.6865 11.0512C11.6776 11.144 11.6665 11.2362 11.6665 11.3329C11.6665 12.4073 12.2882 13.2773 13.0554 13.2773C13.8226 13.2773 14.4443 12.4073 14.4443 11.3329C14.4443 10.7057 14.2282 10.1535 13.8987 9.7979C15.0421 9.40068 16.0954 9.38845 16.1121 9.38845C16.4182 9.38734 16.6665 9.13845 16.666 8.83179C16.6654 8.52568 16.4176 8.27734 16.111 8.27734Z"
                fill="black"
              />
            </svg>
          </span>
          <span className={styles.icon}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47778 20.5 0 16.0228 0 10.5C0 4.97778 4.47778 0.5 10 0.5C15.5228 0.5 20 4.97778 20 10.5Z"
                fill="#EB8D01"
                fill-opacity="0.8"
              />
              <path
                d="M6.38889 11.6106C7.15595 11.6106 7.77778 10.74 7.77778 9.66612C7.77778 8.59224 7.15595 7.72168 6.38889 7.72168C5.62183 7.72168 5 8.59224 5 9.66612C5 10.74 5.62183 11.6106 6.38889 11.6106Z"
                fill="black"
              />
              <path
                d="M13.6111 11.6106C14.3781 11.6106 14.9999 10.74 14.9999 9.66612C14.9999 8.59224 14.3781 7.72168 13.6111 7.72168C12.844 7.72168 12.2222 8.59224 12.2222 9.66612C12.2222 10.74 12.844 11.6106 13.6111 11.6106Z"
                fill="black"
              />
              <path
                d="M13.8888 14.9441H6.11098C5.80431 14.9441 5.55542 14.6958 5.55542 14.3886C5.55542 14.0813 5.80431 13.833 6.11098 13.833H13.8888C14.196 13.833 14.4443 14.0813 14.4443 14.3886C14.4443 14.6958 14.196 14.9441 13.8888 14.9441Z"
                fill="black"
              />
            </svg>
          </span>
          <span className={styles.icon}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z"
                fill="#38B05A"
                fill-opacity="0.8"
              />
              <path
                d="M10 12.1667C7.98725 12.1667 6.65169 11.9323 5.00003 11.6111C4.6228 11.5384 3.88892 11.6111 3.88892 12.7223C3.88892 14.9445 6.44169 17.7222 10 17.7222C13.5578 17.7222 16.1111 14.9445 16.1111 12.7223C16.1111 11.6111 15.3772 11.5378 15 11.6111C13.3484 11.9323 12.0128 12.1667 10 12.1667Z"
                fill="black"
              />
              <path
                d="M5 12.7227C5 12.7227 6.66667 13.2782 10 13.2782C13.3333 13.2782 15 12.7227 15 12.7227C15 12.7227 13.8889 14.9449 10 14.9449C6.11111 14.9449 5 12.7227 5 12.7227Z"
                fill="white"
              />
              <path
                d="M6.66672 9.94455C7.43378 9.94455 8.05561 9.074 8.05561 8.00011C8.05561 6.92622 7.43378 6.05566 6.66672 6.05566C5.89966 6.05566 5.27783 6.92622 5.27783 8.00011C5.27783 9.074 5.89966 9.94455 6.66672 9.94455Z"
                fill="black"
              />
              <path
                d="M13.3332 9.94455C14.1003 9.94455 14.7221 9.074 14.7221 8.00011C14.7221 6.92622 14.1003 6.05566 13.3332 6.05566C12.5662 6.05566 11.9443 6.92622 11.9443 8.00011C11.9443 9.074 12.5662 9.94455 13.3332 9.94455Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className={styles.bar}>
          {[...Array(20)].map((_, index) => (
            <div
              key={index}
              className={`${styles.section} ${
                index < 6
                  ? styles.red
                  : index < 14
                  ? styles.yellow
                  : styles.green
              }`}
            ></div>
          ))}

          {/* Moving marker */}
          <div
            className={styles.marker}
            style={{ left: `${(markerPosition / 20) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ConfidenceScoreBar;
