import React from "react";

const ConsiderationStageIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="118"
      height="119"
      viewBox="0 0 118 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M69.4401 85.1172C69.4401 85.1172 86.667 89.5785 91.8722 97.4298C97.0774 105.281 78.4588 116.84 78.4588 116.84C78.4588 116.84 67.5027 99.0754 69.4401 85.1172Z"
        fill="#02285E"
      />
      <path
        d="M76.2959 107.889C75.9128 107.344 75.8367 106.79 76.1156 106.275C76.4195 105.892 76.6368 105.47 76.8891 105.067C77.8977 103.457 79.1493 101.917 80.7985 100.922C81.6273 100.373 82.6109 99.9855 83.6242 100.25C83.1446 101.206 82.4774 101.633 81.7227 102.239C81.0544 102.764 80.417 103.328 79.8139 103.927C79.0386 104.668 78.3685 105.511 77.8222 106.433C77.7064 106.614 77.6548 106.83 77.5751 107.027C77.3322 107.53 76.91 107.715 76.2938 107.891L76.2959 107.889Z"
        fill="#022C51"
      />
      <path
        d="M27.044 117.947C32.6381 117.774 38.2509 117.895 43.846 117.898C55.4775 117.908 67.109 117.902 78.7406 117.882C78.6999 116.766 78.6539 115.605 78.6024 114.399C77.9436 98.9977 72.8557 90.0161 72.8557 90.0161C65.2282 83.8432 33.446 81.0622 31.0112 84.6141C28.4832 88.3029 22.8938 109.097 21.2378 118.161C23.1763 118.103 25.1143 118.007 27.044 117.947Z"
        fill="#02285E"
      />
      <path
        d="M69.1231 95.973C69.1231 95.973 64.2139 92.4617 58.5975 89.5356C52.981 86.6094 47.5126 84.043 47.5126 84.043L47.2368 83.3345C47.2368 83.3345 55.1364 82.3386 61.1162 82.6905C67.0959 83.0424 71.7382 84.7437 71.7382 84.7437L72.5159 85.4683C72.5159 85.4683 70.7958 91.2238 70.2062 95.6195C70.2062 95.6185 70.1421 96.5264 69.1231 95.973Z"
        fill="#F2C5A7"
      />
      <path
        d="M27.0442 117.947C30.8822 117.829 34.7285 117.848 38.5727 117.873C33.8382 115.995 28.5647 113.684 22.6913 110.854L21.2578 117.817C21.2578 117.817 21.4376 117.933 21.7822 118.143C23.5393 118.086 25.2954 118.001 27.0442 117.947Z"
        fill="#EB2701"
      />
      <path
        d="M70.0409 97.2277C70.0083 97.2277 69.9757 97.2251 69.9435 97.2199C69.8622 97.2072 69.7843 97.1787 69.7141 97.1359C69.6439 97.0931 69.5829 97.0369 69.5344 96.9705C69.486 96.9041 69.4512 96.8288 69.4319 96.749C69.4127 96.6691 69.4094 96.5863 69.4222 96.5051C69.4321 96.4432 70.4183 90.1822 71.9836 85.0598C72.0305 84.9005 72.1388 84.7664 72.2847 84.6868C72.4306 84.6073 72.6021 84.5889 72.7616 84.6357C72.9211 84.6826 73.0554 84.7907 73.135 84.9364C73.2146 85.0821 73.233 85.2535 73.1862 85.4127L73.1825 85.4257C71.6438 90.4613 70.6711 96.6379 70.6618 96.6983C70.6389 96.8461 70.5637 96.9809 70.4498 97.0781C70.3358 97.1752 70.1908 97.2283 70.0409 97.2277Z"
        fill="#0F0F14"
      />
      <path
        d="M73.8389 101.368H73.8185C73.7363 101.366 73.6552 101.347 73.5801 101.314C73.5049 101.28 73.4372 101.232 73.3806 101.172C73.3241 101.112 73.28 101.042 73.2508 100.965C73.2215 100.888 73.2077 100.806 73.2102 100.724C73.2165 100.523 73.3718 95.7195 74.8517 89.8505C74.8717 89.7708 74.9073 89.6958 74.9564 89.6298C75.0055 89.5638 75.0671 89.508 75.1377 89.4658C75.2084 89.4236 75.2867 89.3957 75.3681 89.3837C75.4496 89.3717 75.5326 89.3758 75.6124 89.3958C75.6923 89.4158 75.7674 89.4514 75.8335 89.5004C75.8996 89.5494 75.9554 89.611 75.9976 89.6815C76.0399 89.7521 76.0679 89.8303 76.0799 89.9116C76.0919 89.9929 76.0878 90.0759 76.0677 90.1556C74.6249 95.8861 74.4654 100.713 74.4644 100.762C74.4595 100.924 74.3914 101.078 74.2747 101.191C74.1579 101.305 74.0016 101.368 73.8389 101.368Z"
        fill="#012249"
      />
      <path
        d="M20.5586 53.5645C20.5586 53.5645 22.3116 59.283 16.374 63.0728C10.4365 66.8626 4.47805 64.913 4.47805 64.913L2.00732 56.2834C2.00732 56.2834 7.76089 57.3126 10.6721 56.2834C13.5833 55.2543 18.5732 48.7471 18.5732 48.7471"
        fill="#EB2701"
      />
      <path
        d="M69.8533 88.2534C60.7836 90.875 45.3811 92.1395 40.2818 90.0754C33.5055 87.3304 26.9784 81.3766 24.369 76.7961C21.8592 72.3852 13.8106 55.3796 23.1582 38.8102C31.8948 23.3215 58.2779 11.3675 80.3716 33.5909C86.2732 40.6837 90.6924 59.7384 87.3043 70.4513C85.1051 77.4031 78.6567 85.7073 69.8533 88.2534Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M19.5376 77.7814C19.5605 77.6898 19.5824 77.5987 19.6059 77.5065L19.5767 77.4092C19.5616 77.5326 19.5491 77.6575 19.5376 77.7814Z"
          fill="white"
        />
      </g>
      <path
        d="M35.094 55.037C31.9733 56.2177 30.041 59.2048 30.3219 62.3704C30.752 67.2034 33.6741 74.0167 38.2971 76.3905C44.6402 79.6457 49.6311 70.5403 61.1747 68.8942C73.1255 67.1904 74.4427 70.3961 80.804 66.7427C84.6529 64.5344 83.2961 47.8114 78.9014 46.4527C72.3123 44.4142 69.6961 50.9234 58.2448 53.0406C50.6012 54.454 40.2674 53.0828 35.094 55.037Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18.9583 49.2112C15.9351 44.7894 11.5373 45.9717 7.9636 46.7093C4.38991 47.447 0.969982 43.4297 0.969982 43.4297C-0.854394 45.9373 1.26657 54.7944 1.26657 54.7944C1.26657 54.7944 9.89744 52.7391 12.0997 52.7641C14.302 52.7891 18.8332 55.1859 18.8332 55.1859L19.1799 55.0646"
        fill="#EB2701"
      />
      <path
        d="M84.5441 41.8437C84.5441 41.8437 48.3871 54.6499 18.0618 55.3625C18.0618 55.3625 15.8543 55.4666 15.8501 51.5535C15.8459 47.6403 20.5643 47.4368 20.5643 47.4368C20.5643 47.4368 45.8793 44.8235 54.8459 42.9411C63.8124 41.0587 81.2503 35.7431 81.8394 35.7529C83.1164 35.7738 84.3789 39.3912 84.5441 41.8437Z"
        fill="#EB2701"
      />
      <path
        d="M57.8111 58.0284C55.4285 61.5684 51.7719 63.9219 47.6864 64.5403C43.9745 64.8761 42.1152 64.0989 39.7488 61.0467C37.6763 57.5558 37.8149 56.5625 37.8149 56.5625L57.8111 58.0284Z"
        fill="#EB2701"
      />
      <path
        d="M76.255 56.219C74.8998 58.7032 72.2805 60.102 69.5653 59.786C66.665 59.6819 63.8555 58.6725 61.4927 56.8833L76.0496 48.0527C76.0496 48.0527 76.9394 54.6698 76.255 56.219Z"
        fill="#EB2701"
      />
      <path
        d="M86.6525 76.8653C85.5849 75.871 78.559 77.7144 78.559 77.7144C78.559 77.7144 77.2038 73.2838 75.3001 73.2307C73.3965 73.1776 73.1255 84.5912 73.1255 84.5912C73.1255 84.5912 75.8631 90.3727 79.2502 92.4581C79.2502 92.4581 83.4369 91.8855 85.6652 89.6767C87.8936 87.4679 88.4961 84.0394 86.1865 80.387C86.1865 80.3875 87.72 77.8622 86.6525 76.8653Z"
        fill="#F2C5A7"
      />
      <path
        d="M79.8517 95.754L79.3018 95.2178L79.8418 95.7743L79.8517 95.754Z"
        fill="#3E4049"
      />
      <path
        d="M73.1361 83.7744C73.1361 83.7744 69.0266 86.2586 67.5369 86.8281C64.6934 87.914 63.04 87.8833 55.3615 87.0025C47.6829 86.1217 39.5045 85.737 36.5516 90.3342C34.5871 93.3947 34.0778 95.4879 36.1696 100.205C37.8412 103.972 42.8416 105.567 44.9099 106.317C50.4508 108.325 60.7934 105.637 67.4112 102.517C73.8695 99.4739 77.1263 97.0632 80.2929 92.2478L79.2634 91.6393C75.8732 89.557 73.1361 83.7744 73.1361 83.7744Z"
        fill="#00244F"
      />
      <path
        opacity="0.46"
        d="M60.3823 89.5561C61.9241 89.7862 63.5098 90.0715 65.0902 90.0423C67.9243 90.0257 70.8589 89.1876 73.0909 87.3733C73.3385 87.1651 74.4832 86.2801 74.4868 86.2036C74.3304 85.9496 73.8822 85.2104 73.5402 84.5586C73.4266 84.7897 72.2433 85.582 71.2879 86.2911C70.0562 87.2052 67.8476 88.5124 65.0673 88.5488C63.5614 88.5795 62.0962 88.3104 60.5939 88.0943C59.0661 87.8715 57.5274 87.6586 55.9741 87.5129C49.8958 86.8616 42.4341 87.4285 38.6107 92.9268C38.5573 93.0023 38.5339 93.0949 38.5453 93.1866C38.5566 93.2784 38.6018 93.3625 38.672 93.4228C38.7422 93.483 38.8323 93.5149 38.9248 93.5122C39.0173 93.5095 39.1055 93.4725 39.1721 93.4084C41.3342 91.3261 43.9551 89.9054 46.8757 89.263C51.2604 88.2859 55.9345 88.8986 60.3823 89.5561Z"
        fill="#02285E"
      />
      <g opacity="0.25">
        <path
          d="M88.0088 85.2441V85.2493L88.0134 85.2631L88.0088 85.2441Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M92.216 40.783C92.216 41.5603 92.8388 42.3375 93.7724 42.3375H99.9972C100.931 42.3375 101.553 41.5603 101.553 40.783V39.2291H92.216V40.783ZM96.8858 11.2539C90.8169 11.2539 85.9917 16.0718 85.9917 22.1339C85.9917 25.8639 87.8588 29.1279 90.66 30.9931V34.5674C90.66 35.3446 91.2824 36.1218 92.216 36.1218H101.553C102.487 36.1218 103.11 35.3446 103.11 34.5674V30.9921C105.911 28.9712 107.78 25.7077 107.78 22.1329C107.78 16.0713 102.954 11.2539 96.8858 11.2539Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M97.232 0.280273H96.5054C96.1599 0.280273 95.8799 0.559957 95.8799 0.904964V7.4663C95.8799 7.81131 96.1599 8.09099 96.5054 8.09099H97.232C97.5775 8.09099 97.8575 7.81131 97.8575 7.4663V0.904964C97.8575 0.559957 97.5775 0.280273 97.232 0.280273Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M85.6811 3.53435L85.1188 3.99398C84.8515 4.2125 84.8121 4.60608 85.0309 4.87306L89.1922 9.95049C89.411 10.2175 89.805 10.2568 90.0724 10.0382L90.6347 9.57859C90.902 9.36007 90.9413 8.9665 90.7225 8.69952L86.5613 3.62209C86.3425 3.35511 85.9484 3.31582 85.6811 3.53435Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M103.284 9.13005L103.846 9.58968C104.113 9.80821 104.507 9.76892 104.726 9.50194L108.887 4.42451C109.106 4.15753 109.067 3.76396 108.8 3.54544L108.237 3.0858C107.97 2.86728 107.576 2.90657 107.357 3.17355L103.196 8.25097C102.977 8.51795 103.016 8.91153 103.284 9.13005Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M107.542 13.704L107.79 14.3863C107.908 14.7107 108.266 14.8784 108.591 14.7609L114.768 12.5265C115.093 12.409 115.261 12.0508 115.143 11.7264L114.896 11.0441C114.778 10.7197 114.42 10.552 114.095 10.6695L107.918 12.9039C107.593 13.0214 107.425 13.3796 107.542 13.704Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M78.6005 10.7528L78.2791 11.4036C78.1263 11.713 78.2536 12.0876 78.5634 12.2402L84.4557 15.1422C84.7656 15.2948 85.1406 15.1676 85.2934 14.8582L85.6148 14.2073C85.7676 13.8979 85.6403 13.5234 85.3304 13.3708L79.4381 10.4688C79.1283 10.3162 78.7533 10.4433 78.6005 10.7528Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M117.77 20.7986V20.0729C117.77 19.7279 117.49 19.4482 117.145 19.4482H110.575C110.229 19.4482 109.949 19.7279 109.949 20.0729V20.7986C109.949 21.1436 110.229 21.4233 110.575 21.4233H117.145C117.49 21.4233 117.77 21.1436 117.77 20.7986Z"
        fill="#FFA600"
      />
      <path
        opacity="0.5"
        d="M83.7886 20.7986V20.0729C83.7886 19.7279 83.5085 19.4482 83.1631 19.4482H76.5932C76.2478 19.4482 75.9677 19.7279 75.9677 20.0729V20.7986C75.9677 21.1436 76.2478 21.4233 76.5932 21.4233H83.1631C83.5085 21.4233 83.7886 21.1436 83.7886 20.7986Z"
        fill="#FFA600"
      />
    </svg>
  );
};

export default ConsiderationStageIcon;
