import React from "react";

const UpsellCrossSellCampaignsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.25 20V8.75H0V6.25H13.75V20H11.25ZM17.5 13.75V2.5H6.25V0H20V13.75H17.5Z"
        fill="#EB3223"
      />
    </svg>
  );
};

export default UpsellCrossSellCampaignsIcon;
