import React, { useState, useRef, useEffect } from "react";

import styles from "./NurtureStrategyQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EducationalContentDistribution from "./componenets/EducationalContentDistribution/EducationalContentDistribution";
import ProductDemoInvitations from "./componenets/ProductDemoInvitations/ProductDemoInvitations";
import CustomerTestimonialsDistribution from "./componenets/CustomerTestimonialsDistribution/CustomerTestimonialsDistribution";
import OverallExpectedResults from "./componenets/OverallExpectedResults/OverallExpectedResults";
import { SyncLoader } from "react-spinners";
import {
  setNurtureContentData,
  setNurtureStrategyData,
} from "../../redux/interestStageSlice";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface NurtureStrategyQueryChatProps {
  className?: string;
}

const NurtureStrategyQueryChat: React.FC<NurtureStrategyQueryChatProps> = (
  props
) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const { nurtureStrategyData, nurtureContentData } = useAppSelector(
    (state) => state.interestStage
  );

  const navigate = useNavigate();

  const addOverallExpectedResults = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <OverallExpectedResults
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addCustomerTestimonialsDistribution = (
    type?: "strategy" | "content"
  ) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <CustomerTestimonialsDistribution
            cardType="pinable"
            className={styles["card"]}
            type={type}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addProductDemoInvitations = (type?: "strategy" | "content") => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ProductDemoInvitations
            cardType="pinable"
            className={styles["card"]}
            type={type}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addEducationalContentDistribution = (type?: "strategy" | "content") => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <EducationalContentDistribution
            cardType="pinable"
            className={styles["card"]}
            type={type}
          />
        ),
      },
      ...pC,
    ]);
  };

  const handleCreateStrategy = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs.strategy}
            message="By leveraging insights from our known contacts, we've developed a series of targeted engagements tailored to your leads' behaviors and interactions. Let's dive into how this campaign will educate, engage, and ultimately convert your leads with personalized and relevant content."
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      addEducationalContentDistribution("strategy");
      addProductDemoInvitations("strategy");
      addCustomerTestimonialsDistribution("strategy");
      addOverallExpectedResults();
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["card"], styles["continue-button"])}
              onClick={() => {
                dispatch(
                  setNurtureStrategyData({
                    educationalContentDistribution: true,
                    productDemoInvitation: true,
                    customerTestimonialsDistribution: true,
                    overallExpectedResults: true,
                  })
                );
                handleCreateContent();
              }}
            >
              <TextElement>Pin all and Continue 👍🏻</TextElement>
            </Button>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message="Happy with the nurture strategy I crafted? You can pin all and submit for approval."
            />
          ),
        },
        ...pC,
      ]);
      handleScroll("strategy");
    }, 2000);
  };

  const handleCreateContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs.content}
            message="I’m excited to share our six-week content strategy designed to transform our Known Contacts into Marketing Qualified Leads (MQLs). By providing valuable educational materials, live demos, and compelling customer stories, we aim to engage and build trust with our leads. Each piece of content is crafted to move our audience closer to making informed purchasing decisions."
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      addEducationalContentDistribution("content");
      addProductDemoInvitations("content");
      addCustomerTestimonialsDistribution("content");
      addOverallExpectedResults();
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["card"], styles["continue-button"])}
              onClick={() => {
                dispatch(
                  setNurtureContentData({
                    educationalContentDistribution: true,
                    productDemoInvitation: true,
                    customerTestimonialsDistribution: true,
                  })
                );
              }}
            >
              <TextElement>Pin all and Submit for Approval 👍🏻</TextElement>
            </Button>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message="Happy with the content I generated? You can pin all and submit for approval."
            />
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message="By creating and distributing this content across the specified channels, we can effectively nurture our leads, providing them with valuable information, showcasing our solutions, and building trust through customer testimonials."
            />
          ),
        },
        ...pC,
      ]);
      handleScroll("content");
    }, 2000);
  };
  
  useEffect(() => {
    handleCreateStrategy();
  }, []);

  const [conversation, setConversation] = useState<Question[]>([]);

  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<HTMLDivElement>(null);

  const scrollRefs = {
    strategy: useRef<HTMLDivElement>(null),
    content: useRef<HTMLDivElement>(null),
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleScroll = (id: keyof typeof scrollRefs) => {
    const ref = scrollRefs[id];
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };



  useEffect(() => {
    if (scrollRefs?.strategy?.current) {
      scrollToRef(scrollRefs.strategy);
    }
    if (scrollRefs?.content?.current) {
      scrollToRef(scrollRefs.content);
    }
  }, [scrollRefs?.strategy?.current, scrollRefs?.content?.current]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}
          {conversation?.map((item: Question, index: number) => (
            <div key={index}>{item.content}</div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default NurtureStrategyQueryChat;
