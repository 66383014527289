import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DateCapturedValues, KeyMetrics } from "./types";
import HttpService from "../../lib/api";
import { Product } from "../MarketingDashboardReport/types";
import { OpportunityStage } from "../../pages/RecommendedActionsForSale/useRecommendedActionsForSale";

interface Props {
  product: string;
  dateCaptured: DateCapturedValues | "";
  opportunityStage: OpportunityStage | "";
  opportunityType: string;
}

const useMarketingDashboardReport = (input: Props) => {
  const { product, dateCaptured, opportunityStage, opportunityType } = input;
  const [products, setProducts] = useState<Product[]>([]);

  const dateCapturedOptions: { label: string; value: DateCapturedValues }[] = [
    { label: "Date Captured", value: "" }, // Default / no filter
    { label: "Last Month", value: "1 month" },
    { label: "Last 2 Months", value: "2 months" },
    { label: "Last 3 Months", value: "3 months" },
    { label: "Last 4 Months", value: "4 months" },
    { label: "Last 5 Months", value: "5 months" }, // Fixed from "5 months" to "6 months"
  ];

  const [metrics, setMetrics] = useState<KeyMetrics>({
    totalEstimatedRevenue: 0,
    totalActualRevenue: 0,
    avgSizeClosedWonDeals: 0,
    winRate: 0,
    winLossRatio: 0,
    avgSalesCycleLength: 0,
    totalClosedWonDeals: 0,
    totalOpenOpportunities: 0,
    totalNumberOfSalesActivities: 0,
    averageTouchpointsPerOpportunity: 0,
    averageActivitiesToCloseADeal: 0,
    averageActivitiesBySalesRep: 0,
  });

  const [opportunityTypeOptions, setOpportunityTypeOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const [stalledOpportunities, setStalledOpportunities] = useState<{
    [key in OpportunityStage]: number;
  }>({
    Consideration: 0,
    Evaluation: 0,
    Decision: 0,
  });

  const [
    predictionOfStalledOpportunities,
    setPredictionOfStalledOpportunities,
  ] = useState<{
    considerationAtRisk?: number;
    evaluationAtRisk?: number;
    decisionLikelyToClose?: number;
  }>({
    considerationAtRisk: 0,
    evaluationAtRisk: 0,
    decisionLikelyToClose: 0,
  });

  const [pipelineStageDistribution, setPipelineStageDistribution] = useState<
    (string | number)[][]
  >([]);
  const [revenueTrends, setRevenueTrends] = useState<(string | number)[][]>([]);

  const [stageConversionRate, setStageConversionRate] = useState<
    (string | number)[][]
  >([]);
  const [distributionByWinLossStatus, setDistributionByWinLossStatus] =
    useState<(string | number)[][]>([]);
  const [distributionByOpportunityType, setDistributionByOpportunityType] =
    useState<(string | number)[][]>([]);
  const [distributionByDealSize, setDistributionByDealSize] = useState<
    (string | number)[][]
  >([]);
  const [distributionByIndustrySegment, setDistributionByIndustrySegment] =
    useState<(string | number)[][]>([]);
  const [distributionByProduct, setDistributionByProduct] = useState<
    (string | number)[][]
  >([]);
  const [distributionBySalesRep, setDistributionBySalesRep] = useState<
    (string | number)[][]
  >([]);

  const httpService = new HttpService();

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsResponse = await executeRawQuery<Product[]>(
          "SELECT * FROM public.product;"
        );
        setProducts(productsResponse);
      } catch (error) {
        console.error("Error fetching products", error);
        toast.error("Error fetching products");
      }
    };

    const fetchOpportunityTypes = async () => {
      try {
        const opportunityTypesResponse = await executeRawQuery<
          { opportunityType: string }[]
        >("SELECT DISTINCT opportunity_type FROM public.opportunities;");
        setOpportunityTypeOptions(
          opportunityTypesResponse.map((item) => ({
            label: item.opportunityType,
            value: item.opportunityType,
          }))
        );
      } catch (error) {
        console.error("Error fetching opportunity types", error);
        toast.error("Error fetching opportunity types");
      }
    };

    fetchProducts();
    fetchOpportunityTypes();
  }, []);

  useEffect(() => {
    const productFilter = product ? `AND product_id = '${product}'` : "";
    const dateFilter = dateCaptured
      ? `AND created_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
      : "";
    const opportunityStageFilter = opportunityStage
      ? `AND opportunity_stage = '${opportunityStage}'`
      : "";
    const opportunityTypeFilter = opportunityType
      ? `AND opportunity_type = '${opportunityType}'`
      : "";

    const filters = [
      productFilter,
      dateFilter,
      opportunityStageFilter,
      opportunityTypeFilter,
    ].join(" ");

    const fetchKeyFindings = async () => {
      try {
        const query1 = `
          WITH sales_cycle AS (
            SELECT AVG(DATE_PART('day', closed_date - created_date)) AS avg_sales_cycle_length
            FROM opportunities
            WHERE win_loss_status = 'Closed Won' ${filters}
          )
          SELECT 
            SUM(estimated_revenue) AS total_estimated_revenue,
            SUM(actual_revenue) FILTER (WHERE win_loss_status = 'Closed Won') AS total_actual_revenue,
            AVG(actual_revenue) FILTER (WHERE win_loss_status = 'Closed Won') AS avg_size_closed_won_deals,
            (COUNT(*) FILTER (WHERE win_loss_status = 'Closed Won')::decimal / 
             NULLIF(COUNT(*) FILTER (WHERE win_loss_status IN ('Closed Won', 'Closed Lost')), 0)) * 100 AS win_rate,
            (COUNT(*) FILTER (WHERE win_loss_status = 'Closed Won')::decimal / 
             NULLIF(COUNT(*) FILTER (WHERE win_loss_status in ('Closed Lost', 'Consideration', 'Evaluation', 'Decision')), 0)) AS win_loss_ratio,
            (SELECT avg_sales_cycle_length FROM sales_cycle) AS avg_sales_cycle_length
          FROM opportunities
          WHERE 1=1 ${filters};
        `;

        const query2 = `
          SELECT 
            COUNT(CASE WHEN win_loss_status = 'Closed Won' THEN 1 END) AS total_closed_won_deals,
            COUNT(CASE WHEN win_loss_status NOT IN ('Closed Won', 'Closed Lost') THEN 1 END) AS total_open_opportunities
          FROM opportunities
          WHERE 1=1 ${filters};
        `;

        const query3 = `
          SELECT 
            COUNT(*) AS total_number_of_sales_activities,
            AVG(Activity_Count) AS average_touchpoints_per_opportunity
          FROM (
            SELECT opportunity_id, COUNT(*) AS Activity_Count
            FROM sales_activities
            WHERE opportunity_id IN (
              SELECT opportunity_id
              FROM opportunities
              WHERE 1=1 ${filters}
            )
            GROUP BY opportunity_id
          ) AS Opportunity_Activities;
        `;

        const query4 = `
          SELECT 
            AVG(Activity_Count) AS average_activities_to_close_a_deal,
            (SELECT AVG(Rep_Activity_Count)
             FROM (SELECT activity_owner, COUNT(*) AS Rep_Activity_Count
                   FROM sales_activities
                   WHERE opportunity_id IN (
                     SELECT opportunity_id
                     FROM opportunities
                     WHERE 1=1 ${filters}
                   )
                   GROUP BY activity_owner) AS Rep_Activities) AS average_activities_by_sales_rep
          FROM (
            SELECT opportunity_id, COUNT(*) AS Activity_Count
            FROM sales_activities
            WHERE opportunity_id IN (
              SELECT opportunity_id
              FROM opportunities
              WHERE win_loss_status IN ('Closed Won', 'Closed Lost') ${filters}
            )
            GROUP BY opportunity_id
          ) AS Closed_Opportunity_Activities;
        `;

        // Execute queries in parallel
        const [response1, response2, response3, response4] = await Promise.all([
          executeRawQuery<
            {
              totalEstimatedRevenue: number;
              totalActualRevenue: number;
              avgSizeClosedWonDeals: number;
              winRate: number;
              winLossRatio: number;
              avgSalesCycleLength: number;
            }[]
          >(query1),
          executeRawQuery<
            {
              totalClosedWonDeals: number;
              totalOpenOpportunities: number;
            }[]
          >(query2),
          executeRawQuery<
            {
              totalNumberOfSalesActivities: number;
              averageTouchpointsPerOpportunity: number;
            }[]
          >(query3),
          executeRawQuery<
            {
              averageActivitiesToCloseADeal: number;
              averageActivitiesBySalesRep: number;
            }[]
          >(query4),
        ]);

        // Update state with the results
        setMetrics({
          totalEstimatedRevenue: response1[0]?.totalEstimatedRevenue || 0,
          totalActualRevenue: response1[0]?.totalActualRevenue || 0,
          avgSizeClosedWonDeals: response1[0]?.avgSizeClosedWonDeals || 0,
          winRate: response1[0]?.winRate || 0,
          winLossRatio: response1[0]?.winLossRatio || 0,
          avgSalesCycleLength: response1[0]?.avgSalesCycleLength || 0,
          totalClosedWonDeals: response2[0]?.totalClosedWonDeals || 0,
          totalOpenOpportunities: response2[0]?.totalOpenOpportunities || 0,
          totalNumberOfSalesActivities:
            response3[0]?.totalNumberOfSalesActivities || 0,
          averageTouchpointsPerOpportunity:
            response3[0]?.averageTouchpointsPerOpportunity || 0,
          averageActivitiesToCloseADeal:
            response4[0]?.averageActivitiesToCloseADeal || 0,
          averageActivitiesBySalesRep:
            response4[0]?.averageActivitiesBySalesRep || 0,
        });
      } catch (error) {
        console.error("Error fetching key findings:", error);
        toast.error("Error fetching key findings");
      }
    };
    
    const fetchOpportunitiesData = async () => {
      try {
        const filters = `
          ${product ? `AND product_id = '${product}'` : ""}
          ${
            dateCaptured
              ? `AND created_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
              : ""
          }
          ${
            opportunityType ? `AND opportunity_type = '${opportunityType}'` : ""
          }
        `;

        const considerationQuery = `
              SELECT 
              opportunity_stage, 
              COUNT(*) AS pipeline_stage_count,
              COUNT(CASE WHEN risk_score > 15 THEN 1 END) AS counted_in_result,
                  100.0 * COUNT(CASE WHEN risk_score > 15 THEN 1 END) / COUNT(*) AS percentage
              FROM 
              opportunities
              WHERE 
                (opportunity_stage = 'Consideration') ${filters}
              GROUP BY 
              opportunity_stage;
        `;

        // Query for Evaluation stage
        const evaluationQuery = `
          SELECT 
            opportunity_stage, 
            COUNT(*) AS pipeline_stage_count,
            COUNT(CASE WHEN risk_score > 35 THEN 1 END) AS counted_in_result,
              100.0 * COUNT(CASE WHEN risk_score > 35 THEN 1 END) / COUNT(*) AS percentage
            FROM 
              opportunities
            WHERE 
                (opportunity_stage = 'Evaluation') ${filters}
              GROUP BY 
            opportunity_stage;
        `;

        // Query for Decision stage
        const decisionQuery = `
          SELECT 
            opportunity_stage, 
            COUNT(*) AS pipeline_stage_count,
            COUNT(CASE WHEN confidence_score > 70 THEN 1 END) AS counted_in_result,
              100.0 * COUNT(CASE WHEN confidence_score > 70 THEN 1 END) / COUNT(*) AS percentage
            FROM 
            opportunities
            WHERE 
                (opportunity_stage = 'Decision')
              ${filters}
          GROUP BY 
          opportunity_stage;
        `;

        // Execute queries and fetch results
        const considerationResponse = await executeRawQuery<
          {
            pipelineStageCount: number;
            percentage: number;
          }[]
        >(considerationQuery);

        const evaluationResponse = await executeRawQuery<
          {
            pipelineStageCount: number;
            percentage: number;
          }[]
        >(evaluationQuery);

        const decisionResponse = await executeRawQuery<
          {
            pipelineStageCount: number;
            percentage: number;
          }[]
        >(decisionQuery);

        
        setStalledOpportunities({
          Consideration: considerationResponse[0]?.pipelineStageCount || 0,
          Evaluation: evaluationResponse[0]?.pipelineStageCount || 0,
          Decision: decisionResponse[0]?.pipelineStageCount || 0,
        });

        setPredictionOfStalledOpportunities({
          considerationAtRisk: considerationResponse[0]?.percentage || 0,
          evaluationAtRisk: evaluationResponse[0]?.percentage || 0,
          decisionLikelyToClose: decisionResponse[0]?.percentage || 0,
        });


      } catch (error) {
        console.error("Error fetching opportunities data:", error);
        toast.error("Error fetching opportunities data");
      }
    };

    const fetchPipelineStageDistribution = async () => {
      try {
        const query = `
         SELECT opportunity_stage, COUNT(*) AS pipeline_stage_count
      FROM opportunities
      WHERE 1=1 ${filters}
      GROUP BY opportunity_stage;
        `;

        const response = await executeRawQuery<
          {
            opportunityStage: string;
            pipelineStageCount: number;
          }[]
        >(query);
        setPipelineStageDistribution([
          ["Pipeline Stage", "Distribution"],
          ...response.map((item) => [
            item.opportunityStage,
            item.pipelineStageCount,
          ]),
        ]);
      } catch (error) {
        console.error("Error fetching pipeline stage distribution:", error);
        toast.error("Error fetching pipeline stage distribution");
      }
    };

    const fetchRevenueTrends = async () => {
      try {
        const query = `
          SELECT 
            TO_CHAR(created_date, 'FMMon') AS month,  -- Format as 'Month Year'
            SUM(estimated_revenue) AS total_estimated_revenue,
            SUM(actual_revenue) AS total_actual_revenue
          FROM opportunities
          WHERE 1=1 ${filters}
          GROUP BY month
          ORDER BY MIN(created_date);  -- Ensures correct ordering by actual month
        `;

        const response = await executeRawQuery<
          {
            month: string;
            totalEstimatedRevenue: number;
            totalActualRevenue: number;
          }[]
        >(query);

        setRevenueTrends([
          ["Month", "Actual Revenue", "Estimated Revenue"],
          ...response.map((item) => [
            item.month,
            item.totalActualRevenue,
            item.totalEstimatedRevenue,
          ]),
        ]);
      } catch (error) {
        console.error("Error fetching revenue trends:", error);
        toast.error("Error fetching revenue trends");
      }
    };

    const fetchStageConversionRate = async () => {
      try {
        const query = `
         SELECT 
            opportunity_stage,
            win_loss_status,
            COUNT(*) AS opportunity_count
        FROM 
            Opportunities
	      where opportunity_stage not in ('Closed Lost','Closed Won') ${filters}
        GROUP BY 
          opportunity_stage, 
          win_loss_status
        ORDER BY 
          opportunity_stage DESC,
          win_loss_status DESC;
        `;

        const response = await executeRawQuery<
          {
            opportunityStage: string;
            winLossStatus: string;
            opportunityCount: number;
          }[]
        >(query);
        setStageConversionRate([
          ["From", "To", "Count"],
          ...response.map((item) => [
            item.opportunityStage,
            item.winLossStatus,
            item.opportunityCount,
          ]),
        ]);
      } catch (error) {
        console.error("Error fetching stage conversion rate:", error);
        toast.error("Error fetching stage conversion rate");
      }
    };

    const fetchDistributionByWinLossStatus = async () => {
      try {
        const query = `
         SELECT 
          win_loss_status,
          COUNT(*) AS opportunity_count
            FROM 
          Opportunities
	        where opportunity_stage not in ('Closed Lost','Closed Won') ${filters}
          GROUP BY 
            win_loss_status;
        `;

        const response = await executeRawQuery<
          {
            winLossStatus: string;
            opportunityCount: number;
          }[]
        >(query);
        setDistributionByWinLossStatus([
          ["Win/Loss Status", "Count"],
          ...response.map((item) => [
            item.winLossStatus,
            item.opportunityCount,
          ]),
        ]);
      } catch (error) {
        console.error("Error fetching distribution by win/loss status:", error);
        toast.error("Error fetching distribution by win/loss status");
      }
    };

    const fetchDistributionByOpportunityType = async () => {
      try {
        const query = `
          	SELECT 
		opportunity_type,
		COUNT(*) AS opportunity_count
	FROM 
		Opportunities
		where opportunity_stage not in ('Closed Lost','Closed Won') ${filters}
	GROUP BY 
		opportunity_type;
        `;

        const response = await executeRawQuery<
          {
            opportunityType: string;
            opportunityCount: number;
          }[]
        >(query);
        setDistributionByOpportunityType([
          ["Opportunity Type", "Count"],
          ...response.map((item) => [
            item.opportunityType,
            item.opportunityCount,
          ]),
        ]);
      } catch (error) {
        console.error(
          "Error fetching distribution by opportunity type:",
          error
        );
        toast.error("Error fetching distribution by opportunity type");
      }
    };

    const fetchDistributionByDealSize = async () => {
      try {
        const query = `
         	SELECT 
		deal_size,
		COUNT(*) AS opportunity_count
	FROM 
		Opportunities
		where opportunity_stage not in ('Closed Lost','Closed Won') ${filters}
	GROUP BY 
		deal_size;

        `;

        const response = await executeRawQuery<
          {
            dealSize: string;
            opportunityCount: number;
          }[]
        >(query);
        setDistributionByDealSize([
          ["Deal Size", "Count"],
          ...response.map((item) => [item.dealSize, item.opportunityCount]),
        ]);
      } catch (error) {
        console.error("Error fetching distribution by deal size:", error);
        toast.error("Error fetching distribution by deal size");
      }
    };

    const fetchDistributionByIndustrySegment = async () => {
      try {
        const query = `
        SELECT 
            i.industry_segment_name, 
            SUM(o.actual_revenue) AS revenue
        FROM 
            opportunities o
        JOIN 
          industry_segment i ON o.industry_segment_id = i.industry_segment_id
        WHERE 
          o.win_loss_status = 'Closed Won' ${filters}
      GROUP BY 
          i.industry_segment_id, i.industry_segment_name;
        `;

        const response = await executeRawQuery<
          {
            industrySegmentName: string;
            revenue: number;
          }[]
        >(query);
        setDistributionByIndustrySegment([
          ["Industry Segment", "Revenue"],
          ...response.map((item) => [item.industrySegmentName, item.revenue]),
        ]);
      } catch (error) {
        console.error(
          "Error fetching distribution by industry segment:",
          error
        );
        toast.error("Error fetching distribution by industry segment");
      }
    };

    const fetchDistributionByProduct = async () => {
      const filters = [
        product ? `AND o.product_id = '${product}'` : "",
        dateCaptured
          ? `AND created_date >= CURRENT_DATE - INTERVAL '${dateCaptured}'`
          : "",
        opportunityStage ? `AND opportunity_stage = '${opportunityStage}'` : "",
        opportunityType ? `AND opportunity_type = '${opportunityType}'` : "",
      ].join(" ");

      try {
        const query = `
            SELECT 
              p.product_name,  
              SUM(o.actual_revenue) AS revenue_by_product
            FROM 
              opportunities o
            JOIN 
              product p ON o.product_id = p.product_id
            WHERE 
              o.win_loss_status = 'Closed Won' ${filters}
            GROUP BY 
              p.product_name  
            ORDER BY 
              revenue_by_product DESC;  
        `;

        const response = await executeRawQuery<
          {
            productName: string;
            revenueByProduct: number;
          }[]
        >(query);
        setDistributionByProduct([
          ["Product", "Revenue"],
          ...response.map((item) => [item.productName, item.revenueByProduct]),
        ]);
      } catch (error) {
        console.error("Error fetching distribution by product:", error);
        toast.error("Error fetching distribution by product");
      }
    };

    const fetchDistributionBySalesRep = async () => {
      try {
        const query = `
         SELECT opportunity_owner AS sales_rep, SUM(actual_revenue) AS revenue_by_sales_rep
            FROM Opportunities
            WHERE win_loss_status = 'Closed Won' ${filters}
            GROUP BY sales_rep;
        `;

        const response = await executeRawQuery<
          {
            salesRep: string;
            revenueBySalesRep: number;
          }[]
        >(query);
        setDistributionBySalesRep([
          ["Sales Rep", "Revenue"],
          ...response.map((item) => [item.salesRep, item.revenueBySalesRep]),
        ]);
      } catch (error) {
        console.error("Error fetching distribution by sales rep:", error);
        toast.error("Error fetching distribution by sales rep");
      }
    };

    fetchKeyFindings();
    fetchPipelineStageDistribution();
    fetchRevenueTrends();
    fetchStageConversionRate();
    fetchDistributionByWinLossStatus();
    fetchDistributionByOpportunityType();
    fetchDistributionByDealSize();
    fetchDistributionByIndustrySegment();
    fetchDistributionByProduct();
    fetchDistributionBySalesRep();
    fetchOpportunitiesData();
  }, [product, dateCaptured, opportunityStage, opportunityType]);

  return {
    products,
    metrics,
    pipelineStageDistribution,
    revenueTrends,
    stageConversionRate,
    distributionByWinLossStatus,
    distributionByOpportunityType,
    distributionByDealSize,
    distributionByIndustrySegment,
    distributionByProduct,
    distributionBySalesRep,
    dateCapturedOptions,
    stalledOpportunities,
    opportunityTypeOptions,
    predictionOfStalledOpportunities,
  };
};

export default useMarketingDashboardReport;
