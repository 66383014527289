import React from "react";

const MainFunnelIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M8.27778 13.6668H12.7222V11.4446H8.27778V13.6668ZM0.5 0.333496V2.55572H20.5V0.333496H0.5ZM3.83333 8.11127H17.1667V5.88905H3.83333V8.11127Z"
        fill="#FFF6EB"
      />
    </svg>
  );
};

export default MainFunnelIcon;
