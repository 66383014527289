import React from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import TwoStarIcon from "../../../icons/TwoStarIcon";
import { IoFlash } from "react-icons/io5";
import { CiAt } from "react-icons/ci";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

const FollowUpCard = () => {
  return (
    <div className="p-3 bg-white rounded-lg shadow-md border border-gray-200 mt-2">
      {/* Stage Header */}
      <div className="pb-2 rounded-t-lg">
        <h2 className="text-blue-primary font-bold">
          Stage 2: Follow-Up After Call
        </h2>
      </div>

      {/* Entry Section */}
      <div className="">
        <div className="flex items-center">
          <div className="bg-green-500 border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full ">
              <FaArrowRight className="text-green-500 w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Entry</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(56, 176, 90, 0.05)" }}>
          <p className="">
            Triggered after the customer schedules and completes the call.
          </p>
        </div>
      </div>

      {/* Action Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#18A2F2] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <TwoStarIcon
                className="text-[#18A2F2] w-[12px] h-[12px]"
                fill="#18A2F2"
              />
            </div>

            <h3 className="text-white font-medium ml-2">Action</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(56, 176, 90, 0.05)" }}>
          <p className="">
            Send an email inviting the customer to schedule a call.
          </p>

          <div className="bg-white rounded-lg p-[5px]">
            <div className="  flex items-center pb-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <CiAt className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Subject</h3>
            </div>
            <div className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm">
              <p>Thank You for Connecting - Here’s What’s Next</p>
            </div>

            <div className="  flex items-center py-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <HiOutlineMenuAlt2 className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Body</h3>
            </div>
            <div className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm">
              <p>
                Hi [Customer's First Name],
                <br />
                <br />
                Thank you for discussing your needs with us! We’re excited to
                support you with the 45-day payment extension option we
                discussed. Here’s a quick recap of our conversation and the next
                steps to activate this option.
                <br />
                <br />
                [[Review Next Steps] ]
                <br />
                <br />
                If you have any questions, don’t hesitate to reach out.
                <br />
                <br />
                Sincerely,
                <br />
                Infotech Consultancy Team
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Trigger Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#FFA600] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <IoFlash className="text-[#FFA600] w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Trigger</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(255, 166, 0, 0.05)" }}>
          <p className="">
            Send a follow-up email after the call to recap the discussion and
            outline the next steps for activating the 45-day payment extension.
          </p>
        </div>
      </div>

      {/* Check Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#EB6301] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <FaCheck className="text-[#EB6301] w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Check</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(235, 99, 1, 0.05)" }}>
          <ul style={{ listStyle: "disc", margin: "auto", padding: "inherit" }}>
            <li>
              <b>Condition:</b> Customer reviews the "Next Steps" page or takes
              action to proceed.
            </li>
            <li>
              <b>OnTrue:</b> Exit the journey.
            </li>
            <li>
              <b>OnFalse:</b> If the customer does not review the "Next Steps"
              or respond, they may be moved to a re-engagement campaign or
              flagged for further follow-up (as per your re-engagement or
              retention process).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FollowUpCard;
