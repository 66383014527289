import React, { useState, useEffect } from "react";

import styles from "./JourneyUIView.module.scss";
import { useLocation } from "react-router-dom";
import {
  CustomEdge,
  CustomNode,
} from "../../../componenets/ReactFlowContainer/types";
import useJourneyManagement from "../useJourneyManagment";
import VisualizeUserJourney from "../../../componenets/VisualizeUserJourney/VisualizeUserJourney";

// Example usage

const JourneyUIView: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);


  const { selectedJourney, handleSaveJourney, fetchJourneyById } =
    useJourneyManagement();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id) fetchJourneyById(parseInt(id));
  }, [id]);

  return (
    <div className={styles["journey-management-container"]}>
      {selectedJourney && <VisualizeUserJourney journey={selectedJourney} />}
    </div>
  );
};

export default JourneyUIView;
