import React, { useEffect } from "react";

import styles from "./InterestStageLayoutHeader.module.scss";
import classNames from "classnames";

import { useNavigate } from "react-router-dom";

import { LuHome } from "react-icons/lu";
import { useAppDispatch } from "../../../../redux/hooks";
import { ROUTE_DEFINITIONS } from "../../../../pages/AppRoutes";
import TextElement from "../../../TextElement/TextElement";
import Button from "../../../Button/Button";

type Props = {
  className?: string;
};

const InterestStageLayoutHeader: React.FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={classNames(styles["container"], className)}>
      <button
        className={styles["logo-container"]}
        onClick={() =>
          navigate(ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path)
        }
      >
        <LuHome />
        <TextElement>Back</TextElement>
      </button>
      <TextElement>Nurture Strategy</TextElement>
      <div className={styles["save-share-container"]}>
        <TextElement className={styles["update-status-text"]}>
          All changes saved
        </TextElement>

        <Button className={styles["share-button"]} variant="outline">
          Share
        </Button>
      </div>
    </div>
  );
};

export default InterestStageLayoutHeader;
