import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  includeData: {
    preparation: boolean;
    initialContact: boolean;
    bantQualification: boolean;
    handlingObjections: boolean;
    qualificationAndHandover: boolean;
    keyMetricsToMonitor: boolean;
    continuousImprovement: boolean;
  };
}

const initialState: State = {
  includeData: {
    preparation: false,
    initialContact: false,
    bantQualification: false,
    handlingObjections: false,
    qualificationAndHandover: false,
    keyMetricsToMonitor: false,
    continuousImprovement: false,
  },
};

const tailoredStrategyforBANTQualificationSlice = createSlice({
  name: "tailoredStrategyforBANTQualificationSlice",
  initialState,
  reducers: {
    updateTailoredStrategyforBANTQualification: (
      state,
      action: PayloadAction<State["includeData"]>
    ) => {
      state.includeData = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateTailoredStrategyforBANTQualification } =
  tailoredStrategyforBANTQualificationSlice.actions;

export default tailoredStrategyforBANTQualificationSlice.reducer;
