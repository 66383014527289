import React, { useState, useEffect } from "react";

import styles from "./JourneyManagment.module.scss";
import useJourneyManagement from "./useJourneyManagment";
import { useLocation, useParams } from "react-router-dom";
import ReactFlowContainer from "../../componenets/ReactFlowContainer/ReactFlowContainer";
import JourneyTreeHeader from "./JourneyTreeHeader/JourneyTreeHeader";

const JourneyTreeView: React.FC = () => {

  const { selectedJourney, handleSaveJourney, fetchJourneyById } =
    useJourneyManagement();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id) fetchJourneyById(parseInt(id));
  }, [id]);

  return (
    <div className={styles["journey-tree-view"]}>
      <JourneyTreeHeader
        defaultCampaignName={selectedJourney?.title || "Journey Blueprint"}
      />
      {selectedJourney?.blueprint && (
        <ReactFlowContainer
          journeyBlueprint={selectedJourney?.blueprint}
          updatedJourneyBlueprint={(nodes, edges) => {
            if (selectedJourney)
              handleSaveJourney({
                ...selectedJourney,
                blueprint: { Nodes: nodes, Edges: edges },
              });
          }}
        />
      )}
    </div>
  );
};

export default JourneyTreeView;
