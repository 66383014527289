import React, { useEffect } from "react";
import styles from "../GenerateCampaignPlan.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchPastCampaignAnalysis } from "../../../redux/campaignPlanAnalysisQueryChat";
import PerformanceMetricsCard from "../../PerformanceMetricsCard/PerformanceMetricsCard";
import { toast } from "react-toastify";

interface ShowPastInsightsProps {}

const ShowPastInsights: React.FC<ShowPastInsightsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { pastCampaignAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchPastCampaignAnalysis({
          campaignPlanId: selectedCampaign?.id,
        })
      ).catch(() => {
        console.log("Error fetching past campaign analysis");
        toast.error("Error fetching past campaign analysis");
      });
  }, []);

  return (
    <div className={styles["past-insights"]}>
      {selectedCampaign &&
        Array.isArray(pastCampaignAnalysis) &&
        pastCampaignAnalysis.map((analysis, index: number) => (
          <PerformanceMetricsCard
            title={analysis.metric}
            rate={analysis.benchmark}
            description={analysis.explanation}
            id={parseInt(analysis?.id)}
            key={index}
            className={styles["performance-metrics-card"]}
            cardType="pinable"
          />
        ))}
    </div>
  );
};

export default ShowPastInsights;
