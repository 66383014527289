import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import styles from "./JourneySidebar.module.scss";
import useJourneyManagement, { Journey } from "../useJourneyManagment";

interface JourneySidebarProps {
  journey: Journey | null;
  onClose: () => void;
  onSave: (journey: Journey, json?: string) => void;
}

export type JourneyType =
  | "onboarding"
  | "nurture-journey-by-product"
  | "retention"
  | "upsell-and-cross-sell";

const JourneySidebar: React.FC<JourneySidebarProps> = ({
  journey,
  onClose,
  onSave,
}) => {
  const [title, setTitle] = useState(journey?.title || "");
  const [description, setDescription] = useState(journey?.description || "");
  const [tags, setTags] = useState(journey?.tags.join(", ") || "");
  const [type, setType] = useState<JourneyType>(journey?.type || "onboarding");
  const [json, setJson] = useState("");
  const [product, setProduct] = useState<"">("");

  const { products, fetchProducts } = useJourneyManagement();
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSave = () => {
    if (!title) {
      toast.error("Title is required");
      return;
    }

    const newJourney: Journey = {
      // Ensure id is always provided (can be undefined)
      id: journey?.id ?? -1, // Set default id if undefined
      title,
      description,
      tags: tags.split(",").map((tag) => tag.trim()),
      type,
      blueprint: journey?.blueprint || { Nodes: [], Edges: [] },
      product,
    };

    onSave(newJourney, json);
    onClose();
  };

  return (
    <div
      className={`${styles["journey-sidebar"]} bg-white p-6 rounded-lg shadow-lg w-80 absolute right-0 top-0 h-full`}
    >
      <h3 className="text-xl font-semibold mb-4">
        {journey ? "Edit Journey" : "Add Journey"}
      </h3>
      <div className="my-4">
        <label htmlFor="title" className="block">
          Title
        </label>
        <input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="border p-2 w-full"
        />
      </div>

      <div className="my-4">
        <label htmlFor="description" className="block">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="border p-2 w-full"
        />
      </div>

      <div className="my-4">
        <label htmlFor="tags" className="block">
          Tags (comma separated)
        </label>
        <input
          id="tags"
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          className="border p-2 w-full"
        />
      </div>

      {journey == null && (
        <div className="my-4">
          <label htmlFor="tags" className="block">
            JSON
          </label>
          <textarea
            id="json"
            value={json}
            rows={10}
            cols={10}
            onChange={(e) => setJson(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
      )}

      <div className="my-4">
        <label htmlFor="type" className="block">
          Journey Type
        </label>
        <select
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value as JourneyType)}
          className="border p-2 w-full"
        >
          <option value="onboarding">Onboarding</option>
          <option value="nurture-journey-by-product">
            Nurture Journey by Product
          </option>
          <option value="retention">Retention</option>
          <option value="upsell-and-cross-sell">Upsell & Cross-Sell</option>
        </select>
      </div>

      {type === "nurture-journey-by-product" && (
        <div className="my-4">
          <label htmlFor="type" className="block">
            Product
          </label>
          <select
            id="type"
            value={product as string}
            onChange={(e) => setProduct(e.target.value as any)}
            className="border p-2 w-full"
          >
            {products.map((product) => (
              <option key={product?.productId} value={product?.productId}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="my-4 flex justify-between">
        <button
          onClick={onClose}
          className="bg-gray-500 text-white p-2 rounded"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default JourneySidebar;
