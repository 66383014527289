import React from "react";

import styles from "./Presentation.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import FieldWithIcon from "../FieldWithIcon/FieldWithIcon";

interface PresentationProps {
  className?: string;
  cardType: CardType;
}

const Presentation: React.FC<PresentationProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    // nurtureStrategyData &&
    //   dispatch(
    //     setNurtureStrategyData({
    //       ...nurtureStrategyData,
    //       Presentation: isPinned,
    //     })
    //   );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <TextElement className={styles["heading"]}>
        Pilot Program Presentation Content
      </TextElement>
      <div className={styles["content"]}>
        <div className={styles["slide"]}>
          <TextElement className={styles["heading"]}>
            <span>Slide 1:</span> Introduction
          </TextElement>
          <div className={styles["slide-content"]}>
            <ul>
              <li>
                <b>Title:</b>
                Accelerate Your Business with 5G
              </li>
              <li>
                <b>Subtitle:</b>Pilot Program Overview
              </li>
              <li>
                <b>Presented by:</b>[Your Name]
              </li>
            </ul>
          </div>
        </div>
        <div className={styles["slide"]}>
          <TextElement className={styles["heading"]}>
            <span>Slide 1:</span> Introduction
          </TextElement>
          <div className={styles["slide-content"]}>
            <ul>
              <li>
                <b>Title:</b>
                Accelerate Your Business with 5G
              </li>
              <li>
                <b>Subtitle:</b>Pilot Program Overview
              </li>
              <li>
                <b>Presented by:</b>[Your Name]
              </li>
            </ul>
          </div>
        </div>
        <div className={styles["slide"]}>
          <TextElement className={styles["heading"]}>
            <span>Slide 1:</span> Introduction
          </TextElement>
          <div className={styles["slide-content"]}>
            <ul>
              <li>
                <b>Title:</b>
                Accelerate Your Business with 5G
              </li>
              <li>
                <b>Subtitle:</b>Pilot Program Overview
              </li>
              <li>
                <b>Presented by:</b>[Your Name]
              </li>
            </ul>
          </div>
        </div>
        <div className={styles["slide"]}>
          <TextElement className={styles["heading"]}>
            <span>Slide 1:</span> Introduction
          </TextElement>
          <div className={styles["slide-content"]}>
            <ul>
              <li>
                <b>Title:</b>
                Accelerate Your Business with 5G
              </li>
              <li>
                <b>Subtitle:</b>Pilot Program Overview
              </li>
              <li>
                <b>Presented by:</b>[Your Name]
              </li>
            </ul>
          </div>
        </div>
        
      </div>
    </Card>
  );
};

export default Presentation;
