import React, { useEffect } from "react";

import styles from "./GeneratedActiveOffers.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";

interface Offer {
  heading: string;
  text: string;
  onClick: () => void;
}
interface Props {
  className?: string;
  titleContent?: React.ReactNode;
  offers: Offer[];
  loading: boolean;
  description?: string;
}

const GeneratedActiveOffers: React.FC<Props> = (props) => {
  const { className, offers, loading, description } = props;

  const loadingRef = React.useRef<HTMLImageElement>(null);

  // useEffect(() => {
  //   if (loadingRef?.current)
  //     loadingRef.current.scrollIntoView({ behavior: "smooth" });
  // }, [loading]);

  return (
    <div className={classNames(styles["container"], className)}>
      {!loading && (
        <div className={styles["header"]}>
          <TextElement className={styles["title"]}>
            <svg
              width="28"
              height="25"
              viewBox="0 0 28 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2 22.2222L7.7 14.5833L0 11.1111L7.7 7.63889L11.2 0L14.7 7.63889L22.4 11.1111L14.7 14.5833L11.2 22.2222ZM22.4 25L20.65 21.1806L16.8 19.4444L20.65 17.7083L22.4 13.8889L24.15 17.7083L28 19.4444L24.15 21.1806L22.4 25Z"
                fill="#EB2701"
              />
            </svg>
            Generated Strategies
          </TextElement>
          {description && (
            <TextElement className={styles["description"]}>
              {description}
            </TextElement>
          )}
        </div>
      )}

      {loading ? (
        <img
          ref={loadingRef}
          className={styles["loading"]}
          src="gifs/generating-offers 1.gif"
        />
      ) : (
        <div className={styles["offers"]}>
          {offers?.map((offer, index) => (
            <div
              key={index}
              className={styles["offer"]}
              onClick={offer.onClick}
            >
              <div className={styles["icon-container"]}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.23077 0V2.46154H11.8031L0 14.2646L1.73538 16L13.5385 4.19692V14.7692H16V0H1.23077Z"
                    fill="white"
                  />
                </svg>
              </div>
              <TextElement className={styles["text"]}>
                <span>{offer?.heading}</span>
                <br />
                {offer?.text}
              </TextElement>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GeneratedActiveOffers;
