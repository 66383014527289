import React from "react";

import styles from "./QualificationAndHandover.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface QualificationAndHandoverProps {
  className?: string;
  cardType: CardType;
}

const QualificationAndHandover: React.FC<QualificationAndHandoverProps> = (
  props
) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        qualificationAndHandover: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Qualification and Handover
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Common Objections
        </TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            High Costs: Highlight the long-term savings and ROI.
          </TextElement>
          <TextElement>
            If qualified, summarise the lead’s information and pass it on to the
            sales team for further action.
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Script:</span> “Based on our
              conversation, it sounds like your organisation is ready to move
              forward with implementing 5G solutions. I’ll pass on your
              information to our sales team, who can provide more detailed
              proposals and next steps.”
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Follow-up Actions
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              Schedule a follow-up meeting or call with the sales team if
              needed.
            </li>
            <li>
              Send a thank-you email summarising the call and outlining the next
              steps.
            </li>
          </ul>
        </div>

        <TextElement className={styles["content-heading"]}>
          Thank You Email Template
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Hi [Lead’s Name],
            <br />
            <br />
            Thank you for taking the time to discuss your needs regarding 5G
            solutions. As discussed, I have passed your details to our sales
            team, who will be in touch soon with more detailed information and
            next steps.
            <br />
            <br />
            If you have any questions in the meantime, feel free to reach out to
            me directly.
            <br />
            <br />
            Best regards,
            <br />
            [Your Name]
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default QualificationAndHandover;
