import React from "react";

import styles from "./Timeline.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateSalesQualifiedStrategyData } from "../../../../redux/salesQualifiedStrategySlice";

interface TimelineEvent {
  period: string;
  description: string;
}

interface TimelineProps {
  className?: string;
  cardType: CardType;
  events: TimelineEvent[];
}

const Timeline: React.FC<TimelineProps> = (props) => {
  const { className, cardType, events } = props;

  const dispatch = useAppDispatch();
  const { includeData } = useAppSelector(
    (state) => state.salesQualifiedStrategy
  );

  const handlePinned = (isPinned: boolean) => {
    includeData &&
      dispatch(
        updateSalesQualifiedStrategyData({
          ...includeData,
          timeliine: isPinned,
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(
        styles["container"],
        className,
        styles["product-demo-invitations-content"]
      )}
    >
      <div>
        <TextElement className={styles["heading"]}>
          <span> Bulding Relationships:&nbsp;</span> Timeline of Activities
        </TextElement>
        <ol className={styles.timeline}>
          {events.map((item, index) => (
            <li className={styles.timelineItem} key={index}>
              <div className={styles.timelineDot}></div>
              <div className={styles.timelineContent}>
                <TextElement className={styles["heading"]}>
                  {item.period}
                </TextElement>
                <TextElement>{item.description}</TextElement>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </Card>
  );
};

export default Timeline;
