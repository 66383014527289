import React, { useEffect } from "react";
import { Button } from "../../../componenets";

import styles from "../ConnectingMarketingChannels.module.scss";
import useConnectingMarketingChannels from "../useConnectingMarketingChannels";

interface ConnectWithLinkedInProps {}

const ConnectWithLinkedIn: React.FC<ConnectWithLinkedInProps> = () => {
  // get param with named code
  const { linkedInLogin, sendAuthCodeToLinkedIn } =
    useConnectingMarketingChannels();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      sendAuthCodeToLinkedIn(code)
    }
  }, []);

  return (
    <div className={styles["channel-card"]}>
      <svg
        width="101"
        height="100"
        viewBox="0 0 101 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.4999 0C22.8855 0 0.5 22.3856 0.5 50C0.5 77.6144 22.8855 100 50.4999 100C78.1143 100 100.5 77.6144 100.5 50C100.5 22.3856 78.1139 0 50.4999 0ZM37.1598 74.1727H26.1818V38.7059H37.1598V74.1727ZM31.6185 34.0612C28.0332 34.0612 25.1267 31.131 25.1267 27.517C25.1267 23.9026 28.0336 20.9726 31.6185 20.9726C35.2034 20.9726 38.1099 23.9026 38.1099 27.517C38.1101 31.1312 35.2036 34.0612 31.6185 34.0612ZM78.2998 74.1727H67.3751V55.5556C67.3751 50.4494 65.4355 47.5992 61.398 47.5992C57.0037 47.5992 54.7081 50.5677 54.7081 55.5556V74.1727H44.179V38.7059H54.7081V43.4827C54.7081 43.4827 57.8753 37.6241 65.3957 37.6241C72.9164 37.6241 78.3 42.2161 78.3 51.7156L78.2998 74.1727Z"
          fill="url(#paint0_linear_4173_3774)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4173_3774"
            x1="15.1446"
            y1="14.6446"
            x2="85.8553"
            y2="85.3552"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2489BE" />
            <stop offset="1" stop-color="#0575B3" />
          </linearGradient>
        </defs>
      </svg>
      <Button
        onClick={() => {
          linkedInLogin();
        }}
      >
        Connect LinkedIn Profile
      </Button>
      <Button
      disabled
      >Connect LinkedIn Page</Button>
    </div>
  );
};

export default ConnectWithLinkedIn;
