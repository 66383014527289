import React, { useState, useEffect } from "react";
import JourneySidebar from "./JourneySidebar/JourneySidebar";
import useJourneyManagement, { JourneyTypes } from "./useJourneyManagment";

import styles from "./JourneyManagment.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";

const JourneyManagement: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const {
    journeys,
    selectedJourney,
    setSelectedJourney,
    handleSaveJourney,
    handleDeleteJourney,
    setType,
    type,
    fetchJourneys,
  } = useJourneyManagement();

  useEffect(() => {
    fetchJourneys(type ? type : undefined);
  }, [type]);

  const navigate = useNavigate();

  return (
    <div className={styles["journey-management-container"]}>
      {/* Journey List */}
      <div className={styles["journey-list"]}>
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Journeys</h2>

        {/* Add New Journey Button */}
        <button
          onClick={() => {
            setSelectedJourney(null);
            setSidebarOpen(true);
          }}
          className="bg-blue-500 text-white p-2 rounded mb-6 hover:bg-blue-600"
        >
          Add New Journey
        </button>

        {/* Filter Dropdown */}
        <div className="mb-6">
          <label htmlFor="type-filter" className="mr-2 text-gray-700">
            Filter by Type:
          </label>
          <select
            id="type-filter"
            value={type as string}
            onChange={(e) => setType(e.target.value as JourneyTypes)}
            className="border border-gray-300 p-2 rounded"
          >
            <option value="all">All</option>
            <option value="onboarding">Onboarding</option>
            <option value="nurture-journey-by-product">
              Nurture Journey by Product
            </option>
            <option value="retention">Retention</option>
            <option value="upsell-and-cross-sell">Upsell and Cross-Sell</option>
          </select>
        </div>

        <ul>
          {journeys.map((journey) => (
            <li key={journey.id} className={styles["journey-item"]}>
              <div className="journey-item-actions flex">
                <button
                  onClick={() => {
                    setSelectedJourney(journey);
                    setSidebarOpen(true);
                  }}
                  className="bg-yellow-500 text-white p-2 rounded mr-2 hover:bg-yellow-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteJourney(journey.id)}
                  className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                >
                  Delete
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `${ROUTE_DEFINITIONS.CAMPAIGN_TREE_VIEW.path}?id=${journey.id}`
                    )
                  }
                  className="bg-green-500 text-white p-2 rounded hover:bg-green-600"
                >
                  Journey Tree View
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `${ROUTE_DEFINITIONS.CAMPAIGN_UI_VIEW.path}?id=${journey.id}`
                    )
                  }
                  className="bg-purple-500 text-white p-2 rounded hover:bg-purple-600"
                >
                  Journey UI View
                </button>
              </div>
              <p className="text-gray-600">{journey.description}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Sidebar (Add/Edit Journey) */}
      {isSidebarOpen && (
        <JourneySidebar
          journey={selectedJourney}
          onClose={() => setSidebarOpen(false)}
          onSave={handleSaveJourney}
        />
      )}
    </div>
  );
};

export default JourneyManagement;
