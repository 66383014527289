import React, { useState } from "react";
import styles from "./ActionPlans.module.scss";
import { TextElement } from "../../../../componenets";
import classNames from "classnames";

interface Plan {
  heading: string;
  task: string;
  preparation: string[];
  followUp: string;
}

interface ActionPlansProps {
  plans: Plan[];
}

const ActionPlans: React.FC<ActionPlansProps> = ({ plans }) => {
  const [openPanelIndex, setOpenPanelIndex] = useState<number | null>(null);

  const togglePanel = (index: number) => {
    setOpenPanelIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    
      <div className={styles["plans-container"]}>
        <div className={styles["plans"]}>
          {plans.map((plan, index) => (
            <div
              key={index}
              className={classNames(styles["plan"], {
                [styles["active"]]: openPanelIndex === index,
              })}
              onClick={() => togglePanel(index)}
            >
              <div className={styles["plan-header"]}>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 0.976562V3.46103H10.8333V0.976562H9.16667ZM3.51888 3.30252L2.34049 4.47359L4.10807 6.23019L5.28646 5.05912L3.51888 3.30252ZM16.4811 3.30252L14.7135 5.05912L15.8919 6.23019L17.6595 4.47359L16.4811 3.30252ZM10 5.11735C6.78333 5.11735 4.16667 7.71776 4.16667 10.9145C4.16667 13.2209 5.5325 15.2113 7.5 16.1438V18.3679C7.5 19.2813 8.2475 20.0242 9.16667 20.0242H10.8333C11.7525 20.0242 12.5 19.2813 12.5 18.3679V16.1438C14.4675 15.2113 15.8333 13.2209 15.8333 10.9145C15.8333 7.71776 13.2167 5.11735 10 5.11735ZM0 10.0863V11.7426H2.5V10.0863H0ZM17.5 10.0863V11.7426H20V10.0863H17.5ZM4.10807 15.5987L2.34049 17.3553L3.51888 18.5264L5.28646 16.7698L4.10807 15.5987ZM15.8919 15.5987L14.7135 16.7698L16.4811 18.5264L17.6595 17.3553L15.8919 15.5987Z"
                    fill="#F98C28"
                  />
                </svg>
                <TextElement className={styles["plan-title"]}>
                  {plan.heading}
                </TextElement>

                <svg  
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles["arrow"]}
                  style={{
                    transform: openPanelIndex === index ? "rotate(180deg)" : "",
                  }}
                >
                  <path
                    d="M10 1.88L3.89333 8L10 14.12L8.12 16L0.12 8L8.12 0L10 1.88Z"
                    fill="#778BA8"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>

        {openPanelIndex != null && (
          <div className={styles["plan-details"]}>
            <div className={styles["header"]}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.16667 0V2.48447H10.8333V0H9.16667ZM3.51888 2.32596L2.34049 3.49702L4.10807 5.25362L5.28646 4.08256L3.51888 2.32596ZM16.4811 2.32596L14.7135 4.08256L15.8919 5.25362L17.6595 3.49702L16.4811 2.32596ZM10 4.14079C6.78333 4.14079 4.16667 6.7412 4.16667 9.93789C4.16667 12.2443 5.5325 14.2347 7.5 15.1672V17.3913C7.5 18.3048 8.2475 19.0476 9.16667 19.0476H10.8333C11.7525 19.0476 12.5 18.3048 12.5 17.3913V15.1672C14.4675 14.2347 15.8333 12.2443 15.8333 9.93789C15.8333 6.7412 13.2167 4.14079 10 4.14079ZM0 9.10973V10.766H2.5V9.10973H0ZM17.5 9.10973V10.766H20V9.10973H17.5ZM4.10807 14.6222L2.34049 16.3788L3.51888 17.5498L5.28646 15.7932L4.10807 14.6222ZM15.8919 14.6222L14.7135 15.7932L16.4811 17.5498L17.6595 16.3788L15.8919 14.6222Z"
                  fill="#F98C28"
                />
              </svg>
              <TextElement>{plans[openPanelIndex].heading}</TextElement>
            </div>

            <div className={styles["item"]}>
              <TextElement className={styles["label"]}>Task</TextElement>
              <TextElement className={styles["value"]}>
                {plans[openPanelIndex].task}
              </TextElement>
            </div>

            <div className={styles["item"]}>
              <TextElement className={styles["label"]}>Preparation</TextElement>
              <TextElement className={styles["value"]}>
                <ul>
                  {plans[openPanelIndex].preparation.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ul>
              </TextElement>
            </div>

            <div className={styles["item"]}>
              <TextElement className={styles["label"]}>Follow-Up</TextElement>
              <TextElement className={styles["value"]}>
                {plans[openPanelIndex].followUp}
              </TextElement>
            </div>
          </div>
        )}
      </div>
    
  );
};

export default ActionPlans;
