import React from "react";

import styles from "./SmsContent.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import FieldWithIcon from "../FieldWithIcon/FieldWithIcon";

interface SmsContentProps {
  className?: string;
  cardType: CardType;
}

const SmsContent: React.FC<SmsContentProps> = (props) => {
  const { className, cardType } = props;

  const handlePinned = (isPinned: boolean) => {};

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={styles["content"]}>
        <div className={styles["content-heading"]}>
          <TextElement className={styles["heading"]}>SMS</TextElement>
        </div>
        <FieldWithIcon
          icon={
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.5C4.084 0.5 0.5 4.084 0.5 8.5C0.5 12.916 4.084 16.5 8.5 16.5H12.5V14.9H8.5C5.028 14.9 2.1 11.972 2.1 8.5C2.1 5.028 5.028 2.1 8.5 2.1C11.972 2.1 14.9 5.028 14.9 8.5V9.644C14.9 10.276 14.332 10.9 13.7 10.9C13.068 10.9 12.5 10.276 12.5 9.644V8.5C12.5 6.292 10.708 4.5 8.5 4.5C6.292 4.5 4.5 6.292 4.5 8.5C4.5 10.708 6.292 12.5 8.5 12.5C9.604 12.5 10.612 12.052 11.332 11.324C11.852 12.036 12.748 12.5 13.7 12.5C15.276 12.5 16.5 11.22 16.5 9.644V8.5C16.5 4.084 12.916 0.5 8.5 0.5ZM8.5 10.9C7.172 10.9 6.1 9.828 6.1 8.5C6.1 7.172 7.172 6.1 8.5 6.1C9.828 6.1 10.9 7.172 10.9 8.5C10.9 9.828 9.828 10.9 8.5 10.9Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Text"
          text={
            <>
              Hi [Customer Name], get an extra 10GB of data free this month!
              Stay connected without worries. Click here to claim your offer. –
              [Your Company Name]
            </>
          }
        />
      </div>
    </Card>
  );
};

export default SmsContent;
