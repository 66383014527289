import React from "react";

import styles from "./WidgetSidebar.module.scss";

import { useDnD } from "../../context/DnDContext";
import { CustomNodeTypeVariants } from "../../types";
import Button from "../../../Button/Button";
import TextElement from "../../../TextElement/TextElement";

interface Props {
  onSave: () => void;
  onAlign: () => void;
}

const WidgetSidebar: React.FC<Props> = ({ onSave, onAlign }) => {
  const { setType } = useDnD();

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: CustomNodeTypeVariants
  ) => {
    console.log("dragging", nodeType);
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className={styles["toolbox"]}>
      <TextElement className={styles["toolbox-title"]}>Widgets</TextElement>
      <div
        className={styles["entry"]}
        draggable
        onDragStart={(event) => onDragStart(event, "diamond")}
        onClick={() => {
          console.log("diamond");
        }}
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9 11.5V14.925C9.9 15.1917 10.025 15.3543 10.275 15.413C10.525 15.471 10.7167 15.3833 10.85 15.15L13.875 9.225C13.9583 9.05833 13.95 8.896 13.85 8.738C13.75 8.57933 13.6083 8.5 13.425 8.5H11.25V5.025C11.25 4.75833 11.125 4.59167 10.875 4.525C10.625 4.45833 10.4333 4.54167 10.3 4.775L7.15 10.775C7.06667 10.9417 7.071 11.104 7.163 11.262C7.25433 11.4207 7.39167 11.5 7.575 11.5H9.9ZM10.5 20C9.11667 20 7.81667 19.7373 6.6 19.212C5.38333 18.6873 4.325 17.975 3.425 17.075C2.525 16.175 1.81267 15.1167 1.288 13.9C0.762667 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.762667 7.31667 1.288 6.1C1.81267 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.31233 6.6 0.787C7.81667 0.262333 9.11667 0 10.5 0C11.8833 0 13.1833 0.262333 14.4 0.787C15.6167 1.31233 16.675 2.025 17.575 2.925C18.475 3.825 19.1873 4.88333 19.712 6.1C20.2373 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2373 12.6833 19.712 13.9C19.1873 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6873 14.4 19.212C13.1833 19.7373 11.8833 20 10.5 20Z"
            fill="#FFA600"
          />
        </svg>
        Trigger
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "delay")}
        className={styles["entry"]}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM2 10H10V2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10Z"
            fill="#778BA8"
          />
        </svg>
        Delay
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "email")}
        className={styles["entry"]}
      >
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
            fill="#02285E"
          />
        </svg>
        Content
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "entry")}
        className={styles["entry"]}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7 13.3L13.3 10.7C13.4833 10.5167 13.575 10.2833 13.575 10C13.575 9.71667 13.4833 9.48333 13.3 9.3L10.675 6.675C10.4917 6.49167 10.2627 6.404 9.988 6.412C9.71267 6.42067 9.48333 6.51667 9.3 6.7C9.11667 6.88333 9.025 7.11667 9.025 7.4C9.025 7.68333 9.11667 7.91667 9.3 8.1L10.2 9H6.975C6.69167 9 6.45833 9.09567 6.275 9.287C6.09167 9.479 6 9.71667 6 10C6 10.2833 6.096 10.5207 6.288 10.712C6.47933 10.904 6.71667 11 7 11H10.2L9.275 11.925C9.09167 12.1083 9.004 12.3377 9.012 12.613C9.02067 12.8877 9.11667 13.1167 9.3 13.3C9.48333 13.4833 9.71667 13.575 10 13.575C10.2833 13.575 10.5167 13.4833 10.7 13.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
            fill="#38B05A"
          />
        </svg>
        Entry
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "end")}
        className={styles["entry"]}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.3 13.3L6.7 10.7C6.51667 10.5167 6.425 10.2833 6.425 10C6.425 9.71667 6.51667 9.48333 6.7 9.3L9.325 6.675C9.50833 6.49167 9.73733 6.404 10.012 6.412C10.2873 6.42067 10.5167 6.51667 10.7 6.7C10.8833 6.88333 10.975 7.11667 10.975 7.4C10.975 7.68333 10.8833 7.91667 10.7 8.1L9.8 9H13.025C13.3083 9 13.5417 9.09567 13.725 9.287C13.9083 9.479 14 9.71667 14 10C14 10.2833 13.904 10.5207 13.712 10.712C13.5207 10.904 13.2833 11 13 11H9.8L10.725 11.925C10.9083 12.1083 10.996 12.3377 10.988 12.613C10.9793 12.8877 10.8833 13.1167 10.7 13.3C10.5167 13.4833 10.2833 13.575 10 13.575C9.71667 13.575 9.48333 13.4833 9.3 13.3ZM10 20C11.3833 20 12.6833 19.7373 13.9 19.212C15.1167 18.6873 16.175 17.975 17.075 17.075C17.975 16.175 18.6873 15.1167 19.212 13.9C19.7373 12.6833 20 11.3833 20 10C20 8.61667 19.7373 7.31667 19.212 6.1C18.6873 4.88333 17.975 3.825 17.075 2.925C16.175 2.025 15.1167 1.31233 13.9 0.787C12.6833 0.262333 11.3833 0 10 0C8.61667 0 7.31667 0.262333 6.1 0.787C4.88333 1.31233 3.825 2.025 2.925 2.925C2.025 3.825 1.31267 4.88333 0.788 6.1C0.262667 7.31667 0 8.61667 0 10C0 11.3833 0.262667 12.6833 0.788 13.9C1.31267 15.1167 2.025 16.175 2.925 17.075C3.825 17.975 4.88333 18.6873 6.1 19.212C7.31667 19.7373 8.61667 20 10 20Z"
            fill="#EB2701"
          />
        </svg>
        Exit
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "action")}
        className={styles["entry"]}
      >
        <div
          style={{
            backgroundColor: "#18A2F2",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.7 10.514L4.075 6.96737L0.5 5.35526L4.075 3.74316L5.7 0.196533L7.325 3.74316L10.9 5.35526L7.325 6.96737L5.7 10.514ZM10.9 11.8037L10.0875 10.0304L8.3 9.22431L10.0875 8.41826L10.9 6.64495L11.7125 8.41826L13.5 9.22431L11.7125 10.0304L10.9 11.8037Z"
              fill="white"
            />
          </svg>
        </div>
        Action
      </div>
      <div
        draggable
        onDragStart={(event) => onDragStart(event, "check")}
        className={styles["entry"]}
      >
        <div
          style={{
            backgroundColor: "#EB6301",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.875L5.75 10.125L2 6.375"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 1.875L5.75 10.125L2 6.375"
              stroke="#FFF6EB"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        Check
      </div>

      <Button onClick={onAlign} className={styles["save-button"]}>
        Align
      </Button>

      <Button onClick={onSave} className={styles["save-button"]}>
        Save Blueprint
      </Button>
    </div>
  );
};

export default WidgetSidebar;
