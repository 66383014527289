import React from "react";

const DecisionStageIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="118"
      height="114"
      viewBox="0 0 118 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5596 112.633C19.3081 111.862 18.5093 109.458 18.5093 109.458C18.5093 109.458 20.1492 111.449 21.0446 110.553C21.94 109.657 21.2871 106.246 21.2871 106.246C21.2871 106.246 23.1276 104.204 23.2625 104.029C23.3973 103.855 26.7364 105.813 27.5035 105.016C28.2705 104.219 27.2941 100.272 25.5442 96.7211L25.3602 96.0281L12.3187 95.7695C12.3187 95.7695 11.8379 100.672 12.6022 105.05C13.3664 109.428 15.8111 113.404 17.5596 112.633Z"
        fill="#F2C5A7"
      />
      <path
        d="M27.2134 99.0688C20.9262 100.731 16.0115 98.4426 11.0246 97.1418C9.26601 96.6816 12.7809 80.2278 20.0828 72.8584C27.888 64.981 32.9954 65.1494 34.8382 65.3633C36.0346 65.5014 30.7431 84.6359 30.7431 84.6359C30.7431 84.6359 28.5699 86.5445 27.9846 91.167C27.2617 96.8767 27.6473 94.2268 27.2134 99.0688Z"
        fill="#02285E"
      />
      <path
        d="M89.1829 113.177C87.8315 105.442 85.9837 98.1015 84.8504 93.79C84.8504 93.79 81.9791 104.203 75.1623 107.146C68.3455 110.089 59.7939 106.751 59.7939 106.751C59.7939 106.751 61.1743 109.44 62.8621 112.829C71.6371 112.9 80.4107 113.016 89.1829 113.177Z"
        fill="#02285E"
      />
      <path
        d="M49.8611 112.759C50.4887 112.761 51.1162 112.763 51.7435 112.766C53.0893 109.71 54.1578 107.33 54.1578 107.33C54.1578 107.33 46.2081 108.197 40.1118 107.146C32.7958 105.885 30.3083 94.9463 30.3083 94.9463C29.3162 98.7194 27.8622 105.805 26.9033 112.792C34.5571 112.749 42.2097 112.737 49.8611 112.759Z"
        fill="#02285E"
      />
      <path
        d="M34.2127 64.9797C34.2127 64.9797 41.7156 69.5437 60.1438 67.5058C78.572 65.4679 81.0448 58.9087 81.0448 58.9087C84.4534 58.213 90.0421 66.3072 94.2822 64.7653C100.268 62.5888 102.57 62.0675 104.111 62.2599C105.846 63.1272 107.388 70.6434 108.929 79.2198C104.111 82.3027 98.4947 84.1469 93.7043 83.6522C87.0946 82.9708 84.6471 81.7252 84.6471 81.7252C87.9716 97.914 88.3208 101.982 87.7429 106.993C87.297 110.855 62.7785 107.764 57.7741 107.764C48.716 107.764 28.2871 108.126 28.2871 104.006C28.2862 95.3336 34.2127 64.9797 34.2127 64.9797Z"
        fill="#02285E"
      />
      <path
        d="M57.9428 73.3213C57.9428 73.3213 56.0015 72.1558 53.8973 70.6503C51.7931 69.1447 51.6118 68.501 51.6118 68.501L51.8143 68.1835C51.8143 68.1835 56.2223 67.8154 60.1426 67.5053C64.0629 67.1952 65.2814 66.9531 65.2814 66.9531L65.5262 67.3392C65.5262 67.3392 61.4904 71.7808 61.0735 72.2248C60.6566 72.6689 59.331 73.8413 59.331 73.8413H58.8943L57.9428 73.3213Z"
        fill="#EDBC9A"
      />
      <path
        d="M29.442 90.4184C29.442 90.4184 40.475 93.6702 59.3312 94.0245C78.1873 94.3788 86.1029 89.1172 86.1029 89.1172C86.1029 89.1172 88.185 94.1832 87.5487 98.2236C87.5487 98.2236 79.7821 102.855 59.3312 102.235C38.8802 101.614 28.4302 98.9465 28.4302 98.9465C28.4302 98.9465 29.2092 93.1654 29.442 90.4184Z"
        fill="#EB2701"
      />
      <path
        d="M46.9843 93.2216C47.1822 93.0974 66.8619 80.6251 75.2257 65.3685C75.3845 65.0796 69.5565 68.5793 69.2394 68.6718C64.7104 69.9887 62.7024 70.2197 62.4295 70.406C61.8194 70.8238 36.1896 91.6438 35.9941 91.7662C35.7148 91.942 39.6558 92.5857 41.2939 92.7785C41.5065 92.8033 46.8854 93.2819 46.9843 93.2216Z"
        fill="#01234C"
      />
      <path
        d="M57.805 75.8674C57.9495 75.5706 59.8898 73.6707 59.593 73.5253C59.5397 73.4995 54.2095 70.8699 51.8426 67.8372C51.6397 67.5767 48.6857 69.2401 48.4257 69.443C48.1657 69.6459 44.3038 69.5682 44.5067 69.8286C47.043 73.0804 54.5891 78.0038 54.8178 78.1156C54.902 78.157 57.701 76.079 57.805 75.8674Z"
        fill="#01234C"
      />
      <path
        d="M58.1343 112.797L61.0791 102.934L55.0855 102.312C55.0855 102.312 52.9523 108.101 51.7578 112.766C53.8827 112.774 56.0082 112.784 58.1343 112.797Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M58.1342 112.797L61.079 102.934L58.7259 102.689L55.7148 112.783C56.5207 112.787 57.3271 112.791 58.1342 112.797Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M60.6579 94.7829C60.6579 94.7829 59.2609 91.2965 54.6799 92.3525C50.0988 93.4085 51.4673 100.529 54.0357 102.542C56.6041 104.555 59.4073 102.492 59.4073 102.492C59.4073 102.492 63.1656 104.094 66.5724 101.672C69.9792 99.2503 70.219 93.4071 67.1273 92.7395C64.0357 92.0718 61.9306 93.7007 60.6579 94.7829Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M69.3549 95.5039C69.3549 95.5039 66.4561 99.461 62.9398 99.8903C59.4235 100.32 58.6873 98.786 58.6873 98.786L58.6726 98.2012C58.6726 98.2012 55.4585 100.495 53.9847 99.8719C52.5109 99.2484 52.2036 98.2753 52.2036 98.2753L51.7002 97.447C51.7002 97.447 51.9763 102.208 54.755 103.006C57.5337 103.805 58.921 102.79 58.921 102.79L59.9655 102.433C59.9655 102.433 64.063 103.937 67.1159 101.237C70.1689 98.5371 69.3549 95.5039 69.3549 95.5039Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M69.7809 112.894C68.3692 108.224 65.7589 102.147 65.7589 102.147L59.8066 103.083L63.2838 112.832C65.4495 112.851 67.6152 112.872 69.7809 112.894Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M65.7593 112.853L62.1432 102.714L59.8066 103.082L63.2838 112.83L65.7593 112.853Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M62.401 95.296C61.4003 96.1026 61.0147 97.1066 61.2774 97.3822C61.5401 97.6578 62.3527 97.1015 63.3535 96.2954C64.3543 95.4892 65.165 94.7374 64.9018 94.4618C64.6387 94.1862 63.4018 94.4894 62.401 95.296Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M54.876 93.8557C53.9143 94.2362 53.4035 95.006 53.5545 95.3474C53.7054 95.6889 54.4674 95.4671 55.429 95.0866C56.3907 94.706 57.19 94.3103 57.0395 93.9703C56.889 93.6302 55.8385 93.4756 54.876 93.8557Z"
          fill="white"
        />
      </g>
      <path
        d="M20.1947 27.3378C20.1947 27.3378 20.9673 33.331 14.4169 36.0987C7.86644 38.8664 2.26941 35.9147 2.26941 35.9147L1.27783 26.8984C1.27783 26.8984 6.82655 28.8995 9.89608 28.3708C12.9656 27.8421 19.038 22.2051 19.038 22.2051"
        fill="#EB2701"
      />
      <path
        d="M63.3344 70.2508C53.869 71.3238 38.3314 69.9692 33.61 67.046C27.3375 63.1625 21.8536 56.1248 20.0361 51.1195C18.2904 46.3033 13.1728 27.9988 25.2862 13.0819C36.6091 -0.860093 64.8823 -8.29028 83.0823 17.5911C87.7476 25.6572 88.9057 45.3853 83.7163 55.4797C80.3496 62.0324 72.5241 69.2095 63.3344 70.2508Z"
        fill="#02285E"
      />
      <path
        d="M33.047 33.025C29.7405 33.6692 27.3119 36.3182 27.0542 39.5184C26.6594 44.4036 28.4116 51.687 32.6066 54.8356C38.3633 59.1549 42.9899 51.5425 54.7522 51.8614C66.9291 52.1913 67.8457 56.153 74.7955 53.5961C78.9974 52.0491 82.2225 34.0074 78.0841 31.9078C71.8755 28.7591 68.1669 34.7988 56.4179 34.9644C48.5713 35.0744 38.5253 31.9552 33.047 33.025Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M19.3407 22.7306C17.0823 17.8131 12.511 18.2447 8.83037 18.374C5.14978 18.5033 2.42903 13.9209 2.42903 13.9209C0.186353 16.1079 0.793726 25.2898 0.793726 25.2898C0.793726 25.2898 9.729 24.7073 11.9142 25.1057C14.0993 25.5042 18.2037 28.6598 18.2037 28.6598L18.569 28.5981"
        fill="#EB2701"
      />
      <path
        d="M85.8323 26.5205C85.8323 26.5205 47.6903 33.1437 17.4017 28.7048C17.4017 28.7048 15.1885 28.4338 15.8492 24.5351C16.51 20.6364 21.2406 21.2382 21.2406 21.2382C21.2406 21.2382 46.8643 22.9311 56.1056 22.5772C65.3469 22.2234 83.5933 19.8868 84.1772 19.9959C85.4435 20.2324 86.0849 24.0496 85.8323 26.5205Z"
        fill="#EB2701"
      />
      <path
        d="M53.3839 41.5803C50.4133 44.7023 46.3756 46.4273 42.2059 46.35C38.4568 46.0551 36.74 44.9659 34.9041 41.526C33.4358 37.6968 33.7437 36.7314 33.7437 36.7314L53.3839 41.5803Z"
        fill="#EB2701"
      />
      <path
        d="M72.6187 43.1027C70.8495 45.3473 68.0027 46.2951 65.3602 45.5207C62.4935 44.9226 59.8694 43.4423 57.8228 41.2599L73.8022 34.9336C73.8022 34.9336 73.5629 41.6768 72.6187 43.1027Z"
        fill="#EB2701"
      />
      <path
        d="M106.047 62.6224L112.112 62.2948C112.236 62.2878 112.355 62.2451 112.456 62.1718C112.556 62.0986 112.633 61.9978 112.677 61.8818C112.722 61.7658 112.732 61.6394 112.706 61.5179C112.68 61.3964 112.62 61.2849 112.532 61.1969C110.941 59.6058 112.447 56.5956 112.624 56.2574C112.802 56.0034 113.384 55.0178 113.539 52.8469C113.619 51.7265 113.079 50.8113 111.978 50.2002C111.338 49.8484 110.62 49.6646 109.89 49.666C109.358 49.6661 108.83 49.7606 108.331 49.9449C103.884 51.5802 103.541 55.0436 103.733 56.9628C103.905 58.6804 105.359 62.0891 105.421 62.2331C105.471 62.3489 105.553 62.4476 105.658 62.5169C105.764 62.5863 105.887 62.6232 106.013 62.6233L106.047 62.6224Z"
        fill="#EABFA2"
      />
      <path
        d="M104.984 80.3568C106.186 80.3568 107.525 80.1567 108.962 79.7614C110.088 79.4562 111.09 78.8085 111.831 77.908C113.774 75.5457 116.559 70.4185 113.391 62.6252C113.078 61.8548 112.599 61.1624 111.989 60.5971C111.379 60.0318 110.653 59.6074 109.861 59.3537C108.677 58.9706 107.44 58.7752 106.195 58.7744C104.22 58.7744 102.265 59.2695 100.385 60.2468C99.4335 60.7398 98.6445 61.4968 98.1126 62.427C97.5806 63.3571 97.3282 64.421 97.3857 65.4909C97.3899 65.5664 97.3949 65.6432 97.4014 65.721C97.445 66.3032 97.3667 66.8881 97.1713 67.4382C96.7029 68.7574 96.1512 71.4703 97.7235 74.8159C97.826 75.0353 97.91 75.2627 97.9747 75.496C98.2954 76.6358 99.7448 80.3568 104.984 80.3568Z"
        fill="#F2C5A7"
      />
      <path
        d="M94.8866 28.1738C94.8866 28.1738 94.6105 29.8676 92.244 32.9302C90.4495 35.2552 88.6509 35.783 88.6509 35.783C88.6509 35.783 91.7158 37.2628 92.9839 39.2708C94.9275 42.3481 95.0978 43.2873 95.0978 43.2873C95.0978 43.2873 95.626 41.1734 96.9999 38.9538C98.5469 36.4553 100.594 36.101 100.594 36.101C100.594 36.101 98.3357 34.1633 96.9944 32.6541C96.1496 31.7003 94.8866 28.1738 94.8866 28.1738Z"
        fill="#EB2701"
      />
      <path
        d="M105.88 5.6543C105.88 5.6543 105.4 8.59913 101.283 13.9284C98.1574 17.9734 95.0312 18.8927 95.0312 18.8927C95.0312 18.8927 100.364 21.4694 102.57 24.9605C105.951 30.3145 106.247 31.948 106.247 31.948C106.247 31.948 107.168 28.2706 109.557 24.4092C112.248 20.0628 115.809 19.4449 115.809 19.4449C115.809 19.4449 111.881 16.0735 109.547 13.4471C108.076 11.7943 105.88 5.6543 105.88 5.6543Z"
        fill="#EB2701"
      />
      <path
        d="M112.668 33.9658C112.668 33.9658 112.446 35.3343 110.533 37.8098C109.081 39.6889 107.628 40.1159 107.628 40.1159C107.628 40.1159 110.106 41.3123 111.13 42.9347C112.701 45.4222 112.839 46.1809 112.839 46.1809C112.839 46.1809 113.266 44.4724 114.377 42.6784C115.627 40.6593 117.281 40.3722 117.281 40.3722C117.281 40.3722 115.456 38.8078 114.372 37.5861C113.689 36.8172 112.668 33.9658 112.668 33.9658Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default DecisionStageIcon;
