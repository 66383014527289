import React, { useEffect } from "react";
import styles from "./RecommendedActionsForSale.module.scss";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { Button, TextElement } from "../../componenets";
import classNames from "classnames";
import RecommendedActionsForSaleHeader from "./components/CampaignHeader/RecommendedActionsForSaleHeader";

import ConfidenceScoreBar from "./components/ConfidenceScoreBar/ConfidenceScoreBar";
import Select from "../../componenets/Select/Select";
import DetailCards from "./components/DetailCards/DetailCards";
import ActionPlans from "./components/ActionPlans/ActionPlans";
import { useLocation, useParams } from "react-router-dom";
import useRecommendedActionsForSale from "./useRecommendedActionsForSale";
import DottedLoader from "../../componenets/DottedLoader/DottedLoader";
import DisclosureComponent from "../../componenets/Disclosure/Disclosure";
import Metric from "../../componenets/CampaignReports/components/Metric/Metric";

const RecommendedActionsForSale: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const stage = query.get("stage");

  const {
    opportunities,
    setSelectedOpportunity,
    selectedOpportunity,
    stageDetails,
    confidenceScore,
    metrics,
  } = useRecommendedActionsForSale({
    stage: stage as "Consideration" | "Evaluation" | "Decision",
  });

  const [showActionPlans, setShowActionPlans] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("");

  const considerationPlans = [
    {
      heading: "Resolve Concerns and Build Trust",
      task: "Schedule a call with the client this week to address their specific concerns about implementation and support issues.",
      preparation: [
        "Create a detailed implementation timeline with key milestones.",
        "Collect case studies from similar successful projects.",
        "Include the support team lead in the call to explain how escalations are resolved.",
      ],
      followUp:
        "Send an email summarizing the discussion, with a copy of the timeline and documented responses to their concerns, within 24 hours of the call.",
    },
    {
      heading: "Showcase Strengths",
      task: "Design a tailored presentation to showcase how your solution’s post-implementation support and features outperform competitors.",
      preparation: [
        "Develop a competitor comparison table with specific metrics (e.g., response times, unique features).",
        "Add client testimonials or case studies from similar industries.",
        "Include visuals that highlight ROI and ease of use.",
      ],
      followUp:
        "Share the slide deck and a one-page summary via email within two days of the presentation.",
    },
    {
      heading: "Improve and Deliver Value",
      task: "Deliver a customized demo with a strong focus on their key concerns, such as ease of implementation and ongoing support.",
      preparation: [
        "Tailor demo content to demonstrate how the product solves their pain points.",
        "Prepare responses to potential objections.",
        "Ensure live troubleshooting capabilities are ready.",
      ],
      followUp:
        "Send the demo recording, recap of key highlights, and clear next steps within 24 hours.",
    },
    {
      heading: "Revamp the Proposal",
      task: "Update the proposal to address specific client concerns, including measurable success outcomes.",
      preparation: [
        "Add a roadmap with clear milestones for implementation.",
        "Include metrics such as reduced downtime, faster ROI, and detailed SLAs for support.",
        "Collaborate with the operations team for realistic deliverables.",
      ],
      followUp:
        "Schedule a meeting to present the revised proposal and share a PDF copy via email after the meeting.",
    },
    {
      heading: "Strategize Pricing for Success",
      task: "Offer a tailored pricing package with added value to improve deal appeal.",
      preparation: [
        "Get internal approval for discounts or complimentary services (e.g., training sessions, extended support).",
        "Prepare a cost-benefit comparison showing how the package delivers superior value.",
      ],
      followUp:
        "Discuss the updated pricing package in a scheduled meeting, followed by a detailed email summarizing the offer.",
    },
    {
      heading: "Keep Clients Informed",
      task: "Send a bi-weekly email to the client summarizing key industry trends and product updates.",
      preparation: [
        "Collaborate with internal teams to gather insights tailored to the client’s industry challenges.",
        "Include examples of how your product is evolving to meet market demands.",
      ],
      followUp:
        "Reference these insights during client check-ins to demonstrate adaptability.",
    },
    {
      heading: "Prevent Deal Delays",
      task: "Build and share a tracker for all unresolved client concerns and their resolution status.",
      preparation: [
        "List all concerns with assigned owners and resolution timelines.",
        "Share the tracker with the client after validation.",
      ],
      followUp:
        "Review progress weekly with the client and internally to ensure alignment.",
    },
    {
      heading: "Build Confidence with Recap Emails",
      task: "After every call, meeting, or demo, send a professional recap email to the client.",
      preparation: [
        "Use a template to outline key discussion points, actions taken, and follow-up dates.",
      ],
      followUp:
        "Ensure immediate responses to any queries raised in response to the recap emails.",
    },
    {
      heading: "Finalize Deal and Close the Sale",
      task: "Organize a final meeting with decision-makers to review the proposal and confirm the agreement.",
      preparation: [
        "Prepare a summary document with benefits, pricing, and final terms.",
        "Confirm all internal approvals are completed beforehand.",
      ],
      followUp:
        "Send a signed agreement and confirmation of the implementation start date within 24 hours of the meeting.",
    },
    {
      heading: "Track and Adjust Progress",
      task: "Use your CRM to track client sentiment, feedback, and deal status.",
      preparation: [
        "Review CRM data weekly to adjust your approach if needed.",
      ],
      followUp: "Update your manager on progress and highlight any risks.",
    },
    {
      heading: "Engage Stakeholders Regularly",
      task: "Schedule regular check-ins with key stakeholders to ensure alignment and gather feedback.",
      preparation: [
        "Identify the primary decision-makers and influencers within the client’s team.",
        "Prepare an agenda for each meeting to ensure all concerns are addressed.",
      ],
      followUp:
        "Send meeting notes summarizing action points and follow-up tasks after each check-in.",
    },
    {
      heading: "Strengthen Relationship with Continued Support",
      task: "Proactively reach out to provide ongoing support and monitor post-sale satisfaction.",
      preparation: [
        "Create a post-sale check-in schedule to ensure smooth implementation.",
        "Coordinate with the customer success team to address any immediate needs.",
      ],
      followUp:
        "Send a monthly email to check on client satisfaction and any potential additional needs.",
    },
    {
      heading: "Create and Present a Risk Mitigation Plan",
      task: "Develop and present a risk mitigation plan to reassure the client regarding potential roadblocks.",
      preparation: [
        "Identify potential risks in the implementation process.",
        "Develop solutions to address each identified risk.",
      ],
      followUp:
        "Ensure the client receives a detailed copy of the plan, along with clear next steps.",
    },
    {
      heading: "Establish Post-Implementation Metrics",
      task: "Set clear metrics for success after implementation to measure and report progress.",
      preparation: [
        "Work with the client to define KPIs that matter most for their business.",
        "Prepare a framework for tracking these KPIs after go-live.",
      ],
      followUp:
        "Provide regular progress updates based on agreed-upon metrics to ensure continued success.",
    },
  ];

  const likelyToCloseOpportunitiesPlans = [
    {
      heading: "Finalize Proposal and Terms",
      task: "Ensure that the final proposal reflects the client’s expectations and finalize terms for approval.",
      preparation: [
        "Review the proposal with the client to confirm all details are accurate and aligned with their requirements.",
        "Make any last-minute adjustments to the proposal, including pricing, deliverables, and timelines.",
      ],
      followUp:
        "Send the final version of the proposal for sign-off, with a clear request for approval or feedback.",
    },
    {
      heading: "Confirm Decision Makers and Sign-Off",
      task: "Ensure all key decision-makers are involved in the final discussions and obtain sign-off from all stakeholders.",
      preparation: [
        "Confirm the list of decision-makers involved and schedule a final meeting if necessary.",
        "Prepare a final review presentation that highlights the solution’s benefits, ROI, and alignment with their goals.",
      ],
      followUp:
        "Follow up with all decision-makers after the meeting to confirm their sign-off and address any last-minute concerns.",
    },
    {
      heading: "Provide Post-Sale Support Reassurance",
      task: "Reaffirm the post-sale support and implementation process to build confidence in your solution.",
      preparation: [
        "Prepare a detailed implementation plan that includes timelines, milestones, and support resources.",
        "Ensure the client is aware of the dedicated post-sale team and their availability for ongoing assistance.",
      ],
      followUp:
        "Send an email with a summary of the post-sale support plan, including contact information and next steps.",
    },
    {
      heading: "Offer Flexible Payment Options",
      task: "Present payment options or financing plans that align with the client's budget and increase the likelihood of closing.",
      preparation: [
        "Review possible payment plans or financing options that would make the deal easier for the client.",
        "Prepare any necessary documents or contracts for review.",
      ],
      followUp:
        "Send the client the available payment options and ensure they understand the terms clearly.",
    },
    {
      heading: "Leverage Testimonials and Case Studies",
      task: "Provide testimonials or case studies from similar companies to further validate your solution and reinforce the decision-making process.",
      preparation: [
        "Select relevant case studies or success stories that align with the client’s industry and challenges.",
        "Reach out to current or past clients to provide testimonials if necessary.",
      ],
      followUp:
        "Send the client a set of relevant case studies or testimonials to reinforce the solution’s value.",
    },
    {
      heading: "Review and Confirm All Details",
      task: "Double-check that all terms, features, and commitments in the proposal are clearly understood and agreed upon by the client.",
      preparation: [
        "Review all proposal terms, including pricing, deliverables, and support details, with the client.",
        "Address any final questions or concerns before closing the deal.",
      ],
      followUp:
        "Send a summary email confirming the final terms and get official approval to proceed.",
    },
    {
      heading: "Schedule Kickoff Meeting",
      task: "Schedule a kickoff meeting to transition from sales to implementation and start the project as soon as possible.",
      preparation: [
        "Coordinate with the project management team to schedule the kickoff meeting and allocate resources.",
        "Prepare a clear agenda for the meeting that outlines next steps, project goals, and timelines.",
      ],
      followUp:
        "Send a calendar invite for the kickoff meeting, along with any pre-meeting materials.",
    },
    {
      heading: "Close and Celebrate the Win",
      task: "Finalise the sale and celebrate the new partnership to ensure client satisfaction and set the tone for the next steps.",
      preparation: [
        "Prepare all necessary documentation for the contract and have it ready for signing.",
        "Plan a celebratory email or thank-you note for the client to express appreciation for their trust.",
      ],
      followUp:
        "Send a signed contract and a thank-you email confirming the partnership, as well as next steps in the implementation process.",
    },
    {
      heading: "Cross-Sell or Upsell Relevant Solutions",
      task: "Identify any opportunities for cross-selling or upselling relevant products or services that align with the client's needs.",
      preparation: [
        "Review the client’s profile and needs to identify additional solutions or services that could add value.",
        "Prepare a proposal or discussion points for potential upsell or cross-sell opportunities.",
      ],
      followUp:
        "Send an email or schedule a call to discuss additional offerings that complement the current solution.",
    },
    {
      heading: "Track and Document Client Feedback",
      task: "Track and document any feedback from the client throughout the process to refine the sales process and improve future interactions.",
      preparation: [
        "Set up a system for capturing client feedback during final negotiations and post-sale.",
        "Use CRM or project management tools to document the client’s responses and feedback.",
      ],
      followUp:
        "Send a feedback form after the deal closes to gather insights and improve future sales strategies.",
    },
  ];

  const atRiskOpportunitiesPlans = [
    {
      heading: "Reaffirm Value and Address Concerns",
      task: "Reach out to the client to reaffirm the value of your solution and address any current concerns they may have.",
      preparation: [
        "Review previous conversations and notes to identify any unresolved concerns or objections.",
        "Prepare a tailored communication emphasizing how your solution aligns with their current goals and challenges.",
        "Involve internal teams if necessary to provide additional information or support.",
      ],
      followUp:
        "Send a follow-up email summarizing the conversation, addressing their concerns, and restating the value proposition, within 24 hours.",
    },
    {
      heading: "Strengthen Relationship with Executive Involvement",
      task: "Involve senior leadership or a high-level executive to meet with the client to provide reassurances and discuss high-level strategic alignment.",
      preparation: [
        "Coordinate with senior leadership to schedule a meeting with the client.",
        "Provide the executive with context on the client’s current concerns and priorities.",
        "Prepare an agenda that focuses on long-term partnership opportunities and strategic value.",
      ],
      followUp:
        "Send a thank-you email after the meeting, summarizing key points discussed and reinforcing the strategic value your solution provides.",
    },
    {
      heading: "Offer Additional Incentives to Accelerate Decision",
      task: "Present a limited-time offer or additional incentives to motivate the client to move forward with the decision.",
      preparation: [
        "Evaluate feasible discount options, complimentary services, or extended support as part of the incentive.",
        "Ensure that any offered incentives are aligned with the overall business goals and won't compromise profitability.",
      ],
      followUp:
        "Send a proposal outlining the incentive, including detailed terms, to prompt the client to make a decision within a specified timeline.",
    },
    {
      heading: "Revisit Timeline and Roadmap",
      task: "Review the client’s project timeline and roadmap to understand if delays are due to internal factors or misunderstanding of the implementation process.",
      preparation: [
        "Schedule a call to revisit the client’s timeline and identify any roadblocks.",
        "Adjust your solution’s implementation plan to better align with their internal schedule if needed.",
      ],
      followUp:
        "Send an updated proposal or timeline reflecting adjustments to accommodate their concerns, within 24 hours.",
    },
    {
      heading: "Clarify the ROI and Metrics for Success",
      task: "Reiterate the expected ROI and success metrics, emphasizing how your solution will help them achieve business goals in a measurable way.",
      preparation: [
        "Prepare a detailed ROI calculation and projected business outcomes that align with their specific objectives.",
        "Provide relevant case studies or examples where your solution has delivered measurable success.",
      ],
      followUp:
        "Send a summary email highlighting the expected outcomes and success metrics, reinforcing how your solution will deliver value.",
    },
    {
      heading: "Collaborate on a Custom Solution",
      task: "Engage the client in discussions to further customize the solution to better meet their specific needs, addressing any lingering doubts about the fit of your solution.",
      preparation: [
        "Schedule a collaborative session with the client to explore customizations or additional features that could address their unique needs.",
        "Involve product or technical experts who can propose modifications or enhancements to the solution.",
      ],
      followUp:
        "Send a revised solution proposal with the customized elements discussed, ensuring alignment with their specific needs.",
    },
    {
      heading: "Ensure Clear Communication and Transparency",
      task: "Maintain clear, open communication and offer transparency regarding any potential delays or challenges in the process, building trust and addressing skepticism.",
      preparation: [
        "Be proactive in identifying any potential delays or challenges and develop a clear plan to address them.",
        "Ensure communication is consistent and transparent to avoid surprises for the client.",
      ],
      followUp:
        "Send a weekly status update, including any progress or challenges, and highlight your commitment to timely delivery.",
    },
    {
      heading: "Review and Strengthen the Proposal",
      task: "Review the current proposal with the client and make necessary adjustments to ensure it’s aligned with their budget, goals, and expectations.",
      preparation: [
        "Revisit the proposal details and pricing structure to identify any areas that need improvement.",
        "Ensure that the proposal is tailored to the client’s pain points and goals.",
      ],
      followUp:
        "Send an updated proposal with all requested changes and schedule a meeting to discuss any final adjustments.",
    },
    {
      heading: "Leverage Peer Influence or Testimonials",
      task: "Provide peer testimonials or connect the client with references from similar companies who have benefited from your solution.",
      preparation: [
        "Identify appropriate client references or peers who can provide a positive testimonial.",
        "Coordinate with the client to schedule conversations or send written testimonials from similar industry peers.",
      ],
      followUp:
        "Send a follow-up email introducing the references/testimonials, and highlight how others in their industry have seen success with your solution.",
    },
    {
      heading: "Reassess and Align Expectations",
      task: "Ensure that both parties have a clear understanding of expectations and project scope to avoid any misalignments or confusion.",
      preparation: [
        "Review the project scope, deliverables, and expectations to ensure both parties are aligned.",
        "Identify any discrepancies between client expectations and the proposed solution.",
      ],
      followUp:
        "Send a confirmation email outlining mutually agreed-upon expectations, timelines, and next steps.",
    },
  ];

  return (
    <div className={styles["container"]}>
      <RecommendedActionsForSaleHeader
        defaultCampaignName={
          selectedOpportunity?.opportunityName || "Opportunity Name"
        }
        className={styles["header"]}
      />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={[
            {
              id: 1,
              tabIcon: <img src="/gifs/ninja.gif" />,
              content: (
                <div className={classNames(styles["campaign-query-chat"])}>
                  <CampaignQueryChat
                    payload={JSON.stringify({
                      opportunities,
                      selectedOpportunity,
                    })}
                  />
                </div>
              ),
            },
          ]}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
          defaultActiveTab={null}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>{stage} STAGE</TextElement>
          <div className={styles["content"]}>
            <DisclosureComponent
              content={
                <div className={styles["plan"]}>
                  <div className={classNames(styles["consideration-stage"])}>
                    {opportunities?.length > 0 && (
                      <Select
                        options={
                          opportunities
                            ? opportunities.map((opportunity) => ({
                                value: opportunity.opportunityId,
                                label: opportunity.opportunityName,
                              }))
                            : []
                        }
                        value={selectedOpportunity?.opportunityId as string}
                        placeholderText="Filter by Opportunity"
                        onChange={(value) => {
                          const foundOpportunity = opportunities?.find(
                            (opportunity) => opportunity.opportunityId === value
                          );
                          setSelectedOpportunity(
                            foundOpportunity
                              ? foundOpportunity
                              : opportunities[0]
                          );
                        }}
                        buttonClassName={styles["select-button"]}
                        className={styles["select-menu"]}
                      />
                    )}
                    <TextElement className={styles["account-details"]}>
                      Opportunity No:{" "}
                      {selectedOpportunity
                        ? selectedOpportunity.opportunityName
                        : "Opportunity Name"}
                    </TextElement>
                    <TextElement className={styles["confidence-score-heading"]}>
                      {selectedOpportunity
                        ? "Confidence Score"
                        : "Average Confidence Score Across Opportunities"}
                    </TextElement>
                    <ConfidenceScoreBar
                      score={
                        selectedOpportunity?.confidenceScore || confidenceScore
                      }
                      onSeeMoreDetails={() => {
                        setShowDetails(true);
                      }}
                    />
                  </div>
                  <div className={styles["metric-container"]}>
                    <Metric
                      value={
                        selectedOpportunity
                          ? selectedOpportunity.estimatedRevenue
                          : metrics.totalOpportunities
                      }
                      label={
                        selectedOpportunity
                          ? "Estimated Potential Revenue"
                          : `Total Opportunities in ${
                              stage === "Consideration"
                                ? "20-40"
                                : stage === "Evaluation"
                                ? "40-60"
                                : "60-80"
                            } Stage`
                      }
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        selectedOpportunity ? (
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.3467 7.02222C3.32892 6.49778 2.68003 5.95556 2.68003 5.11111C2.68003 4.14222 3.57781 3.46667 5.08003 3.46667C6.66225 3.46667 7.24892 4.22222 7.30225 5.33333H9.2667C9.20447 3.80444 8.27114 2.4 6.41336 1.94667V0H3.7467V1.92C2.02225 2.29333 0.635586 3.41333 0.635586 5.12889C0.635586 7.18222 2.33336 8.20444 4.81336 8.8C7.03559 9.33333 7.48003 10.1156 7.48003 10.9422C7.48003 11.5556 7.04448 12.5333 5.08003 12.5333C3.24892 12.5333 2.52892 11.7156 2.43114 10.6667H0.475586C0.582253 12.6133 2.04003 13.7067 3.7467 14.0711V16H6.41336V14.0889C8.1467 13.76 9.52448 12.7556 9.52448 10.9333C9.52448 8.40889 7.36448 7.54667 5.3467 7.02222Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4ZM8 12.8C6.66667 12.8 5.53333 12.3333 4.6 11.4C3.66667 10.4667 3.2 9.33333 3.2 8C3.2 6.66667 3.66667 5.53333 4.6 4.6C5.53333 3.66667 6.66667 3.2 8 3.2C9.33333 3.2 10.4667 3.66667 11.4 4.6C12.3333 5.53333 12.8 6.66667 12.8 8C12.8 9.33333 12.3333 10.4667 11.4 11.4C10.4667 12.3333 9.33333 12.8 8 12.8ZM8 11.2C8.88 11.2 9.63333 10.8867 10.26 10.26C10.8867 9.63333 11.2 8.88 11.2 8C11.2 7.12 10.8867 6.36667 10.26 5.74C9.63333 5.11333 8.88 4.8 8 4.8C7.12 4.8 6.36667 5.11333 5.74 5.74C5.11333 6.36667 4.8 7.12 4.8 8C4.8 8.88 5.11333 9.63333 5.74 10.26C6.36667 10.8867 7.12 11.2 8 11.2ZM8 9.6C7.56 9.6 7.18333 9.44333 6.87 9.13C6.55667 8.81667 6.4 8.44 6.4 8C6.4 7.56 6.55667 7.18333 6.87 6.87C7.18333 6.55667 7.56 6.4 8 6.4C8.44 6.4 8.81667 6.55667 9.13 6.87C9.44333 7.18333 9.6 7.56 9.6 8C9.6 8.44 9.44333 8.81667 9.13 9.13C8.81667 9.44333 8.44 9.6 8 9.6Z"
                              fill="white"
                            />
                          </svg>
                        )
                      }
                    />

                    <Metric
                      value={
                        selectedOpportunity
                          ? selectedOpportunity.daysSinceLastActivity
                          : metrics.daysSinceLastActivity
                      }
                      label={
                        selectedOpportunity
                          ? "Days Since Last Activity"
                          : "Estimated Potential Revenue"
                      }
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        selectedOpportunity ? (
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.52234 3.15613L3.21325 1.85431L2.1878 2.87977L3.48962 4.18159L4.52234 3.15613ZM2.51507 7.2725H0.333252V8.72704H2.51507V7.2725ZM9.06052 0.0361328H7.60598V2.18159H9.06052V0.0361328ZM14.4787 2.87977L13.4533 1.85431L12.1514 3.15613L13.1769 4.18159L14.4787 2.87977ZM12.1442 12.8434L13.446 14.1525L14.4714 13.127L13.1623 11.8252L12.1442 12.8434ZM14.1514 7.2725V8.72704H16.3333V7.2725H14.1514ZM8.33325 3.63613C5.92598 3.63613 3.96962 5.5925 3.96962 7.99977C3.96962 10.407 5.92598 12.3634 8.33325 12.3634C10.7405 12.3634 12.6969 10.407 12.6969 7.99977C12.6969 5.5925 10.7405 3.63613 8.33325 3.63613ZM7.60598 15.9634H9.06052V13.818H7.60598V15.9634ZM2.1878 13.1198L3.21325 14.1452L4.51507 12.8361L3.48962 11.8107L2.1878 13.1198Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.67995 7.02222C3.66217 6.49778 3.01328 5.95556 3.01328 5.11111C3.01328 4.14222 3.91106 3.46667 5.41328 3.46667C6.9955 3.46667 7.58217 4.22222 7.6355 5.33333H9.59995C9.53773 3.80444 8.60439 2.4 6.74662 1.94667V0H4.07995V1.92C2.3555 2.29333 0.968838 3.41333 0.968838 5.12889C0.968838 7.18222 2.66662 8.20444 5.14662 8.8C7.36884 9.33333 7.81328 10.1156 7.81328 10.9422C7.81328 11.5556 7.37773 12.5333 5.41328 12.5333C3.58217 12.5333 2.86217 11.7156 2.76439 10.6667H0.808838C0.915505 12.6133 2.37328 13.7067 4.07995 14.0711V16H6.74662V14.0889C8.47995 13.76 9.85773 12.7556 9.85773 10.9333C9.85773 8.40889 7.69773 7.54667 5.67995 7.02222Z"
                              fill="white"
                            />
                          </svg>
                        )
                      }
                    />

                    <Metric
                      value={
                        selectedOpportunity
                          ? metrics?.timeInPipeline
                          : metrics.averageDaysInStage
                      }
                      label={
                        selectedOpportunity
                          ? "Time in Pipeline (Days Since Creation)"
                          : "Average Days in Stage"
                      }
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        selectedOpportunity ? (
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.01345 7.02222C2.99567 6.49778 2.34678 5.95556 2.34678 5.11111C2.34678 4.14222 3.24456 3.46667 4.74678 3.46667C6.329 3.46667 6.91567 4.22222 6.969 5.33333H8.93344C8.87122 3.80444 7.93789 2.4 6.08011 1.94667V0H3.41344V1.92C1.689 2.29333 0.302334 3.41333 0.302334 5.12889C0.302334 7.18222 2.00011 8.20444 4.48011 8.8C6.70233 9.33333 7.14678 10.1156 7.14678 10.9422C7.14678 11.5556 6.71122 12.5333 4.74678 12.5333C2.91567 12.5333 2.19567 11.7156 2.09789 10.6667H0.142334C0.249001 12.6133 1.70678 13.7067 3.41344 14.0711V16H6.08011V14.0889C7.81345 13.76 9.19122 12.7556 9.19122 10.9333C9.19122 8.40889 7.03122 7.54667 5.01345 7.02222Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.85584 3.15613L3.54675 1.85431L2.52129 2.87977L3.82311 4.18159L4.85584 3.15613ZM2.84857 7.2725H0.666748V8.72704H2.84857V7.2725ZM9.39402 0.0361328H7.93948V2.18159H9.39402V0.0361328ZM14.8122 2.87977L13.7867 1.85431L12.4849 3.15613L13.5104 4.18159L14.8122 2.87977ZM12.4777 12.8434L13.7795 14.1525L14.8049 13.127L13.4958 11.8252L12.4777 12.8434ZM14.4849 7.2725V8.72704H16.6667V7.2725H14.4849ZM8.66675 3.63613C6.25948 3.63613 4.30311 5.5925 4.30311 7.99977C4.30311 10.407 6.25948 12.3634 8.66675 12.3634C11.074 12.3634 13.0304 10.407 13.0304 7.99977C13.0304 5.5925 11.074 3.63613 8.66675 3.63613ZM7.93948 15.9634H9.39402V13.818H7.93948V15.9634ZM2.52129 13.1198L3.54675 14.1452L4.84857 12.8361L3.82311 11.8107L2.52129 13.1198Z"
                              fill="white"
                            />
                          </svg>
                        )
                      }
                    />

                    <Metric
                      value={
                        selectedOpportunity
                          ? metrics?.engagementFrequency
                          : metrics.averageEstimatedRevenuePerOpportunity
                      }
                      label={
                        selectedOpportunity
                          ? "Engagement Frequency (total activities logged)"
                          : "Average Estimated Revenue Per Opportunity"
                      }
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        selectedOpportunity ? (
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.71787 12.0503C4.11285 11.9667 2.75862 11.3481 1.65517 10.1945C0.551724 9.04091 0 7.65324 0 6.0315C0 4.35961 0.585162 2.93851 1.75549 1.76818C2.92581 0.597857 4.34692 0.0126953 6.01881 0.0126953C7.64054 0.0126953 9.02821 0.56442 10.1818 1.66787C11.3354 2.77132 11.954 4.12555 12.0376 5.73056L9.93103 5.1036C9.71369 4.20078 9.24556 3.46097 8.52665 2.88417C7.80773 2.30737 6.97179 2.01897 6.01881 2.01897C4.91536 2.01897 3.97074 2.41186 3.18495 3.19765C2.39916 3.98344 2.00627 4.92806 2.00627 6.0315C2.00627 6.98448 2.29467 7.82043 2.87147 8.53934C3.44828 9.25826 4.18809 9.72638 5.09091 9.94373L5.71787 12.0503ZM10.2821 12.276L9.52978 14.5582C9.44619 14.7922 9.28736 14.9051 9.05329 14.8967C8.81923 14.8883 8.6604 14.7671 8.5768 14.5331L6.29467 6.93433C6.2278 6.75042 6.26959 6.58323 6.42006 6.43276C6.57053 6.28229 6.73772 6.24049 6.92163 6.30737L14.5204 8.5895C14.7544 8.67309 14.8757 8.83192 14.884 9.06599C14.8924 9.30005 14.7795 9.45888 14.5455 9.54248L12.2633 10.2948L15.5737 13.6052C15.8579 13.8894 16 14.2154 16 14.5832C16 14.951 15.8579 15.2771 15.5737 15.5613C15.2894 15.8455 14.9592 15.9876 14.5831 15.9876C14.2069 15.9876 13.8767 15.8455 13.5925 15.5613L10.2821 12.276Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.3467 7.02222C3.32892 6.49778 2.68003 5.95556 2.68003 5.11111C2.68003 4.14222 3.57781 3.46667 5.08003 3.46667C6.66225 3.46667 7.24892 4.22222 7.30225 5.33333H9.2667C9.20447 3.80444 8.27114 2.4 6.41336 1.94667V0H3.7467V1.92C2.02225 2.29333 0.635586 3.41333 0.635586 5.12889C0.635586 7.18222 2.33336 8.20444 4.81336 8.8C7.03559 9.33333 7.48003 10.1156 7.48003 10.9422C7.48003 11.5556 7.04448 12.5333 5.08003 12.5333C3.24892 12.5333 2.52892 11.7156 2.43114 10.6667H0.475586C0.582253 12.6133 2.04003 13.7067 3.7467 14.0711V16H6.41336V14.0889C8.1467 13.76 9.52448 12.7556 9.52448 10.9333C9.52448 8.40889 7.36448 7.54667 5.3467 7.02222Z"
                              fill="white"
                            />
                          </svg>
                        )
                      }
                    />

                    <Metric
                      value={metrics.winRateProjection}
                      label="Win Rate Projection"
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.88881 6.93333V3.55556H2.11103V4.44444C2.11103 5.00741 2.27399 5.51481 2.59992 5.96667C2.92584 6.41852 3.35547 6.74074 3.88881 6.93333ZM12.7777 6.93333C13.311 6.74074 13.7407 6.41852 14.0666 5.96667C14.3925 5.51481 14.5555 5.00741 14.5555 4.44444V3.55556H12.7777V6.93333ZM7.44436 14.2222V11.4667C6.71844 11.3037 6.07029 10.9963 5.49992 10.5444C4.92955 10.0926 4.51103 9.52593 4.24436 8.84444C3.13325 8.71111 2.20362 8.22593 1.45547 7.38889C0.707326 6.55185 0.333252 5.57037 0.333252 4.44444V3.55556C0.333252 3.06667 0.507326 2.64815 0.855474 2.3C1.20362 1.95185 1.62214 1.77778 2.11103 1.77778H3.88881C3.88881 1.28889 4.06288 0.87037 4.41103 0.522222C4.75918 0.174074 5.1777 0 5.66659 0H10.9999C11.4888 0 11.9073 0.174074 12.2555 0.522222C12.6036 0.87037 12.7777 1.28889 12.7777 1.77778H14.5555C15.0444 1.77778 15.4629 1.95185 15.811 2.3C16.1592 2.64815 16.3333 3.06667 16.3333 3.55556V4.44444C16.3333 5.57037 15.9592 6.55185 15.211 7.38889C14.4629 8.22593 13.5333 8.71111 12.4221 8.84444C12.1555 9.52593 11.737 10.0926 11.1666 10.5444C10.5962 10.9963 9.94807 11.3037 9.22214 11.4667V14.2222H11.8888C12.1407 14.2222 12.3518 14.3074 12.5221 14.4778C12.6925 14.6481 12.7777 14.8593 12.7777 15.1111C12.7777 15.363 12.6925 15.5741 12.5221 15.7444C12.3518 15.9148 12.1407 16 11.8888 16H4.7777C4.52584 16 4.31473 15.9148 4.14436 15.7444C3.97399 15.5741 3.88881 15.363 3.88881 15.1111C3.88881 14.8593 3.97399 14.6481 4.14436 14.4778C4.31473 14.3074 4.52584 14.2222 4.7777 14.2222H7.44436Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />

                    <Metric
                      value={
                        selectedOpportunity
                          ? metrics?.closureReadiness
                          : metrics.opportunitiesFlaggedForInactivity
                      }
                      label={
                        selectedOpportunity
                          ? "Closure Readiness"
                          : "Oppurtunities Flagged for Inactivity"
                      }
                      className={styles["metric"]}
                      iconContainerClassName={styles["icon-wrapper"]}
                      icon={
                        selectedOpportunity ? (
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.2478 6.73134C11.9361 7.04311 11.4164 7.04311 11.1047 6.73134L7.53135 3.16602L1.89558 8.79379L1.36798 8.29017C0.432679 7.35487 0.432679 5.83601 1.36798 4.90071L4.75744 1.51126C5.69273 0.575958 7.2116 0.575958 8.14689 1.51126L12.2478 5.60419C12.5596 5.91595 12.5596 6.41958 12.2478 6.73134ZM12.8074 5.03661C13.4309 5.66014 13.4309 6.67538 12.8074 7.29892C11.7922 8.31415 10.721 7.47478 10.5451 7.29892L7.53935 4.29317L3.08669 8.74583C2.77492 9.0576 2.77492 9.56122 3.08669 9.87298C3.39846 10.1848 3.90208 10.1848 4.22184 9.87298L7.91507 6.17976L8.48264 6.74733L4.78941 10.4406C4.47765 10.7523 4.47765 11.2559 4.78941 11.5677C5.10118 11.8795 5.6048 11.8795 5.92456 11.5677L9.61779 7.87448L10.1854 8.44206L6.49213 12.1353C6.18037 12.4471 6.18037 12.9507 6.49213 13.2624C6.8039 13.5742 7.30752 13.5742 7.61929 13.2624L11.3125 9.56921L11.8801 10.1368L8.18686 13.83C7.8751 14.1418 7.8751 14.6454 8.18686 14.9572C8.49863 15.2689 9.00225 15.2689 9.31402 14.9572L15.965 8.29017C16.9003 7.35487 16.9003 5.83601 15.965 4.90071L12.5756 1.51126C11.6563 0.591946 10.1694 0.575958 9.23408 1.46329L12.8074 5.03661Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="17"
                            height="14"
                            viewBox="0 0 17 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.666504 13.909H16.6665L8.6665 0.0908203L0.666504 13.909ZM9.39378 11.7272H7.93923V10.2726H9.39378V11.7272ZM9.39378 8.81809H7.93923V5.909H9.39378V8.81809Z"
                              fill="white"
                            />
                          </svg>
                        )
                      }
                    />
                  </div>
                  {stageDetails && (
                    <DetailCards
                      onSeeLess={() => {
                        setShowDetails(false);
                      }}
                      details={stageDetails}
                    />
                  )}
                  <Button
                    className={styles["generate-action-plan"]}
                    disabled={selectedOpportunity ? false : true}
                    onClick={() => {
                      setLoadingText("Generating Recommended Actions  ");
                      setTimeout(() => {
                        setShowActionPlans(true);
                        setLoadingText("");
                      }, 2000);
                    }}
                  >
                    Generate Action Plan
                  </Button>
                </div>
              }
              defaultOpen
              header={
                <TextElement>
                  Opportunities Overview{" "}
                  {selectedOpportunity
                    ? ""
                    : stage === "Consideration"
                    ? "(20-40 Stage)"
                    : stage === "Evaluation"
                    ? "(40-60 Stage)"
                    : "(60-80 Stage)"}
                </TextElement>
              }
              headerClassName={styles["closure-header"]}
              contentClassName={styles["closure-content"]}
              openHeaderClassName={styles["open-closure-header"]}
              className={styles["disclosure"]}
            />

            {loadingText && (
              <div className={styles["loading-container"]}>
                <TextElement>{loadingText}</TextElement>
                <DottedLoader />
              </div>
            )}

            <DisclosureComponent
              header={<TextElement>Action Plan</TextElement>}
              headerClassName={styles["closure-header"]}
              contentClassName={styles["closure-content"]}
              openHeaderClassName={styles["open-closure-header"]}
              className={styles["disclosure"]}
              content={
                <ActionPlans
                  plans={(() => {
                    const getRandomItems = (array: any[], count: number) => {
                      const shuffled = [...array].sort(
                        () => 0.5 - Math.random()
                      );
                      return shuffled.slice(0, count);
                    };
                    switch (stage) {
                      case "Consideration":
                        return getRandomItems(considerationPlans, 5);
                      case "Evaluation":
                        return getRandomItems(
                          likelyToCloseOpportunitiesPlans,
                          5
                        );
                      case "Decision":
                        return getRandomItems(atRiskOpportunitiesPlans, 5);
                      default:
                        return [];
                    }
                  })()}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedActionsForSale;
