import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  includeData: {
    emailContentIds: number[];
    followUpEmailIds: number[];
    extendedPaymentTerms: boolean;
    pilotProgram: boolean;
    customBundles: boolean;
    suggestedDiscounts: boolean;
    pricingAndDiscounts: boolean;
    followUpProtocol: boolean;
    tieredPricing: boolean;
  };
}

const initialState: State = {
  includeData: {
    emailContentIds: [],
    followUpEmailIds: [],
    extendedPaymentTerms: false,
    pilotProgram: false,
    customBundles: false,
    suggestedDiscounts: false,
    pricingAndDiscounts: false,
    followUpProtocol: false,
    tieredPricing: false,
  },
};

const negotiateStrategySlice = createSlice({
  name: "negotiateStrategySlice",
  initialState,
  reducers: {
    updateNegotiateStrategyData: (
      state,
      action: PayloadAction<State["includeData"]>
    ) => {
      state.includeData = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateNegotiateStrategyData } = negotiateStrategySlice.actions;

export default negotiateStrategySlice.reducer;
