import React, { useEffect } from "react";
import ListingCard from "../ListingCard/ListingCard";

import styles from "./common.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import classNames from "classnames";
import Card, { CardType } from "../Card/Card";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import ShowMoreLess from "../ShowMoreLess/ShowMoreLess";
import TextElement from "../TextElement/TextElement";

interface Props {
  className?: string;
  cardType: CardType;
  segmentationId: number;
  backgroundColor?: string;
  borderColor?: string;
}

const COLORS: {
  [key: number]: string;
} = {
  1: "#38B05A",
  2: "#EB8D01",
  3: "#EB2701",
  4: "#02285E",
};

const BORDER_COLORS: {
  [key: number]: string;
} = {
  1: "rgba(56, 176, 90, 0.50)",
  2: "rgba(235, 141, 1, 0.50)",
  3: "rgba(235, 39, 1, 0.50)",
  4: "rgba(2, 40, 94, 0.50)",
};

const BACKGROUND_COLORS: {
  [key: number]: string;
} = {
  1: "rgba(56, 176, 90, 0.10)",
  2: "rgba(235, 141, 1, 0.10)",
  3: "rgba(235, 39, 1, 0.10)",
  4: "rgba(2, 40, 94, 0.10)",
};

const AudienceSegmentation: React.FC<Props> = (props) => {
  const { className, cardType, segmentationId } = props;

  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  const dispatch = useAppDispatch();

  const { audienceSegments, loading, error } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [audienceSegmentById, setAudienceSegmentById] = React.useState<{
    [key: string]: string;
    Industry: string;
  }>({
    Industry: "",
  });

  useEffect(() => {
    if (segmentationId !== undefined) {
      setAudienceSegmentById(audienceSegments[segmentationId] as any);
    }
    if (audienceSegments) {
    }
  }, [audienceSegments, segmentationId]);

  const handlePin = (isPinned: boolean, id: number) => {
    if (!selectedCampaign) return null;
    if (!isPinned) {
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            audienceSegmentation:
              selectedCampaign.includedData.audienceSegmentation.filter(
                (segmentId) => segmentId !== id
              ),
          },
        })
      );
    } else {
      if (selectedCampaign.includedData.audienceSegmentation.includes(id))
        return;
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            audienceSegmentation: [
              ...selectedCampaign.includedData.audienceSegmentation,
              id,
            ],
          },
        })
      );
    }
  };

  if (!audienceSegmentById) return <div></div>;
  const {
    Content: content,
    Industry: industry,
    "Names of All Companies": nameOfAllCompanies,
    "Number of All Companies": companiesLength,
    "Number of Companies Using Salesforce": salesForceUsers,
    "Number of Companies Hiring for Salesforce Related Positions":
      hiringPositions,
    "Company Revenue": revenue,
    "Company Size": size,
    State: state,
    Relevance: relevance,
    ...rest
  } = audienceSegmentById as {
    [key: string]: string;
  };

  return (
    <Card
      className={classNames(styles["segment"], className)}
      type={cardType}
      onPin={() => handlePin(true, segmentationId)}
      unPin={() => handlePin(false, segmentationId)}
    >
      <TextElement className={styles["segment-heading"]}>
        Segment {String.fromCharCode(65 + segmentationId)}: {industry}
      </TextElement>

      <div className={styles["segment-overview"]}>
        <div
          className={styles["icon-container"]}
          style={{
            backgroundColor: COLORS[(segmentationId as number) + 1],
          }}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.66667V0H0V30H30V6.66667H15ZM6 26.6667H3V23.3333H6V26.6667ZM6 20H3V16.6667H6V20ZM6 13.3333H3V10H6V13.3333ZM6 6.66667H3V3.33333H6V6.66667ZM12 26.6667H9V23.3333H12V26.6667ZM12 20H9V16.6667H12V20ZM12 13.3333H9V10H12V13.3333ZM12 6.66667H9V3.33333H12V6.66667ZM27 26.6667H15V23.3333H18V20H15V16.6667H18V13.3333H15V10H27V26.6667ZM24 13.3333H21V16.6667H24V13.3333ZM24 20H21V23.3333H24V20Z"
              fill="white"
            />
          </svg>
        </div>
        <div
          className={classNames(styles["overview"])}
          style={{
            backgroundColor: BACKGROUND_COLORS[(segmentationId as number) + 1],
          }}
        >
          <TextElement>
            Company Size: <span>{size}</span>
          </TextElement>
          <TextElement>
            Revenue: <span>{revenue}</span>
          </TextElement>
          <TextElement>
            Location: <span>{state}</span>
          </TextElement>
        </div>
      </div>
      <div
        className={styles["divider"]}
        style={{
          borderColor: BORDER_COLORS[(segmentationId as number) + 1],
        }}
      ></div>
      <div className={styles["segments-statisitcs"]}>
        <TextElement className={classNames(styles["item"])}>
          Total Companies:
          <span>&nbsp; {companiesLength}</span>
        </TextElement>
        <TextElement className={classNames(styles["item"])}>
          Salesforce Users:
          <span>&nbsp; {salesForceUsers}</span>
        </TextElement>
        <TextElement className={classNames(styles["item"])}>
          Currently Hiring for Salesforce:
          <span>&nbsp; {hiringPositions}</span>
        </TextElement>

        <TextElement className={classNames(styles["item"])}>
          Companies 
          <span>
            &nbsp;
            <ShowMoreLess
              text={
                Array.isArray(nameOfAllCompanies)
                  ? nameOfAllCompanies.join(", ")
                  : nameOfAllCompanies
              }
              maxWords={10}
            />
          </span>
        </TextElement>
      </div>
    </Card>
  );
};

export default AudienceSegmentation;
