import React from "react";

import styles from "./HandlingObjections.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface HandlingObjectionsProps {
  className?: string;
  cardType: CardType;
}

const HandlingObjections: React.FC<HandlingObjectionsProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        handlingObjections: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Handling Objections
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Common Objections
        </TextElement>
        <div className={styles["content"]}>
          <TextElement className={styles["heading"]}>
            High Costs: Highlight the long-term savings and ROI.
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Script:</span> “I understand
              the concern about costs. Many of our clients have realised
              significant savings and a high ROI after implementing our 5G
              solutions. I can share some case studies with you.”
            </li>
          </ul>
          <TextElement className={styles["heading"]}>
            High Costs: Highlight the long-term savings and ROI.
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Script:</span> “I understand
              the concern about costs. Many of our clients have realised
              significant savings and a high ROI after implementing our 5G
              solutions. I can share some case studies with you.”
            </li>
          </ul>
          <TextElement className={styles["heading"]}>
            Technical Concerns: Provide case studies or technical documentation
            to address concerns.
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Script:</span> “Our
              technical documentation and case studies might address some of
              your concerns. I can send these over to you. When would be a good
              time to discuss this further?”
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Response Techniques
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            <span className={styles["highlight"]}>Active Listening:</span>{" "}
            Ensure you understand the lead’s concerns before responding. this
            further?”
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Empathy: </span> Show
            understanding and relate to their challenges.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Information:</span> Provide
            clear, concise information to address objections.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default HandlingObjections;
