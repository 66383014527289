import React, { useEffect } from "react";

import styles from "./Metric.module.scss";

import classNames from "classnames";
import TextElement from "../../../TextElement/TextElement";

interface MetricProps {
  value: string | number | null | undefined;
  label: string;
  className?: string;
  icon?: React.ReactNode;
  iconContainerClassName?: string;
}

const Metric: React.FC<MetricProps> = ({
  value,
  label,
  className,  
  icon,
  iconContainerClassName,
}) => {
  return (
    <div className={classNames(styles["container"], className)}>
      <span
        className={classNames(styles["icon-container"], iconContainerClassName)}
      >
        {icon}
      </span>
      <TextElement className={styles["value"]}>{value}</TextElement>
      <TextElement className={styles["label"]}>{label}</TextElement>
    </div>
  );
};

export default Metric;
