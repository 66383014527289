import HomeIcon from "../../icons/HomeIcon";
import TwoStarIcon from "../../icons/TwoStarIcon";
import OfferCard from "./components/OfferCard";
import Accordion from "./components/AccordionItem";
import { MdPushPin } from "react-icons/md";
import StageCard from "./components/StageCard";
import FollowUpCard from "./components/FollowUpCard";

type IOfferCardData = {
  id: number;
  title: string;
  description: string;
};

const OfferCardData: IOfferCardData[] = [
  {
    id: 1,
    title: "Flexible Payment Option",
    description:
      "Extend your payment terms to 45 days to accommodate your financial needs",
  },
  {
    id: 2,
    title: "Referral Bonus (2 Months Free)",
    description: "Refer a client and get 2 months of free services.",
  },
  {
    id: 3,
    title: "Service Enhancement/Free Upgrade",
    description:
      "Get a free upgrade to our Premium Support tier for the next 3 months",
  },
  {
    id: 4,
    title: "Payment Discount",
    description:
      "Renew your contract with us today and enjoy a 10% discount on your monthly fees",
  },
];

const accordionData = [
  {
    title: (
      <div className="flex items-center">
        <p className="font-medium text-xl ">Flexible Payment Option Offer</p>
        <button className="bg-blue-primary py-3 px-5 ml-2 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
          Change Offer
        </button>
      </div>
    ),
    content: (
      <div className="rounded-[10px] bg-white shadow-[5px_5px_3px_0px_rgba(0,0,0,0.05)] p-3">
        <div className="p-3">
          <div className="flex items-center justify-between mb-3">
            <h4 className="text-blue-primary font-bold text-base ">
              Offer Overview
            </h4>
            <button>
              <MdPushPin />
            </button>
          </div>
          <p className="text-base">
            This offer is aimed at retaining high-risk profile customers by
            offering a flexible payment option, allowing them to extend payment
            terms to 45 days to better meet their financial needs. The goal is
            to remind them of the value of our service while addressing their
            financial concerns, making it easier for them to continue their
            partnership with us.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: <p className="font-medium text-xl">Stages and Actions</p>,
    content: (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <StageCard />
        <FollowUpCard />
      </div>
    ),
  },
];
const CampaignDashboard = () => {
  return (
    <div className="h-full">
      <div className="w-full bg-white py-5 px-4 border-b-2 flex items-center justify-between">
        <button className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md">
          <HomeIcon />
          <span className="text-gray-700">Back</span>
        </button>
        <div>
          <p className="m-0"> Retention Campaign Name Here</p>
        </div>
        <div className="flex items-center gap-3">
          <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
            Submit for approval
          </button>
        </div>
      </div>
      <div className="flex h-[90vh]">
        <div className="min-w-[60px]  bg-[#FAFAFA]">
          <div
            className="p-3 bg-[#eb270119]
"
          >
            icon
            {/* <img src="" alt="" className="max-w-[20px] " /> */}
          </div>
        </div>
        <div>
          <div className="mt-5 px-4 bg-[#FAFAFA]">
            <div className="bg-blue-primary text-white p-2 rounded-tl-md rounded-tr-md">
              <h2 className="uppercase font-bold text-base">
                Your RETENTION CAMPAIGN
              </h2>
            </div>
            <div className="p-3">
              <div className="flex items-center">
                <TwoStarIcon />
                <h4 className="text-blue-primary font-bold text-xl ms-3 mt-2">
                  Generated Offers
                </h4>
              </div>
              <p className="text-base">
                We’ve generated these targeted retention offers to help keep
                your high-risk customers engaged. Pick the one you think will
                resonate best—hover over each offer to see more details.
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
                {OfferCardData.map((data) => (
                  <OfferCard key={data.id} data={data} />
                ))}
              </div>
            </div>

            <div className="w-full ">
              <Accordion items={accordionData} />
            </div>
          </div>

          <div className="w-full my-2 px-4">
            <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
              Build Offer Journey
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDashboard;
//

// CAMPAIGN_DASHBOARD: {
//   path: "/campaign-dashboard",
//   element: <CampaignDashboard />,
// },

//
