import React from "react";
import styles from "./FieldWithIcon.module.scss";
import TextElement from "../../../TextElement/TextElement";

interface FieldWithIconProps {
  label: string;
  icon: React.ReactNode;
  text: string | React.ReactNode;
}

const FieldWithIcon: React.FC<FieldWithIconProps> = ({ label, icon, text }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["label-container"]}>
        <div className={styles.icon}>{icon}</div>
        <TextElement className={styles.label}>{label}</TextElement>
      </div>
      <TextElement className={styles.text}>{text}</TextElement>
    </div>
  );
};

export default FieldWithIcon;
