import React, { useEffect, useState } from "react";
import {
  FaEnvelope,
  FaClock,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa"; // Icons for different types of nodes
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { Journey } from "../../pages/JourneyManagment/useJourneyManagment";
import {
  CustomEdge,
  CustomNode,
  CustomNodeTypeVariants,
} from "../ReactFlowContainer/types";

import styles from "./VisualizeUserJourney.module.scss";
import DisclosureComponent from "../Disclosure/Disclosure";
import TextElement from "../TextElement/TextElement";
import Card from "../Card/Card";
import classNames from "classnames";
import { group } from "console";
import StagesAndActions from "./StagesAndActions";

interface JourneyBlueprint {
  Nodes: CustomNode[];
  Edges: CustomEdge[];
}

interface JourneyBlueprint {
  Nodes: CustomNode[];
  Edges: CustomEdge[];
}

interface VisualizeUserJourneyProps {
  journey: Journey;
}

const VisualizeUserJourney: React.FC<VisualizeUserJourneyProps> = ({
  journey,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles["container"]}>
      <DisclosureComponent
        header={<TextElement>Overview</TextElement>}
        content={
          <Card type="pinable" className={styles["overview-card"]}>
            <TextElement className={styles["title"]}>
              {journey?.title}
            </TextElement>
            <TextElement>{journey?.description}</TextElement>
          </Card>
        }
        headerClassName={styles["closure-header"]}
        contentClassName={styles["closure-content"]}
        openHeaderClassName={styles["open-closure-header"]}
        defaultOpen
      />
      <DisclosureComponent
        header={<TextElement>Stages and Actions</TextElement>}
        content={<StagesAndActions blueprint={journey?.blueprint} />}
        headerClassName={styles["closure-header"]}
        contentClassName={styles["closure-content"]}
        openHeaderClassName={styles["open-closure-header"]}
        defaultOpen
      />
      <Button
        onClick={() => {
          navigate(
            `${ROUTE_DEFINITIONS.CAMPAIGN_TREE_VIEW.path}?id=${journey.id}`
          );
        }}
        className={styles["build-journey-button"]}
      >
        Build Nurture Journey
      </Button>
    </div>
  );
};

export default VisualizeUserJourney;
