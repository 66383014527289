import React from "react";
import styles from "./MarkerterViewLayout.module.scss";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import classNames from "classnames";

interface MarkerterViewLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const MarkerterViewLayout: React.FC<MarkerterViewLayoutProps> = ({
  children,
  header,  
  className,
  contentClassName,
}) => {
  return (
    <ProtectedRoleRoute requiredRole={[UserRoleType.MARKETING]}>
      <div className={classNames(styles["container"], className)}>
        <SideBar className={styles["side-bar"]} />
        {header && (
          <Header className={styles["header"]} titleContent={header} />
        )}
        <div className={classNames(styles["content"], contentClassName)}>{children}</div>
      </div>
    </ProtectedRoleRoute>
  );
};

export default MarkerterViewLayout;
