import React, { useEffect } from "react";

import CampaignHeader from "../CampaignHeader/CampaignHeader";
import SideBar from "../SideBar/SideBar";

import styles from "./ShortlistStageLayout.module.scss";

import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import MQLInsights from "../CampaignReports/MQLInsights";
import TailoredStrategyForBANTQualification from "../TailoredStrategyForBANTQualification/TailoredStrategyForBANTQualification";
import TailoredStrategyForBANTQualificationBuilder from "../TailoredStrategyForBANTQualificationBuilder/TailoredStrategyForBANTQualificationBuilder";
import ShortlistStageLayoutSideBar from "./componenets/ShortlistStageLayoutSideBar/ShortlistStageLayoutSideBar";
import ShortlistStageLayoutHeader from "./componenets/ShortlistStageLayoutHeader/ShortlistStageLayoutHeader";

interface Props {
  children?: React.ReactNode;
  operation: "report" | "content";
}

const ShortlistStageLayout: React.FC<Props> = (props) => {
  const { operation } = props;

  const [open, setOpen] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  useEffect(() => {
    dispatch(setSelectedCampaign(""));
  }, []);

  return (
    <ProtectedRoleRoute
      requiredRole={[UserRoleType.MARKETING]}
      className={styles["campaign-builder-layout"]}
    >
      <div className={styles["container"]}>
        <ShortlistStageLayoutHeader className={styles["header"]} />
        <div className={styles["content"]}>
          <ShortlistStageLayoutSideBar className={styles["side-bar"]} />
          {operation === "report" && (
            <MQLInsights className={styles["mql-insights"]} />
          )}
          {operation === "content" && (
            <>
              <TailoredStrategyForBANTQualification />
              <TailoredStrategyForBANTQualificationBuilder
                open={open}
                onClose={() => setOpen(!open)}
              />
            </>
          )}
        </div>
      </div>
    </ProtectedRoleRoute>
  );
};

export default ShortlistStageLayout;
