import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  includeData: {
    leadIds: number[];
    objectionIds: number[];
    suggestionIds: number[];
    timeliine: boolean;
    strategyIds: number[];
    customizedSolutionIds: number[];
  };
}

const initialState: State = {
  includeData: {
    leadIds: [],
    objectionIds: [],
    suggestionIds: [],
    timeliine: false,
    strategyIds: [],
    customizedSolutionIds: [],
  },
};

const salesQualifiedStrategySlice = createSlice({
  name: "salesQualifiedStrategySlice",
  initialState,
  reducers: {
    updateSalesQualifiedStrategyData: (
      state,
      action: PayloadAction<State["includeData"]>
    ) => {
      state.includeData = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateSalesQualifiedStrategyData } =
  salesQualifiedStrategySlice.actions;

export default salesQualifiedStrategySlice.reducer;
