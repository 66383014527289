import React from "react";

const MarketingDashboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.77778 12.9444H4V6.72222H5.77778V12.9444ZM9.33333 12.9444H7.55556V4.05556H9.33333V12.9444ZM12.8889 12.9444H11.1111V9.38889H12.8889V12.9444ZM15.1111 14.8111H1.77778V2.27778H15.1111V14.8111ZM15.1111 0.5H1.77778C0.8 0.5 0 1.3 0 2.27778V14.7222C0 15.7 0.8 16.5 1.77778 16.5H15.1111C16.0889 16.5 16.8889 15.7 16.8889 14.7222V2.27778C16.8889 1.3 16.0889 0.5 15.1111 0.5Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default MarketingDashboardIcon;
