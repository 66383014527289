import React, { useEffect } from "react";
import styles from "./SalesQualifiedLeadsBuilder.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Button from "../Button/Button";
import { useAppSelector } from "../../redux/hooks";
import { useSalesQualifiedLeadsQueryChat } from "../SalesQualifiedLeadsQueryChat/useSalesQualifiedLeadsQueryChat";
import ObjectionHandling from "../SalesQualifiedLeadsQueryChat/componenets/ObjectionHandling/ObjectionHandling";
import DisclosureComponent from "../Disclosure/Disclosure";
import ValuePropositionCard from "../SalesQualifiedLeadsQueryChat/componenets/ValuePropositionCard/ValuePropositionCard";
import CustomizedSolutions from "../SalesQualifiedLeadsQueryChat/componenets/CustomizedSolutions/CustomizedSolutions";
import StrategyCard from "../SalesQualifiedLeadsQueryChat/componenets/StrategyCard/StrategyCard";
import Timeline from "../SalesQualifiedLeadsQueryChat/componenets/Timeline/Timeline";
import Suggestions from "../SalesQualifiedLeadsQueryChat/componenets/Suggestions/Suggestions";

interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

const SalesQualifiedLeadsBuilder: React.FC<Props> = ({
  open,
  onClose,
  className,
}: Props) => {
  const { includeData } = useAppSelector(
    (state) => state.salesQualifiedStrategy
  );

  const {
    objections,
    leads,
    suggestions,
    timelineEvents,
    customizedSolutions,
    strategies,
  } = useSalesQualifiedLeadsQueryChat();

  return (
    <div className={classNames(styles["container"], className)}>
      <button
        className={classNames(
          styles["collapse-btn"],
          open ? styles["open"] : styles["closed"]
        )}
        onClick={onClose}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <path
            d="M17.655 2.655L15 0L0 15L15 30L17.655 27.345L5.31 15L17.655 2.655Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        className={classNames(
          styles["campaign-builder"],
          open ? styles["open"] : styles["closed"]
        )}
      >
        <div className={styles["header"]}>
          <TextElement className={styles["header-text"]}>
            Your Campaign Assets
          </TextElement>
          <Button className={styles["submit-btn"]} disabled={true}>
            <TextElement>Submit for approval ✅</TextElement>
          </Button>
        </div>
        <div className={styles["campaign-builder-content"]}>
          {includeData?.leadIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Value Proposition</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {leads
                    ?.filter((obj) => includeData?.leadIds?.includes(obj.id))
                    .map((ob, index) => (
                      <ValuePropositionCard
                        cardType="ellipses"
                        className={styles["card"]}
                        id={ob.id}
                        leadType={ob.leadType}
                        leadsText={ob.leadsText}
                      />
                    ))}
                </div>
              }
            />
          )}
          {includeData?.customizedSolutionIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Customized Solution</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {customizedSolutions
                    ?.filter((obj) =>
                      includeData?.customizedSolutionIds?.includes(obj.id)
                    )
                    .map((ob, index) => (
                      <CustomizedSolutions
                        cardType="ellipses"
                        className={styles["card"]}
                        leadType={ob.leadType}
                        items={ob.items}
                        id={ob.id}
                      />
                    ))}
                </div>
              }
            />
          )}

          {includeData?.strategyIds?.length > 0 && (
            <DisclosureComponent
              header={
                <TextElement>Strategy to Engage Decision Makers</TextElement>
              }
              content={
                <div className={styles["campaign-data"]}>
                  {strategies
                    ?.filter((obj) =>
                      includeData?.strategyIds?.includes(obj.id)
                    )
                    .map((ob, index) => (
                      <StrategyCard
                        cardType="ellipses"
                        className={styles["card"]}
                        strategies={ob.strategies}
                        id={ob.id}
                        offer={ob.offer}
                        strategyType={ob.strategyType}
                      />
                    ))}
                </div>
              }
            />
          )}

          {includeData?.timeliine && (
            <DisclosureComponent
              header={<TextElement>Timeline</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <Timeline
                    cardType="ellipses"
                    className={styles["card"]}
                    events={timelineEvents}
                  />
                </div>
              }
            />
          )}

          {includeData?.suggestionIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Suggestions</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {suggestions
                    ?.filter((obj) =>
                      includeData?.suggestionIds?.includes(obj.id)
                    )
                    .map((sug, index) => (
                      <Suggestions
                        className={styles["card"]}
                        heading={sug.heading}
                        description={sug.description}
                        cardType="ellipses"
                        id={sug.id}
                      />
                    ))}
                </div>
              }
            />
          )}

          {includeData?.objectionIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Objection Handling</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {objections
                    ?.filter((obj) =>
                      includeData?.objectionIds?.includes(obj.id)
                    )
                    .map((ob, index) => (
                      <ObjectionHandling
                        cardType="ellipses"
                        className={styles["card"]}
                        heading={ob.heading}
                        id={ob.id}
                        key={ob.id}
                        objectionDescription={ob.description}
                      />
                    ))}
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesQualifiedLeadsBuilder;
