import React, { useEffect, useState } from "react";

import styles from "./CampaignPlanList.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TableComponent from "../../componenets/Table/Table";
import SearchInput from "../../componenets/SearchInput/SearchInput";
import BinIcon from "../../icons/BinIcon";
import {
  Campaign,
  CampaignStatusType,
  deleteCampaign,
  fetchCampaigns,
  setSelectedCampaign,
} from "../../redux/campaignPinningSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Tag from "../../componenets/Tag/Tag";
import NinjaResponseModal from "../../componenets/NinjaResponseModal/NinjaResponseModal";
import DeleteCampaignModal from "../../componenets/DeleteCampaignModal/DeleteCampaignModal";
import { UserRoleType } from "../../redux/authSlice";
import CampaignPlanEllipses from "./componenets/CampaignPlanEllipses/CampaignPlanEllipses";
import classNames from "classnames";
import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import { clearCampaignQueryAnalysis } from "../../redux/campaignPlanAnalysisQueryChat";
import { clearContentGenerationState } from "../../redux/contentGenerationSlice";

interface Props {
  userRole: UserRoleType;
}

const CampaignPlanList: React.FC<Props> = (props) => {
  const { userRole } = props;
  const dispatch = useAppDispatch();
  const { campaigns } = useAppSelector((state) => state.campaignData);
  const navigate = useNavigate();

  const { posts } = useAppSelector((state) => state.contentGeneration);

  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState<boolean>(false);
  const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] =
    useState<boolean>(false);
  const [deleteCampaignName, setDeleteCampaignName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[] | []>(
    campaigns
  );

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredCampaigns(campaigns);
    } else {
      setFilteredCampaigns(
        campaigns.filter((campaign) =>
          campaign.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, campaigns]);

  const handleDeleteCampaigns = async () => {
    if (selectedCampaignIds.length === 0) {
      return;
    }
    try {
      setIsDeleteConfirmModalOpen(false);
      Promise.all(
        selectedCampaignIds.map((campaignId) =>
          dispatch(deleteCampaign(campaignId)).unwrap()
        )
      ).then(() => {
        setIsDeleteSuccessModalOpen(true);
        setTimeout(() => {
          setIsDeleteSuccessModalOpen(false);
          setSelectedCampaignIds([]);
        }, 2000);
      });
    } catch (error) {
      console.error("Error deleting campaigns", error);
    }
  };

  const getTagBackgroundColor = (text: string) => {
    switch (text as CampaignStatusType) {
      case CampaignStatusType.APPROVED:
        return "#EB2701";
      case CampaignStatusType.ACTIVE:
        return "#38B05A";
      case CampaignStatusType.REJECTED:
        return "#EB8D01";
      default:
        return "#02285E";
    }
  };

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  const getUserLayoutWrapper = (children: React.ReactNode) => {
    if (userRole === UserRoleType.MARKETING)
      return (
        <MarkerterViewLayout
          header={
            <TextElement className={styles["header-text"]}>
              <button
                onClick={() => {
                  navigate(ROUTE_DEFINITIONS.HOME.path);
                }}
                className={styles["back-link"]}
              >
                Back to Main Funnel
              </button>
              <br />
              Campaigns Plans
            </TextElement>
          }
        >
          {children}
        </MarkerterViewLayout>
      );
    else if (userRole === UserRoleType.PRIMARY_APPROVER)
      return (
        <ApproverViewLayout
          header={
            <TextElement className={styles["header-text"]}>
              Campaigns Plans
            </TextElement>
          }
        >
          {children}
        </ApproverViewLayout>
      );
    else {
      return null;
    }
  };

  return getUserLayoutWrapper(
    <>
      <NinjaResponseModal
        isOpen={isDeleteSuccessModalOpen}
        closeModal={() => {}}
        heading={
          selectedCampaignIds.length === 1
            ? deleteCampaignName
            : `Selected Campaigns`
        }
        message="successfully deleted!"
      />

      <DeleteCampaignModal
        isOpen={isDeleteConfirmModalOpen}
        closeModal={() => setIsDeleteConfirmModalOpen(false)}
        message={
          selectedCampaignIds.length === 1
            ? `Are you sure you want to delete ${deleteCampaignName} campaign?`
            : `Are you sure you want to delete selected campaigns?`
        }
        onOk={handleDeleteCampaigns}
      />

      <div className={styles["filter-container"]}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className={styles["delete-btn"]}
          onClick={() => {
            if (selectedCampaignIds.length === 0) return;
            setDeleteCampaignName(
              (prevName) =>
                filteredCampaigns.find(
                  (campaign) => campaign.id === selectedCampaignIds[0]
                )?.title as string
            );
            setIsDeleteConfirmModalOpen(true);
          }}
        > 
          <BinIcon />
          <span>Delete</span>
        </button>
        {userRole === UserRoleType.MARKETING && (
          <Button
            variant="outline"
            className={classNames(styles["new-campaign-btn"])}
            onClick={() => {
              dispatch(setSelectedCampaign(""));
              dispatch(clearCampaignQueryAnalysis({}));
              dispatch(clearContentGenerationState());
              navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2222 0H1.77778C0.791111 0 0 0.8 0 1.77778V14.2222C0 15.2 0.791111 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 14.2222H1.77778V1.77778H14.2222V14.2222ZM7.11111 12.4444H8.88889V8.88889H12.4444V7.11111H8.88889V3.55556H7.11111V7.11111H3.55556V8.88889H7.11111V12.4444Z"
                fill="#EB2701"
              />
            </svg>
            <TextElement> New Campaign Plan</TextElement>
          </Button>
        )}
      </div>
      <div className={styles["table-wrapper"]}>
        <TableComponent
          rows={
            filteredCampaigns.map((campaign) => ({
              id: campaign?.id,
              name: (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setSelectedCampaign(campaign?.id as string));
                    if (userRole === UserRoleType.MARKETING) {
                      navigate(
                        `${ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_DATA.path}?campaignPlanId=${campaign?.id}`
                      );
                    } else if (
                      userRole === UserRoleType.PRIMARY_APPROVER ||
                      userRole === UserRoleType.SECONDARY_APPROVER
                    ) {
                      navigate(
                        `${ROUTE_DEFINITIONS.APPROVER_CAMPAGIN_DATA.path}?campaignPlanId=${campaign?.id}`
                      );
                    }
                  }}
                >
                  {campaign?.title}{" "}
                </a>
              ),
              createdBy: campaign?.createdBy,
              lastDateModified: new Date(
                campaign?.updatedAt as string
              ).toDateString(),
              status: (
                <div className={styles["status-container"]}>
                  <Tag
                    text={campaign?.status?.toString() as string}
                    backgroundColor={getTagBackgroundColor(
                      campaign?.status as CampaignStatusType
                    )}
                  />
                  <CampaignPlanEllipses onDeleteCampaign={() => {}} />
                </div>
              ),
            })) || []
          }
          columns={[
            {
              name: "Campaign",
              key: "name",
            },
            {
              name: "Created By",
              key: "createdBy",
            },
            {
              name: "Last Date Modified",
              key: "lastDateModified",
            },
            {
              name: "Status",
              key: "status",
            },
          ]}
          rowClassName={styles["table-row"]}
          onRowSelect={(row) => {
            setSelectedCampaignIds([...selectedCampaignIds, row.id as string]);
          }}
          onRowSelectAll={(rows) => {
            const updatedRowIds = rows.map((row) => row.id as string);
            setSelectedCampaignIds(updatedRowIds);
          }}
        />
      </div>
    </>
  );
};

export default CampaignPlanList;
