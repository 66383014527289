import React from "react";

import styles from "./Suggestions.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import TextElement from "../../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateSalesQualifiedStrategyData } from "../../../../redux/salesQualifiedStrategySlice";

interface SuggestionsProps {
  className?: string;
  cardType: CardType;
  heading: string;
  description: React.ReactNode;
  id: number;
}

const Suggestions: React.FC<SuggestionsProps> = (props) => {
  const { className, cardType, heading, description, id } = props;

  const dispatch = useAppDispatch();
  const { includeData } = useAppSelector(
    (state) => state.salesQualifiedStrategy
  );

  const handlePinned = (isPinned: boolean) => {
    let updatedSuggestionIds = [...includeData?.suggestionIds];
    if (isPinned) {
      updatedSuggestionIds?.push(id);
    } else {
      updatedSuggestionIds = updatedSuggestionIds.filter(
        (pinId) => pinId !== id
      );
    }
    includeData &&
      dispatch(
        updateSalesQualifiedStrategyData({
          ...includeData,
          suggestionIds: Array.from(new Set(updatedSuggestionIds)),
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={styles["heading-container"]}>
        <svg
          width="28"
          height="25"
          viewBox="0 0 28 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2 22.2222L7.7 14.5833L0 11.1111L7.7 7.63889L11.2 0L14.7 7.63889L22.4 11.1111L14.7 14.5833L11.2 22.2222ZM22.4 25L20.65 21.1806L16.8 19.4444L20.65 17.7083L22.4 13.8889L24.15 17.7083L28 19.4444L24.15 21.1806L22.4 25Z"
            fill="#EB2701"
          />
        </svg>

        <TextElement className={styles["heading"]}>{heading}</TextElement>
      </div>
      {description}
    </Card>
  );
};

export default Suggestions;
