import React, { useEffect } from "react";
import styles from "./CustomerSuccessContentSideBar.module.scss";
import { FaHome, FaUser, FaCog } from "react-icons/fa";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateChatScrolPosition } from "../../../../redux/CampaignQueryChatScrollControllerSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../../../pages/AppRoutes";

interface CustomerSuccessContentSideBarProps {
  className?: string;
}

enum CustomerSuccessContentSideBarItems {
  SALES_QUALIFIED_LEADS = "SALES_QUALIFIED_LEADS",
  DEVELOP_STRATEGY = "DEVELOP_STRATEGY",
  PRESENT_AND_PROVE_VALUE = "PRESENT_AND_PROVE_VALUE",
}

const CustomerSuccessContentSideBar: React.FC<
  CustomerSuccessContentSideBarProps
> = (props) => {
  const { className } = props;
  const [activeItem, setActiveItem] =
    React.useState<CustomerSuccessContentSideBarItems>(
      CustomerSuccessContentSideBarItems.SALES_QUALIFIED_LEADS
    );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  return <div className={classNames(styles["container"], className)}></div>;
};

export default CustomerSuccessContentSideBar;
