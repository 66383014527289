import React, { useState, useRef, useEffect } from "react";

import styles from "./SalesQualifiedLeadsQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ValuePropositionCard from "./componenets/ValuePropositionCard/ValuePropositionCard";

import CustomizedSolutions from "./componenets/CustomizedSolutions/CustomizedSolutions";
import StrategyCard from "./componenets/StrategyCard/StrategyCard";
import Timeline from "./componenets/Timeline/Timeline";
import Suggestions from "./componenets/Suggestions/Suggestions";
import ObjectionHandling from "./componenets/ObjectionHandling/ObjectionHandling";
import InitialOutreach from "./componenets/InitialOutreach/InitialOutreach";
import WhitePaperTopics from "./componenets/WhitePaperTopics/WhitePaperTopics";
import UsercaseStory from "./componenets/UsercaseStory/UsercaseStory";
import Presentation from "./componenets/Presentation/Presentation";
import { useSalesQualifiedLeadsQueryChat } from "./useSalesQualifiedLeadsQueryChat";
import { SyncLoader } from "react-spinners";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface SalesQualifiedLeadsQueryChatProps {
  className?: string;
  type?: "content" | "strategy";
}

const SalesQualifiedLeadsQueryChat: React.FC<
  SalesQualifiedLeadsQueryChatProps
> = (props) => {
  const { className, type = "strategy" } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    objections,
    suggestions,
    timelineEvents,
    strategies,
    customizedSolutions,
    leads,
  } = useSalesQualifiedLeadsQueryChat();

  const addPresentation = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: <Presentation className={styles["card"]} cardType="pinable" />,
      },
      ...pC,
    ]);
  };

  const addUserStory = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <UsercaseStory className={styles["card"]} cardType="pinable" />
        ),
      },
      ...pC,
    ]);
  };

  const addWhitePaperTopics = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <WhitePaperTopics className={styles["card"]} cardType="pinable" />
        ),
      },
      ...pC,
    ]);
  };

  const addInititalOutreach = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <InitialOutreach className={styles["card"]} cardType="pinable" />
        ),
      },
      ...pC,
    ]);
  };

  const addObjections = () => {
    setConversation((pC) => [
      ...objections.map(
        (ob: { heading: string; description: string; id: number }) => ({
          type: MessageType.ANSWER,
          content: (
            <ObjectionHandling
              cardType="pinable"
              className={styles["card"]}
              heading={ob.heading}
              id={ob.id}
              key={ob.id}
              objectionDescription={ob.description}
            />
          ),
        })
      ),
      ...pC,
    ]);
  };

  const addSuggestions = () => {
    setConversation((pC) => [
      ...suggestions.map(
        (sug: { heading: string; description: JSX.Element; id: number }) => ({
          type: MessageType.ANSWER,
          content: (
            <Suggestions
              className={styles["card"]}
              heading={sug.heading}
              description={sug.description}
              cardType="pinable"
              id={sug.id}
            />
          ),
        })
      ),
      ...pC,
    ]);
  };

  const addTimeline = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <Timeline
            cardType="pinable"
            className={styles["card"]}
            events={timelineEvents}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addStrategies = () => {
    setConversation((pC) => [
      ...strategies.map(
        (str: {
          strategies: string[];
          id: number;
          offer: string;
          strategyType: string;
        }) => ({
          type: MessageType.ANSWER,
          content: (
            <StrategyCard
              cardType="pinable"
              className={styles["card"]}
              strategies={str.strategies}
              id={str.id}
              offer={str.offer}
              strategyType={str.strategyType}
            />
          ),
        })
      ),
      // {
      //   type: MessageType.ANSWER,
      //   content: (
      //     <StrategyCard
      //       cardType="pinable"
      //       className={styles["card"]}
      //       strategies={[
      //         "Slow transaction speeds",
      //         "Data security and compliance issues",
      //         "High infrastructure costs",
      //       ]}
      //       id={1}
      //       strategyType="Finance"
      //       offer="Implement a high-speed, secure transaction processing system with compliance features."
      //     />
      //   ),
      // },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message={
              <TextElement className={styles["chat-text"]}>
                First, let’s identify some pain points and offer for each
                industry
              </TextElement>
            }
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message={
              <>
                <TextElement className={styles["chat-heading"]}>
                  Strategy to Engage Decision Makers
                </TextElement>
                <TextElement className={styles["chat-text"]}>
                  Engaging decision makers is all about understanding their pain
                  points and building strong relationships over time. Here’s how
                  we can approach this:
                </TextElement>
              </>
            }
          />
        ),
      },
      ...pC,
    ]);
  };

  const addCustomizedSolutions = () => {
    setConversation((pC) => [
      ...customizedSolutions.map(
        (cs: {
          leadType: string;
          items: {
            heading: string;
            content: string;
            example?: string;
          }[];
          id: number;
        }) => ({
          type: MessageType.ANSWER,
          content: (
            <CustomizedSolutions
              cardType="pinable"
              className={styles["card"]}
              leadType={cs.leadType}
              items={cs.items}
              id={cs.id}
            />
          ),
        })
      ),
      // {
      //   type: MessageType.ANSWER,
      //   content: (
      //     <CustomizedSolutions
      //       cardType="pinable"
      //       className={styles["card"]}
      //       leadType="Leads with Confirmed Budgets"
      //       items={[
      //         {
      //           content: "Provide pre-configured 5G deployment kits.",
      //           example:
      //             "'5G in 5 Days' kit with all necessary hardware and software.",
      //           heading: "Rapid Deployment Kits",
      //         },
      //         {
      //           content:
      //             "Establish a dedicated support team with a 24/7 hotline and a 4-hour on-site response time for critical issues.",
      //           example: "",
      //           heading: "Priority Support",
      //         },
      //       ]}
      //     />
      //   ),
      // },
      // {
      //   type: MessageType.ANSWER,
      //   content: (
      //     <CustomizedSolutions
      //       cardType="pinable"
      //       className={styles["card"]}
      //       leadType="Leads with Confirmed Budgets"
      //       items={[
      //         {
      //           content:
      //             "Offer flexible financing options such as CAPEX to OPEX transitions, leasing, and subscription models.",
      //           example: "Leasing option spreading the cost over three years",
      //           heading: "Financial Solutions",
      //         },
      //         {
      //           content:
      //             "Develop bespoke 5G packages that match their specific business requirements and budget constraints. ",
      //           example:
      //             "Custom package including enhanced security features and network management tools for enterprises.",
      //           heading: "Tailored Packages",
      //         },
      //       ]}
      //     />
      //   ),
      // },
      // {
      //   type: MessageType.ANSWER,
      //   content: (
      //     <ChatCard
      //       messageClass={styles["chat-message"]}
      //       message={
      //         <>
      //           <TextElement className={styles["chat-heading"]}>
      //             Customised Solutions
      //           </TextElement>
      //           <TextElement className={styles["chat-text"]}>
      //             Next up, let’s talk about how we tailor our solutions to fit
      //             the specific needs of our leads. It’s all about offering
      //             something that meets their exact requirements:
      //           </TextElement>
      //         </>
      //       }
      //     />
      //   ),
      // },
      ...pC,
    ]);
  };

  const addLeads = () => {
    setConversation((pC) => [
      ...leads?.map((lead) => ({
        type: MessageType.ANSWER,
        content: (
          <ValuePropositionCard
            cardType="pinable"
            className={styles["card"]}
            id={lead.id}
            leadType={lead.leadType}
            leadsText={lead.leadsText}
          />
        ),
      })),

      ...pC,
    ]);
  };

  const handleCreateContent = async () => {
    if (type === "strategy") {
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              ref={scrollRefs?.content}
              message={
                <>
                  With 750 SQLs generated and a strong pipeline value of
                  $750,000, we have a tremendous opportunity ahead of us. This
                  strategy is designed to help you engage these leads,
                  understand their needs, and provide tailored solutions that
                  will drive conversions and deliver value.
                  <br />
                  <br />
                  Below, you'll find a detailed breakdown of our approach. Let’s
                  dive in and make the most of our SQLs!
                </>
              }
            />
          ),
        },
        ...pC,
      ]);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  <>
                    <TextElement className={styles["chat-heading"]}>
                      Value Proposition
                    </TextElement>
                    <TextElement className={styles["chat-text"]}>
                      Alright, let’s get to the heart of it—what makes our 5G
                      solutions stand out? Here’s how we can clearly communicate
                      our unique value to different types of leads:
                    </TextElement>
                  </>
                }
              />
            ),
          },

          ...pC,
        ]);
        addLeads();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  <>
                    <TextElement className={styles["chat-heading"]}>
                      Customised Solutions
                    </TextElement>
                    <TextElement className={styles["chat-text"]}>
                      Next up, let’s talk about how we tailor our solutions to
                      fit the specific needs of our leads. It’s all about
                      offering something that meets their exact requirements:
                    </TextElement>
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        addCustomizedSolutions();
        addStrategies();

        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  <TextElement className={styles["chat-text"]}>
                    Here’s my suggested timeline for your outreach strategy 👇🏻
                  </TextElement>
                }
              />
            ),
          },
          ...pC,
        ]);

        addTimeline();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  <TextElement className={styles["chat-text"]}>
                    Here’s some other things I suggest you implement to build
                    relationship with your SQLs 👇🏻
                  </TextElement>
                }
              />
            ),
          },
          ...pC,
        ]);
        addSuggestions();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  <>
                    <TextElement className={styles["chat-heading"]}>
                      Objection Handling
                    </TextElement>
                    <TextElement className={styles["chat-text"]}>
                      Now, let’s address common objections. Here’s how we can
                      tackle potential concerns and keep the sales process
                      moving smoothly.👇🏻
                    </TextElement>
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        addObjections();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <Button
                className={classNames(
                  styles["card"],
                  styles["continue-button"]
                )}
              >
                Continue 👍🏻
              </Button>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message={
                  "With this strategy, your sales team is equipped to effectively engage and convert high-priority leads. Let’s go out there and make it happen!"
                }
              />
            ),
          },
          ...pC,
        ]);
        handleScroll("content");
      }, 2000);
    } else if (type == "content") {
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              ref={scrollRefs?.content}
              message={
                <>
                  First off, let’s start with an email drip campaign based on
                  the timeline I provided earlier. This campaign aims to engage
                  decision makers, build relationships, and convert leads over a
                  period of 10 months.
                </>
              }
            />
          ),
        },
        ...pC,
      ]);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        addInititalOutreach();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.content}
                message={
                  <>
                    Next, let’s create an outline for your product’s whitepaper.
                    You can get your marketing team to work on developing this
                    whitepaper. Here are a few topics to tackle:
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        addWhitePaperTopics();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.content}
                message={
                  <>
                    Your marketing team can also work on detailed case studies.
                    Here are a few topics to focus on:
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        addUserStory();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.content}
                message={
                  <>
                    And lastly, here’s a guide for your Pilot Program
                    presentation deck. You can also assign your marketing to
                    work on this presentation.
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        addPresentation();
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.content}
                message={
                  <>
                    With this comprehensive set of content, your sales team will
                    be well-equipped to engage leads, communicate our value
                    proposition, and drive conversions.
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        setConversation((pC) => [
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.content}
                message={
                  <>
                    Happy with all the content generated? Click continue. Need
                    more content? Let me know in the chatbox below. 👇🏻
                  </>
                }
              />
            ),
          },
          ...pC,
        ]);
        handleScroll("content");
      }, 2000);
    }
  };

  const [conversation, setConversation] = useState<Question[]>([]);

  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<HTMLDivElement>(null);

  const scrollRefs = {
    content: useRef<HTMLDivElement>(null),
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleScroll = (id: keyof typeof scrollRefs) => {
    const ref = scrollRefs[id];
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (scrollRefs?.content?.current) {
      scrollToRef(scrollRefs.content);
    }
  }, [scrollRefs?.content?.current]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  useEffect(() => {
    handleCreateContent();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}
          {conversation?.map((item: Question, index: number) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default SalesQualifiedLeadsQueryChat;
