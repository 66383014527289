import {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { CustomNode, CustomNodeTypeVariants } from "../types";

interface DnDContextType {
  type: CustomNodeTypeVariants | null;
  setType: Dispatch<SetStateAction<CustomNodeTypeVariants | null>>;
}

const DnDContext = createContext<DnDContextType>({
  type: null,
  setType: () => {},
});

interface DnDProviderProps {
  children: ReactNode;
}

export const DnDProvider: React.FC<DnDProviderProps> = ({ children }) => {
  const [type, setType] = useState<CustomNodeTypeVariants | null>(null);

  return (
    <DnDContext.Provider value={{ type, setType }}>
      {children}
    </DnDContext.Provider>
  );
};

export default DnDContext;

export const useDnD = (): DnDContextType => {
  return useContext(DnDContext);
};
