import React from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import TwoStarIcon from "../../../icons/TwoStarIcon";
import { IoFlash } from "react-icons/io5";
import { CiAt } from "react-icons/ci";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

const StageCard = () => {
  return (
    <div className="p-3 bg-white rounded-lg shadow-md border border-gray-200 mt-2">
      {/* Stage Header */}
      <div className="pb-2 rounded-t-lg">
        <h2 className="text-blue-primary font-bold">
          Stage 1: Schedule Call For Flexible Payment
        </h2>
      </div>

      {/* Entry Section */}
      <div className="">
        <div className="flex items-center">
          <div className="bg-green-500 border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full ">
              <FaArrowRight className="text-green-500 w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Entry</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(56, 176, 90, 0.05)" }}>
          <p className="">
            Triggered when an MQL is identified as having dropped off.
          </p>
        </div>
      </div>

      {/* Action Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#18A2F2] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <TwoStarIcon
                className="text-[#18A2F2] w-[12px] h-[12px]"
                fill="#18A2F2"
              />
            </div>

            <h3 className="text-white font-medium ml-2">Action</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(56, 176, 90, 0.05)" }}>
          <p className="">
            Send an email inviting the customer to schedule a call.
          </p>

          <div className="bg-white rounded-lg p-[5px]">
            <div className="  flex items-center pb-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <CiAt className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Subject</h3>
            </div>
            <div className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm">
              <p>Let's Talk About Flexible Payment Options</p>
            </div>

            <div className="  flex items-center py-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <HiOutlineMenuAlt2 className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Body</h3>
            </div>
            <div className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm">
              <p>
                Hi [Customer's First Name],
                <br />
                <br />
                We understand that flexibility is important. To better support
                you, we’re offering an extended payment term of 45 days. Let’s
                discuss how this can benefit you!
                <br />
                <br />
                Click below to schedule a call at your convenience.
                <br />
                <br />
                [Schedule a Call]
                <br />
                <br />
                Best regards,
                <br />
                Infotech Consultancy’s Support Team
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Trigger Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#FFA600] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <IoFlash className="text-[#FFA600] w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Trigger</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(255, 166, 0, 0.05)" }}>
          <p className="">
            CTA_Clicked (Customer clicks the “Schedule a Call” button).
          </p>
        </div>
      </div>

      {/* Check Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#EB6301] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <FaCheck className="text-[#EB6301] w-[12px] h-[12px]" />
            </div>

            <h3 className="text-white font-medium ml-2">Check</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(235, 99, 1, 0.05)" }}>
          <ul style={{ listStyle: "disc", margin: "auto", padding: "inherit" }}>
            <li>
              <b>Condition:</b> If the customer clicks the "Schedule a Call"
              button.
            </li>
            <li>
              <b>OnTrue:</b> Move to the next stage, FollowUpAfterCall.
            </li>
            <li>
              <b>OnFalse:</b> If no action is taken, a reminder may be set up in
              a separate campaign or added after a period of inactivity (not
              included in this journey).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StageCard;
