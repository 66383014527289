import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Import the GFM plugin

interface MarkdownPreviewProps {
  markdown: string;
}

const MarkdownPreview: React.FC<MarkdownPreviewProps> = ({ markdown }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]} // Use remarkGfm plugin for bold, tables, strikethrough, etc.
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default MarkdownPreview;
