import React from "react";

import styles from "./FollowUpProtocol.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateNegotiateStrategyData } from "../../../../redux/negotiateStrategySlice";

interface FollowUpProtocolProps {
  className?: string;
  cardType: CardType;
}

const FollowUpProtocol: React.FC<FollowUpProtocolProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateNegotiateStrategyData({
        ...includeData,
        followUpProtocol: isPinned,
      })
    );
  };
  
  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(
        styles["container"],
        className,
        styles["product-demo-invitations-content"]
      )}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Follow-Up Protocol Budgets
        </TextElement>
        <TextElement>
          <span>Objective:</span> Ensure timely and effective follow-ups to
          accelerate the closing process.
        </TextElement>
      </div>
    </Card>
  );
};

export default FollowUpProtocol;
