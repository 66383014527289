import React, { useState } from "react";
import styles from "./Input.module.scss";

import classNames from "classnames";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  error,
  className,
  type = "text",
  inputClassName,
  disabled,
  ...rest
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <div className={classNames(styles["input-field"], className)}>
      <label>{label}</label>
      <div
        className={classNames(
          styles["input-container"],
          inputClassName,
          type === "password" ? styles["password-input-container"] : "",
          disabled ? styles["disabled"] : ""
        )}
      >
        <input
          type={type === "password" && isPasswordVisible ? "text" : type}
          {...rest}
        />
        {type === "password" ? (
          !isPasswordVisible ? (
            <svg
              width="30"
              height="20"
              viewBox="0 0 30 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsPasswordVisible(true)}
              className={styles["eye-icon"]}
            >
              <path
                d="M11.7333 2.13333C15.776 2.13333 19.3813 4.40533 21.1413 8C19.3813 11.5947 15.7867 13.8667 11.7333 13.8667C7.68 13.8667 4.08533 11.5947 2.32533 8C4.08533 4.40533 7.69067 2.13333 11.7333 2.13333ZM11.7333 0C6.4 0 1.84533 3.31733 0 8C1.84533 12.6827 6.4 16 11.7333 16C17.0667 16 21.6213 12.6827 23.4667 8C21.6213 3.31733 17.0667 0 11.7333 0ZM11.7333 5.33333C13.2053 5.33333 14.4 6.528 14.4 8C14.4 9.472 13.2053 10.6667 11.7333 10.6667C10.2613 10.6667 9.06667 9.472 9.06667 8C9.06667 6.528 10.2613 5.33333 11.7333 5.33333ZM11.7333 3.2C9.088 3.2 6.93333 5.35467 6.93333 8C6.93333 10.6453 9.088 12.8 11.7333 12.8C14.3787 12.8 16.5333 10.6453 16.5333 8C16.5333 5.35467 14.3787 3.2 11.7333 3.2Z"
                fill="#A3B3C9"
              />
            </svg>
          ) : (
            <svg
              width="26"
              height="19"
              viewBox="0 0 26 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsPasswordVisible(false)}
              className={styles["eye-icon"]}
            >
              <path
                d="M12 2.18182C16.1345 2.18182 19.8218 4.50546 21.6218 8.18182C19.8218 11.8582 16.1455 14.1818 12 14.1818C7.85455 14.1818 4.17818 11.8582 2.37818 8.18182C4.17818 4.50546 7.86545 2.18182 12 2.18182ZM12 0C6.54545 0 1.88727 3.39273 0 8.18182C1.88727 12.9709 6.54545 16.3636 12 16.3636C17.4545 16.3636 22.1127 12.9709 24 8.18182C22.1127 3.39273 17.4545 0 12 0ZM12 5.45455C13.5055 5.45455 14.7273 6.67636 14.7273 8.18182C14.7273 9.68727 13.5055 10.9091 12 10.9091C10.4945 10.9091 9.27273 9.68727 9.27273 8.18182C9.27273 6.67636 10.4945 5.45455 12 5.45455ZM12 3.27273C9.29455 3.27273 7.09091 5.47636 7.09091 8.18182C7.09091 10.8873 9.29455 13.0909 12 13.0909C14.7055 13.0909 16.9091 10.8873 16.9091 8.18182C16.9091 5.47636 14.7055 3.27273 12 3.27273Z"
                fill="#A3B3C9"
              />
              <line
                x1="23.3438"
                y1="2.08642"
                x2="2.0862"
                y2="16.7469"
                stroke="#A3B3C9"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          )
        ) : null}
      </div>
      {error && <span className={styles["error"]}>{error}</span>}
    </div>
  );
};

export default InputField;
