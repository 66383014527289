import React, { useEffect } from "react";
import styles from "./NurtureStrategyBuilder.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Button from "../Button/Button";
import { useAppSelector } from "../../redux/hooks";
import EducationalContentDistribution from "../NurtureStrategyQueryChat/componenets/EducationalContentDistribution/EducationalContentDistribution";
import ProductDemoInvitations from "../NurtureStrategyQueryChat/componenets/ProductDemoInvitations/ProductDemoInvitations";
import OverallExpectedResults from "../NurtureStrategyQueryChat/componenets/OverallExpectedResults/OverallExpectedResults";

interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

const NurtureStrategyBuilder: React.FC<Props> = ({
  open,
  onClose,
  className,
}: Props) => {
  const { nurtureStrategyData, nurtureContentData } = useAppSelector(
    (state) => state.interestStage
  );

  return (
    <div className={classNames(styles["container"], className)}>
      <button
        className={classNames(
          styles["collapse-btn"],
          open ? styles["open"] : styles["closed"]
        )}
        onClick={onClose}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <path
            d="M17.655 2.655L15 0L0 15L15 30L17.655 27.345L5.31 15L17.655 2.655Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        className={classNames(
          styles["campaign-builder"],
          open ? styles["open"] : styles["closed"]
        )}
      >
        <div className={styles["header"]}>
          <TextElement className={styles["header-text"]}>
            Your Campaign Assets
          </TextElement>
          <Button className={styles["submit-btn"]} disabled={true}>
            <TextElement>Submit for approval ✅</TextElement>
          </Button>
        </div>
        <div className={styles["campaign-builder-content"]}>
          {nurtureStrategyData?.educationalContentDistribution && (
            <EducationalContentDistribution
              type="strategy"
              cardType="ellipses"
            />
          )}
          {nurtureStrategyData?.productDemoInvitation && (
            <ProductDemoInvitations type="strategy" cardType="ellipses" />
          )}
          {nurtureStrategyData?.customerTestimonialsDistribution && (
            <EducationalContentDistribution
              type="strategy"
              cardType="ellipses"
            />
          )}
          {nurtureStrategyData?.overallExpectedResults && (
            <OverallExpectedResults cardType="ellipses" />
          )}
          {nurtureContentData?.educationalContentDistribution && (
            <EducationalContentDistribution
              type="content"
              cardType="ellipses"
            />
          )}
          {nurtureContentData?.productDemoInvitation && (
            <ProductDemoInvitations type="content" cardType="ellipses" />
          )}
          {nurtureContentData?.customerTestimonialsDistribution && (
            <EducationalContentDistribution
              type="content"
              cardType="ellipses"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NurtureStrategyBuilder;
