import React from "react";

import styles from "./ContinuousImprovement.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface ContinuousImprovementProps {
  className?: string;
  cardType: CardType;
}

const ContinuousImprovement: React.FC<ContinuousImprovementProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        continuousImprovement: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Continuous Improvement
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Review and Adjust
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Regularly review call recordings and qualification outcomes to
            identify areas for improvement.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Action:</span>
            Monthly review sessions with the telemarketing team.
          </TextElement>
        </div>

        <TextElement className={styles["content-heading"]}>
          Sales Alignment
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Maintain close communication with the sales team to ensure alignment
            on lead quality and expectations.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Action:</span>
            Bi-weekly check-ins with sales leaders.
          </TextElement>
        </div>

        <TextElement className={styles["content-heading"]}>
          Lead Drop-off Rate
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Provide continuous training and updates to the telemarketing team
            based on performance data and feedback.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Action:</span>
            Quarterly training workshops focused on the latest BANT techniques
            and industry trends.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default ContinuousImprovement;
