import React, { useEffect } from "react";
import styles from "./NegotiateStrategyBuilder.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Button from "../Button/Button";
import { useAppSelector } from "../../redux/hooks";
import EducationalContentDistribution from "../NurtureStrategyQueryChat/componenets/EducationalContentDistribution/EducationalContentDistribution";
import ProductDemoInvitations from "../NurtureStrategyQueryChat/componenets/ProductDemoInvitations/ProductDemoInvitations";
import OverallExpectedResults from "../NurtureStrategyQueryChat/componenets/OverallExpectedResults/OverallExpectedResults";
import { useNegotiateStrategyQueryChat } from "../NegotiateStrategyQueryChat/useNegotiateStrategyQueryChat";
import DisclosureComponent from "../Disclosure/Disclosure";
import FollowUp from "../NegotiateStrategyQueryChat/componenets/FollowUp/FollowUp";
import FollowUpProtocol from "../NegotiateStrategyQueryChat/componenets/FollowUpProtocol/FollowUpProtocol";
import PricingAndDiscounts from "../NegotiateStrategyQueryChat/componenets/PricingAndDiscounts/PricingAndDiscounts";
import SuggestedDiscounts from "../NegotiateStrategyQueryChat/componenets/SuggestedDiscounts/SuggestedDiscounts";
import CustomBundles from "../NegotiateStrategyQueryChat/componenets/CustomBundles/CustomBundles";
import PilotProgram from "../NegotiateStrategyQueryChat/componenets/PilotProgram/PilotProgram";
import ExtendedPaymentTerms from "../NegotiateStrategyQueryChat/componenets/ExtendedPaymentTerms/ExtendedPaymentTerms";
import EmailContentTemplate from "../NegotiateStrategyQueryChat/componenets/EmailContentTemplate/EmailContentTemplate";

interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

const NegotiateStrategyBuilder: React.FC<Props> = ({
  open,
  onClose,
  className,
}: Props) => {
  const { emailsContent, followUpsContent } = useNegotiateStrategyQueryChat();
  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  useEffect(() => {
    console.log("includeData", includeData?.followUpEmailIds);
  }, [includeData]);

  return (
    <div className={classNames(styles["container"], className)}>
      <button
        className={classNames(
          styles["collapse-btn"],
          open ? styles["open"] : styles["closed"]
        )}
        onClick={onClose}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <path
            d="M17.655 2.655L15 0L0 15L15 30L17.655 27.345L5.31 15L17.655 2.655Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        className={classNames(
          styles["campaign-builder"],
          open ? styles["open"] : styles["closed"]
        )}
      >
        <div className={styles["header"]}>
          <TextElement className={styles["header-text"]}>
            Your Campaign Assets
          </TextElement>
          <Button className={styles["submit-btn"]} disabled={true}>
            <TextElement>Submit for approval ✅</TextElement>
          </Button>
        </div>
        <div className={styles["campaign-builder-content"]}>
          {includeData?.followUpProtocol && (
            <DisclosureComponent
              header={<TextElement>Follow-Up Protocol</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <FollowUpProtocol
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.followUpEmailIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Follow-Up Protocol</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {followUpsContent
                    ?.filter((obj) =>
                      includeData?.followUpEmailIds.includes(obj.id)
                    )
                    .map((followUp, index) => (
                      <FollowUp
                        cardType="ellipses"
                        className={styles["card"]}
                        id={followUp.id}
                        heading={followUp.heading}
                        timeline={followUp.timeline}
                        action={followUp.action}
                        detail={followUp.detail}
                        key={followUp.id}
                      />
                    ))}
                </div>
              }
            />
          )}

          {includeData?.pricingAndDiscounts && (
            <DisclosureComponent
              header={<TextElement>Pricing and Discounts</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <PricingAndDiscounts
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.tieredPricing && (
            <DisclosureComponent
              header={<TextElement>Tiered Pricing</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <PricingAndDiscounts
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.suggestedDiscounts && (
            <DisclosureComponent
              header={<TextElement>Suggested Discounts</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <SuggestedDiscounts
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.customBundles && (
            <DisclosureComponent
              header={<TextElement>Custom Bundles</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <CustomBundles
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.pilotProgram && (
            <DisclosureComponent
              header={<TextElement>Pilot Program</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <PilotProgram
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.extendedPaymentTerms && (
            <DisclosureComponent
              header={<TextElement>Extended Payment Terms</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  <ExtendedPaymentTerms
                    className={styles["follow-up-protocol"]}
                    cardType="ellipses"
                  />
                </div>
              }
            />
          )}

          {includeData?.emailContentIds?.length > 0 && (
            <DisclosureComponent
              header={<TextElement>Email Content</TextElement>}
              content={
                <div className={styles["campaign-data"]}>
                  {emailsContent
                    ?.filter((obj) =>
                      includeData?.emailContentIds.includes(obj.id)
                    )
                    .map((email, index) => (
                      <EmailContentTemplate
                        key={email.id}
                        heading={email.subject}
                        body={email?.body}
                        subject={email.subject}
                        cardType="ellipses"
                        className={styles["card"]}
                        id={email.id}
                      />
                    ))}
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NegotiateStrategyBuilder;
