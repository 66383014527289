import React from "react";

import styles from "./TieredPricing.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateNegotiateStrategyData } from "../../../../redux/negotiateStrategySlice";

interface TieredPricingProps {
  className?: string;
  cardType: CardType;
}

const TieredPricing: React.FC<TieredPricingProps> = (props) => {
  const { className, cardType } = props;
  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateNegotiateStrategyData({
        ...includeData,
        tieredPricing: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={classNames(styles["content-container"])}>
        <TextElement className={styles["heading"]}>Tiered Pricing</TextElement>
        <div className={styles["follow-item"]}>
          <div className={styles["icon-container"]}>
            <svg
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.048 15.1107L0.236084 8.29883V9.69044C0.236084 10.0592 0.382203 10.4141 0.646608 10.6715L6.06692 16.0918C6.60964 16.6346 7.49331 16.6346 8.03604 16.0918L12.357 11.7709C12.8997 11.2282 12.8997 10.3445 12.357 9.80176L7.048 15.1107Z"
                fill="#EB2701"
              />
              <path
                d="M6.06692 12.6139C6.60964 13.1567 7.49331 13.1567 8.03604 12.6139L12.357 8.293C12.8997 7.75027 12.8997 6.8666 12.357 6.32387L6.93667 0.903566C6.67227 0.646119 6.31741 0.5 5.94863 0.5H1.62769C0.862307 0.5 0.236084 1.12622 0.236084 1.89161V6.21255C0.236084 6.58132 0.382203 6.93618 0.646608 7.19363L6.06692 12.6139ZM3.19325 2.58741C3.67335 2.58741 4.063 2.97706 4.063 3.45716C4.063 3.93727 3.67335 4.32692 3.19325 4.32692C2.71314 4.32692 2.32349 3.93727 2.32349 3.45716C2.32349 2.97706 2.71314 2.58741 3.19325 2.58741Z"
                fill="#EB2701"
              />
            </svg>
          </div>
          <TextElement className={styles["text"]}>
            <span>Volume Discounts </span>
            <ul>
              <li>Tier 1: 5% discount for contracts over $50,000.</li>
              <li>Tier 2: 5% discount for contracts over $50,000.</li>
              <li>Tier 3: 5% discount for contracts over $50,000.</li>
            </ul>
          </TextElement>
        </div>
        <div className={styles["follow-item"]}>
          <div className={styles["icon-container"]}>
            <svg
              width="19"
              height="15"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7899 3.35202H10.4289V5.21002H18.7899V3.35202ZM18.7899 10.784H10.4289V12.642H18.7899V10.784ZM3.49861 7.06802L0.209961 3.77936L1.51985 2.46948L3.48932 4.43895L7.42828 0.5L8.73816 1.80989L3.49861 7.06802ZM3.49861 14.5L0.209961 11.2113L1.51985 9.90146L3.48932 11.8709L7.42828 7.93198L8.73816 9.24187L3.49861 14.5Z"
                fill="#EB2701"
              />
            </svg>
          </div>
          <TextElement className={styles["text"]}>
            <span>Action -</span> Apply the appropriate discount tier based on
            the contract size and clearly outline the savings in the proposal.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default TieredPricing;
