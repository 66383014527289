import React, { useEffect } from "react";

import styles from "./CampaignHeader.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ShareCampaignModal from "../ShareCampaignModal/ShareCampaignModal";
import { LuHome } from "react-icons/lu";
import { IoMdShare } from "react-icons/io";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import ApprovalCampaignModal from "../ApprovalCampaignModal/ApprovalCampaignModal";
import ApprovalSubmittedCampaignModal from "../ApprovalSubmittedCampaignModal/ApprovalSubmittedCampaignModal";

type Props = {
  className?: string;
};

const CampaignHeader: React.FC<Props> = (props) => {
  const { className } = props;
  const [campaignName, setCampaignName] =
    React.useState<string>("Untitled Campaign");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { posts } = useAppSelector((state) => state.contentGeneration);

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const [isApprovalModalOpen, setIsApprovalModalOpen] =
    React.useState<boolean>(false);
  const [isApprovalSubmittedModalOpen, setIsApprovalSubmittedModalOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.title as string);
    } else {
      setCampaignName("Untitled Campaign");
    }
  }, [selectedCampaign]);

  return (
    <div className={classNames(styles["container"], className)}>
      <ApprovalCampaignModal
        isOpen={isApprovalModalOpen}
        closeModal={() => {
          setIsApprovalModalOpen(false);
        }}
        onOk={() => {
          setIsApprovalModalOpen(false);
          setIsApprovalSubmittedModalOpen(true);
        }}
      />
      <ApprovalSubmittedCampaignModal
        isOpen={isApprovalSubmittedModalOpen}
        closeModal={() => {
          setIsApprovalSubmittedModalOpen(false);
        }}
      />

      <button
        className={styles["back-container"]}
        onClick={() =>
          navigate(ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path)
        }
      >
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L0 6L6 0L7.4 1.45L3.85 5H16V7H3.85L7.4 10.55L6 12Z"
            fill="#02285E"
          />
        </svg>
        <TextElement>Back</TextElement>
      </button>
      <input
        value={campaignName}
        className={styles["campaign-name-input"]}
        onChange={(e) => {
          selectedCampaign &&
            dispatch(
              updateCampaign({
                ...selectedCampaign,
                title: e.target.value,
              })
            );
          setCampaignName(e.target.value);
        }}
        disabled={!selectedCampaign}
      />
      <Button
        className={styles["submit-button"]}
        onClick={() => {
          setIsApprovalModalOpen(true);
        }}
        disabled={!(posts && posts.length > 0)}
      >
        Submit for approval
      </Button>
    </div>
  );
};

export default CampaignHeader;
