import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ReactFlow,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
  addEdge,
  Node,
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  ConnectionMode,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import styles from "./ReactFlowContainer.module.scss";

import {
  ConditionalNode,
  EntryNode,
  ActionNode,
  DelayNode,
  EmailContentNode,
  EndNode,
} from "./components/NodeComponents/NodeComponents";

import dagre from "dagre";
import WidgetSidebar from "./components/WidgetSidebar/WidgetSidebar";
import { useDnD } from "./context/DnDContext";
import UpdateNodeEdge from "./components/UpdateNodeEdgeData/UpdateNodeEdgeData";

import { CustomNode, CustomEdge } from "./types";

const alignNodes = (nodes: CustomNode[], edges: CustomEdge[]): CustomNode[] => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: "TB" }); // Top-to-bottom layout

  nodes.forEach((node) =>
    dagreGraph.setNode(node.id, { width: 200, height: 120 })
  );
  edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));

  dagre.layout(dagreGraph);

  return nodes.map((node) => {
    const layoutNode = dagreGraph.node(node.id);
    return {
      ...node,
      position: { x: layoutNode.x, y: layoutNode.y },
    };
  });
};

interface Props {
  journeyBlueprint: {
    Nodes: CustomNode[];
    Edges: CustomEdge[];
  };
  updatedJourneyBlueprint: (nodes: CustomNode[], edges: CustomEdge[]) => void;
}

const ReactFlowContainer: React.FC<Props> = ({
  journeyBlueprint,
  updatedJourneyBlueprint,
}) => {
  const { type } = useDnD();
  const reactFlowWrapper = useRef(null);
  const [selectedEdge, setSelectedEdge] = useState<CustomEdge | null>(null);
  const [selectedNode, setSelectedNode] = useState<CustomNode | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNode>(
    journeyBlueprint.Nodes?.map((n) => {
      return {
        ...n,
        selected: false,
      };
    }, journeyBlueprint.Edges)
  );

  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>(
    journeyBlueprint?.Edges
  );

  const { screenToFlowPosition } = useReactFlow();

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      if (!type) {
        return;
      }

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      let data = {};
      if (type == "action") {
        data = { label: `${type} node`, selected: false, action: "" };
      } else if (type == "delay") {
        data = { label: `${type} node`, selected: false, duration: "" };
      } else if (type == "email") {
        data = {
          label: `${type} node`,
          selected: false,
          subject: "",
          body: "",
          CTA: "",
        };
      } else if (type == "diamond") {
        data = {
          label: `${type} node`,
          selected: false,
        };
      } else if (type == "check" || type == "entry" || type == "end") {
        data = {
          label: `${type} node`,
          selected: false,
        };
      }

      const newNode: any = {
        id: nodes?.length ? `${type}-${nodes.length + 1}` : `${type}-1`,
        type,
        position,
        data,
      };
      setNodes([...nodes, newNode]);
    },
    [screenToFlowPosition, type]
  );

  const updateNode = (updatedNode: CustomNode) => {
    const updatedOne = nodes.findIndex((node) => node.id === updatedNode.id);

    const newNodes = [...nodes];
    newNodes[updatedOne] = updatedNode;
    setNodes(newNodes);
  };

  const updateEdge = (updatedEdge: CustomEdge) => {
    console.log("Updated Edge", updatedEdge, edges);
    const updatedOne = edges.findIndex((edge) => edge.id === updatedEdge.id);
    console.log("Updated One index: ", updatedOne);

    const newEdges = [...edges];
    newEdges[updatedOne] = updatedEdge;
    console.log("New Edges", newEdges[updatedOne]);
    setEdges(newEdges);
  };

  return (
    <div className={styles["container"]}>
      <WidgetSidebar
        onSave={() => {
          updatedJourneyBlueprint(nodes, edges);
        }}
        onAlign={() => {
          setNodes(alignNodes(nodes, edges));
        }}
      />
      <div className={styles["reactflow-wrapper"]} ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onConnect={onConnect}
          nodeTypes={{
            entry: EntryNode,
            action: ActionNode,
            delay: DelayNode,
            email: EmailContentNode,
            diamond: ConditionalNode,
            end: EndNode,
          }}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          defaultEdgeOptions={{
            type: "step",
            label: "add a label",
            markerEnd: {
              type: MarkerType.ArrowClosed,  
              color: "#b1b1b7",
            },
            focusable: true,
            style: {
              strokeWidth: 2,
            },
          }}
          onNodeClick={(event, node) => {
            setSelectedNode(node);
            setSelectedEdge(null);
            setNodes((prevNodes) =>
              prevNodes.map(
                (n) =>
                  ({
                    ...n,
                    data: {
                      ...n.data,
                      selected: n.id === node.id,
                    },
                  } as any)
              )
            );
          }}
          onEdgeClick={(event, edge) => {
            setSelectedEdge(edge);
            setSelectedNode(null);
            setNodes((prevNodes) =>
              prevNodes.map(
                (n) =>
                  ({
                    ...n,
                    data: {
                      ...n.data,
                      selected: false,
                    },
                  } as any)
              )
            );
          }}
          snapToGrid={false}
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={{ backgroundColor: "#FFF6EB" }}
          connectionMode={ConnectionMode.Loose}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
      <UpdateNodeEdge
        selectedElement={selectedNode || selectedEdge}
        updateNode={updateNode}
        updateEdge={updateEdge}
        onClose={() => {
          setSelectedNode(null);
          setSelectedEdge(null);
        }}
      />
    </div>
  );
};

export default ReactFlowContainer;
