import React, { useState } from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";

import styles from "./CompetitorSliderCard.module.scss";
import CompetitorAnalysisCard from "../CompetitorAnalysisCard/CompetitorAnalysisCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Button from "../Button/Button";
import Card, { CardType } from "../Card/Card";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  type: CardType;
}

const SLIDE_LENGTH = 3;

const CompetitorSliderCard: React.FC<Props> = (props) => {
  const { className , type} = props;
  const { competitors } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < competitors.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentCompetitors = competitors.slice(
    currentIndex,
    currentIndex + SLIDE_LENGTH
  );

  const handlePin = (isPinned: boolean) => {
    console.log(competitors);
    if (!selectedCampaign) return;
    let competitorIds = isPinned ? competitors?.map((_, index) => index) : [];
    dispatch(
      updateCampaign({
        ...selectedCampaign,
        includedData: {
          ...selectedCampaign.includedData,
          competitorIds,
        },
      })
    );
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      type={type}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      pinIconClassName={styles["pin-icon"]}
     
    >
      <div className={styles["competitors"]}>
        {currentCompetitors.map((c, index) => (
          <CompetitorAnalysisCard
            className={styles["card"]}
            id={currentIndex + index}
            cardType="default"
            key={currentIndex + index}
          />
        ))}
      </div>
      <div className={styles["navigation"]}>
        <Button onClick={handleBack} disabled={currentIndex === 0}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33325 4.26676H10.6666M1.33325 4.26676L3.99992 6.93343M1.33325 4.26676L3.99992 1.6001"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
        <Button
          onClick={handleNext}
          disabled={currentIndex >= competitors.length - SLIDE_LENGTH}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.19995 4.26676H10.5333H1.19995Z" fill="#D9D9D9" />
            <path
              d="M7.86662 6.93343L10.5333 4.26676L7.86662 6.93343Z"
              fill="#D9D9D9"
            />
            <path
              d="M7.86662 1.6001L10.5333 4.26676L7.86662 1.6001Z"
              fill="#D9D9D9"
            />
            <path
              d="M1.19995 4.26676H10.5333M10.5333 4.26676L7.86662 6.93343M10.5333 4.26676L7.86662 1.6001"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </div>
    </Card>
  );
};

export default CompetitorSliderCard;
