import React, { useEffect, useState } from "react";
import styles from "./SwotAnalysisComparison.module.scss";
import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import { SWOTAnalysisType } from "../../redux/campaignPlanAnalysisQueryChat";
import { useMyBranding } from "../../pages/MyBranding/useMyBranding";
import Switch from "react-switch";
import Modal from "../Modal/Modal";
interface Props {
  className?: string;
  cardType: CardType;
  analysisComparison: {
    company_swot: SWOTAnalysisType;
    industry_swot: SWOTAnalysisType;
  };
}

const SwotAnalysisComparison: React.FC<Props> = (props) => {
  const { className, cardType, analysisComparison } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const [companySwotToggle, setCompanySwotToggle] = useState(true);

  const { company } = useMyBranding();

  const [showDetailInfo, setShowDetailInfo] = useState(false);

  const [showMore, setShowMore] = useState({
    strengths: false,
    weaknesses: false,
    opportunities: false,
    threats: false,
  });

  const maxItemsToShow = 3;

  const getHeading = (headingKey: keyof SWOTAnalysisType) => {
    switch (headingKey) {
      case "strengths":
        return "Strengths";
      case "weaknesses":
        return "Weaknesses";
      case "opportunities":
        return "Opportunities";
      case "threats":
        return "Threats";
    }
  };

  const handlePin = (isPinned: boolean) => {
    if (selectedCampaign?.includedData) {
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            swotAnalysis: {
              opportunities: isPinned,
              strengths: isPinned,
              threats: isPinned,
              weaknesses: isPinned,
            },
          },
        })
      );
    }
  };

  const renderAnalysis = (
    swotAnalysis: SWOTAnalysisType,
    showLess: boolean
  ) => {
    return Object.entries(swotAnalysis).map(([key, value]) => {
      return (
        <div
          className={classNames(styles["swot-analysis-container"], styles[key])}
          key={key as keyof SWOTAnalysisType}
        >
          <div className={styles["content"]}>
            <TextElement className={styles["heading"]}>
              {getHeading(key as keyof SWOTAnalysisType)}
            </TextElement>
            <ul>
              {value?.slice(0, !showLess ? value?.length : maxItemsToShow).map(
                (
                  analy: {
                    pointName: string;
                    description: string;
                  },
                  index: number
                ) => (
                  <li key={index} className={styles["strategy"]}>
                    {analy?.description}
                  </li>
                )
              )}
            </ul>
          </div>
          <TextElement className={styles["swot-tag"]}>
            {key === "strengths" && "S"}
            {key === "weaknesses" && "W"}
            {key === "opportunities" && "O"}
            {key === "threats" && "T"}
          </TextElement>
        </div>
      );
    });
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      type={cardType}
    >
      <Modal
        isOpen={showDetailInfo}
        closeModal={() => {
          setShowDetailInfo(false);
        }}
        className={styles["modal"]}
      >
        <div className={styles["swot-analysis-comparison-container"]}>
          {analysisComparison?.company_swot &&
            companySwotToggle &&
            renderAnalysis(analysisComparison.company_swot, false)}
          {analysisComparison?.industry_swot &&
            !companySwotToggle &&
            renderAnalysis(analysisComparison.industry_swot, false)}
        </div>
      </Modal>
      <button
        className={styles["toggle-btn"]}
        onClick={() => setCompanySwotToggle(!companySwotToggle)}
      >
        <div className={styles["switch-filter"]}>
          <TextElement>Competitors</TextElement>

          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            checked={companySwotToggle}
            onChange={(value) => {
              setCompanySwotToggle(value);
            }}
            onColor="#EB2701"
            className={styles["switch"]}
          />
          <TextElement> {company?.name || "Company"} </TextElement>
        </div>
      </button>
      <div className={styles["swot-analysis-comparison-container"]}>
        {analysisComparison?.company_swot &&
          companySwotToggle &&
          renderAnalysis(analysisComparison.company_swot, true)}
        {analysisComparison?.industry_swot &&
          !companySwotToggle &&
          renderAnalysis(analysisComparison.industry_swot, true)}
      </div>
      <button
        className={styles["show-detail-btn"]}
        onClick={() => setShowDetailInfo(true)}
      >
        see more...
      </button>
    </Card>
  );
};

export default SwotAnalysisComparison;
