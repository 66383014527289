import React from "react";

const SalesStrategiesIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 9L0 7V3L3.5 1L7 3V7L3.5 9ZM3.5 6.7L5 5.85V4.15L3.5 3.3L2 4.15V5.85L3.5 6.7ZM14.5 9.775V7.45L18 9.5V16.5L12 20L6 16.5V9.5L9.5 7.45V9.775L8 10.65V15.35L12 17.675L16 15.35V10.65L14.5 9.775ZM11 12V0H20L18 3L20 6H13V12H11Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default SalesStrategiesIcon;
