import React from "react";

const CompanyProfileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9611 4.44444V4.59444H11.1111H22.0722V19.85H0.15V0.15H10.9611V4.44444ZM4.44444 17.9278H4.59444V17.7778V15.5556V15.4056H4.44444H2.22222H2.07222V15.5556V17.7778V17.9278H2.22222H4.44444ZM4.44444 13.4833H4.59444V13.3333V11.1111V10.9611H4.44444H2.22222H2.07222V11.1111V13.3333V13.4833H2.22222H4.44444ZM4.44444 9.03889H4.59444V8.88889V6.66667V6.51667H4.44444H2.22222H2.07222V6.66667V8.88889V9.03889H2.22222H4.44444ZM4.44444 4.59444H4.59444V4.44444V2.22222V2.07222H4.44444H2.22222H2.07222V2.22222V4.44444V4.59444H2.22222H4.44444ZM8.88889 17.9278H9.03889V17.7778V15.5556V15.4056H8.88889H6.66667H6.51667V15.5556V17.7778V17.9278H6.66667H8.88889ZM8.88889 13.4833H9.03889V13.3333V11.1111V10.9611H8.88889H6.66667H6.51667V11.1111V13.3333V13.4833H6.66667H8.88889ZM8.88889 9.03889H9.03889V8.88889V6.66667V6.51667H8.88889H6.66667H6.51667V6.66667V8.88889V9.03889H6.66667H8.88889ZM8.88889 4.59444H9.03889V4.44444V2.22222V2.07222H8.88889H6.66667H6.51667V2.22222V4.44444V4.59444H6.66667H8.88889ZM20 17.9278H20.15V17.7778V6.66667V6.51667H20H11.1111H10.9611V6.66667V8.88889V9.03889H11.1111H13.1833V10.9611H11.1111H10.9611V11.1111V13.3333V13.4833H11.1111H13.1833V15.4056H11.1111H10.9611V15.5556V17.7778V17.9278H11.1111H20ZM15.7056 10.9611V9.03889H17.6278V10.9611H15.7056ZM15.7056 15.4056V13.4833H17.6278V15.4056H15.7056Z"
        fill="#EB2701"
        stroke="white"
        stroke-width="0.3"
      />
    </svg>
  );
};

export default CompanyProfileIcon;
