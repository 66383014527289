import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import axios from "axios";

type RiskProfileType = "Low Risk" | "Medium Risk" | "High Risk";

interface RiskProfile {
  offer: string;
  offerDetails: string;
  type: string;
  riskProfile: RiskProfileType;
  tags: string;
  engagementScore: string;
  noOfServiceComplaints: string;
  satisfactionScore: string;
}

const useRiskProfile = () => {
  const [riskProfiles, setRiskProfiles] = useState<RiskProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [error, setError] = useState<string | null>(null);
  const httpService = new HttpService();

  const fetchRiskProfile = async (riskProfileType: RiskProfileType) => {
    try {
      setLoading(true);
      const response = await httpService.post<
        RiskProfile[],
        {
          query: string;
        }
      >("api/execute-raw-sql/", {
        query: `select * from offers where risk_profile='${riskProfileType}';`,
      });

      if (response?.data) {
        setRiskProfiles(response.data);
      }
    } catch (error) {
      console.error("Error fetching risk profile", error);
    } finally {
      setLoading(false);
    }
  };

  return { riskProfiles, loading, error, fetchRiskProfile };
};

export default useRiskProfile;
