import React, { useEffect } from "react";

import CampaignHeader from "../CampaignHeader/CampaignHeader";
import SideBar from "../SideBar/SideBar";

import styles from "./DevelopStrategyStageLayout.module.scss";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";

import InsightsFromKnownContacts from "../CampaignReports/InsightsFromKnownContacts";
import NurtureStrategyBuilder from "../NurtureStrategyBuilder/NurtureStrategyBuilder";
import NurtureStrategyQueryChat from "../NurtureStrategyQueryChat/NurtureStrategyQueryChat";
import DevelopStrategySideBar from "./componenets/DevelopStrategySideBar/DevelopStrategySideBar";
import DevelopStrategyHeader from "./componenets/DevelopStrategyHeader/DevelopStrategyHeader";
import SalesQualifiedLeadsReport from "../CampaignReports/SalesQualifiedLeads";
import SalesQualifiedLeadsQueryChat from "../SalesQualifiedLeadsQueryChat/SalesQualifiedLeadsQueryChat";
import SalesQualifiedLeadsBuilder from "../SalesQualifiedLeadsBuilder/SalesQualifiedLeadsBuilder";

interface Props {
  children?: React.ReactNode;
  operation: "content" | "strategy";
}

const DevelopStrategyStageLayout: React.FC<Props> = (props) => {
  const { operation } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  useEffect(() => {
    dispatch(setSelectedCampaign(""));
  }, []);

  const getContentJsx = () => {
    switch (operation) {
      case "content":
        return;
      case "strategy":
        return (
          <div className={styles["content"]}>
            <DevelopStrategySideBar className={styles["side-bar"]} />
            <SalesQualifiedLeadsQueryChat
              className={styles["nurture-strategy-chat"]}
            />
            <SalesQualifiedLeadsBuilder
              open={open}
              onClose={() => setOpen(!open)}
            />
          </div>
        );
    }
  };

  const getContainerClassName = () => {
    switch (operation) {
      case "content":
        return styles["container-content"];
      case "strategy":
        return styles["container-strategy"];
    }
  };

  return (
    <ProtectedRoleRoute
      requiredRole={[UserRoleType.MARKETING]}
      className={styles["campaign-builder-layout"]}
    >
      <div className={getContainerClassName()}>
        <DevelopStrategyHeader className={styles["header"]} />
        {getContentJsx()}
      </div>
    </ProtectedRoleRoute>
  );
};

export default DevelopStrategyStageLayout;
