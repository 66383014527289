export const useNegotiateStrategyQueryChat = () => {
  const emailsContent = [
    {
      id: 4,
      heading: "Final Decision Follow-Up",
      subject: "Finalising Your 5G Corporate Solution",
      body: (
        <>
          Hi [Client Name], <br />
          <br />
          I hope all is well. As we approach the final decision stage for your
          5G Corporate Solution, I wanted to reiterate our commitment to
          providing a solution that truly aligns with your business objectives.
          <br />
          <br />
          We’ve structured our offer to deliver maximum value, and I’m confident
          this partnership will be highly beneficial. If there’s anything else
          you need before making your decision, I’m here to assist. <br />
          <br />
          Please let me know if you’re ready to move forward, and we can
          finalize the details promptly. <br />
          Looking forward to connecting with you. Best regards, <br /> <br />
          Best regards, <br />
          [Your Name] <br />
          [Your Position] <br />
          [Your Contact Information]
        </>
      ),
    },
    {
      id: 3,
      heading: "Pre-Negotiation Follow-Up",
      subject: "Preparing for Our 5G Solution Discussion",
      body: (
        <>
          Hi [Client Name], <br />
          <br />
          As we prepare for our upcoming discussion, I wanted to ensure we’re
          aligned on the key aspects of the 5G Corporate Solution proposal. This
          is a great opportunity to address any concerns you might have and to
          fine-tune the solution to fit your business needs perfectly.
          <br />
          <br />
          Please feel free to share any specific points you’d like to cover, and
          I’ll ensure we’re ready to make the most of our meeting. <br />
          <br />
          Looking forward to a productive conversation.
          <br /> <br />
          Best regards, <br />
          [Your Name] <br />
          [Your Position] <br />
          [Your Contact Information]
        </>
      ),
    },
    {
      id: 2,
      heading: "Proposal Follow-Up",
      subject: "Review of Your 5G Corporate Solution Proposal",
      body: (
        <>
          Hi [Client Name], <br />
          <br />
          I wanted to check in regarding the proposal we shared for your 5G
          Corporate Solution. We’ve tailored it specifically to meet the unique
          needs of [Client’s Business], and I’m confident it offers significant
          value.
          <br />
          <br />
          Please let me know if you’ve had a chance to review it, or if there
          are any areas you’d like to discuss further. I’m available to walk
          through the proposal at your convenience.
          <br />
          <br />
          Looking forward to a productive conversation.
          <br /> <br />
          Best regards, <br />
          [Your Name] <br />
          [Your Position] <br />
          [Your Contact Information]
        </>
      ),
    },
    {
      id: 1,
      heading: "Initial Contact Follow-Up",
      subject: "Unlock the Future with Our Cutting-Edge 5G Solutions",
      body: (
        <>
          Hi [Client Name], <br />
          <br />
          I hope you're doing well. Following up on our initial conversation
          about our 5G Corporate Solution, I wanted to ensure you have all the
          information needed to consider how this technology can benefit
          [Client’s Business]. <br />
          <br />
          If you have any questions or need further details, I’d be happy to
          discuss them with you. Let me know a convenient time for a quick call.{" "}
          <br />
          Looking forward to a productive conversation.
          <br /> <br />
          Best regards, <br />
          [Your Name] <br />
          [Your Position] <br />
          [Your Contact Information]
        </>
      ),
    },
  ];

  const followUpsContent = [
    {
      id: 1,
      heading: "Final Decision Follow-Up",
      timeline: "Within 10 days of proposal submission.",
      action:
        "Reach out to finalise the contract and address any last-minute concerns.",
      detail:
        "Ensure all contractual details are clear and confirm the client’s decision to proceed.",
    },
    {
      id: 2,
      heading: "Pre-Negotiation Follow-Up",
      timeline: "Within 7 days of the proposal follow-up.",
      action:
        "Review any client feedback or objections and adjust the proposal as needed.",
      detail:
        "Provide additional information or revisions based on client feedback and prepare for negotiation discussions.",
    },
    {
      id: 3,
      heading: "Proposal Contact Follow-Up",
      timeline: "Within 48 hours of sending the proposal.",
      action:
        "Schedule a follow-up call or meeting to discuss the proposal and address any questions or concerns.",
      detail:
        "Prepare to discuss key proposal elements and highlight how the solution meets the client’s specific needs.",
    },
    {
      id: 4,
      heading: "Initial Contact Follow-Up",
      timeline: "Within 24 hours of receiving an SQO.",
      action:
        "Send a personalised email or make a phone call to acknowledge the opportunity and outline next steps.",
      detail:
        "Include a summary of the client’s expressed needs and the proposed timeline for the next steps.",
    },
  ];

  return {
    emailsContent,
    followUpsContent,
  };
};
