import React from "react";

import styles from "./Preparation.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface PreparationProps {
  className?: string;
  cardType: CardType;
}

const Preparation: React.FC<PreparationProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        preparation: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Preparation
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Review Lead Information
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Ensure telemarketing representatives have access to detailed lead
            information, including:
          </TextElement>
          <ul>
            <li>
              <span className={styles["highlight"]}>Source:</span> Email
              Campaigns (500), Social Media (300), Webinars (200), Organic
              Search (150), Paid Ads (100).
            </li>
            <li>
              <span className={styles["highlight"]}>Industry:</span>{" "}
              Telecommunications (400), Technology (300), Healthcare (250),
              Manufacturing (200), Others (100).
            </li>
            <li>
              <span className={styles["highlight"]}>
                Geographical Distribution:
              </span>{" "}
              New South Wales (400), Victoria (300), Queensland (200), Western
              Australia (150), South Australia (100), Others (100).
            </li>
            <li>
              <span className={styles["highlight"]}>Engagement History:</span>{" "}
              Whitepapers Downloaded (600), Webinars Attended (200), Website
              Visits (1,500).
            </li>
            <li>
              <span className={styles["highlight"]}>Lead Score:</span> 80-100
              Points (300), 60-79 Points (500), 40-59 Points (300), Below 40
              Points (150).
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Training
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              Conduct training sessions on BANT criteria and effective
              questioning techniques.
            </li>
            <li>
              Provide scripts and role-playing scenarios to practice handling
              various lead responses.
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default Preparation;
