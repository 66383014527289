import React, { useEffect } from "react";

import styles from "./EducationalContentDistribution.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import {
  setNurtureContentData,
  setNurtureStrategyData,
} from "../../../../redux/interestStageSlice";

interface EducationalContentDistributionProps {
  className?: string;
  cardType: CardType;
  type?: "strategy" | "content";
}

const EducationalContentDistribution: React.FC<
  EducationalContentDistributionProps
> = (props) => {
  const { className, cardType, type = "content" } = props;

  const dispatch = useAppDispatch();

  const { nurtureStrategyData, nurtureContentData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    if (type === "strategy" && nurtureStrategyData) {
      dispatch(
        setNurtureStrategyData({
          ...nurtureStrategyData,
          educationalContentDistribution: isPinned,
        })
      );
    } else if (type === "content" && nurtureContentData) {
      dispatch(
        setNurtureContentData({
          ...nurtureContentData,
          educationalContentDistribution: isPinned,
        })
      );
    }
  };

  if (type === "content") {
    return (
      <Card
        type={cardType}
        onPin={() => handlePinned(true)}
        unPin={() => handlePinned(false)}
        className={classNames(styles["container"], className)}
      >
        <div>
          <TextElement className={styles["heading"]}>
            Week 1-2: Educational Content Distribution
          </TextElement>
          <TextElement className={styles["sub-heading-text"]}>
            The Future of 5G in Telecommunications
          </TextElement>
        </div>
        <div className={styles["content-container"]}>
          <TextElement className={styles["content-heading"]}>
            Article
          </TextElement>
          <div className={styles["content"]}>
            <TextElement className={styles["heading"]}>
              Title: The Future of 5G in Telecommunications
            </TextElement>
            <br />
            <TextElement className={styles["heading"]}>
              Introduction
            </TextElement>
            <TextElement>
              The telecommunications industry is on the brink of a major
              transformation with the advent of 5G technology. In this article,
              we explore how 5G is set to revolutionise telecom operations,
              enhance connectivity, and drive innovation.
            </TextElement>

            <br />
            <TextElement className={styles["heading"]}>Body</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>
                  The Evolution of 5G:
                </span>{" "}
                Discuss the history and development of 5G technology.
              </li>
              <li>
                <span className={styles["highlight"]}>Key Benefits:</span>{" "}
                Highlight the key benefits of 5G, including faster speeds, lower
                latency, and increased capacity.
              </li>
              <li>
                <span className={styles["highlight"]}>Case Studies:</span>{" "}
                Include real-world examples of companies successfully
                implementing 5G.
              </li>
              <li>
                <span className={styles["highlight"]}>Expert Opinions:</span>{" "}
                Feature quotes and insights from industry leaders. Conclusion:
                The future of 5G in telecommunications is bright, with endless
                possibilities for innovation and growth.
              </li>
            </ul>
          </div>
          <TextElement className={styles["content-heading"]}>
            LinkedIn Post
          </TextElement>
          <div className={styles["content"]}>
            <TextElement>
              <span className={styles["highlight"]}>Caption:</span> 5G is here
              to change the telecom landscape. Learn about its impact and future
              potential in our latest article. [Read More] #Telecom #5G
              #Innovation
            </TextElement>
            <img src="contentImages/image1.png" alt="LinkedIn Post" />
          </div>
          <TextElement className={styles["content-heading"]}>
            Twitter
          </TextElement>
          <div className={styles["content"]}>
            <TextElement>
              Discover the future of 5G in telecommunications. Read our in-depth
              article now! #5G #Telecom #Innovation
              <span className={styles["highlight"]}>[Read More] Link:</span>
            </TextElement>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Week 1-2: Educational Content Distribution
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Action: Send Now
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Topics</TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>
                "The Future of 5G in Telecommunications":
              </span>{" "}
              An in-depth article on how 5G technology is poised to
              revolutionize the telecom industry, with case studies and expert
              opinions.
            </li>
            <li>
              <span className={styles["highlight"]}>
                "How IoT is Transforming Network Management":
              </span>{" "}
              A whitepaper detailing the integration of IoT in telecom,
              including benefits, challenges, and real-world applications.
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Channels
        </TextElement>
        <div className={styles["content"]}>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>Email</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>Subject Line:</span>{" "}
                "Discover the Future of 5G in Telecommunications"
              </li>
              <li>
                <span className={styles["highlight"]}>Body:</span>
                "Dear [Lead Name], as a leader in the telecom industry, it's
                crucial to stay ahead of technological advancements. Dive into
                our latest article on the future of 5G and see how it can impact
                your business.{" "}
                <span className={styles["sub-heading-text"]}>[Read More]</span>"
              </li>
              <li>
                <span className={styles["highlight"]}>Call to Action:</span>
                "Download our whitepaper on IoT integration in telecom and learn
                how to optimize your network management.{" "}
                <span className={styles["sub-heading-text"]}>
                  [Download Now]
                </span>
                "
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Social Media
            </TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>LinkedIn Post:</span> "5G
                is here to change the telecom landscape. Learn about its impact
                and future potential in our latest article.{" "}
                <span className={styles["sub-heading-text"]}>[Read More]</span>"
              </li>
              <li>
                <span className={styles["highlight"]}>Twitter Post:</span>
                "Unlock the power of IoT in telecom. Download our new whitepaper
                now! #Telecom #IoT{" "}
                <span className={styles["sub-heading-text"]}>
                  [Download Here]
                </span>
                "
              </li>
            </ul>
          </div>
        </div>
        <TextElement className={styles["content-heading"]}>
          Metrics to Track
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>Email open rates:</span>{" "}
              Target 30%
            </li>
            <li>
              <span className={styles["highlight"]}>
                Click-through rates (CTR):
              </span>{" "}
              Target 15%
            </li>
            <li>
              <span className={styles["highlight"]}>
                Social media engagement (likes, shares, comments):
              </span>{" "}
              Target 10% increase
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Expected Results
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            <span className={styles["sub-heading-text"]}>
              Increase engagement rates by 20%{" "}
            </span>{" "}
            through industry-specific content, leading to higher lead scores.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default EducationalContentDistribution;
