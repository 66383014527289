import React, { useEffect, useState } from "react";
import styles from "./CreateNurtureCampaign.module.scss";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { TextElement } from "../../componenets";
import classNames from "classnames";
import CreateNurtureCampaignHeader from "./CreateNurtureCampaignHeader/CreateNurtureCampaignHeader";
import useJourneyManagement from "../JourneyManagment/useJourneyManagment";
import VisualizeUserJourney from "../../componenets/VisualizeUserJourney/VisualizeUserJourney";
import { useLocation } from "react-router-dom";

const CreateNurtureCampaign: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [loadingText, setLoadingText] = React.useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const productId = query.get("product");

  const { fetchJourneyByProductId, selectedJourney, handleSaveJourney } =
    useJourneyManagement();

  useEffect(() => {
    if (productId) {
      setLoadingText("Generating Nurture Campaign");
      fetchJourneyByProductId(productId).then(() => {
        setLoadingText("");
      });
    }
  }, [productId]);

  return (
    <div className={styles["container"]}>
      <CreateNurtureCampaignHeader
        defaultCampaignName={
          selectedJourney ? selectedJourney.title : "Nurture Campaign"
        }
       className={styles["header"]} />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={[
            {
              id: 1,
              tabIcon: <img src="/gifs/ninja.gif" />,
              content: (
                <div className={classNames(styles["campaign-query-chat"])}>
                  <CampaignQueryChat
                    payload={JSON.stringify(selectedJourney)}
                  />
                </div>
              ),
            },
          ]}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
          defaultActiveTab={null}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>
            Your NURTURE Strategy
          </TextElement>
          <div className={classNames(styles["content"], styles["plan"])}>
            {loadingText && (
              <div className={styles["loader-container"]}>
                <TextElement>{loadingText}</TextElement>
                <div className={styles["loader"]}></div>
              </div>
            )}
            {selectedJourney && (
              <VisualizeUserJourney journey={selectedJourney} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNurtureCampaign;
