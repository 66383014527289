import React, { useState, useEffect } from "react";
import JourneySidebar from "./JourneySidebar/JourneySidebar";

import styles from "./JourneyManagment.module.scss";
import useJourneyManagement from "./useJourneyManagment";
import { useLocation, useParams } from "react-router-dom";
import ReactFlowContainer from "../../componenets/ReactFlowContainer/ReactFlowContainer";
import {
  CustomEdge,
  CustomNode,
} from "../../componenets/ReactFlowContainer/types";
const journeyBlueprint: {
  Nodes: CustomNode[];
  Edges: CustomEdge[];
} = {
  Nodes: [
    {
      id: "entry-1",
      type: "entry",
      position: { x: 100, y: 50 },
      data: {
        selected: false,
        label: "Entry: DroppedOffLeads",
      },
    },
    {
      id: "stage-1",
      type: "action",
      position: { x: 300, y: 150 },
      data: {
        selected: false,
        label:
          "Action: Send Email - Unlock the Future with Our Cloud Solutions",
        instructionTitle: "Send Email",
        instructionBody:
          "Hello! Have you considered how cloud solutions can streamline your operations? We specialize in customizable cloud services that can elevate your business efficiency. Let's explore the possibilities together.",
      },
    },
    {
      id: "decision-1",
      type: "diamond",
      position: { x: 500, y: 150 },
      data: {
        selected: false,
        label: "Check: CTA Clicked?",
      },
    },
    {
      id: "stage-2",
      type: "action",
      position: { x: 700, y: 150 },
      data: {
        selected: false,
        label: "Action: Send Email - Discover Cost Savings with Cloud Adoption",
        instructionTitle: "Send Email",
        instructionBody:
          "Cloud solutions not only enhance performance but also offer significant cost savings. Check out how we can help you reduce overhead and maximize ROI through our tailored cloud strategies.",
      },
    },
    {
      id: "decision-2",
      type: "diamond",
      position: { x: 900, y: 150 },
      data: {
        selected: false,
        label: "Check: CTA Clicked?",
      },
    },
    {
      id: "stage-3",
      type: "action",
      position: { x: 1100, y: 150 },
      data: {
        selected: false,
        label: "Action: Send Email - Hear From Our Satisfied Clients",
        instructionTitle: "Send Email",
        instructionBody:
          "Don’t just take our word for it! Discover how our cloud solutions have transformed businesses like yours. Read testimonials and case studies that showcase our impact.",
      },
    },
    {
      id: "decision-3",
      type: "diamond",
      position: { x: 1300, y: 150 },
      data: {
        selected: false,
        label: "Check: CTA Clicked?",
      },
    },
    {
      id: "stage-4",
      type: "action",
      position: { x: 1500, y: 150 },
      data: {
        selected: false,
        label: "Action: Send Email - Join Our Webinar on Cloud Transformation",
        instructionTitle: "Send Email",
        instructionBody:
          "Ready to dive deeper? Join our upcoming webinar where we’ll explore the latest trends in cloud technology and how it can revolutionize your business.",
      },
    },
    {
      id: "decision-4",
      type: "diamond",
      position: { x: 1700, y: 150 },
      data: {
        selected: false,
        label: "Check: Webinar Registered?",
      },
    },
    {
      id: "stage-5",
      type: "action",
      position: { x: 1900, y: 150 },
      data: {
        selected: false,
        label:
          "Action: Send Email - Thanks for Joining! Let’s Take the Next Step",
        instructionTitle: "Send Email",
        instructionBody:
          "We appreciate you attending our webinar! If you’re interested in discussing how our cloud solutions can specifically benefit your business, let’s set up a time to chat.",
      },
    },
    {
      id: "decision-5",
      type: "diamond",
      position: { x: 2100, y: 150 },
      data: {
        selected: false,
        label: "Check: Meeting Scheduled?",
      },
    },
    {
      id: "end",
      type: "end",
      position: { x: 2300, y: 150 },
      data: {
        selected: false,
        label: "End Journey",
      },
    },
  ],
  Edges: [
    {
      id: "edge-1",
      source: "entry-1",
      target: "stage-1",
      label: "Trigger: Start Journey",
    },
    {
      id: "edge-2",
      source: "stage-1",
      target: "decision-1",
      label: "Next: Check CTA Clicked",
    },
    {
      id: "edge-3",
      source: "decision-1",
      target: "stage-2",
      label: "On True: Proceed to Show Cost Benefits",
    },
    {
      id: "edge-4",
      source: "stage-2",
      target: "decision-2",
      label: "Next: Check CTA Clicked",
    },
    {
      id: "edge-5",
      source: "decision-2",
      target: "stage-3",
      label: "On True: Proceed to Share Client Testimonials",
    },
    {
      id: "edge-6",
      source: "stage-3",
      target: "decision-3",
      label: "Next: Check CTA Clicked",
    },
    {
      id: "edge-7",
      source: "decision-3",
      target: "stage-4",
      label: "On True: Proceed to Invite to Webinar",
    },
    {
      id: "edge-8",
      source: "stage-4",
      target: "decision-4",
      label: "Next: Check Webinar Registered",
    },
    {
      id: "edge-9",
      source: "decision-4",
      target: "stage-5",
      label: "On True: Proceed to Follow Up After Webinar",
    },
    {
      id: "edge-10",
      source: "stage-5",
      target: "decision-5",
      label: "Next: Check Meeting Scheduled",
    },
    {
      id: "edge-11",
      source: "decision-5",
      target: "end",
      label: "On True: End Journey",
    },
  ],
};
const JourneyTest: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className={styles["journey-management-container"]}>
      {
        <ReactFlowContainer
          journeyBlueprint={{
            Nodes: journeyBlueprint.Nodes,
            Edges: journeyBlueprint.Edges,
          }}
          updatedJourneyBlueprint={(nodes, edges) => {}}
        />
      }
    </div>
  );
};

export default JourneyTest;
