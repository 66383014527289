import React, { useEffect, useState } from "react";

import styles from "./UpsellAndCrossSellCampaigns.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TableComponent from "../../componenets/Table/Table";
import SearchInput from "../../componenets/SearchInput/SearchInput";
import BinIcon from "../../icons/BinIcon";
import {
  CampaignStatusType,
  fetchCampaigns,
} from "../../redux/campaignPinningSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Tag from "../../componenets/Tag/Tag";

interface Props {}

const UpsellAndCrossSellCampaigns: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const getTagBackgroundColor = (text: string) => {
    switch (text as CampaignStatusType) {
      case CampaignStatusType.APPROVED:
        return "#EB2701";
      case CampaignStatusType.ACTIVE:
        return "#38B05A";
      case CampaignStatusType.REJECTED:
        return "#EB8D01";
      default:
        return "#02285E";
    }
  };

  const retentionCampaigns = [
    {
      id: 1,
      name: "Data Boose",
      createdBy: "Sabeh Hassan",
      status: CampaignStatusType.NEEDS_APPROVAL,
      createdAt: "April 23, 2024",
    },
  ];

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  const getUserLayoutWrapper = (children: React.ReactNode) => {
    return (
      <MarkerterViewLayout
        header={
          <TextElement className={styles["header-text"]}>
            <button
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.HOME.path);
              }}
              className={styles["back-link"]}
            >
              Back to Main Funnel
            </button>
            <br />
            Upsell and Cross-Sell Campaigns
          </TextElement>
        }
      >
        {children}
      </MarkerterViewLayout>
    );
  };

  return getUserLayoutWrapper(
    <>
      <div className={styles["filter-container"]}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
        />

        <button className={styles["delete-btn"]} onClick={() => {}}>
          <BinIcon />
          <span>Delete</span>
        </button>
      </div>
      <div className={styles["table-wrapper"]}>
        <TableComponent
          rows={retentionCampaigns?.map((c) => {
            return {
              ...c,
              status: (
                <Tag
                  text={c?.status?.toString() as string}
                  backgroundColor={getTagBackgroundColor(
                    c?.status as CampaignStatusType
                  )}
                />
              ),
            };
          })}
          columns={[
            {
              name: "Campaign",
              key: "name",
            },
            {
              name: "Created By",
              key: "createdBy",
            },
            {
              name: "Date Created",
              key: "createdAt",
            },
            {
              name: "Status",
              key: "status",
            },
          ]}
          rowClassName={styles["table-row"]}
        />
      </div>
    </>
  );
};

export default UpsellAndCrossSellCampaigns;
