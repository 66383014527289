import React, { useEffect, useState } from "react";

import styles from "./SalesDashboardReport.module.scss";

import TextElement from "../TextElement/TextElement";

import Metric from "../CampaignReports/components/Metric/Metric";
import Select from "../Select/Select";

import classNames from "classnames";
import { NinjaIcon } from "../../icons";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import GraphWrapper from "../Graph/GraphWrapper";

import useSalesDashboardReport from "./useSalesDashboardReport";
import { Context } from "chartjs-plugin-datalabels";
import { formatNumberStandard } from "../../lib/helpers";
import { DateCapturedValues } from "./types";
import { Chart } from "react-google-charts";
import { pipeline } from "stream";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { OpportunityStage } from "../../pages/RecommendedActionsForSale/useRecommendedActionsForSale";
import chroma from "chroma-js";
import CreateStrategyBox from "./components/CreateStrategyBox/CreateStrategyBox";
import ConsiderationStageIcon from "../../icons/ConsiderationStageIcon";
import EvaluationStageIcon from "../../icons/EvaluationStageIcon";
import DecisionStageIcon from "../../icons/DecisionStageIcon";

type Props = {
  className?: string;
  onClick?: () => void;
};

const SalesDashboardReport: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [dateCaptured, setDateCaptured] =
    React.useState<DateCapturedValues>("");

  const [selectedOpportunityStage, setSelectedOpportunityStage] =
    React.useState("");

  const [selectedOpportunityType, setSelectedOpportunityType] =
    React.useState("");

  const {
    products,
    metrics,
    pipelineStageDistribution,
    revenueTrends,
    stageConversionRate,
    distributionByWinLossStatus,
    distributionByOpportunityType,
    distributionByDealSize,
    distributionByIndustrySegment,
    distributionByProduct,
    distributionBySalesRep,
    dateCapturedOptions,
    stalledOpportunities,
    opportunityTypeOptions,
    predictionOfStalledOpportunities,
  } = useSalesDashboardReport({
    product: selectedProduct,
    opportunityStage: selectedOpportunityStage as OpportunityStage,
    opportunityType: selectedOpportunityType,
    dateCaptured: dateCaptured,
  });

  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={onClick}
    >
      <div className={styles["filters"]}>
        <Select
          options={
            products
              ? [
                  {
                    label: "Product",
                    value: "",
                  },
                  ...products.map((product) => ({
                    label: product.productName,
                    value: product.productId,
                  })),
                ]
              : []
          }
          value={selectedProduct}
          placeholderText="Product"
          onChange={(value) => setSelectedProduct(value as string)}
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
        />
        <Select
          className={styles["select-menu"]}
          buttonClassName={styles["select-button"]}
          options={dateCapturedOptions.map((date) => ({
            label: date?.label,
            value: date?.value,
          }))}
          value={dateCaptured}
          onChange={(value) => {
            setDateCaptured(value as DateCapturedValues);
          }}
          placeholderText="Date Captured"
        />
        <Select
          className={styles["select-menu"]}
          buttonClassName={styles["select-button"]}
          options={
            opportunityTypeOptions
              ? [
                  {
                    label: "Opportunity Type",
                    value: "",
                  },
                  ...opportunityTypeOptions,
                ]
              : []
          }
          value={selectedOpportunityType}
          onChange={(value) => {
            setSelectedOpportunityType(value as string);
          }}
          placeholderText="Opportunity Type"
        />
      </div>
      <div className={styles["header"]}>
        <TextElement className={styles["sub-heading"]}>
          Total Opportunities
        </TextElement>
      </div>
      <div className={classNames(styles["create-strategy-boxes"])}>
        <CreateStrategyBox
          value={stalledOpportunities["Consideration"]}
          valueLabel="at 20-40 Stage (Consideration)"
          onLabelClick={() => {
            navigate(
              `${ROUTE_DEFINITIONS.RECOMMENDED_ACTIONS_FOR_SALE.path}?stage=Consideration`
            );
          }}
          warningText={`${predictionOfStalledOpportunities?.considerationAtRisk?.toFixed(
            0
          )}%  at-risk`}
          icon={<ConsiderationStageIcon />}
          furtherInfo="Opportunities at this stage are flagged as at-risk due to delays in movement, lack of buyer engagement, or unmet progression milestones like insufficient follow-ups or missing documents."
        />
        <CreateStrategyBox
          value={stalledOpportunities["Evaluation"]}
          valueLabel="40-60 Stage (Evaluation)"
          onLabelClick={() => {
            navigate(
              `${ROUTE_DEFINITIONS.RECOMMENDED_ACTIONS_FOR_SALE.path}?stage=Evaluation`
            );
          }}
          warningText={`${predictionOfStalledOpportunities?.evaluationAtRisk?.toFixed(
            0
          )}%  at-risk`}
          icon={<EvaluationStageIcon />}
          furtherInfo="These are at-risk if follow-ups are delayed, buyer objections remain unresolved, or engagement metrics, such as meeting attendance or proposal reviews, fall below expected levels."
        />
        <CreateStrategyBox
          value={stalledOpportunities["Decision"]}
          valueLabel="60-100 Stage (Decision)"
          onLabelClick={() => {
            navigate(
              `${ROUTE_DEFINITIONS.RECOMMENDED_ACTIONS_FOR_SALE.path}?stage=Decision`
            );
          }}
          warningText={`${predictionOfStalledOpportunities?.decisionLikelyToClose?.toFixed(
            0
          )}%  likely to close`}
          icon={<DecisionStageIcon />}
          furtherInfo="Opportunities likely to close are identified by consistent buyer engagement, positive proposal feedback, alignment with decision-makers, and fulfillment of key closing criteria such as signed agreements or final approvals."
        />
      </div>
      <TextElement className={styles["sub-heading"]}>Key Findings</TextElement>
      <div className={styles["findings"]}>
        <Metric
          label="Total Estimated Revenue"
          value={`$${formatNumberStandard(metrics?.totalEstimatedRevenue)}`}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 12.0927L9.08889 6.69268C8.68889 6.38156 8.24089 6.25919 7.74489 6.32556C7.2483 6.39253 6.85185 6.63342 6.55556 7.04823L4.11111 10.426L1.11111 8.04823C0.933333 7.90008 0.748148 7.79638 0.555556 7.73712C0.362963 7.67786 0.177778 7.64823 0 7.64823V4.3149C0.0740741 4.3149 0.159408 4.32586 0.256 4.34779C0.352 4.37031 0.444444 4.4186 0.533333 4.49268L3.55556 6.75934L7.44444 1.29268C7.59259 1.08527 7.79259 0.963194 8.04445 0.926453C8.2963 0.88912 8.52593 0.951935 8.73333 1.1149L12.4444 4.09268H15.1111C15.363 4.09268 15.5739 4.17771 15.744 4.34779C15.9147 4.51845 16 4.72971 16 4.98156V12.0927ZM0.888889 15.6482C0.637037 15.6482 0.426074 15.5629 0.256 15.3922C0.0853334 15.2222 0 15.0112 0 14.7593V9.67045C0.103704 9.67045 0.2 9.68527 0.288889 9.7149C0.377778 9.74453 0.466667 9.79638 0.555556 9.87045L4.44444 12.9816L7.46667 8.82601C7.61481 8.6186 7.81096 8.49623 8.05511 8.4589C8.29985 8.42216 8.52593 8.48527 8.73333 8.64823L16 14.3371V14.7593C16 15.0112 15.9147 15.2222 15.744 15.3922C15.5739 15.5629 15.363 15.6482 15.1111 15.6482H0.888889Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Total Actual Revenue"
          value={`$${formatNumberStandard(metrics?.totalActualRevenue)}`}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8831 7.30347C3.65324 6.77903 2.93615 6.23681 2.93615 5.39236C2.93615 4.42347 3.92829 3.74792 5.58841 3.74792C7.33693 3.74792 7.98526 4.50347 8.0442 5.61458H10.2151C10.1464 4.08569 9.11493 2.68125 7.06189 2.22792V0.28125H4.11493V2.20125C2.20923 2.57458 0.676817 3.69458 0.676817 5.41014C0.676817 7.46347 2.55304 8.48569 5.29371 9.08125C7.74951 9.61458 8.24067 10.3968 8.24067 11.2235C8.24067 11.8368 7.75933 12.8146 5.58841 12.8146C3.56483 12.8146 2.76916 11.9968 2.6611 10.9479H0.5C0.617878 12.8946 2.22888 13.9879 4.11493 14.3524V16.2812H7.06189V14.3701C8.97741 14.0413 10.5 13.0368 10.5 11.2146C10.5 8.69014 8.11297 7.82792 5.8831 7.30347Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Average Size of Closed Won Deals"
          value={`$${formatNumberStandard(
            parseInt(metrics?.avgSizeClosedWonDeals?.toFixed(0))
          )}`}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8831 7.30347C3.65324 6.77903 2.93615 6.23681 2.93615 5.39236C2.93615 4.42347 3.92829 3.74792 5.58841 3.74792C7.33693 3.74792 7.98526 4.50347 8.0442 5.61458H10.2151C10.1464 4.08569 9.11493 2.68125 7.06189 2.22792V0.28125H4.11493V2.20125C2.20923 2.57458 0.676817 3.69458 0.676817 5.41014C0.676817 7.46347 2.55304 8.48569 5.29371 9.08125C7.74951 9.61458 8.24067 10.3968 8.24067 11.2235C8.24067 11.8368 7.75933 12.8146 5.58841 12.8146C3.56483 12.8146 2.76916 11.9968 2.6611 10.9479H0.5C0.617878 12.8946 2.22888 13.9879 4.11493 14.3524V16.2812H7.06189V14.3701C8.97741 14.0413 10.5 13.0368 10.5 11.2146C10.5 8.69014 8.11297 7.82792 5.8831 7.30347Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Average Sales Cycle Length"
          value={`${formatNumberStandard(
            parseInt(metrics?.avgSalesCycleLength?.toFixed(0))
          )}`}
          className={styles["metric"]}
          icon={
            <svg
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7222 2.17036H13.8333V0.392578H12.0556V2.17036H4.94444V0.392578H3.16667V2.17036H2.27778C1.29111 2.17036 0.508889 2.97036 0.508889 3.94813L0.5 16.3926C0.5 17.3704 1.29111 18.1704 2.27778 18.1704H14.7222C15.7 18.1704 16.5 17.3704 16.5 16.3926V3.94813C16.5 2.97036 15.7 2.17036 14.7222 2.17036ZM14.7222 16.3926H2.27778V7.50369H14.7222V16.3926ZM5.83333 11.0592H4.05556V9.28147H5.83333V11.0592ZM9.38889 11.0592H7.61111V9.28147H9.38889V11.0592ZM12.9444 11.0592H11.1667V9.28147H12.9444V11.0592ZM5.83333 14.6148H4.05556V12.837H5.83333V14.6148ZM9.38889 14.6148H7.61111V12.837H9.38889V14.6148ZM12.9444 14.6148H11.1667V12.837H12.9444V14.6148Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Win Rate"
          value={`${formatNumberStandard(metrics?.winRate)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.09524 15.6789C5.67619 15.6789 5.31759 15.5298 5.01943 15.2317C4.72076 14.933 4.57143 14.5741 4.57143 14.1551V6.40271C4.57143 6.19954 4.61283 6.00576 4.69562 5.82138C4.7779 5.63751 4.88889 5.47573 5.02857 5.33605L9.1619 1.22176C9.35238 1.04398 9.5779 0.936048 9.83848 0.897952C10.0985 0.859857 10.3492 0.904302 10.5905 1.03129C10.8317 1.15827 11.0062 1.33605 11.1139 1.56462C11.2221 1.79319 11.2444 2.02811 11.181 2.26938L10.3238 5.77414H14.4762C14.8825 5.77414 15.2381 5.92652 15.5429 6.23129C15.8476 6.53605 16 6.8916 16 7.29795V8.82176C16 8.91065 15.9903 9.00589 15.971 9.10748C15.9523 9.20906 15.9238 9.3043 15.8857 9.39319L13.6 14.7646C13.4857 15.0186 13.2952 15.2345 13.0286 15.4122C12.7619 15.59 12.4825 15.6789 12.1905 15.6789H6.09524ZM1.52381 15.6789C1.10476 15.6789 0.746159 15.5298 0.448 15.2317C0.149333 14.933 0 14.5741 0 14.1551V7.29795C0 6.8789 0.149333 6.52005 0.448 6.22138C0.746159 5.92322 1.10476 5.77414 1.52381 5.77414C1.94286 5.77414 2.30146 5.92322 2.59962 6.22138C2.89829 6.52005 3.04762 6.8789 3.04762 7.29795V14.1551C3.04762 14.5741 2.89829 14.933 2.59962 15.2317C2.30146 15.5298 1.94286 15.6789 1.52381 15.6789Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Win:Loss Rate"
          value={`${formatNumberStandard(metrics?.winLossRatio)}`}
          className={styles["metric"]}
          icon={
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.83333 9.61512C1.46667 9.61512 1.15289 9.48446 0.892 9.22312C0.630667 8.96223 0.5 8.64846 0.5 8.28179V4.43179C0.5 4.25401 0.533333 4.08446 0.6 3.92312C0.666667 3.76223 0.761111 3.62068 0.883333 3.49846L3.6 0.781788C3.71111 0.670677 3.85 0.606677 4.01667 0.589788C4.18333 0.573344 4.33333 0.609566 4.46667 0.698455C4.6 0.787344 4.7 0.906677 4.76667 1.05646C4.83333 1.20668 4.85 1.35957 4.81667 1.51512L4.36667 3.61512H7.2C7.73333 3.61512 8.11667 3.82068 8.35 4.23179C8.58333 4.6429 8.61111 5.05957 8.43333 5.48179L7.01667 8.79846C6.90556 9.05401 6.74178 9.25401 6.52533 9.39846C6.30844 9.5429 6.06667 9.61512 5.8 9.61512H1.83333ZM5.8 8.28179L7.16667 5.04846V4.94846H2.73333L3.13333 3.14846L1.83333 4.41512V8.28179H5.8ZM12.4 15.7651C12.2889 15.6762 12.2196 15.5651 12.192 15.4318C12.164 15.2985 12.1611 15.1707 12.1833 15.0485L12.6333 12.9485H9.83333C9.3 12.9485 8.90844 12.7456 8.65867 12.3398C8.40844 11.9345 8.37778 11.5151 8.56667 11.0818L9.98333 7.76512C10.0944 7.50957 10.2584 7.30957 10.4753 7.16512C10.6918 7.02068 10.9333 6.94846 11.2 6.94846H15.1667C15.5333 6.94846 15.8473 7.0789 16.1087 7.33979C16.3696 7.60112 16.5 7.91512 16.5 8.28179V12.1318C16.5 12.3096 16.4667 12.4791 16.4 12.6405C16.3333 12.8013 16.2389 12.9429 16.1167 13.0651L13.4 15.7818C13.2556 15.9262 13.0889 15.9902 12.9 15.9738C12.7111 15.9569 12.5444 15.8873 12.4 15.7651ZM11.2 8.28179L9.83333 11.5151V11.6151H14.2667L13.8667 13.4151L15.1667 12.1485V8.28179H11.2ZM1.83333 8.28179V4.41512L3.13333 3.14846L2.73333 4.94846H7.16667V5.04846L5.8 8.28179H1.83333ZM15.1667 8.28179V12.1485L13.8667 13.4151L14.2667 11.6151H9.83333V11.5151L11.2 8.28179H15.1667Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
      </div>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="PieChart"
              data={pipelineStageDistribution}
              options={{
                is3D: true,
                pieStartAngle: 0,
                chartArea: {
                  top: 1,
                  width: "100%",
                  height: "91%",
                },
                legend: {
                  position: "bottom",
                  textStyle: {
                    color: "#222",
                    fontSize: 11,
                  },
                },
                colors: [
                  "#CE2605",
                  "#FF6143",
                  "#FF7A60",
                  "#FFA998",
                  "#EB2701",
                  "#F93A15",
                ],
              }}
              width={"100%"}
              height={"100%"}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Pipeline Stage Distribution"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="LineChart"
              width="100%"
              height="100%"
              data={revenueTrends}
              legendToggle
              options={{
                curveType: "function",
                colors: ["#02285E", "#FC8A73"],
                chartArea: {
                  right: 13,
                  top: 15,
                  width: "85%",
                  height: "81%",
                },
                legend: {
                  position: "bottom",
                  textStyle: {
                    color: "#222",
                    fontSize: 11,
                  },
                },
              }}
            />
          }
          containerClassName={classNames(
            styles["graph-container"],
            styles["line-container"]
          )}
          titleClassName={styles["sub-heading"]}
          title={"Revenue Trends"}
        />

        <GraphWrapper
          graphComonent={
            <Chart
              chartType="Sankey"
              width="100%"
              height="100%"
              data={stageConversionRate}
              options={{
                sankey: {
                  node: {},
                  link: {
                    colorMode: "gradient",
                  },
                },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Stage Conversion Rate"
        />
      </div>

      <TextElement className={styles["sub-heading"]}>
        Sales Performance Metrics
      </TextElement>

      <div className={styles["findings"]}>
        <Metric
          label="Total Closed Won Deals"
          value={formatNumberStandard(metrics?.totalClosedWonDeals)}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 12.0927L9.08889 6.69268C8.68889 6.38156 8.24089 6.25919 7.74489 6.32556C7.2483 6.39253 6.85185 6.63342 6.55556 7.04823L4.11111 10.426L1.11111 8.04823C0.933333 7.90008 0.748148 7.79638 0.555556 7.73712C0.362963 7.67786 0.177778 7.64823 0 7.64823V4.3149C0.0740741 4.3149 0.159408 4.32586 0.256 4.34779C0.352 4.37031 0.444444 4.4186 0.533333 4.49268L3.55556 6.75934L7.44444 1.29268C7.59259 1.08527 7.79259 0.963194 8.04445 0.926453C8.2963 0.88912 8.52593 0.951935 8.73333 1.1149L12.4444 4.09268H15.1111C15.363 4.09268 15.5739 4.17771 15.744 4.34779C15.9147 4.51845 16 4.72971 16 4.98156V12.0927ZM0.888889 15.6482C0.637037 15.6482 0.426074 15.5629 0.256 15.3922C0.0853334 15.2222 0 15.0112 0 14.7593V9.67045C0.103704 9.67045 0.2 9.68527 0.288889 9.7149C0.377778 9.74453 0.466667 9.79638 0.555556 9.87045L4.44444 12.9816L7.46667 8.82601C7.61481 8.6186 7.81096 8.49623 8.05511 8.4589C8.29985 8.42216 8.52593 8.48527 8.73333 8.64823L16 14.3371V14.7593C16 15.0112 15.9147 15.2222 15.744 15.3922C15.5739 15.5629 15.363 15.6482 15.1111 15.6482H0.888889Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Total Open Opportunities"
          value={formatNumberStandard(metrics?.totalOpenOpportunities)}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8831 7.30347C3.65324 6.77903 2.93615 6.23681 2.93615 5.39236C2.93615 4.42347 3.92829 3.74792 5.58841 3.74792C7.33693 3.74792 7.98526 4.50347 8.0442 5.61458H10.2151C10.1464 4.08569 9.11493 2.68125 7.06189 2.22792V0.28125H4.11493V2.20125C2.20923 2.57458 0.676817 3.69458 0.676817 5.41014C0.676817 7.46347 2.55304 8.48569 5.29371 9.08125C7.74951 9.61458 8.24067 10.3968 8.24067 11.2235C8.24067 11.8368 7.75933 12.8146 5.58841 12.8146C3.56483 12.8146 2.76916 11.9968 2.6611 10.9479H0.5C0.617878 12.8946 2.22888 13.9879 4.11493 14.3524V16.2812H7.06189V14.3701C8.97741 14.0413 10.5 13.0368 10.5 11.2146C10.5 8.69014 8.11297 7.82792 5.8831 7.30347Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Average Touchpoints Per Opportunity"
          value={formatNumberStandard(
            metrics?.averageTouchpointsPerOpportunity
          )}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="17"
              viewBox="0 0 11 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8831 7.30347C3.65324 6.77903 2.93615 6.23681 2.93615 5.39236C2.93615 4.42347 3.92829 3.74792 5.58841 3.74792C7.33693 3.74792 7.98526 4.50347 8.0442 5.61458H10.2151C10.1464 4.08569 9.11493 2.68125 7.06189 2.22792V0.28125H4.11493V2.20125C2.20923 2.57458 0.676817 3.69458 0.676817 5.41014C0.676817 7.46347 2.55304 8.48569 5.29371 9.08125C7.74951 9.61458 8.24067 10.3968 8.24067 11.2235C8.24067 11.8368 7.75933 12.8146 5.58841 12.8146C3.56483 12.8146 2.76916 11.9968 2.6611 10.9479H0.5C0.617878 12.8946 2.22888 13.9879 4.11493 14.3524V16.2812H7.06189V14.3701C8.97741 14.0413 10.5 13.0368 10.5 11.2146C10.5 8.69014 8.11297 7.82792 5.8831 7.30347Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Total No. of Sales Activities"
          value={formatNumberStandard(metrics?.totalNumberOfSalesActivities)}
          className={styles["metric"]}
          icon={
            <svg
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7222 2.17036H13.8333V0.392578H12.0556V2.17036H4.94444V0.392578H3.16667V2.17036H2.27778C1.29111 2.17036 0.508889 2.97036 0.508889 3.94813L0.5 16.3926C0.5 17.3704 1.29111 18.1704 2.27778 18.1704H14.7222C15.7 18.1704 16.5 17.3704 16.5 16.3926V3.94813C16.5 2.97036 15.7 2.17036 14.7222 2.17036ZM14.7222 16.3926H2.27778V7.50369H14.7222V16.3926ZM5.83333 11.0592H4.05556V9.28147H5.83333V11.0592ZM9.38889 11.0592H7.61111V9.28147H9.38889V11.0592ZM12.9444 11.0592H11.1667V9.28147H12.9444V11.0592ZM5.83333 14.6148H4.05556V12.837H5.83333V14.6148ZM9.38889 14.6148H7.61111V12.837H9.38889V14.6148ZM12.9444 14.6148H11.1667V12.837H12.9444V14.6148Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Average No. of Activities to Close A Deal"
          value={formatNumberStandard(metrics?.averageActivitiesToCloseADeal)}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.09524 15.6789C5.67619 15.6789 5.31759 15.5298 5.01943 15.2317C4.72076 14.933 4.57143 14.5741 4.57143 14.1551V6.40271C4.57143 6.19954 4.61283 6.00576 4.69562 5.82138C4.7779 5.63751 4.88889 5.47573 5.02857 5.33605L9.1619 1.22176C9.35238 1.04398 9.5779 0.936048 9.83848 0.897952C10.0985 0.859857 10.3492 0.904302 10.5905 1.03129C10.8317 1.15827 11.0062 1.33605 11.1139 1.56462C11.2221 1.79319 11.2444 2.02811 11.181 2.26938L10.3238 5.77414H14.4762C14.8825 5.77414 15.2381 5.92652 15.5429 6.23129C15.8476 6.53605 16 6.8916 16 7.29795V8.82176C16 8.91065 15.9903 9.00589 15.971 9.10748C15.9523 9.20906 15.9238 9.3043 15.8857 9.39319L13.6 14.7646C13.4857 15.0186 13.2952 15.2345 13.0286 15.4122C12.7619 15.59 12.4825 15.6789 12.1905 15.6789H6.09524ZM1.52381 15.6789C1.10476 15.6789 0.746159 15.5298 0.448 15.2317C0.149333 14.933 0 14.5741 0 14.1551V7.29795C0 6.8789 0.149333 6.52005 0.448 6.22138C0.746159 5.92322 1.10476 5.77414 1.52381 5.77414C1.94286 5.77414 2.30146 5.92322 2.59962 6.22138C2.89829 6.52005 3.04762 6.8789 3.04762 7.29795V14.1551C3.04762 14.5741 2.89829 14.933 2.59962 15.2317C2.30146 15.5298 1.94286 15.6789 1.52381 15.6789Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Metric
          label="Average No of Activities by Sales Rep"
          value={formatNumberStandard(metrics?.averageActivitiesBySalesRep)}
          className={styles["metric"]}
          icon={
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.83333 9.61512C1.46667 9.61512 1.15289 9.48446 0.892 9.22312C0.630667 8.96223 0.5 8.64846 0.5 8.28179V4.43179C0.5 4.25401 0.533333 4.08446 0.6 3.92312C0.666667 3.76223 0.761111 3.62068 0.883333 3.49846L3.6 0.781788C3.71111 0.670677 3.85 0.606677 4.01667 0.589788C4.18333 0.573344 4.33333 0.609566 4.46667 0.698455C4.6 0.787344 4.7 0.906677 4.76667 1.05646C4.83333 1.20668 4.85 1.35957 4.81667 1.51512L4.36667 3.61512H7.2C7.73333 3.61512 8.11667 3.82068 8.35 4.23179C8.58333 4.6429 8.61111 5.05957 8.43333 5.48179L7.01667 8.79846C6.90556 9.05401 6.74178 9.25401 6.52533 9.39846C6.30844 9.5429 6.06667 9.61512 5.8 9.61512H1.83333ZM5.8 8.28179L7.16667 5.04846V4.94846H2.73333L3.13333 3.14846L1.83333 4.41512V8.28179H5.8ZM12.4 15.7651C12.2889 15.6762 12.2196 15.5651 12.192 15.4318C12.164 15.2985 12.1611 15.1707 12.1833 15.0485L12.6333 12.9485H9.83333C9.3 12.9485 8.90844 12.7456 8.65867 12.3398C8.40844 11.9345 8.37778 11.5151 8.56667 11.0818L9.98333 7.76512C10.0944 7.50957 10.2584 7.30957 10.4753 7.16512C10.6918 7.02068 10.9333 6.94846 11.2 6.94846H15.1667C15.5333 6.94846 15.8473 7.0789 16.1087 7.33979C16.3696 7.60112 16.5 7.91512 16.5 8.28179V12.1318C16.5 12.3096 16.4667 12.4791 16.4 12.6405C16.3333 12.8013 16.2389 12.9429 16.1167 13.0651L13.4 15.7818C13.2556 15.9262 13.0889 15.9902 12.9 15.9738C12.7111 15.9569 12.5444 15.8873 12.4 15.7651ZM11.2 8.28179L9.83333 11.5151V11.6151H14.2667L13.8667 13.4151L15.1667 12.1485V8.28179H11.2ZM1.83333 8.28179V4.41512L3.13333 3.14846L2.73333 4.94846H7.16667V5.04846L5.8 8.28179H1.83333ZM15.1667 8.28179V12.1485L13.8667 13.4151L14.2667 11.6151H9.83333V11.5151L11.2 8.28179H15.1667Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
      </div>

      <TextElement className={styles["sub-heading"]}>
        Opportunity Distribution
      </TextElement>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="100%"
              data={distributionByWinLossStatus}
              options={{
                colors: ["#FFE5E0"],
                chartArea: { top: 20, width: "90%", height: "84%" },
                legend: { position: "none" },
                bars: "vertical", // Required for Material Bar Charts.
              }}
              legendToggle
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Win-Loss Status"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="PieChart"
              data={distributionByOpportunityType}
              options={{
                is3D: true,
                pieStartAngle: 0,
                chartArea: {
                  top: 1,
                  width: "100%",
                  height: "91%",
                },
                tooltip: {
                  ignoreBounds: false,
                },
                legend: {
                  position: "bottom",
                  textStyle: {
                    color: "#222",
                    fontSize: 11,
                  },
                },
                colors: [
                  "#CE2605",
                  "#FF6143",
                  "#FF7A60",
                  "#FFA998",
                  "#EB2701",
                  "#F93A15",
                ],
              }}
              width={"100%"}
              height={"100%"}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Opportunity Type"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="100%"
              data={distributionByDealSize}
              options={{
                colors: ["#FFE5E0"],
                chartArea: { top: 20, width: "90%", height: "84%" },
                legend: { position: "none" },
                bars: "vertical", // Required for Material Bar Charts.
              }}
              legendToggle
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Deal Size"
        />
      </div>

      <TextElement className={styles["sub-heading"]}>
        Revenue Distribution
      </TextElement>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={distributionByIndustrySegment}
              options={{
                colors: ["#FFE5E0"],
                chartArea: { top: 20, width: "64%", height: "84%" },
                legend: { position: "none" },
                bars: "vertical",
              }}
              legendToggle
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Industry Segment"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="PieChart"
              data={distributionByProduct}
              options={{
                is3D: true,
                pieStartAngle: 0,
                chartArea: {
                  top: 1,
                  width: "100%",
                  height: "91%",
                },
                legend: {
                  position: "bottom",
                  textStyle: {
                    color: "#222",
                    fontSize: 11,
                  },
                },
                tooltip: {
                  ignoreBounds: true,
                  isHtml: false,
                  text: "value",
                },
                colors: chroma
                  .scale(["#CE2605", "#FFA998"]) // Define the color gradient
                  .mode("lch") // Use perceptually uniform color space
                  .colors(distributionByProduct?.length),
              }}
              width={"100%"}
              height={"100%"}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Product"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={distributionBySalesRep}
              options={{
                colors: ["#FFE5E0"],
                chartArea: { top: 20, width: "64%", height: "82%" },
                legend: { position: "none" },
                bars: "vertical",
              }}
              legendToggle
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Distribution by Sales Rep"
        />
      </div>
    </div>
  );
};

export default SalesDashboardReport;
