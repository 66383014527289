import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  LogarithmicScale,
  ArcElement,
  RadialLinearScale,
  Filler
} from "chart.js";

import styles from "./Graph.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  LogarithmicScale,
  ArcElement,
  RadialLinearScale,
  ChartDataLabels,
  Filler
);

type GraphProps = {
  title?: string | React.ReactNode;
  containerClassName?: string;
  titleClassName?: string;
  graphComonent?: React.ReactNode;
};

const GraphWrapper: React.FC<GraphProps> = (props: GraphProps) => {
  const {
    title ,  
    titleClassName,
    containerClassName,
    graphComonent,
  } = props;

  return (
    <div className={classNames(styles["container"], containerClassName)}>
      <TextElement className={classNames(styles["title"], titleClassName)}>
        {title}
      </TextElement>
      {graphComonent}
    </div>
  );
};

export default GraphWrapper;
