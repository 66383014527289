import { Listbox } from "@headlessui/react";

import styles from "./MultiSelect.module.scss";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

interface MultiSelectProps {
  options: {
    value: string | number | null;
    label: React.ReactNode;
    disabled?: boolean;
  }[];
  values: (string | number | null)[];
  onChange: (values: (string | number | null)[]) => void;
  width?: string;
  placeholderText?: string;
  isStatic?: boolean;
  optionClassName?: string;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
  icon?: React.ReactNode;
}

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const {
    onChange,
    options,
    values,
    width,
    placeholderText = "Select Options",
    isStatic,
    optionClassName,
    disabled = false,
    className,
    buttonClassName,
    icon,
  } = props;

  const listboxRef = useRef<HTMLDivElement>(null);

  const [listOpen, setListOpen] = React.useState(false);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (listboxRef?.current && !listboxRef?.current?.contains(e.target)) {
        setListOpen(false);
      }
    };

    if (isStatic) {
      document.addEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isStatic]);

  const toggleOption = (value: string | number | null) => {
    console.log("toggleOption", values);
    if (values?.includes(value)) {
      const newValues = values.filter((v) => v !== value);
      onChange(newValues);
      //   onChange(values.filter((v) => v !== value));
    } else {
      onChange([...values, value]);
    }
  };

  return (
    <div
      className={classNames(
        styles["custom-multi-select"],
        disabled ? styles["disabled"] : "",
        className
      )}
      style={
        width
          ? {
              width: width,
            }
          : {}
      }
      ref={listboxRef}
    >
      <Listbox value={values} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={classNames(styles["select-button"], buttonClassName)}
              onClick={() => setListOpen(!listOpen)}
            >
              {values.length > 0 ? (
                <span className={styles["selected-options"]}>
                  {values
                    .map(
                      (value) =>
                        options.find((option) => option.value === value)?.label
                    )
                    .join(", ")}
                </span>
              ) : (
                <span className={styles["placeholder-text"]}>
                  {placeholderText}
                </span>
              )}
              {icon ? (
                icon
              ) : (
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <path
                    d="M5.29756 6L0.709648 0L9.88546 0L5.29756 6Z"
                    fill="#00718F"
                  />
                </svg>
              )}
            </Listbox.Button>
            <Listbox.Options
              className={styles["option-list"]}
              static={isStatic ? listOpen : undefined}
            >
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  as="div"
                  value={option.value} // Provide the value prop
                  className={classNames(
                    styles["option"],
                    option?.disabled ? styles["disabled-option"] : "",
                    optionClassName
                  )}
                  onClick={() => {
                    if (!option.disabled) {
                      toggleOption(option.value);
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    disabled={option.disabled}
                    checked={
                      values?.includes(option.value) || option.disabled
                        ? true
                        : false
                    }
                    // onChange={() => toggleOption(option.value)}
                  />
                  <label>{option.label}</label>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MultiSelect;
