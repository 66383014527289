import React, { useEffect } from "react";
import styles from "./TailoredStrategyForBANTQualificationBuilder.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Button from "../Button/Button";
import { useAppSelector } from "../../redux/hooks";
import AddBANTQualificationQuestions from "../TailoredStrategyForBANTQualification/componenets/AddBANTQualificationQuestions/AddBANTQualificationQuestions";
import Preparation from "../TailoredStrategyForBANTQualification/componenets/Preparation/Preparation";
import InitialContact from "../TailoredStrategyForBANTQualification/componenets/InitialContact/InitialContact";
import HandlingObjections from "../TailoredStrategyForBANTQualification/componenets/HandlingObjections/HandlingObjections";
import QualificationAndHandover from "../TailoredStrategyForBANTQualification/componenets/QualificationAndHandover/QualificationAndHandover";
import KeyMetricsToMonitor from "../TailoredStrategyForBANTQualification/componenets/KeyMetricsToMonitor/KeyMetricsToMonitor";
import ContinuousImprovement from "../TailoredStrategyForBANTQualification/componenets/ContinuousImprovement/ContinuousImprovement";

interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

const TailoredStrategyForBANTQualificationBuilder: React.FC<Props> = ({
  open,
  onClose,
  className,
}: Props) => {
  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );
  return (
    <div className={classNames(styles["container"], className)}>
      <button
        className={classNames(
          styles["collapse-btn"],
          open ? styles["open"] : styles["closed"]
        )}
        onClick={onClose}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <path
            d="M17.655 2.655L15 0L0 15L15 30L17.655 27.345L5.31 15L17.655 2.655Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        className={classNames(
          styles["campaign-builder"],
          open ? styles["open"] : styles["closed"]
        )}
      >
        <div className={styles["header"]}>
          <TextElement className={styles["header-text"]}>
            Your Campaign Assets Bant
          </TextElement>
          <Button className={styles["submit-btn"]} disabled={true}>
            <TextElement>Submit for approval ✅</TextElement>
          </Button>
        </div>
        <div className={styles["campaign-builder-content"]}>
          <div className={styles["campaign-data"]}>
            {includeData?.preparation && <Preparation cardType="ellipses" />}
            {includeData?.initialContact && (
              <InitialContact cardType="ellipses" />
            )}
            {includeData?.bantQualification && (
              <AddBANTQualificationQuestions cardType="ellipses" />
            )}
            {includeData?.handlingObjections && (
              <HandlingObjections cardType="ellipses" />
            )}
            {includeData?.qualificationAndHandover && (
              <QualificationAndHandover cardType="ellipses" />
            )}
            {includeData?.keyMetricsToMonitor && (
              <KeyMetricsToMonitor cardType="ellipses" />
            )}
            {includeData?.continuousImprovement && (
              <ContinuousImprovement cardType="ellipses" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailoredStrategyForBANTQualificationBuilder;
