import React from "react";
import classNames from "classnames";
import styles from "./RiskAnalysisCard.module.scss";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";

interface RiskAnalysisCardProps {
  riskType: "alert" | "warning" | "success";
  headerText: string;
  customerCount: number;
  engagementScore: string;
  serviceComplaints: string;
  satisfactionScore: string;
  lateBillPayments: string;
  onClickGenerateOffers: () => void;
}

const RiskAnalysisCard: React.FC<RiskAnalysisCardProps> = ({
  riskType,
  headerText,
  customerCount,
  engagementScore,
  serviceComplaints,
  satisfactionScore,
  lateBillPayments,
  onClickGenerateOffers,
}) => {
  const headerClass = classNames(styles.header, {
    [styles.alert]: riskType === "alert",
    [styles.warning]: riskType === "warning",
    [styles.success]: riskType === "success",
  });

  const getWarningIcon = () => {
    switch (riskType) {
      case "alert":
        return (
          <svg
            width="36"
            height="32"
            viewBox="0 0 36 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles["triangle"]}
          >
            <path
              d="M27.3756 15.0169L27.3693 15.0055L27.3627 14.9943L20.5881 3.4376C19.4291 1.46048 16.5709 1.46048 15.4119 3.4376L8.6373 14.9943L8.63071 15.0055L8.62442 15.0169L2.26092 26.5508C1.15778 28.5502 2.60409 31 4.88765 31H18H31.1123C33.3959 31 34.8422 28.5502 33.7391 26.5508L27.3756 15.0169Z"
              fill="white"
              stroke="#EB2701"
              stroke-width="2"
            />
            <path
              d="M4.88907 26.0031C4.12178 27.3364 5.08418 29.0006 6.62253 29.0006H29.2217C30.7601 29.0006 31.7225 27.3364 30.9552 26.0031L19.6556 6.36771C18.8864 5.03112 16.9578 5.03112 16.1887 6.36771L4.88907 26.0031ZM19.2638 24.9514H16.5805V22.2519H19.2638V24.9514ZM19.2638 19.5524H16.5805V14.1534H19.2638V19.5524Z"
              fill="#EB2701"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            width="36"
            height="32"
            viewBox="0 0 36 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles["triangle"]}
          >
            <path
              d="M27.3756 15.0169L27.3693 15.0055L27.3627 14.9943L20.5881 3.4376C19.4291 1.46048 16.5709 1.46048 15.4119 3.4376L8.6373 14.9943L8.63071 15.0055L8.62442 15.0169L2.26092 26.5508C1.15778 28.5502 2.60409 31 4.88765 31H18H31.1123C33.3959 31 34.8422 28.5502 33.7391 26.5508L27.3756 15.0169Z"
              fill="white"
              stroke="#FFA600"
              stroke-width="2"
            />
            <path
              d="M4.88907 26.0031C4.12178 27.3364 5.08418 29.0006 6.62253 29.0006H29.2217C30.7601 29.0006 31.7225 27.3364 30.9552 26.0031L19.6556 6.36771C18.8864 5.03112 16.9578 5.03112 16.1887 6.36771L4.88907 26.0031ZM19.2638 24.9514H16.5805V22.2519H19.2638V24.9514ZM19.2638 19.5524H16.5805V14.1534H19.2638V19.5524Z"
              fill="#FFA600"
            />
          </svg>
        );

      case "success":
        return (
          <svg
            width="36"
            height="32"
            viewBox="0 0 36 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles["triangle"]}
          >
            <path
              d="M27.3756 15.0169L27.3693 15.0055L27.3627 14.9943L20.5881 3.4376C19.4291 1.46048 16.5709 1.46048 15.4119 3.4376L8.6373 14.9943L8.63071 15.0055L8.62442 15.0169L2.26092 26.5508C1.15778 28.5502 2.60409 31 4.88765 31H18H31.1123C33.3959 31 34.8422 28.5502 33.7391 26.5508L27.3756 15.0169Z"
              fill="white"
              stroke="#18C148"
              stroke-width="2"
            />
            <path
              d="M4.88907 26.0031C4.12178 27.3364 5.08418 29.0006 6.62253 29.0006H29.2217C30.7601 29.0006 31.7225 27.3364 30.9552 26.0031L19.6556 6.36771C18.8864 5.03112 16.9578 5.03112 16.1887 6.36771L4.88907 26.0031ZM19.2638 24.9514H16.5805V22.2519H19.2638V24.9514ZM19.2638 19.5524H16.5805V14.1534H19.2638V19.5524Z"
              fill="#18C148"
            />
          </svg>
        );
    }
  };

  return (
    <div className={styles.card}>
      <div className={headerClass}>
        <div className={styles["icon"]}>
          <svg
            width="60"
            height="61"
            viewBox="0 0 60 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.49839 44.8832L2.49859 44.8829C3.49881 43.2261 4.81637 41.9755 6.45928 41.1102C10.268 39.1746 14.132 37.7253 18.0519 36.7589C21.9734 35.7946 25.9556 35.3125 30 35.3125C34.0444 35.3125 38.0266 35.7946 41.9481 36.7589C45.868 37.7253 49.732 39.1746 53.5408 41.1102C55.1837 41.9756 56.5012 43.2261 57.5014 44.8829L57.5016 44.8832C58.5003 46.5361 59 48.343 59 50.325V53.375C59 55.2096 58.3693 56.7532 57.0864 58.0602C55.8011 59.3647 54.2901 60 52.5 60H7.5C5.70997 60 4.20054 59.3648 2.91793 58.0608C1.63196 56.7534 1 55.2096 1 53.375V50.325C1 48.343 1.49967 46.5361 2.49839 44.8832ZM30 29.5C26.1477 29.5 22.872 28.1177 20.1192 25.3191C17.3659 22.5199 16 19.1817 16 15.25C16 11.3183 17.3659 7.98011 20.1192 5.18093C22.872 2.38225 26.1477 1 30 1C33.8523 1 37.128 2.38225 39.8808 5.18093C42.6341 7.98011 44 11.3183 44 15.25C44 19.1817 42.6341 22.5199 39.8808 25.3191C37.128 28.1177 33.8523 29.5 30 29.5Z"
              fill="white"
              stroke="#EB2701"
              stroke-width="2"
            />
          </svg>
          {getWarningIcon()}
        </div>
        <div className={styles["stats"]}>
          <TextElement className={styles["customerCount"]}>
            {customerCount}
          </TextElement>
          <TextElement className={styles["headerText"]}>
            {headerText}
          </TextElement>
        </div>
      </div>
      <div className={styles.content}>
        <TextElement className={styles.metric}>
          <strong>Engagement Score</strong>
          <br />
          {engagementScore}
        </TextElement>
        <div className={styles["divider"]}></div>
        <TextElement className={styles.metric}>
          <strong>Service Complaints</strong>
          <br /> {serviceComplaints}
        </TextElement>
        <div className={styles["divider"]}></div>
        <TextElement className={styles.metric}>
          <strong>Satisfaction Score</strong>
          <br /> {satisfactionScore}
        </TextElement>
        <div className={styles["divider"]}></div>
        <TextElement className={styles.metric}>
          <strong>Late Bill Payments</strong>
          <br /> {lateBillPayments}
        </TextElement>
        <div className={styles["divider"]}></div>
      </div>
      <Button className={styles.generateButton} onClick={onClickGenerateOffers}>
        Generate Upsell Offers
      </Button>
    </div>
  );
};

export default RiskAnalysisCard;
