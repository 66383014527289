import React from "react";

import styles from "./WhitePaperTopics.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import FieldWithIcon from "../FieldWithIcon/FieldWithIcon";

interface WhitePaperTopicsProps {
  className?: string;
  cardType: CardType;
}

const WhitePaperTopics: React.FC<WhitePaperTopicsProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    // nurtureStrategyData &&
    //   dispatch(
    //     setNurtureStrategyData({
    //       ...nurtureStrategyData,
    //       WhitePaperTopics: isPinned,
    //     })
    //   );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div
        className={styles['content']}
      >
        <TextElement className={styles["heading"]}>
          Title: Maximising Efficiency with 5G: A Comprehensive Guide
        </TextElement>
        <FieldWithIcon
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7857 14.2143H0.357178V16.5H11.7857V14.2143ZM18.6429 5.07143H0.357178V7.35714H18.6429V5.07143ZM0.357178 11.9286H18.6429V9.64286H0.357178V11.9286ZM0.357178 0.5V2.78571H18.6429V0.5H0.357178Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Outline"
          text={
            <TextElement className={styles["body-text"]}>
              <ol>
                <li className={styles["list-item"]}>
                  <strong>Introduction</strong>
                  <ul>
                    <li>Overview of 5G technology</li>
                    <li>Importance of 5G in today’s business landscape</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Key Benefits of 5G</strong>
                  <ul>
                    <li>Enhanced Connectivity</li>
                    <li>Cost Savings</li>
                    <li>Scalability</li>
                    <li>Security</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Industry-Specific Applications</strong>
                  <ul>
                    <li>Healthcare</li>
                    <li>Manufacturing</li>
                    <li>Finance</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Case Studies</strong>
                  <ul>
                    <li>Detailed analysis of successful 5G implementations</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Implementation Strategies</strong>
                  <ul>
                    <li>Assessing current infrastructure</li>
                    <li>Developing a phased rollout plan</li>
                    <li>Engaging with a trusted 5G partner</li>
                  </ul>
                </li>
                <li className={styles["list-item"]}>
                  <strong>Conclusion</strong>
                  <ul>
                    <li>Summary of benefits</li>
                    <li>Call to action for partnering with us</li>
                  </ul>
                </li>
              </ol>
            </TextElement>
          }
        />
      </div>
    </Card>
  );
};

export default WhitePaperTopics;
