import React from "react";

const NewCustomerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="154"
      height="114"
      viewBox="0 0 154 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_6219_7263)">
        <path
          d="M0.72998 82.5313L2.29652 82.1246L77.5631 65.334L153.73 82.5533L77.5631 102.872L0.72998 82.5313Z"
          fill="#724B30"
        />
        <path
          d="M153.73 82.5532V92.9574L77.5631 113.276L0.72998 92.9299V82.5312L77.5631 102.872L153.73 82.5532Z"
          fill="#915120"
        />
        <path
          d="M77.5633 102.877L16.2603 86.642C16.5933 88.4282 16.7807 90.2529 16.9785 92.0612L77.5633 108.104L147.703 89.3186V84.0918L77.5633 102.877Z"
          fill="#915120"
        />
        <path
          d="M121.588 91.0446C120.948 89.8409 119.688 87.659 118.985 86.4059C113.51 76.4579 101.915 73.9297 101.915 73.9297C94.1499 77.1064 75.5076 80.7339 75.5076 80.7339C75.5076 80.7339 56.4177 77.6176 48.6371 74.4408C48.6371 74.4408 39.7895 80.1403 34.3145 90.0828C34.0282 90.6324 33.7524 90.9731 33.4609 91.4733C40.6014 93.0397 73.3009 101.245 77.2354 103.059C77.9432 103.422 117.179 93.0506 121.588 91.0446Z"
          fill="#1A2C5D"
        />
        <path
          d="M73.7951 91.6919C73.7951 91.6919 70.9586 88.647 69.3713 86.7069C67.7839 84.7668 66.5869 83.2114 66.5869 83.2114L66.7326 82.4199C66.7326 82.4199 73.056 82.7497 75.3356 82.7937C77.6151 82.8376 83.2775 82.4199 83.2775 82.4199V82.9695C82.1182 84.8297 80.853 86.6137 79.4887 88.3118C78.2149 89.8844 76.7821 91.3054 75.2159 92.5493L74.3675 92.3129L73.7951 91.6919Z"
          fill="#E9D0AF"
        />
        <g opacity="0.25">
          <path
            d="M48.3145 73.6758C48.3145 73.6758 56.7665 86.5697 75.2838 86.5697C93.8012 86.5697 101.519 74.6156 101.519 74.6156C101.519 74.6156 85.2087 80.469 75.0653 80.469C64.9218 80.469 49.7092 75.2092 48.3145 73.6758Z"
            fill="#16161B"
          />
        </g>
        <path
          d="M37.9733 32.8451C37.9733 32.8451 38.983 39.8747 31.728 43.2877C29.6016 44.2915 27.3011 44.8185 24.972 44.8355C22.6429 44.8526 20.3358 44.3592 18.1965 43.3867L16.8901 32.8286C16.8901 32.8286 23.1355 35.0271 26.5288 34.3291C29.922 33.6311 36.5525 26.8379 36.5525 26.8379"
          fill="#EB2701"
        />
        <path
          d="M87.0822 82.1735C76.564 83.6849 59.2281 82.4978 53.9039 79.1836C46.8155 74.7867 40.5389 66.647 38.3947 60.8101C36.3285 55.1986 30.1769 33.8078 43.3181 15.9454C55.5798 -0.74628 86.8792 -10.2271 107.796 19.7377C113.188 29.0811 114.968 52.2747 109.44 64.2562C105.829 72.0717 97.2881 80.717 87.0822 82.1735Z"
          fill="#02285E"
        />
        <path
          d="M51.8063 37.781C50.0624 38.1493 48.4807 39.1107 47.3031 40.5181C46.1254 41.9255 45.4167 43.7015 45.2852 45.5745C44.9677 51.3344 47.1015 59.8643 51.848 63.4423C58.3691 68.3888 62.4806 58.5343 75.5906 58.5947C89.1586 58.6662 90.9385 65.5363 98.615 62.3486C103.257 60.4195 106.656 40.9138 101.998 38.5449C95.0032 35.0055 90.9541 38.9462 77.8754 39.4463C69.1371 39.7816 57.8851 36.3795 51.8063 37.781Z"
          fill="#FBF5EA"
          stroke="#EB2701"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M36.9062 27.4478C34.278 21.7154 29.1933 22.3529 25.1026 22.5948C21.0119 22.8366 17.8632 17.5273 17.8632 17.5273C15.4171 20.16 16.3019 30.9433 16.3019 30.9433C16.3019 30.9433 26.258 30.0255 28.6728 30.4322C31.0877 30.8389 35.7613 34.4499 35.7613 34.4499L36.1464 34.3564"
          fill="#EB2701"
        />
        <path
          d="M111.975 32.5936C111.975 32.5936 68.7417 38.9526 34.8973 34.5282C34.8973 34.5282 32.4252 34.2644 33.0653 29.6642C33.7055 25.0639 38.988 25.6465 38.988 25.6465C38.988 25.6465 67.4042 28.488 77.683 27.845C87.9617 27.2019 109.232 23.9977 109.883 24.1021C111.309 24.3384 112.204 29.6807 111.975 32.5936Z"
          fill="#EB2701"
        />
        <path
          d="M74.139 46.9376C72.5871 48.7701 70.6906 50.241 68.5705 51.2564C66.4504 52.2718 64.1531 52.8094 61.8253 52.8349C57.6617 52.5876 55.6997 51.351 53.5762 47.3388C51.8432 42.887 52.1658 41.7383 52.1658 41.7383L74.139 46.9376Z"
          fill="#EB2701"
        />
        <path
          d="M94.9307 50.625C93.894 51.8038 92.5449 52.6252 91.0595 52.9821C89.574 53.339 88.0212 53.2147 86.6036 52.6256C83.47 51.5758 80.7188 49.532 78.7241 46.7723L97.127 41.1992C97.127 41.1992 96.1173 49.0642 94.9307 50.625Z"
          fill="#EB2701"
        />
        <path
          d="M77.4071 103.015C84.5216 94.815 90.2465 86.0212 94.9305 79.6348C93.0361 79.8271 85.7342 82.6686 83.4391 82.6411C82.4398 83.7404 76.8815 91.7482 69.689 100.317C71.6458 101.284 75.4503 102.02 77.4071 103.015Z"
          fill="#182A51"
        />
        <path
          d="M71.1932 98.9043C65.9523 94.8152 57.0111 81.0749 57.2921 80.8276C57.5732 80.5803 64.7709 81.1739 66.0668 81.6465C67.7062 82.2456 71.1567 88.8959 76.3456 92.4464C76.6214 92.6497 71.3077 98.9978 71.1932 98.9043Z"
          fill="#182A51"
        />
        <path
          d="M105.454 95.9134C105.454 95.9134 109.779 84.1188 112.683 79.1613C114.064 76.8043 115.974 74.8428 118.243 73.45C120.512 72.0572 123.071 71.276 125.694 71.1755C134.542 70.8347 142.135 78.1775 142.26 87.5484C142.317 91.9452 141.609 96.4795 139.325 100.112C135.728 105.85 125.215 102.729 125.215 95.842L124.768 90.115L105.454 95.9134Z"
          fill="#02285E"
        />
        <path
          d="M47.1642 94.8138C47.0185 94.5115 42.1211 85.0032 39.2222 80.0512C37.84 77.6949 35.9304 75.7335 33.6615 74.34C31.3926 72.9465 28.834 72.1634 26.2111 72.0599C17.3636 71.7246 9.79635 79.0674 9.66624 88.4328C9.60899 92.8297 10.322 97.3694 12.5963 101.002C16.1978 106.74 25.9405 104.196 25.9405 97.309L26.8409 89.7299L47.1642 94.8138Z"
          fill="#02285E"
        />
      </g>
      <defs>
        <clipPath id="clip0_6219_7263">
          <rect
            width="153"
            height="113"
            fill="white"
            transform="translate(0.72998 0.28125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewCustomerIcon;
