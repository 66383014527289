import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Question, Sender } from "../componenets/CampaignQueryChat/types";

interface CampaignAnalysisQueryChatContextType {
  conversation: Question[];
  updateConversation: (appendConversation: Question[]) => void;
  clearConversation: () => void;
}

const CampaignAnalysisQueryChatContext = createContext<
  CampaignAnalysisQueryChatContextType | undefined
>(undefined);

export const CampaignAnalysisQueryChatProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [conversation, setConversation] = useState<Question[]>([
    {
      sender: Sender.NINJA,
      content: "Hello! How can I help you today?",
    },
  ]);
  
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const selectedCampaingRef = React.useRef(selectedCampaign);

  useEffect(() => {
    selectedCampaingRef.current = selectedCampaign;
  }, [selectedCampaign]);

  const updateConversation = (appendConversation: Question[]) => {
    setConversation((prevConversation) => [
      ...appendConversation,
      ...prevConversation,
    ]);
  };

  const clearConversation = () => {
    setConversation([]);
  };

  return (
    <CampaignAnalysisQueryChatContext.Provider
      value={
        {
        conversation,
        updateConversation,
        clearConversation,
        }
    }
    >
      {children}
    </CampaignAnalysisQueryChatContext.Provider>
  );
};

export const useCampaignAnalysisQueryChat =
  (): CampaignAnalysisQueryChatContextType => {
    const context = useContext(CampaignAnalysisQueryChatContext);
    if (!context) {
      throw new Error(
        "useCampaignAnalysisQueryChat must be used within a CampaignAnalysisQueryChatProvider"
      );
    }
    return context;
  };
