import React from "react";

const RetentionCampaignsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.88889 20V12.6667L3.72222 17.8611L2.13889 16.2778L7.33333 11.1111H0V8.88889H7.33333L2.13889 3.72222L3.72222 2.13889L8.88889 7.33333V0H11.1111V7.33333L16.2778 2.13889L17.8611 3.72222L12.6667 8.88889H20V11.1111H12.6667L17.8611 16.2778L16.2778 17.8611L11.1111 12.6667V20H8.88889Z"
        fill="#EB3223"
      />
    </svg>
  );
};

export default RetentionCampaignsIcon;
