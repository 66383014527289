export enum Sender {
  USER = "user",
  NINJA = "ninja",
}

export type Question = {
  sender: Sender;
  content?: React.ReactNode;
  contentAsString?: string;
};
export interface CampaignQueryChatProps {
  className?: string;
  payload: string;
}
