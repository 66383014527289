import React from "react";

import styles from "./InitialOutreach.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import FieldWithIcon from "../FieldWithIcon/FieldWithIcon";

interface InitialOutreachProps {
  className?: string;
  cardType: CardType;
}

const InitialOutreach: React.FC<InitialOutreachProps> = (props) => {
  const { className, cardType } = props;

  const handlePinned = (isPinned: boolean) => {};

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div className={styles["content"]}>
        <div className={styles["content-heading"]}>
          <TextElement className={styles["heading"]}>
            Month 1-2: Initial Outreach and Introduction
          </TextElement>
          <TextElement>
            <span>Email 1: Initial Outreach</span>
          </TextElement>
        </div>   
        <FieldWithIcon
          icon={
            <svg
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1 2.1H12.3V0.5H10.7V2.1H4.30005V0.5H2.70005V2.1H1.90005C1.01205 2.1 0.308049 2.82 0.308049 3.7L0.300049 14.9C0.300049 15.78 1.01205 16.5 1.90005 16.5H13.1C13.98 16.5 14.7 15.78 14.7 14.9V3.7C14.7 2.82 13.98 2.1 13.1 2.1ZM13.1 14.9H1.90005V6.9H13.1V14.9ZM13.1 5.3H1.90005V3.7H13.1V5.3ZM5.10005 10.1H3.50005V8.5H5.10005V10.1ZM8.30005 10.1H6.70005V8.5H8.30005V10.1ZM11.5 10.1H9.90005V8.5H11.5V10.1ZM5.10005 13.3H3.50005V11.7H5.10005V13.3ZM8.30005 13.3H6.70005V11.7H8.30005V13.3ZM11.5 13.3H9.90005V11.7H11.5V13.3Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Send Date"
          text="Day 1"
        />
        <FieldWithIcon
          icon={
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.5C4.084 0.5 0.5 4.084 0.5 8.5C0.5 12.916 4.084 16.5 8.5 16.5H12.5V14.9H8.5C5.028 14.9 2.1 11.972 2.1 8.5C2.1 5.028 5.028 2.1 8.5 2.1C11.972 2.1 14.9 5.028 14.9 8.5V9.644C14.9 10.276 14.332 10.9 13.7 10.9C13.068 10.9 12.5 10.276 12.5 9.644V8.5C12.5 6.292 10.708 4.5 8.5 4.5C6.292 4.5 4.5 6.292 4.5 8.5C4.5 10.708 6.292 12.5 8.5 12.5C9.604 12.5 10.612 12.052 11.332 11.324C11.852 12.036 12.748 12.5 13.7 12.5C15.276 12.5 16.5 11.22 16.5 9.644V8.5C16.5 4.084 12.916 0.5 8.5 0.5ZM8.5 10.9C7.172 10.9 6.1 9.828 6.1 8.5C6.1 7.172 7.172 6.1 8.5 6.1C9.828 6.1 10.9 7.172 10.9 8.5C10.9 9.828 9.828 10.9 8.5 10.9Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Subject"
          text="Unlock the Future with Our Cutting-Edge 5G Solutions"
        />
        <FieldWithIcon
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7857 14.2143H0.357178V16.5H11.7857V14.2143ZM18.6429 5.07143H0.357178V7.35714H18.6429V5.07143ZM0.357178 11.9286H18.6429V9.64286H0.357178V11.9286ZM0.357178 0.5V2.78571H18.6429V0.5H0.357178Z"
                fill="#EB2701"
              />
            </svg>
          }
          label="Body"
          text={
            <TextElement className={styles["body-text"]}>
              Hi [Lead’s First Name], <br />
              <br />
              I hope this email finds you well. <br />
              <br />
              My name is [Your Name], and I’m with [Your Company]. We specialise
              in delivering robust and scalable 5G connectivity solutions that
              can transform your business operations. <br />
              <br />
              We understand that your organisation is looking to enhance its
              technology infrastructure, and we believe our 5G solutions can
              offer significant benefits, including: <br />
              <ul>
                <li>Reducing operational costs by up to 20%</li>
                <li>Increasing productivity by 30%</li>
                <li>Seamless integration with existing systems</li>
                <li>Minimising disruptions during the transition to 5G</li>
              </ul>
              <br />
              Would you be available for a brief call this week to discuss how our solutions can specifically meet your needs?
              <br /> <br />
              Looking forward to connecting with you.
              Best regards, <br /> <br />
              [Your Name] <br />
              [Your Position] <br />
              [Your Contact Information]
            </TextElement>
          }
        />
      </div>
    </Card>
  );
};

export default InitialOutreach;
