import React, { useEffect, useState } from "react";

import styles from "./GenerateCampaignPlan.module.scss";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DisclosureComponent from "../Disclosure/Disclosure";
import TextElement from "../TextElement/TextElement";
import CompetitorStrategies from "../CompetitorStrategies/CompetitorStrategies";
import classNames from "classnames";

import ShowPastInsights from "./componenets/ShowPastInsights";
import ShowAudienceSegments from "./componenets/ShowAudienceSegments";
import MarketSentimentCard from "../MarketSentimentCard/MarketSentimentCard";
import {
  fetchBudgetAllocationForDifferentChannels,
  fetchCompetitors,
  fetchMarketSentiments,
  fetchPastCampaignAnalysis,
  fetchSegments,
  fetchSwotAnalysis,
} from "../../redux/campaignPlanAnalysisQueryChat";
import SwotAnalysisComparison from "../SwotAnalysisComparison/SwotAnalysisComparison";

import { MarketSentiment } from "../../redux/campaignPinningSlice";
import useCampaignFlags from "./useCampaignAnalysisData";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import { fetchPosts } from "../../redux/contentGenerationSlice";
import PostSchedulesCalendarListView from "../PostSchedulesCalendarListView/PostSchedulesCalendarListView";
import ContentDeliveryPlans from "../ContentDelieveryPlans/ContentDeliveryPlans";
import RecommendedMarketingChannels from "../RecommendedMarketingChannels/RecommendedMarketingChannels";
import RecommendedProductOfferingsCard from "../RecommendedProductOfferingsCard/RecommendedProductOfferingsCard";
import CompetitorAnalysisModal from "../CompetitorSliderCard/CompetitorSliderCard";
import CompetitorSliderCard from "../CompetitorSliderCard/CompetitorSliderCard";

interface Props {
  className?: string;
  onClick?: () => void;
  enableCampaignPlanGeneration: boolean;
}

const GenerateCampaignPlan: React.FC<Props> = (props) => {
  const { className, onClick, enableCampaignPlanGeneration } = props;
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const selectedCampaignRef = React.useRef(selectedCampaign);
  const dispatch = useAppDispatch();

  const [loadingText, setLoadingText] = React.useState("");

  const {  
    marketSentiments,
    swotAnalysis,
    competitors,
    audienceSegments,
    recommendation,
    reverse_campaign_brief,
    pastCampaignAnalysis,
  } = useAppSelector((state) => state.campaignPlanAnalysisQueryChat);

  const [showAudienceSegmentButton, setShowAudienceSegmentButton] =
    useState(false);
  const [showMarketSentimentButton, setShowMarketSentimentButton] =
    useState(false);
  const [showSwotAnalysisButton, setShowSwotAnalysisButton] = useState(false);
  const [showCompetitorAnalysisButton, setShowCompetitorAnalysisButton] =
    useState(false);
  const [showPastInsightsButton, setShowPastInsightsButton] = useState(false);
  const [
    showCampaignRecommendationsButton,
    setShowCampaignRecommendationsButton,
  ] = useState(false);
  const [showContentCalendarButton, setShowContentCalendarButton] =
    useState(false);

  useEffect(() => {
    if (selectedCampaign) {
      selectedCampaignRef.current = selectedCampaign;
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (enableCampaignPlanGeneration && selectedCampaignRef?.current) {
      setLoadingText("Fetching past campaign analysis");
      setShowPastInsightsButton(false);
      dispatch(
        fetchPastCampaignAnalysis({
          campaignPlanId: selectedCampaignRef.current?.id,
        })
      )
        .catch(() => {
          toast.error("Error fetching past campaign analysis");
          setShowPastInsightsButton(true);
        })
        .finally(() => {
          setLoadingText("");
          setShowAudienceSegmentButton(true);
        });
    }
  }, [enableCampaignPlanGeneration]);

  const { posts } = useAppSelector((state) => state.contentGeneration);

  const {
    hasPastInsights,
    hasAudienceSegmentation,
    hasCampaignRecommendations,
    hasCompetitorAnalysis,
    hasMarketSentiments,
    hasSwoTAnalysis,
  } = useCampaignFlags();

  const handleAudienceSegments = () => {
    if (!selectedCampaignRef.current) return;
    setLoadingText("Fetching Audience Segments");
    setShowAudienceSegmentButton(false);
    dispatch(fetchSegments({ campaignPlanId: selectedCampaignRef.current?.id }))
      .catch(() => {
        toast.error("Error fetching audience segments");
        setShowAudienceSegmentButton(true);
      })
      .finally(() => {
        setShowMarketSentimentButton(true);
        setLoadingText("");
      });
  };

  const handleMarketSentiments = () => {
    if (!selectedCampaignRef?.current) return;
    setLoadingText("Fetching Market Sentiments");
    setShowMarketSentimentButton(false);
    dispatch(
      fetchMarketSentiments({
        campaignPlanId: selectedCampaignRef?.current?.id,
      })
    )
      .catch(() => {
        toast.error("Error fetching market sentiments");
        setShowMarketSentimentButton(true);
      })
      .finally(() => {
        setShowSwotAnalysisButton(true);
        setLoadingText("");
      });
  };

  const handleSwotAnalysis = () => {
    if (!selectedCampaignRef?.current) return;
    setLoadingText("Fetching SWOT Analysis");
    setShowSwotAnalysisButton(false);
    dispatch(
      fetchSwotAnalysis({ campaignPlanId: selectedCampaignRef.current?.id })
    )
      .catch(() => {
        toast.error("Error fetching SWOT Analysis");
        setShowSwotAnalysisButton(true);
      })
      .finally(() => {
        setShowCompetitorAnalysisButton(true);
        setLoadingText("");
      });
  };

  const handleCompetitorAnalysis = () => {
    if (!selectedCampaignRef?.current) return;
    setLoadingText("Fetching Competitor Analysis");
    setShowCompetitorAnalysisButton(false);
    dispatch(
      fetchCompetitors({ campaignPlanId: selectedCampaignRef.current?.id })
    )
      .catch(() => {
        toast.error("Error fetching Competitor Analysis");
        setShowCompetitorAnalysisButton(true);
      })
      .finally(() => {
        setLoadingText("");
        setShowCampaignRecommendationsButton(true);
      });
  };

  const handleRecommendations = () => {
    if (!selectedCampaignRef?.current) return;
    setLoadingText("Fetching Campaign Recommendations");
    setShowCampaignRecommendationsButton(false);
    dispatch(
      fetchBudgetAllocationForDifferentChannels({
        campaignPlanId: selectedCampaignRef.current?.id,
      })
    )
      .catch(() => {
        toast.error("Error fetching Recommendations");
        setShowCampaignRecommendationsButton(true);
      })
      .finally(() => {
        setLoadingText("");
        setShowContentCalendarButton(true);
      });
  };

  const handleContentGeneration = () => {
    if (!selectedCampaignRef?.current) return;
    setLoadingText("Fetching Content Calendar");
    setShowContentCalendarButton(false);
    dispatch(fetchPosts({ campaignPlanId: selectedCampaignRef.current?.id }))
      .catch(() => {
        toast.error("Error fetching Content Calendar");
        setShowContentCalendarButton(true);
      })
      .finally(() => {
        setLoadingText("");
      });  
  };

  return (
    <div className={classNames(styles["content"], className)} onClick={onClick}>
      {hasPastInsights && (
        <DisclosureComponent
          header={<TextElement>Past Campaign Analysis</TextElement>}
          content={
            <div className={styles["tab-content"]}>
              <ShowPastInsights />
            </div> 
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {hasAudienceSegmentation && (
        <DisclosureComponent
          header={<TextElement>Audience Segments</TextElement>}
          content={
            <div className={styles["tab-content"]}>
              <ShowAudienceSegments />
            </div>
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {hasMarketSentiments && (
        <DisclosureComponent
          header={<TextElement>Market Sentiments</TextElement>}
          content={
            <div
              className={classNames(
                styles["tab-content"],
                styles["market-sentiments"]
              )}
            >
              {marketSentiments?.map((marketSentiment: MarketSentiment) => (
                <MarketSentimentCard
                  key={marketSentiment.id}
                  marketSentiment={marketSentiment}
                  cardType="pinable"
                  className={styles["card"]}
                />
              ))}
            </div>
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {hasSwoTAnalysis && (
        <DisclosureComponent
          header={<TextElement>SWOT Analysis</TextElement>}
          content={
            <SwotAnalysisComparison
            analysisComparison={swotAnalysis}
            cardType="pinable"
            className={styles["swot-analysis"]}
          />  
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {hasCompetitorAnalysis && (
        <DisclosureComponent
          header={<TextElement>Competitor Analysis</TextElement>}
          content={<CompetitorSliderCard type="pinable" />}
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {hasCampaignRecommendations && (
        <DisclosureComponent
          header={<TextElement>Campaign Recommendations</TextElement>}
          content={
            <div
              className={classNames(
                styles["tab-content"],
                styles["recommendations"]
              )}
            >
              <RecommendedProductOfferingsCard
                className={styles["card"]}
                cardType="pinable"
               
              />
              <RecommendedMarketingChannels
                className={styles["card"]}
                cardType="pinable"
              />
              <ContentDeliveryPlans className={styles["card"]} />
            </div>
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      {posts && posts?.length > 0 && (
        <DisclosureComponent
          header={<TextElement>Content Calendar</TextElement>}
          content={
            <div
              className={classNames(
                styles["tab-content"],
                styles["content-calendar"]
              )}
            >
              <PostSchedulesCalendarListView />
            </div>
          }
          headerClassName={styles["closure-header"]}
          contentClassName={styles["closure-content"]}
          openHeaderClassName={styles["open-closure-header"]}
        />
      )}

      <div className={styles["generate-buttons-container"]}>
        {showAudienceSegmentButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleAudienceSegments}
          >
            Generate Segments
          </Button>
        )}
        {showMarketSentimentButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleMarketSentiments}
          >
            Generate Market Sentiments
          </Button>
        )}
        {showSwotAnalysisButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleSwotAnalysis}
          >
            Generate SWOT Analysis
          </Button>
        )}
        {showCompetitorAnalysisButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleCompetitorAnalysis}
          >
            Generate Competitor Analysis
          </Button>
        )}
        {showCampaignRecommendationsButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleRecommendations}
          >
            Generate Campaign Recommendations
          </Button>
        )}
        {showContentCalendarButton && (
          <Button
            className={styles["generate-button"]}
            onClick={handleContentGeneration}
          >
            Generate Content Calendar
          </Button>
        )}
      </div>

      {loadingText && (
        <div className={styles["loader-container"]}>
          <TextElement>{loadingText}</TextElement>
          <div className={styles["loader"]}></div>
        </div>
      )}
    </div>
  );
};

export default GenerateCampaignPlan;
