import React from "react";

import styles from "./KeyMetricsToMonitor.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface KeyMetricsToMonitorProps {
  className?: string;
  cardType: CardType;
}

const KeyMetricsToMonitor: React.FC<KeyMetricsToMonitorProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        keyMetricsToMonitor: isPinned,
      })
    );
  };
  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Ke Metrics To Monitor
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Call Conversion Rate
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Percentage of calls that convert from MQL to SQL.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Target:</span>
            Aim for a <span>conversion rate of 25%</span> based on past
            performance.
          </TextElement>
        </div>

        <TextElement className={styles["content-heading"]}>
          Qualification Time
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Average time taken to qualify an MQL to an SQL.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Target:</span>
            Maintain an <span>average of 15 days</span>.
          </TextElement>
        </div>

        <TextElement className={styles["content-heading"]}>
          Lead Drop-off Rate
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Percentage of leads that do not move past the qualification stage.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Target:</span>
            Keep <span>drop-off rate below 10%.</span>
          </TextElement>
        </div>

        <TextElement className={styles["content-heading"]}>
          Feedback Loop
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Regular feedback from the sales team on the quality of SQLs passed
            on.
          </TextElement>
          <TextElement>
            <span className={styles["highlight"]}>Action: </span>
            Weekly meetings with sales to discuss lead quality and areas for
            improvement.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default KeyMetricsToMonitor;
