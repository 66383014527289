import React, { useEffect } from "react";

import styles from "./SalesQualifiedOpportunities.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { TextElement } from "../../componenets";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import SalesQualifiedLeadsReport from "../../componenets/CampaignReports/SalesQualifiedLeads";
import SalesQualifiedOpportunitiesInsights from "../../componenets/CampaignReports/SalesQualifiedOpportunitiesInsights";

interface Props {}

const SalesQualifiedOpportunities: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <MarkerterViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          <button
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.HOME.path);
            }}
            className={styles["back-link"]}
          >
            Back to Main Funnel
          </button>
          <br />
          Sales Qualified Opportunities
        </TextElement>
      }
    >
      <SalesQualifiedOpportunitiesInsights className={styles["report"]} />
    </MarkerterViewLayout>
  );
};

export default SalesQualifiedOpportunities;
