import React from "react";

const MediumLowRiskCustomerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="108"
      height="115"
      viewBox="0 0 108 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <g clip-path="url(#clip0_6216_6814)">
        <path
          d="M92.4931 20.8999C92.4931 20.8999 91.8987 25.4839 96.9393 27.6007C101.98 29.7176 106.285 27.4589 106.285 27.4589L107.05 20.5637C107.05 20.5637 102.78 22.0943 100.418 21.6885C98.0559 21.2826 93.3832 16.9727 93.3832 16.9727"
          fill="#EB2701"
        />
        <g opacity="0.4">
          <path
            d="M35.1759 42.1106C33.8412 39.4304 34.0593 36.8173 34.602 34.8887C34.0894 36.8894 33.9004 39.5274 35.1759 42.1106Z"
            fill="white"
          />
        </g>
        <g opacity="0.4">
          <path
            d="M36.8128 21.4902C35.949 27.087 35.9657 31.7796 35.9657 31.7796C35.9657 31.7796 35.1315 33.0084 34.6018 34.8889C35.1194 32.867 35.9674 31.4973 35.9674 31.4973C35.9674 31.4973 35.8243 26.9581 36.8128 21.4902Z"
            fill="white"
          />
        </g>
        <g opacity="0.25">
          <path
            d="M37.4524 14.6969C37.0845 14.6306 36.8926 14.5945 36.8926 14.5945C36.8885 14.5866 37.1712 14.6409 37.4524 14.6969Z"
            fill="#0F0F14"
          />
        </g>
        <g opacity="0.25">
          <path
            d="M38.0079 14.7967C37.9858 14.7967 37.8323 14.7689 37.6462 14.7324C37.7555 14.7519 37.8765 14.7726 38.0079 14.7967Z"
            fill="#0F0F14"
          />
        </g>
        <path
          d="M55.6289 103.412C52.7151 103.697 50.7347 104.075 50.7347 104.075C50.7347 104.075 45.2595 106.595 44.9517 107.994C44.6439 109.392 45.619 112.92 45.619 112.92L66.4179 113.362C66.4179 113.362 66.2352 108.972 66.2723 103.457C62.7471 103.522 58.7621 103.112 55.6289 103.412Z"
          fill="#02285E"
        />
        <path
          d="M85.1777 50.7891C65.0407 55.8585 51.662 50.9367 51.662 50.9367C48.0391 56.0857 47.9544 68.9935 47.9544 68.9935C46.7232 81.842 50.7627 104.104 50.7627 104.104C50.7627 104.104 54.0344 103.422 57.0746 103.412C60.1232 103.403 62.5065 103.357 66.3066 103.597C66.3066 103.409 66.2745 94.3813 66.2762 94.186C66.3179 88.1992 67.1554 86.0574 67.1554 86.0574H68.6503C70.277 88.3302 71.089 103.635 71.089 103.635C71.089 103.635 79.1657 103.585 86.7718 104.962C86.8598 100.067 88.2921 77.3211 88.2921 70.1008C88.2921 62.7289 85.1777 50.7891 85.1777 50.7891Z"
          fill="#02285E"
        />
        <path
          d="M91.6906 109.938C91.0061 108.714 86.6854 104.078 86.6854 104.078C79.0788 102.702 71.114 103.606 71.114 103.606C71.114 103.606 70.8938 106.622 71.114 108.238C71.3342 109.855 71.3363 113.365 71.3363 113.365L91.9129 113.586C91.9129 113.586 92.375 111.163 91.6906 109.938Z"
          fill="#02285E"
        />
        <path
          d="M67.0597 57.6784C67.0597 57.6784 70.135 54.9447 70.5909 54.48C71.0468 54.0152 71.2958 53.7598 71.4251 53.6259C71.5544 53.492 71.7316 53.1226 71.7316 53.1226H71.5548C71.5548 53.1226 66.216 52.9013 65.9949 52.8896C65.7738 52.878 62.9284 52.5829 62.636 52.6562C62.3436 52.7296 62.168 52.7512 62.0141 52.7512C61.8957 52.7552 61.7779 52.7698 61.6621 52.7947V53.1678C61.6621 53.1678 62.7253 54.7337 63.4606 55.5098C64.196 56.2858 66.1655 58.0669 66.1655 58.0669L66.5893 58.1498L66.8662 58.1112L67.0597 57.6784Z"
          fill="#F2C5A7"
        />
        <path
          d="M88.2919 73.5631C88.2919 73.5631 82.0113 77.5986 67.3629 77.5986C52.7145 77.5986 47.676 74.7239 47.676 74.7239C47.676 74.7239 47.7177 71.8898 47.7874 70.2651C47.7874 70.2651 51.6526 73.5631 67.1406 73.5631C82.6286 73.5631 88.2919 69.5098 88.2919 69.5098C88.2919 69.5098 88.3879 72.8711 88.2919 73.5631Z"
          fill="#FF2434"
        />
        <path
          d="M57.3649 81.383C62.2804 85.8529 69.6947 86.8902 74.9664 83.4256C75.7538 82.8978 76.5367 82.2416 77.1987 81.3991C77.2454 81.3291 77.3238 81.3332 77.3655 81.4124C77.3838 81.4493 77.3929 81.4901 77.3918 81.5313C77.3908 81.5724 77.3798 81.6127 77.3597 81.6487C76.7169 82.6549 75.949 83.3816 75.149 84.0317C70.5118 87.6066 64.1636 87.202 59.5226 83.8182C58.7167 83.215 57.9376 82.5417 57.2285 81.6653C57.2037 81.6291 57.189 81.587 57.1857 81.5434C57.1825 81.4997 57.1909 81.4559 57.2102 81.4165C57.2481 81.3423 57.3157 81.3295 57.3645 81.3821L57.3649 81.383Z"
          fill="#012551"
        />
        <path
          d="M50.3918 71.5168C51.2761 71.8949 54.2446 72.4355 55.221 72.7514C56.9607 71.6619 70.7244 61.2979 77.7625 52.2129C76.4578 52.3373 73.3855 52.9446 71.8059 52.9255C70.5263 54.2738 60.4451 65.4822 50.3918 71.5168Z"
          fill="#012551"
        />
        <path
          d="M63.9541 61.8287C63.8595 61.8287 63.7674 61.7985 63.6914 61.7425C60.0735 59.0938 55.1125 52.6181 54.9032 52.3441C54.8678 52.2981 54.8418 52.2456 54.8269 52.1896C54.8119 52.1336 54.8082 52.0752 54.8159 52.0178C54.8237 51.9604 54.8428 51.9051 54.872 51.855C54.9013 51.8049 54.9402 51.7611 54.9866 51.7259C55.1818 51.5784 60.3604 50.9382 60.5085 51.1319C60.559 51.1978 63.1779 56.0205 66.762 58.3301C66.9676 58.4624 64.4555 61.4527 64.3103 61.6467C64.2695 61.7028 64.216 61.7484 64.1541 61.78C64.0922 61.8117 64.0237 61.8283 63.9541 61.8287Z"
          fill="#012551"
        />
        <path
          d="M43.3567 65.3816C43.3567 65.3816 40.9509 66.0835 34.8834 64.8601C28.8159 63.6367 26.4414 56.7315 26.4414 56.7315C28.3759 52.4485 31.8057 50.8242 31.8057 50.8242C34.3558 52.4332 39.3238 53.5849 42.525 52.099C45.7262 50.6132 51.8108 50.9382 51.8108 50.9382C51.8108 50.9382 50.1529 55.8505 49.438 57.4239C48.7231 58.9972 48.4741 62.878 48.4741 62.878"
          fill="#02285E"
        />
        <path
          d="M26.4093 56.9922C26.4093 56.9922 22.6604 56.4023 21.5393 55.9434C20.4181 55.4844 19.7366 54.4646 19.7366 54.4646C19.7366 54.4646 17.7579 54.698 17.4501 53.4306C17.4501 53.4306 16.175 53.016 16.1988 51.9883C16.1988 51.9883 14.4399 51.3113 13.8906 50.3715C13.3413 49.4316 13.8184 49.2969 13.8184 49.2969L16.5295 50.6339C16.5295 50.6339 17.8484 50.131 18.288 51.0269C18.288 51.0269 19.8488 50.4589 20.3105 51.4415C20.3105 51.4415 21.4759 50.6986 21.8713 51.6819C22.2667 52.6653 22.3772 53.0363 22.3772 53.0363C22.3772 53.0363 26.3121 54.0417 26.9936 54.1727C27.6752 54.3037 27.953 54.1851 27.8395 54.3385C27.7261 54.4919 26.4093 56.9922 26.4093 56.9922Z"
          fill="#F2C5A7"
        />
        <path
          d="M69.1223 77.6692C69.1223 77.6692 69.7087 79.7454 67.4468 82.4621C65.1849 85.1788 63.031 86.1887 63.031 86.1887C63.031 86.1887 64.8817 88.8722 66.2939 89.4937C66.2939 89.4937 70.175 86.8918 72.2451 82.1354C74.3151 77.379 69.9669 74.3828 69.9669 74.3828"
          fill="#FF2434"
        />
        <g opacity="0.25">
          <path
            d="M69.8029 76.2402C69.8029 76.2402 70.9867 78.2882 70.3452 80.6567C69.7037 83.0252 66.1379 86.3061 66.0491 86.5814C65.9602 86.8567 67.0209 88.2211 67.5448 88.2277C68.0687 88.2343 66.2935 89.4926 66.2935 89.4926C66.2935 89.4926 63.5582 87.4487 63.0852 86.2651C63.0852 86.2651 67.725 82.9763 68.6609 80.7927C69.5969 78.6091 69.1197 77.6676 69.1197 77.6676L69.8029 76.2402Z"
            fill="#0F0F14"
          />
        </g>
        <path
          d="M61.9863 78.649C61.9863 78.649 60.2412 85.8792 53.6469 86.1503L51.8901 82.8657C51.8901 82.8657 54.9533 82.5468 56.424 80.4852C57.8946 78.4235 59.6577 75.4912 61.5067 75.5517"
          fill="#FF2434"
        />
        <g opacity="0.25">
          <path
            d="M53.527 82.6098C53.527 82.6098 54.1994 83.9779 55.2 84.1355C56.2006 84.293 58.6406 82.5 59.3092 80.9615C59.9778 79.423 61.3663 77.1113 61.3663 77.1113L61.6875 77.9794L61.9377 78.9496C61.9377 78.9496 60.9417 82.323 58.5885 84.2188C56.2352 86.1147 53.6459 86.1524 53.6459 86.1524L51.9929 82.854L53.527 82.6098Z"
            fill="#0F0F14"
          />
        </g>
        <path
          d="M65.8678 74.896C65.8678 74.896 66.4793 72.533 69.5207 72.8377C72.5622 73.1424 72.2706 77.8466 70.7824 79.3556C69.2942 80.8647 67.3147 79.7644 67.3147 79.7644C67.3147 79.7644 65.024 81.106 62.6261 79.8245C60.2283 78.5431 59.5897 74.7945 61.5275 74.11C63.4653 73.4255 64.9573 74.3028 65.8678 74.896Z"
          fill="#FF2434"
        />
        <g opacity="0.25">
          <path
            d="M60.3195 76.0744C60.3195 76.0744 62.5159 78.3877 64.82 78.3761C67.124 78.3645 67.4702 77.314 67.4702 77.314L67.4314 76.9359C67.4314 76.9359 69.6941 78.151 70.593 77.6278C71.4918 77.1046 71.6086 76.4517 71.6086 76.4517L71.8647 75.875C71.8647 75.875 72.082 78.9677 70.3557 79.7107C68.6293 80.4536 67.6516 79.9134 67.6516 79.9134L66.9488 79.7695C66.9488 79.7695 64.4312 81.0759 62.2386 79.5838C60.0459 78.0917 60.3195 76.0744 60.3195 76.0744Z"
            fill="#0F0F14"
          />
        </g>
        <g opacity="0.4">
          <path
            d="M64.7865 75.3697C65.4989 75.8075 65.8292 76.4231 65.6841 76.623C65.5389 76.8228 64.9675 76.5301 64.2555 76.0923C63.5435 75.6545 62.9579 75.2366 63.1048 75.0372C63.2516 74.8378 64.0745 74.9319 64.7865 75.3697Z"
            fill="white"
          />
        </g>
        <g opacity="0.4">
          <path
            d="M69.5187 73.8235C70.1706 73.9893 70.5652 74.4453 70.4947 74.6767C70.4242 74.908 69.9162 74.8288 69.2643 74.6626C68.6124 74.4963 68.0643 74.3065 68.1348 74.0747C68.2053 73.843 68.8672 73.6572 69.5187 73.8235Z"
            fill="white"
          />
        </g>
        <path
          d="M59.2972 53.722C66.5809 54.5428 78.5369 53.5068 82.1703 51.271C86.9969 48.3006 91.2166 42.9177 92.6139 39.0895C93.9586 35.4052 97.8964 21.4058 88.5752 9.99539C79.8637 -0.667916 58.1063 -6.35009 44.0999 13.4459C40.5099 19.6152 39.619 34.7041 43.6119 42.4248C46.2041 47.4362 52.2257 52.9256 59.2972 53.722Z"
          fill="#02285E"
        />
        <path
          d="M81.5605 23.9096C84.1047 24.4029 85.9733 26.4286 86.1719 28.8762C86.4755 32.614 85.1291 38.1835 81.8991 40.5917C77.4696 43.8951 72.4586 37.6068 63.4076 37.8506C54.0376 38.103 53.4482 40.7149 48.1002 38.7589C44.8669 37.5757 43.7199 24.6612 46.9044 23.0552C51.6818 20.6481 54.5348 25.2665 63.5766 25.393C69.6144 25.4771 77.3449 23.0916 81.5605 23.9096Z"
          fill="#FFF6EB"
          stroke="#EB2701"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M93.1504 17.3748C94.888 13.6138 98.4058 13.9438 101.238 14.042C104.07 14.1403 106.164 10.6367 106.164 10.6367C107.889 12.3095 107.422 19.3324 107.422 19.3324C107.422 19.3324 100.546 18.8868 98.865 19.1911C97.1837 19.4954 94.0267 21.9099 94.0267 21.9099L93.7456 21.8626"
          fill="#EB2701"
        />
        <path
          d="M41.9854 20.2743C41.9854 20.2743 71.3356 25.3384 94.6424 21.9451C94.6424 21.9451 96.3454 21.7378 95.837 18.7557C95.3286 15.7737 91.6894 16.2343 91.6894 16.2343C91.6894 16.2343 71.9721 17.529 64.861 17.2583C57.7499 16.9876 43.7093 15.2007 43.26 15.2841C42.2845 15.4648 41.791 18.3847 41.9854 20.2743Z"
          fill="#EB2701"
        />
        <path
          d="M64.5652 29.1342C66.8513 31.5221 69.9582 32.8396 73.1665 32.7824C76.0516 32.5569 77.3729 31.7236 78.7852 29.0927C79.9151 26.1641 79.6782 25.4258 79.6782 25.4258L64.5652 29.1342Z"
          fill="#EB2701"
        />
        <path
          d="M50.2091 30.1504C51.5705 31.8672 53.7611 32.5923 55.7956 31.9998C58.0017 31.5438 60.0208 30.4104 61.5958 28.7409L49.2998 23.9023C49.2998 23.9023 49.4825 29.0597 50.2091 30.1504Z"
          fill="#EB2701"
        />
        <path
          d="M92.2252 50.0742C92.2252 50.0742 92.1289 49.1696 91.2054 47.0718C90.282 44.9741 89.6668 43.4887 88.1719 43.7507C86.677 44.0127 87.1604 47.7721 87.1604 47.7721C87.1604 47.7721 80.4773 47.7721 79.2381 49.1617C77.9989 50.5514 80.2575 52.1421 80.2575 52.1421C80.2575 52.1421 79.9939 52.492 79.5981 53.4331C79.2023 54.3742 80.3455 55.1142 80.3455 55.1142C80.3455 55.1142 80.5653 56.5126 81.5326 58.2169C82.4998 59.9212 85.314 60.2272 85.314 60.2272C95.4264 60.49 92.2252 50.0742 92.2252 50.0742Z"
          fill="#F2C5A7"
        />
        <path
          d="M104.308 59.1174C103.649 55.1843 91.4061 47.6934 91.4061 47.6934L92.2252 50.0759C92.2252 50.0759 95.4264 60.4901 85.314 60.2281C85.314 60.2281 92.4934 67.0748 97.8573 66.8563C103.221 66.6378 104.968 63.0505 104.308 59.1174Z"
          fill="#02285E"
        />
        <path
          d="M34.8751 12.3085C28.4234 10.986 22.6008 18.1101 21.5084 20.9578C19.3395 18.8049 11.0894 14.6778 5.74972 18.5118C0.410067 22.3458 -2.09501 30.5619 5.57037 41.7094C13.2358 52.8569 28.7321 54.4659 28.7321 54.4659C28.7321 54.4659 42.2043 46.6872 44.6013 33.3963C46.9984 20.1054 41.3271 13.6327 34.8751 12.3085Z"
          fill="#EB2701"
        />
        <path
          d="M38.5692 13.7207C41.1239 17.1733 42.4011 22.5512 41.0276 30.1682C38.6305 43.4591 25.1583 51.2378 25.1583 51.2378C25.1583 51.2378 15.4972 50.2328 7.65576 44.3218C15.7316 53.115 28.7316 54.4665 28.7316 54.4665C28.7316 54.4665 42.2038 46.6878 44.6008 33.3969C46.522 22.7456 43.2611 16.4731 38.5692 13.7207Z"
          fill="#E21D03"
        />
        <g opacity="0.5">
          <path
            d="M9.75833 35.6016C10.1264 34.3457 9.40045 33.031 8.13694 32.6652C6.87343 32.2994 5.55079 33.0209 5.18275 34.2768C4.81471 35.5327 5.54063 36.8473 6.80414 37.2132C8.06765 37.579 9.39029 36.8574 9.75833 35.6016Z"
            fill="white"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M6.39072 29.7369C5.76925 30.1494 4.92255 29.7983 4.69565 29.0906C4.68272 29.0491 4.67104 29.0114 4.65978 28.972C4.59686 28.739 4.55499 28.501 4.53465 28.2606C4.52342 28.0256 4.52774 27.7902 4.54758 27.5558C4.61201 26.6071 4.80262 25.671 5.11441 24.7719C5.25748 24.3105 5.4702 23.8727 5.67499 23.4291C5.9119 23.0025 6.14214 22.566 6.43911 22.1709C6.98509 21.3712 7.68038 20.6747 8.44617 20.0872C9.33709 19.4032 10.6443 19.5189 11.3558 20.3857C11.673 20.7714 11.8389 21.2581 11.823 21.7559C11.807 22.2537 11.6103 22.729 11.2691 23.0937L11.1314 23.2401C10.6651 23.7376 10.1558 24.1729 9.72123 24.6936C9.4814 24.9349 9.28328 25.2122 9.05722 25.4742C8.94961 25.6094 8.85284 25.7528 8.74773 25.893C8.69685 25.9634 8.63845 26.0306 8.58965 26.1036L8.44492 26.3249C8.03413 26.9288 7.67154 27.5636 7.36047 28.2237C7.20779 28.5858 7.01665 28.9306 6.7903 29.2523C6.70868 29.4498 6.56953 29.6186 6.39072 29.7369Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6216_6814">
          <rect
            width="107"
            height="114"
            fill="white"
            transform="translate(0.730225 0.28125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MediumLowRiskCustomerIcon;
