import React, { useEffect, useState } from "react";
import styles from "./VisualizeUserJourney.module.scss";
import {
  CustomEdge,
  CustomNode,
  CustomNodeTypeVariants,
} from "../ReactFlowContainer/types";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import Card from "../Card/Card";

interface Props {}

interface JourneyBlueprint {
  Nodes: CustomNode[];
  Edges: CustomEdge[];
}

const renderNodeIcon = (type: CustomNodeTypeVariants) => {
  switch (type) {
    case "entry":
      return (
        <div
          className={classNames(styles["node-icon"], styles["entry-node-icon"])}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.06 10.8937L11.14 8.81366C11.2867 8.667 11.36 8.48033 11.36 8.25366C11.36 8.027 11.2867 7.84033 11.14 7.69366L9.04 5.59366C8.89333 5.447 8.71013 5.37686 8.4904 5.38326C8.27013 5.3902 8.08667 5.467 7.94 5.61366C7.79333 5.76033 7.72 5.947 7.72 6.17366C7.72 6.40033 7.79333 6.587 7.94 6.73366L8.66 7.45366H6.08C5.85333 7.45366 5.66667 7.5302 5.52 7.68326C5.37333 7.83686 5.3 8.027 5.3 8.25366C5.3 8.48033 5.3768 8.6702 5.5304 8.82326C5.68347 8.97686 5.87333 9.05366 6.1 9.05366H8.66L7.92 9.79366C7.77333 9.94033 7.7032 10.1238 7.7096 10.3441C7.71653 10.5638 7.79333 10.747 7.94 10.8937C8.08667 11.0403 8.27333 11.1137 8.5 11.1137C8.72667 11.1137 8.91333 11.0403 9.06 10.8937ZM8.5 16.2537C7.39333 16.2537 6.35333 16.0435 5.38 15.6233C4.40667 15.2035 3.56 14.6337 2.84 13.9137C2.12 13.1937 1.55013 12.347 1.1304 11.3737C0.710133 10.4003 0.5 9.36033 0.5 8.25366C0.5 7.147 0.710133 6.107 1.1304 5.13366C1.55013 4.16033 2.12 3.31366 2.84 2.59366C3.56 1.87366 4.40667 1.30353 5.38 0.883262C6.35333 0.463529 7.39333 0.253662 8.5 0.253662C9.60667 0.253662 10.6467 0.463529 11.62 0.883262C12.5933 1.30353 13.44 1.87366 14.16 2.59366C14.88 3.31366 15.4499 4.16033 15.8696 5.13366C16.2899 6.107 16.5 7.147 16.5 8.25366C16.5 9.36033 16.2899 10.4003 15.8696 11.3737C15.4499 12.347 14.88 13.1937 14.16 13.9137C13.44 14.6337 12.5933 15.2035 11.62 15.6233C10.6467 16.0435 9.60667 16.2537 8.5 16.2537Z"
              fill="white"
            />
          </svg>
          <TextElement>Entry</TextElement>
        </div>
      );

    case "action":
      return (
        <div
          className={classNames(
            styles["node-icon"],
            styles["action-node-icon"]
          )}
        >
          <div
            style={{
              backgroundColor: "#18A2F2",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.253662"
                width="16"
                height="16"
                rx="8"
                fill="white"
              />
              <path
                d="M7.3 12.4203L5.8 9.14648L2.5 7.65839L5.8 6.17029L7.3 2.89648L8.8 6.17029L12.1 7.65839L8.8 9.14648L7.3 12.4203ZM12.1 13.6108L11.35 11.9739L9.7 11.2298L11.35 10.4858L12.1 8.84886L12.85 10.4858L14.5 11.2298L12.85 11.9739L12.1 13.6108Z"
                fill="#18A2F2"
              />
            </svg>
          </div>
          <TextElement>Action</TextElement>
        </div>
      );

    case "delay":
      return (
        <div
          className={classNames(styles["node-icon"], styles["delay-node-icon"])}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM2 10H10V2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10Z"
              fill="#778BA8"
            />
          </svg>
          <TextElement>Delay</TextElement>
        </div>
      );

    case "email":
      return (
        <div
          className={classNames(styles["node-icon"], styles["email-node-icon"])}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
              fill="#02285E"
            />
          </svg>
          <TextElement>Email</TextElement>
        </div>
      );

    case "check":
      return (
        <div
          className={classNames(styles["node-icon"], styles["check-node-icon"])}
        >
          <div
            style={{
              backgroundColor: "#EB6301",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.875L5.75 10.125L2 6.375"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 1.875L5.75 10.125L2 6.375"
                stroke="#FFF6EB"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <TextElement>Check</TextElement>
        </div>
      );

    case "diamond":
      return (
        <div
          className={classNames(
            styles["node-icon"],
            styles["diamond-node-icon"]
          )}
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.9 11.7537V15.1787C9.9 15.4453 10.025 15.608 10.275 15.6667C10.525 15.7247 10.7167 15.637 10.85 15.4037L13.875 9.47866C13.9583 9.312 13.95 9.14966 13.85 8.99166C13.75 8.833 13.6083 8.75366 13.425 8.75366H11.25V5.27866C11.25 5.012 11.125 4.84533 10.875 4.77866C10.625 4.712 10.4333 4.79533 10.3 5.02866L7.15 11.0287C7.06667 11.1953 7.071 11.3577 7.163 11.5157C7.25433 11.6743 7.39167 11.7537 7.575 11.7537H9.9ZM10.5 20.2537C9.11667 20.2537 7.81667 19.991 6.6 19.4657C5.38333 18.941 4.325 18.2287 3.425 17.3287C2.525 16.4287 1.81267 15.3703 1.288 14.1537C0.762667 12.937 0.5 11.637 0.5 10.2537C0.5 8.87033 0.762667 7.57033 1.288 6.35366C1.81267 5.137 2.525 4.07866 3.425 3.17866C4.325 2.27866 5.38333 1.566 6.6 1.04066C7.81667 0.515995 9.11667 0.253662 10.5 0.253662C11.8833 0.253662 13.1833 0.515995 14.4 1.04066C15.6167 1.566 16.675 2.27866 17.575 3.17866C18.475 4.07866 19.1873 5.137 19.712 6.35366C20.2373 7.57033 20.5 8.87033 20.5 10.2537C20.5 11.637 20.2373 12.937 19.712 14.1537C19.1873 15.3703 18.475 16.4287 17.575 17.3287C16.675 18.2287 15.6167 18.941 14.4 19.4657C13.1833 19.991 11.8833 20.2537 10.5 20.2537Z"
              fill="white"
            />
          </svg>
          <TextElement>Trigger</TextElement>
        </div>
      );

    case "end":
      return (
        <div
          className={classNames(styles["node-icon"], styles["exit-node-icon"])}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // revert it
            style={{
              transform: "rotate(180deg)",
            }}
          >
            <path
              d="M9.06 10.8937L11.14 8.81366C11.2867 8.667 11.36 8.48033 11.36 8.25366C11.36 8.027 11.2867 7.84033 11.14 7.69366L9.04 5.59366C8.89333 5.447 8.71013 5.37686 8.4904 5.38326C8.27013 5.3902 8.08667 5.467 7.94 5.61366C7.79333 5.76033 7.72 5.947 7.72 6.17366C7.72 6.40033 7.79333 6.587 7.94 6.73366L8.66 7.45366H6.08C5.85333 7.45366 5.66667 7.5302 5.52 7.68326C5.37333 7.83686 5.3 8.027 5.3 8.25366C5.3 8.48033 5.3768 8.6702 5.5304 8.82326C5.68347 8.97686 5.87333 9.05366 6.1 9.05366H8.66L7.92 9.79366C7.77333 9.94033 7.7032 10.1238 7.7096 10.3441C7.71653 10.5638 7.79333 10.747 7.94 10.8937C8.08667 11.0403 8.27333 11.1137 8.5 11.1137C8.72667 11.1137 8.91333 11.0403 9.06 10.8937ZM8.5 16.2537C7.39333 16.2537 6.35333 16.0435 5.38 15.6233C4.40667 15.2035 3.56 14.6337 2.84 13.9137C2.12 13.1937 1.55013 12.347 1.1304 11.3737C0.710133 10.4003 0.5 9.36033 0.5 8.25366C0.5 7.147 0.710133 6.107 1.1304 5.13366C1.55013 4.16033 2.12 3.31366 2.84 2.59366C3.56 1.87366 4.40667 1.30353 5.38 0.883262C6.35333 0.463529 7.39333 0.253662 8.5 0.253662C9.60667 0.253662 10.6467 0.463529 11.62 0.883262C12.5933 1.30353 13.44 1.87366 14.16 2.59366C14.88 3.31366 15.4499 4.16033 15.8696 5.13366C16.2899 6.107 16.5 7.147 16.5 8.25366C16.5 9.36033 16.2899 10.4003 15.8696 11.3737C15.4499 12.347 14.88 13.1937 14.16 13.9137C13.44 14.6337 12.5933 15.2035 11.62 15.6233C10.6467 16.0435 9.60667 16.2537 8.5 16.2537Z"
              fill="white"
            />
          </svg>
          <TextElement>End</TextElement>
        </div>
      );

    default:
      return null;
  }
};

const renderNodeDetails = (node: CustomNode) => {
  switch (node.type) {
    case "end":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["entry-node-details"],
              styles["details"]
            )}
          >
            <TextElement className={styles["text"]}>
              {node?.data?.label}
            </TextElement>
          </div>
        </div>
      );
    case "action":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["action-node-details"],
              styles["details"]
            )}
          >
            <TextElement className={styles["text"]}>
              {(node?.data as any)?.label}
            </TextElement>
            {Object.entries(node?.data).map(([key, value]) => {
              if (key === "selected" || key == 'label' || key == 'instructionTitle') return null;
              return (
                <TextElement className={styles["text"]}>
                  <span className={styles["heading"]}>{key?.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
                  <br /> {value}
                </TextElement>
              );
            })}
          </div>
        </div>
      );
    case "delay":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["delay-node-details"],
              styles["details"]
            )}
          >
            {Object.entries(node?.data).map(([key, value]) => {
              if (key === "selected") return null;
              return (
                <TextElement className={styles["text"]}>
                  <span className={styles["heading"]}>{key}</span>
                  <br /> {value}
                </TextElement>
              );
            })}
          </div>
        </div>
      );
    case "email":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["email-node-details"],
              styles["details"]
            )}
          >
            {" "}
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>CTA</span>
              <br />
              {(node?.data as any)?.cta}
            </TextElement>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>{node?.data?.label}</span>
            </TextElement>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>Subject</span>
              <br />
              {node?.data?.subject}
            </TextElement>
            {Object.entries(node?.data).map(([key, value]) => {
              if (
                key === "selected" ||
                key == "label" ||
                key == "subject" ||
                key == "cta"
              )
                return null;
              return (
                <TextElement className={styles["text"]}>
                  <span className={styles["heading"]}>{key}</span>
                  <br />
                  {value}
                </TextElement>
              );
            })}
          </div>
        </div>
      );
    case "check":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["check-node-details"],
              styles["details"]
            )}
          >
            {Object.entries(node?.data).map(([key, value]) => {
              if (key === "selected") return null;
              return (
                <TextElement className={styles["text"]}>
                  <span className={styles["heading"]}>{key}</span>
                  <br /> {value}
                </TextElement>
              );
            })}
          </div>
        </div>
      );
    case "diamond":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["diamond-node-details"],
              styles["details"]
            )}
          >
            {Object.entries(node?.data).map(([key, value]) => {
              if (key === "selected") return null;
              return (
                <TextElement className={styles["text"]}>
                  {key === "label" ? (
                    ""
                  ) : (
                    <span className={styles["heading"]}>
                      {key}
                      <br />
                    </span>
                  )}
                  {value}
                </TextElement>
              );
            })}
          </div>
        </div>
      );
    case "entry":
      return (
        <div className={styles["node-details-wrapper"]}>
          {renderNodeIcon(node.type)}
          <div
            className={classNames(
              styles["entry-node-details"],
              styles["details"]
            )}
          >
            <TextElement className={styles["text"]}>
              {node?.data?.label}
            </TextElement>
          </div>
        </div>
      );
    default:
      return null;
  }
};

function splitNodesByDiamondType(
  journeyBlueprint: JourneyBlueprint
): CustomNode[][] {
  const { Nodes, Edges } = journeyBlueprint;

  const nodeMap = new Map(Nodes.map((node) => [node.id, node]));
  const adjacencyList = new Map<string, string[]>();

  // Build adjacency list for easier traversal
  Edges.forEach((edge) => {
    if (!adjacencyList.has(edge.source)) {
      adjacencyList.set(edge.source, []);
    }
    adjacencyList.get(edge.source)!.push(edge.target);
  });

  const result: CustomNode[][] = [];
  let currentGroup: CustomNode[] = [];
  const visited = new Set<string>();

  function traverse(nodeId: string) {
    if (visited.has(nodeId)) return;

    visited.add(nodeId);
    const node = nodeMap.get(nodeId);
    if (!node) return;

    // Add the current node to the group
    currentGroup.push(node);

    // If it's a diamond node, finalize the group and start a new one
    if (node.type === "diamond") {
      result.push([...currentGroup]);
      currentGroup = [];
    }

    const neighbors = adjacencyList.get(nodeId) || [];
    neighbors.forEach(traverse);
  }

  // Start traversal from the entry node
  const entryNode = Nodes.find((node) => node.type === "entry");
  if (entryNode) {
    traverse(entryNode.id);
  }

  // Add any remaining nodes to the result
  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  return result;
}

interface Props {
  blueprint: JourneyBlueprint;
}

const StagesAndActions: React.FC<Props> = ({ blueprint }) => {
  const [groups, setGroups] = useState<CustomNode[][]>([]);

  useEffect(() => {
    const splitGroups = splitNodesByDiamondType(blueprint);
    setGroups(splitGroups);
  }, [blueprint]);

  return (
    <div className={styles["groups-container"]}>
      {groups?.map((group, index) => {
        return (
          <Card type="pinable" className={styles["group-card"]}>
            <TextElement className={styles["stage-heading"]}>
              Stage {index + 1}
            </TextElement>
            <div className={styles["group"]}>
              {group.map((node, index) => {
                return renderNodeDetails(node);
              })}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default StagesAndActions;
