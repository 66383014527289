import React, { useEffect, useState } from "react";
import styles from "./VerticalTabs.module.scss";
import classNames from "classnames";

interface Tab {
  id: number;
  tabIcon: React.ReactNode;
  content: React.ReactNode;
}

interface VerticalTabsProps {
  tabs: Tab[];
  tabClassName?: string;
  activeTabClassName?: string;
  className?: string;
  onCollapse?: (isCollapse: boolean) => void;
  defaultActiveTab?: number | null;
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({
  tabs,
  tabClassName,
  activeTabClassName,
  className,
  onCollapse,
  defaultActiveTab = 1,
}) => {
  const [activeTab, setActiveTab] = useState<number | null>(defaultActiveTab);

  const handleTabClick = (id: number) => {
    setActiveTab(activeTab === id ? null : id);
  };

  useEffect(() => {
    if (activeTab === null && onCollapse) {
      onCollapse(true);
    } else if (onCollapse) {
      onCollapse(false);
    }
  }, [activeTab]);

  return (
    <div className={classNames(styles["container"], className)}>
      {activeTab !== null && (
        <div className={styles["tab-content"]}>
          {tabs.find((tab) => tab.id === activeTab)?.content}
        </div>
      )}

      <div className={styles["tabs"]}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={classNames(
              styles["tab"],
              tabClassName,
              activeTab === tab.id &&
                styles["active-tab"] + " " + activeTabClassName
            )}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.tabIcon}
          </button>
        ))}
      </div>
    </div>
  );
};

export default VerticalTabs;
