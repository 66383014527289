export enum SidebarItem {
  LOGO = "LOGO",
  AVATAR = "AVATAR",

  ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER",

  MAIN_FUNNEL = "MAIN_FUNNEL",

  MARKETING_CAMPAIGNS = "MARKETING_CAMPAIGNS",
  MARKETING_DASHBOARD = "MARKETING_DASHBOARD",

  SALES_CAMPAIGNS = "SALES_CAMPAIGNS",
  SALES_DASHBOARD = "SALES_DASHBOARD",


  CUSTOMER_SUCCESS_DASHBOARD = "CUSTOMER_SUCCESS_DASHBOARD",
  RETENTION_CAMPAIGNS = "RETENTION_CAMPAIGNS",
  UPSELL_CROSS_SELL_CAMPAIGNS = "UPSELL_CROSS_SELL_CAMPAIGNS",


  MY_TEAM = "MY_TEAM",
  NOTIFICATIONS = "NOTIFICATIONS",

  MARKETING_CHANNELS = "MARKETING_CHANNEL",

  COMPANY_PROFILE = "COMPANY_PROFILE",
  PIPELINE_CONFIGURATION = "PIPELINE_CONFIGURATION",
 
  LOGOUT = "LOGOUT",
}
