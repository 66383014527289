import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import { Opportunity } from "./types";
import { Journey } from "../JourneyManagment/useJourneyManagment";
import { toast } from "react-toastify";

export type OpportunityStage = "Consideration" | "Evaluation" | "Decision";

const useOutreachCampaign = () => {
  const httpService = new HttpService();

  const [journey, setJourney] = useState<null | Journey>(null);
  const [loading, setLoading] = useState(false);
  const [showJourney, setShowJourney] = useState(false);

  const [personaList, setPersonaList] = useState<
    {
      personaId: number;
      personaName: string;
    }[]
  >([]);

  const [industrySegmentList, setIndustrySegmentList] = useState<
    {
      industrySegmentId: number;
      industrySegmentName: string;
    }[]
  >([]);

  const [metrics, setMetrics] = useState<{
    totalMarketingQualifiedLeads: number;
    dormatMarketingQualifiedLeads: number;
    averageDormacyPeriod: string;
    reactivationRate: string;
    leadConversionPotential: string;
    engagementScoreTrend: string;
  }>({
    totalMarketingQualifiedLeads: 10183,
    dormatMarketingQualifiedLeads: 198,
    averageDormacyPeriod: "60 days",
    reactivationRate: "12%",
    leadConversionPotential: "25%",
    engagementScoreTrend: "20%",
  });

  const [totalEnagagementSources, setTotalEnagagementSources] = useState<
    (string | number)[][]
  >([
    ["Source", "Leads"],
    ["Email", 30],
    ["LinkedIn", 30],
    ["Landing Page", 45],
  ]);

  const [dormancyAgeGroups, setDormancyAgeGroups] = useState<
    (string | number)[][]
  >([
    ["Age Group", "Leads"],
    ["30-60 days", 28],
    ["60-90 days", 15],
    ["91+ days", 22],
  ]);

  const [reactivationOpportunities, setReactivationOpportunities] = useState<
    (string | number)[][]
  >([
    ["Metrics", "Leads Percentage"],
    ["Warm", 30],
    ["Cold", 35],
    ["Priority", 25],
  ]);

  const executeRawQuery = async <T = any,>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      return [] as T;
    }
  };

  useEffect(() => {
    const response = executeRawQuery<
      {
        personaId: number;
        personaName: string;
        industrySegmentId: number;
        industrySegmentName: string;
      }[]
    >(`
        SELECT 
          j.persona_id,
          p.persona_name,
          j.industry_segment_id,
          i.industry_segment_name
      FROM 
          public.journeys j
      INNER JOIN 
          public.persona p ON j.persona_id = p.persona_id
      INNER JOIN 
          public.industry_segment i ON j.industry_segment_id = i.industry_segment_id
      WHERE 
          j.type = 'outreach-campaign';
        `);

    response.then((data) => {
      const personas = data.map((item) => ({
        personaId: item.personaId,
        personaName: item.personaName,
      }));

      const industrySegments = data.map((item) => ({
        industrySegmentId: item.industrySegmentId,
        industrySegmentName: item.industrySegmentName,
      }));

      setPersonaList(personas);
      setIndustrySegmentList(industrySegments);
    });
  }, []);

  const fetchJourneyBasedOnPersonaAndIndustry = async (
    personaId: number,
    industrySegmentId: number
  ) => {
    setLoading(true);
    setShowJourney(true);
    const response = await executeRawQuery<Journey[]>(
      `SELECT * FROM journeys WHERE persona_id = ${personaId} AND industry_segment_id = ${industrySegmentId} AND type = 'outreach-campaign'`
    );


    if (response?.length === 0) {
      setShowJourney(false);
      setLoading(false);
      return toast.error(
        "No journey found for the selected persona and industry segment"
      );
    }

    if (response.length) {
      setLoading(false);
      setJourney(response[0]);
    }
  };

  return {
    metrics,
    totalEnagagementSources,
    dormancyAgeGroups,
    reactivationOpportunities,
    executeRawQuery,
    personaList,
    industrySegmentList,
    journey,
    loading,
    fetchJourneyBasedOnPersonaAndIndustry,
    showJourney,
  };
};

export default useOutreachCampaign;
