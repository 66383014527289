import React from "react";

import styles from "./CustomerTestimonialsDistribution.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureContentData, setNurtureStrategyData } from "../../../../redux/interestStageSlice";

interface CustomerTestimonialsDistributionProps {
  className?: string;
  cardType: CardType;
  type?: "strategy" | "content";
}

const CustomerTestimonialsDistribution: React.FC<
  CustomerTestimonialsDistributionProps
> = (props) => {
  const { className, cardType, type = "content" } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData, nurtureContentData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    if (type === "strategy" && nurtureStrategyData) {
      dispatch(
        setNurtureStrategyData({
          ...nurtureStrategyData,
          customerTestimonialsDistribution: isPinned,
        })
      );
    } else if (type === "content" && nurtureContentData) {
      dispatch(
        setNurtureContentData({
          ...nurtureContentData,
          customerTestimonialsDistribution: isPinned,
        })
      );
    }
  };
  
  if (type === "content") {
    return (
      <Card
        type={cardType}
        onPin={() => handlePinned(true)}
        unPin={() => handlePinned(false)}
        className={classNames(styles["container"], className)}
      >
        <div>
          <TextElement className={styles["heading"]}>
            Week 5-6: Customer Testimonials Distribution
          </TextElement>
          <TextElement className={styles["sub-heading-text"]}>
            Customer Story: Enhancing Network Efficiency with IoT
          </TextElement>
        </div>
        <div className={styles["content-container"]}>
          <TextElement className={styles["content-heading"]}>
            Customer Story Content
          </TextElement>
          <div className={styles["content"]}>
            <span className={styles["highlight"]}>
              Title: The Future of 5G in Telecommunicatio ns
            </span>
            <br />
            <span className={styles["highlight"]}>Introduction</span>
            <TextElement>
              Learn how a satisfied customer improved their network management
              through our IoT solutions, showcasing the ROI and operational
              efficiencies gained.
            </TextElement>

            <br />
            <TextElement className={styles["heading"]}>Body</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>
                  The Evolution of 5G:
                </span>{" "}
                Discuss the history and development of 5G technology.
              </li>
              <li>
                <span className={styles["highlight"]}>The Challenge:</span>{" "}
                Outline the customer's initial network management issues.
              </li>
              <li>
                <span className={styles["highlight"]}>Our Solution:</span>{" "}
                Describe the IoT solutions we provided.
              </li>
              <li>
                <span className={styles["highlight"]}>The Results:</span> Detail
                the improvements in network efficiency and ROI.
              </li>
              <li>
                <span className={styles["highlight"]}>Customer Feedback: </span>{" "}
                Share the customer's positive feedback and experience.
              </li>
              <li>
                <span className={styles["highlight"]}>Conclusion:</span> Our IoT
                solutions have significantly enhanced network efficiency,
                proving their value in real-world applications.
              </li>
            </ul>
          </div>
          <TextElement className={styles["content-heading"]}>
            LinkedIn Post
          </TextElement>
          <div className={styles["content"]}>
            <TextElement>
              <span className={styles["highlight"]}>Caption:</span> See how IoT
              is making a difference in network management. #TelecomSuccess
              [Read Story] #Innovation
            </TextElement>
            <br/>
            <img src="contentImages/image1.png" alt="LinkedIn Post" />
          </div>
          <TextElement className={styles["content-heading"]}>
            Twitter
          </TextElement>
          <div className={styles["content"]}>
            <TextElement>
              Discover how IoT is transforming network efficiency in our latest
              customer story. #IoT #Telecom [Read Story]
              <br/>
              <span className={styles["highlight"]}>Link:</span>
            </TextElement>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Week 5-6: Customer Testimonials Distribution
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Action: Distribute Content
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Topics</TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>"Case Study:</span>{" "}
              Successful 5G Rollout for Urban Areas": Detailed story of a
              client's success with our 5G deployment, highlighting the
              challenges faced, solutions implemented, and the positive impact
              on their operations.
            </li>
            <li>
              <span className={styles["highlight"]}>"Customer Story:</span>{" "}
              Enhancing Network Efficiency with IoT": A testimonial from a
              satisfied customer who improved their network management through
              our IoT solutions, showcasing the ROI and operational efficiencies
              gained.
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Channels
        </TextElement>
        <div className={styles["content"]}>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>Email</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>Subject Line:</span> "See
                How Our Clients Are Succeeding with 5G"
              </li>
              <li>
                <span className={styles["highlight"]}>Body:</span>
                "Dear [Lead Name], discover how our clients are achieving
                success with our 5G and IoT solutions. Read our latest case
                studies and learn how you can benefit too. [Read Case Study]"
                <span className={styles["sub-heading-text"]}>[Read More]</span>"
              </li>
              <li>
                <span className={styles["highlight"]}>Call to Action:</span>
                "Interested in similar results? Let's discuss how we can help.
                [Contact Us]"
                <span className={styles["sub-heading-text"]}>
                  [Download Now]
                </span>
                "
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Website Content
            </TextElement>
            <ul>
              <li>
                Feature these case studies on the homepage and relevant service
                pages to build credibility and trust.
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Social Media
            </TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>LinkedIn Post:</span>
                "Our 5G solutions are transforming businesses. Read our latest
                success story. [Read Now] "
              </li>
              <li>
                <span className={styles["highlight"]}>Twitter Post:</span>
                "See how IoT is making a difference in network management.
                #TelecomSuccess [Read Story]" "
              </li>
            </ul>
          </div>
        </div>
        <TextElement className={styles["content-heading"]}>
          Metrics to Track
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>
                Email engagement (open rates, CTR):
              </span>{" "}
              Target 25%
            </li>
            <li>
              <span className={styles["highlight"]}>
                Website metrics (time on page, bounce rate):
              </span>{" "}
              Target 30% increase in engagement time
            </li>
            <li>
              <span className={styles["highlight"]}>
                Social media metrics (engagement, shares):
              </span>{" "}
              Target 20% increase
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Expected Results
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Improve SQL conversion rates by 30% by showcasing real-world
            applications and ROI, increasing trust and credibility.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default CustomerTestimonialsDistribution;
