import React, { useState, useRef, useEffect } from "react";

import styles from "./TailoredStrategyForBANTQualification.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Preparation from "./componenets/Preparation/Preparation";
import AddBANTQualificationQuestions from "./componenets/AddBANTQualificationQuestions/AddBANTQualificationQuestions";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import InitialContact from "./componenets/InitialContact/InitialContact";
import HandlingObjections from "./componenets/HandlingObjections/HandlingObjections";
import QualificationAndHandover from "./componenets/QualificationAndHandover/QualificationAndHandover";
import KeyMetricsToMonitor from "./componenets/KeyMetricsToMonitor/KeyMetricsToMonitor";
import ContinuousImprovement from "./componenets/ContinuousImprovement/ContinuousImprovement";
import { SyncLoader } from "react-spinners";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface TailoredStrategyForBANTQualificationProps {
  className?: string;
}

const TailoredStrategyForBANTQualification: React.FC<
  TailoredStrategyForBANTQualificationProps
> = (props) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const addContinuousImprovement = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ContinuousImprovement
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addKeyMetricsToMonitor = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <KeyMetricsToMonitor cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addQualificationAndHandover = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <QualificationAndHandover
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addHandlingObjection = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <HandlingObjections cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addBANTQualificationQuestions = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <AddBANTQualificationQuestions
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },
      ...pC,
    ]);
  };

  const addInititalContact = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <InitialContact cardType="pinable" className={styles["card"]} />
        ),
      },
      ...pC,
    ]);
  };

  const addPreparation = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: <Preparation cardType="pinable" className={styles["card"]} />,
      },
      ...pC,
    ]);
  };

  const handleCreateContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            ref={scrollRefs.strategy}
            messageClass={styles["chat-message"]}
            message="Based on the insights gathered from our Marketing Qualified Leads (MQLs) during the 5G Deployment Accelerator campaign, we have identified key characteristics and preferences among our leads. This detailed strategy outlines how our telemarketing team can effectively use the BANT (Budget, Authority, Need, Timeline) qualification criteria to convert these MQLs into Sales Qualified Leads (SQLs)."
          />
        ),
      },
      ...pC,
    ]);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      addPreparation();
      addInititalContact();
      addBANTQualificationQuestions();
      addHandlingObjection();
      addQualificationAndHandover();
      addKeyMetricsToMonitor();
      addContinuousImprovement();
      setConversation((pC) => [
        {
          type: MessageType.ANSWER,
          content: (
            <Button
              className={classNames(styles["card"], styles["continue-button"])}
              onClick={() => {
                selectedCampaign &&
                  dispatch(
                    updateCampaign({
                      ...selectedCampaign,
                      activeConnectingDataSourcesStep: true,
                    })
                  );

                navigate(
                  `${ROUTE_DEFINITIONS.MARKETING_CONNECTING_DATA_SOURCES.path}`
                );
              }}
            >
              <TextElement>Continue 👍🏻</TextElement>
            </Button>
          ),
        },
        {
          type: MessageType.ANSWER,
          content: (
            <ChatCard
              messageClass={styles["chat-message"]}
              message={
                <div className={styles["next-actions-container"]}>
                  <TextElement>
                    Implementing this strategy will help your telemarketing team
                    effectively convert MQLs into SQLs, optimising the success
                    of your 5G Deployment Accelerator campaign.
                  </TextElement>
                  <br />
                  <TextElement>Next Steps:</TextElement>
                  <ol>
                    <li>
                      Conduct training sessions for the telemarketing team.
                    </li>
                    <li>
                      Start the qualification process with priority leads.
                    </li>
                    <li>
                      Monitor performance metrics and adjust strategies as
                      needed.
                    </li>
                  </ol>
                </div>
              }
            />
          ),
        },
        ...pC,
      ]);
    }, 2000);
  };

  const [conversation, setConversation] = useState<Question[]>([]);

  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<HTMLDivElement>(null);

  const scrollRefs = {
    strategy: useRef<HTMLDivElement>(null),
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleScroll = (id: keyof typeof scrollRefs) => {
    const ref = scrollRefs[id];
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (scrollRefs?.strategy?.current) {
      scrollToRef(scrollRefs.strategy);
    }
  }, [scrollRefs?.strategy?.current]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  useEffect(() => {
    handleCreateContent();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}
          {conversation?.map((item: Question, index: number) => (
            <div
              style={{
                width: "100%",
              }}
              key={index}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default TailoredStrategyForBANTQualification;
