import React from "react";

import styles from "./GifLoadingModal.module.scss";
import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";

interface GifLoadingModalProps {
  gifSrc: string;
  loadingText: string;
  isOpen: boolean;
}
 
const GifLoadingModal: React.FC<GifLoadingModalProps> = (props) => {
  const { gifSrc, loadingText , isOpen} = props;
  return (
    <Modal
      contentClassName={styles["container"]}
      isOpen={isOpen}
      closeModal={() => {}}
    >
      <img src={gifSrc} className={styles["gif"]} />
      <TextElement className={styles["loading-text"]}>
        {loadingText}
      </TextElement>
      <div className={styles["loader"]}></div>
    </Modal>
  );
};

export default GifLoadingModal;
