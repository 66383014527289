import React, { useEffect } from "react";
import styles from "../GenerateCampaignPlan.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchSegments } from "../../../redux/campaignPlanAnalysisQueryChat";

import classNames from "classnames";
import { toast } from "react-toastify";
import AudienceSegmentation from "../../AudienceSegmentationCards/AudienceSegmentation";

interface ShowAudienceSegmentsProps {}

const ShowAudienceSegments: React.FC<ShowAudienceSegmentsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { audienceSegments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchSegments({
          campaignPlanId: selectedCampaign?.id,
        })
      ).catch(() => {
        toast.error("Error fetching segments");
        console.log("Error fetching segments");
      });  
  }, []);

  return (
    <div className={classNames(styles["audience-segmentation"])}>
      {Array.isArray(audienceSegments) &&
        audienceSegments.map((segment, index: number) => (
          <AudienceSegmentation
            cardType="pinable"
            segmentationId={index}
            key={index}
            className={styles["audience-segmentation-card"]}
          />
        ))}   
    </div>
  );
};

export default ShowAudienceSegments;  
