import React from "react";

import styles from "./PilotProgram.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import TextElement from "../../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateNegotiateStrategyData } from "../../../../redux/negotiateStrategySlice";

interface PilotProgramProps {
  className?: string;
  cardType: CardType;
}

const PilotProgram: React.FC<PilotProgramProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector((state) => state.negotiateStrategy);

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateNegotiateStrategyData({
        ...includeData,
        pilotProgram: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <TextElement className={styles["heading"]}>Pilot Program</TextElement>
      <div className={classNames(styles["content-container"])}>
        <div className={styles["follow-item"]}>
          <div className={styles["icon-container"]}>
            <svg
              width="19"
              height="15"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 6.625V8.375H18.25V6.625H14.75ZM13 12.4088C13.84 13.03 14.9338 13.8525 15.8 14.5C16.15 14.0363 16.5 13.5637 16.85 13.1C15.9837 12.4525 14.89 11.63 14.05 11C13.7 11.4725 13.35 11.945 13 12.4088ZM16.85 1.9C16.5 1.43625 16.15 0.96375 15.8 0.5C14.9338 1.1475 13.84 1.97 13 2.6C13.35 3.06375 13.7 3.53625 14.05 4C14.89 3.37 15.9837 2.55625 16.85 1.9ZM2.5 4.875C1.5375 4.875 0.75 5.6625 0.75 6.625V8.375C0.75 9.3375 1.5375 10.125 2.5 10.125H3.375V13.625H5.125V10.125H6L10.375 12.75V2.25L6 4.875H2.5ZM12.5625 7.5C12.5625 6.33625 12.055 5.28625 11.25 4.56875V10.4225C12.055 9.71375 12.5625 8.66375 12.5625 7.5Z"
                fill="#EB2701"
              />
            </svg>
          </div>
          <TextElement className={styles["text"]}>
            <span className={styles["sub-heading"]}>Detail - </span>
            3-month pilot program to test the solution.
          </TextElement>
        </div>
        <div className={styles["follow-item"]}>
          <div className={styles["icon-container"]}>
            <svg
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.048 15.1107L0.236084 8.29883V9.69044C0.236084 10.0592 0.382203 10.4141 0.646608 10.6715L6.06692 16.0918C6.60964 16.6346 7.49331 16.6346 8.03604 16.0918L12.357 11.7709C12.8997 11.2282 12.8997 10.3445 12.357 9.80176L7.048 15.1107Z"
                fill="#EB2701"
              />
              <path
                d="M6.06692 12.6139C6.60964 13.1567 7.49331 13.1567 8.03604 12.6139L12.357 8.293C12.8997 7.75027 12.8997 6.8666 12.357 6.32387L6.93667 0.903566C6.67227 0.646119 6.31741 0.5 5.94863 0.5H1.62769C0.862307 0.5 0.236084 1.12622 0.236084 1.89161V6.21255C0.236084 6.58132 0.382203 6.93618 0.646608 7.19363L6.06692 12.6139ZM3.19325 2.58741C3.67335 2.58741 4.063 2.97706 4.063 3.45716C4.063 3.93727 3.67335 4.32692 3.19325 4.32692C2.71314 4.32692 2.32349 3.93727 2.32349 3.45716C2.32349 2.97706 2.71314 2.58741 3.19325 2.58741Z"
                fill="#EB2701"
              />
            </svg>
          </div>
          <TextElement className={styles["text"]}>
            <p className={styles["heading"]}>Detail</p>
            <span>Cost: </span>
            $20,000 (full price $30,000).
            <br />
            <span>Includes:</span> Full access to core features, limited
            support, and performance evaluation.
            <br />
            <span>Outcome:</span> If the pilot is successful, the cost is
            deducted from the final contract value if the client signs a full
            contract.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default PilotProgram;
