import React, { useState, useEffect } from "react";
import styles from "./ContentDeliveryPlans.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import Select from "../Select/Select";
import Input from "../MarketingBriefFormCard/components/Input/Input";
import CheckBoxSelect from "./components/CheckBoxSelect";
import useContentDeliveryPlans from "./useContentDelieveryPlans";
import Button from "../Button/Button";
import { useAppSelector } from "../../redux/hooks";

export interface ExecutionPlan {
  id: string;
  theme: string;
  campaignId: string;
  channelId: string;
  contentTypeId: string;
  startDate: string;
  endDate: string;
  numberOfPostsPerDay: number;
  daysOfTheWeek: string[];
  numberOfWeeks: number;
}

interface Props {
  className?: string;
}

const ContentDeliveryPlans: React.FC<Props> = (props) => {
  const { className } = props;
  const { contentTypes, channels, updateExecutionPlans } =
    useContentDeliveryPlans();
  const { recommendation } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [updatePlanActive, setUpdatePlanActive] = useState<boolean>(false);

  // State to manage the modified execution plans
  const [updatedPlans, setUpdatedPlans] = useState<ExecutionPlan[]>([]);

  useEffect(() => {
    const plans = recommendation?.execution_plans.map((plan) => {
      return {
        id: plan.id,
        theme: plan.theme,
        campaignId: plan.campaign_id,
        channelId: plan.channel_id,
        contentTypeId: plan.content_type_id,
        startDate: plan.start_date,
        endDate: plan.end_date,
        numberOfPostsPerDay: plan.number_of_posts_per_day,
        daysOfTheWeek: plan.days_of_the_week,
        numberOfWeeks: plan.number_of_weeks,
      };
    });
    setUpdatedPlans(plans);
  }, [recommendation?.execution_plans]);

  // Handler to update the plan when a field changes
  const handlePlanChange = (
    planId: string,
    field: keyof ExecutionPlan,
    value: any
  ) => {
    setUpdatedPlans((prevPlans) =>
      prevPlans.map((plan) =>
        plan.id === planId ? { ...plan, [field]: value } : plan
      )
    );
  };

  // Function to handle the "Update" button click
  const handleUpdate = () => {
    setUpdatePlanActive(false);
    updateExecutionPlans(
      updatedPlans?.map((plan) => ({
        id: plan.id,
        startDate: plan.startDate,
        endDate: plan.endDate,
        numberOfPostsPerDay: plan.numberOfPostsPerDay,
        daysOfTheWeek: plan.daysOfTheWeek,
        numberOfWeeks: plan.numberOfWeeks,
        theme: plan.theme,
        channel: plan.channelId,
      }))
    );
    // Here, you can send the updated plans to the server or process them further
  };

  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["header"]}>
        <TextElement>Content Delivery Recommendation</TextElement>
        {!updatePlanActive && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles["edit-icon"]}
            onClick={() => setUpdatePlanActive(true)}
          >
            <path
              d="M0 15.834V20.0001H4.16609L16.4533 7.71287L12.2872 3.54678L0 15.834Z"
              fill="#EB2701"
            />
            <path
              d="M19.6752 2.92459L17.0756 0.324955C16.6423 -0.108318 15.9424 -0.108318 15.5091 0.324955L13.4761 2.35801L17.6422 6.52409L19.6752 4.49104C20.1085 4.05777 20.1085 3.35787 19.6752 2.92459Z"
              fill="#EB2701"
            />
          </svg>
        )}
      </div>
      <div className={styles["plans-container"]}>
        {updatedPlans.map((plan, index) => {
          return (
            <div className={styles["plan"]} key={plan.id}>
              <div className={styles["field"]}>
                <TextElement className={styles["label"]}>
                  Marketing Channel
                </TextElement>
                <Select
                  value={plan.channelId}
                  options={
                    channels.map((channel) => ({
                      value: channel.id,
                      label: channel.channelName,
                      disabled: !channel.isEnabled,
                    })) || []
                  }
                  onChange={(value) => {
                    const selectedChannel = channels.find(
                      (channel) => channel.id === value
                    );
                    if (selectedChannel?.isEnabled)
                      handlePlanChange(plan.id, "channelId", value);
                  }}
                  optionClassName={styles["option"]}
                  width="100%"
                  className={styles["select"]}
                  buttonClassName={styles["select-button"]}
                />
              </div>
              <Input
                className={classNames(
                  styles["input"],
                  styles["disabled-input"]
                )}
                label="Content Title"
                value={plan.theme}
                disabled={true}
              />
              <div className={styles["two-fields"]}>
                <Input
                  className={classNames(
                    styles["input"],
                    styles["disabled-input"]
                  )}
                  label="Start Date"
                  type="date"
                  value={new Date(plan.startDate)
                    .toISOString()
                    .substring(0, 10)}
                  onChange={(e) =>
                    handlePlanChange(plan.id, "startDate", e.target.value)
                  }
                />
                <Input
                  label="End Date"
                  type="date"
                  value={new Date(plan.endDate).toISOString().substring(0, 10)}
                  onChange={(e) =>
                    handlePlanChange(plan.id, "endDate", e.target.value)
                  }
                />
              </div>
              <div className={styles["two-fields"]}>
                <Input
                  label="No. Of Weeks"
                  type="number"
                  value={plan.numberOfWeeks || 0}
                  onChange={(e) =>
                    handlePlanChange(plan.id, "numberOfWeeks", e.target.value)
                  }
                />
                <Input
                  type="number"
                  label="No. Of Posts Per Day"
                  value={plan.numberOfPostsPerDay || 0}
                  onChange={(e) =>
                    handlePlanChange(
                      plan.id,
                      "numberOfPostsPerDay",
                      e.target.value
                    )
                  }
                />
              </div>
              <CheckBoxSelect
                selectedDays={
                  plan.daysOfTheWeek || ["Monday", "Tuesday", "Wednesday"]
                }
                onChange={(days) =>
                  handlePlanChange(plan.id, "daysOfTheWeek", days)
                }
              />
              {recommendation?.execution_plans?.length - 1 !== index && (
                <div className={styles["divider"]}></div>
              )}
            </div>
          );
        })}
        {updatePlanActive && (
          <Button className={styles["update-button"]} onClick={handleUpdate}>
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContentDeliveryPlans;
