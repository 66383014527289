import React, { useState, useRef, useEffect } from "react";

import styles from "./CustomerSuccessContentQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DataBoostRetentionCampaign from "./componenets/DataBoostRetentionCampaign/DataBoostRetentionCampaign";

import { updateCampaign } from "../../redux/campaignPinningSlice";
import EmailContentTemplate from "./componenets/EmailContentTemplate/EmailContentTemplate";
import SmsContent from "./componenets/SmsContent/SmsContent";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CustomerSuccessContentQueryChatProps {
  className?: string;
}

const CustomerSuccessContentQueryChat: React.FC<
  CustomerSuccessContentQueryChatProps
> = (props) => {
  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const addEmailContent = () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <EmailContentTemplate
            heading="Email"
            body={
              <>
                Dear [Customer Name], <br />
                <br />
                We noticed you’ve been facing some issues with your current data
                plan. To show our appreciation for being a valued customer,
                we’re offering you an extra 10GB of data free for the next
                month!
                <br />
                Enjoy more browsing, streaming, and staying connected without
                worrying about running out of data. <br />
                <br />
                Best regards, <br />
                [Your Company Name]
              </>
            }
            subject="Get an Extra 10GB Data Free This Month!"
            cardType="pinable"
            className={styles["card"]}
          />
        ),
      },

      ...pC,
    ]);
  };

  const handleCreateContent = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <DataBoostRetentionCampaign
            className={styles["card"]}
            cardType="pinable"
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message={
              "Great for choosing the Data Boost Offer. Here’s some content you can use to let your high-risk customers know about it:"
            }
          />
        ),
      },

      ...pC,
    ]);

    addEmailContent();

    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message={
              "Satisfied with this offer content? Pin it to the editor panel and submit for approval. Alternatively, you can request a new offer content below 👇🏻"
            }
          />
        ),
      },
      {
        type: MessageType.ANSWER,
        content: <SmsContent className={styles["card"]} cardType="pinable" />,
      },

      ...pC,
    ]);
  };

  const [conversation, setConversation] = useState<Question[]>([]);

  useEffect(() => {
    handleCreateContent();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {conversation?.map((item: Question, index: number) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default CustomerSuccessContentQueryChat;
