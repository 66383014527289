import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

type Message = ["Client" | "Assistant", string];

interface ErikoAiChatAsistantState {
  history: Message[];
}

const initialState: ErikoAiChatAsistantState = {
  history: [],
};

const httpService = new HttpService(process.env.REACT_APP_CHAT_API_URL);

export const fetchErikoAiAssistantResponse = createAsyncThunk<
  Message,
  { message: string; history: Message[]; campaignPlanId: string }
>(
  "erikoAiChatAsistant/fetchErikoAiAssistantResponse",
  async ({ message, history, campaignPlanId }) => {
    try {
      const response = await httpService.post<
        { response: string },
        { message: string; history: Message[]; campaignPlanId: string }
      >("message", { message, history, campaignPlanId });
      return ["Assistant", response.data.response] as Message;
    } catch (error) {
      console.error("Failed to fetch Eriko AI assistant response", error);
      return ["Assistant", "Failed to fetch response"] as Message;
    }
  }
);

export const fetchErikoAiAssistantPayloadBasedResponse = createAsyncThunk<
  Message,
  { message: string; history: Message[]; payload: string }
>(
  "erikoAiChatAsistant/fetchErikoAiAssistantPayloadBasedResponse",
  async ({ message, history, payload }) => {
    try {
      const response = await httpService.post<
        { response: string },
        { message: string; history: Message[]; payload: any }
      >("payload_message", { message, history, payload: JSON.parse(payload) });
      return ["Assistant", response.data.response] as Message;
    } catch (error) {
      console.error("Failed to fetch Eriko AI assistant response", error);
      return ["Assistant", "Failed to fetch response"] as Message;
    }
  }
);

const erikoAiChatAsistantSlice = createSlice({
  name: "erikoAiChatAsistant",
  initialState,
  reducers: {
    updateErikoChatHistory: (state, action: PayloadAction<Message>) => {
      state.history.push(action.payload);
    },
    clearHistory: (state) => {
      state.history = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchErikoAiAssistantResponse.pending, (state) => {
        console.log("Fetching Eriko AI assistant response...");
      })
      .addCase(fetchErikoAiAssistantResponse.fulfilled, (state, action) => {
        state.history.push(action.payload);
      })
      .addCase(fetchErikoAiAssistantResponse.rejected, (state, action) => {
        state.history.push(["Assistant", "Failed to fetch response"]);
        console.error(
          "Failed to fetch Eriko AI assistant response",
          action.error
        );
        throw new Error("Failed to fetch Eriko AI assistant response");
      });

    builder
      .addCase(fetchErikoAiAssistantPayloadBasedResponse.pending, (state) => {
        console.log("Fetching Eriko AI assistant response...");
      })
      .addCase(
        fetchErikoAiAssistantPayloadBasedResponse.fulfilled,
        (state, action) => {
          state.history.push(action.payload);
        }
      )
      .addCase(
        fetchErikoAiAssistantPayloadBasedResponse.rejected,
        (state, action) => {
          state.history.push(["Assistant", "Failed to fetch response"]);
          console.error(
            "Failed to fetch Eriko AI assistant response",
            action.error
          );
          throw new Error("Failed to fetch Eriko AI assistant response");
        }
      );
  },
});

export const { updateErikoChatHistory } = erikoAiChatAsistantSlice.actions;
export default erikoAiChatAsistantSlice.reducer;
