import React from "react";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";

import styles from "./Modal.module.scss";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  contentClassName?: string;
  className?: string;
  style?: React.CSSProperties;
  showOverlay?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  children,
  contentClassName,
  className,
  style,
  showOverlay = true,
}) => {
  return (
    <>
      {isOpen && showOverlay && <div className={styles["modal-overlay"]}></div>}
      <Dialog
        open={isOpen}
        onClose={closeModal}
        className={classNames(styles["modal"], className)}
        style={style}
      >
        <Dialog.Panel
          className={classNames(styles["content"], contentClassName)}
        >
          {children}
        </Dialog.Panel>
      </Dialog>
    </>
  );
};

export default Modal;
