import { useState, useEffect, useCallback } from "react";
import HttpService from "../../../../lib/api";
import { toast } from "react-toastify";

export interface PromptTemplate {
  task: string;
  template: string;
  default: string;
  args: string[];
}

interface UpdateTemplateResponse {
  message: string;
}

interface Log {
  id: string;
  datetime: string;
}

interface FetchLogsResponse {
  logs: Log[];
}

export type ModelKeyType =
  | "swot_competitor_analysis"
  | "market_sentiments"
  | "content_generation"
  | "audience_segmentation" |"past_campaign_analysis";

export function usePromptEngineering(apiPath: string, modelKey: ModelKeyType) {
  const [promptTemplates, setPromptTemplates] = useState<PromptTemplate[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const api = new HttpService(apiPath);

  const [logs, setLogs] = useState<Log[]>([]);
  const [log, setLog] = useState<JSON | null>(null);

  const getTemplate = async () => {
    setLoading(true);
    try {
      const response = await api.get<PromptTemplate[]>(
        `/prompt_template?model_name=${modelKey}`
      );
      setPromptTemplates(response.data);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const updateTemplate = async (updatedTemplate: PromptTemplate) => {
    setLoading(true);
    try {
      const response = await api.put<UpdateTemplateResponse, PromptTemplate>(
        `/prompt_template/${updatedTemplate.task}`,
        updatedTemplate
      );
      toast.success(response.data.message);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const fetchLogs = async (campaignPlanId: string) => {
    try {
      setLoading(true);
      const response = await api.get<FetchLogsResponse>(
        `model_logs?campaign_plan_id=${campaignPlanId}&model_name=${modelKey}`
      );
      setLogs(response?.data?.logs || []);
      if (response?.data?.logs?.length > 0) {
        fetchLogById(response?.data?.logs[0].id);
      }
    } catch (err) {
      setError((err as Error).message);
      toast.error("Failed to fetch logs");
    } finally {
      setLoading(false);
    }
  };

  const fetchLogById = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.get<JSON>(`model_logs/${id}`);
      setLog(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTemplate();
  }, []);

  return {
    promptTemplates,
    loading,
    error,
    getTemplate,
    updateTemplate,
    fetchLogs,
    logs,
    log,
    fetchLogById,
    setLog
  };
}
