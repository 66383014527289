import React from "react";

import styles from "./CustomizedSolutions.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateSalesQualifiedStrategyData } from "../../../../redux/salesQualifiedStrategySlice";

interface CustomizedSolutionsProps {
  className?: string;
  cardType: CardType;
  leadType: string;
  id: number;
  items: {
    heading: string;
    content: string;
    example?: string;
  }[];
}

const CustomizedSolutions: React.FC<CustomizedSolutionsProps> = (props) => {
  const { className, cardType, leadType, items, id } = props;

  const dispatch = useAppDispatch();
  const { includeData } = useAppSelector(
    (state) => state.salesQualifiedStrategy
  );

  const handlePinned = (isPinned: boolean) => {
    let updatedIds = [...includeData?.customizedSolutionIds];
    if (isPinned) {
      updatedIds?.push(id);
    } else {
      updatedIds = updatedIds.filter((pinId) => pinId !== id);
    }
    includeData &&
      dispatch(
        updateSalesQualifiedStrategyData({
          ...includeData,
          customizedSolutionIds: Array.from(new Set(updatedIds)),
        })
      );
  };
  
  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <TextElement className={styles["heading"]}>
        <span>Lead Type:</span> {leadType}
      </TextElement>

      <div className={styles["content-container"]}>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <span className={styles["heading"]}>{item.heading}</span>{" "}
              {item.content}
              <br />
              {item.example && (
                <span className={styles["highlight"]}>Example:</span>
              )}
              {item?.example}
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default CustomizedSolutions;
