import React, { useEffect } from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";

import styles from "./UpdatePostContentModal.module.scss";
import { useAppDispatch } from "../../redux/hooks";
import usePostSchedulesCalendarListView, {
  PostSchedule,
} from "../PostSchedulesCalendarListView/usePostSchedulesCalendarListView";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  onOk: (text: string) => void;
  post: PostSchedule;
}

const UpdatePostContentModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen, onOk, post } = props;

  if (!post) {
    return null;
  }

  const { getImageUrl } = usePostSchedulesCalendarListView();

  return (
    <Modal
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      {post?.data?.mediaSources && post?.data?.mediaSources?.length > 0 && (
        <img src={getImageUrl(post?.data.mediaSources[0])} />
      )}
      <textarea
        value={post?.data?.text ? post?.data?.text : "No text found"}
        onChange={(e) => onOk(e.target.value)}
        rows={10}
      >
        {post?.data?.text}
      </textarea>

      <footer>
        <Button className={styles["action-btn"]} onClick={closeModal}>
          Save
        </Button>
        <svg
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.57138 24.3095C1.91662 24.3095 1.3561 24.0764 0.889834 23.6101C0.423565 23.1438 0.19043 22.5833 0.19043 21.9286V5.2619C0.19043 4.60714 0.423565 4.04663 0.889834 3.58036C1.3561 3.11409 1.91662 2.88095 2.57138 2.88095H3.76186V0.5H6.14281V2.88095H15.6666V0.5H18.0476V2.88095H19.238C19.8928 2.88095 20.4533 3.11409 20.9196 3.58036C21.3859 4.04663 21.619 4.60714 21.619 5.2619V12.0179C21.242 11.8393 20.8551 11.6905 20.4583 11.5714C20.0615 11.4524 19.6547 11.3631 19.238 11.3036V10.0238H2.57138V21.9286H10.0714C10.2103 22.3651 10.374 22.7817 10.5625 23.1786C10.7509 23.5754 10.9742 23.9524 11.2321 24.3095H2.57138ZM18.0476 25.5C16.4007 25.5 14.997 24.9196 13.8363 23.7589C12.6755 22.5982 12.0952 21.1944 12.0952 19.5476C12.0952 17.9008 12.6755 16.497 13.8363 15.3363C14.997 14.1756 16.4007 13.5952 18.0476 13.5952C19.6944 13.5952 21.0982 14.1756 22.2589 15.3363C23.4196 16.497 24 17.9008 24 19.5476C24 21.1944 23.4196 22.5982 22.2589 23.7589C21.0982 24.9196 19.6944 25.5 18.0476 25.5ZM20.0416 22.375L20.875 21.5417L18.6428 19.3095V15.9762H17.4523V19.7857L20.0416 22.375Z"
            fill="#778BA8"
          />
        </svg>
      </footer>
    </Modal>
  );
};

export default UpdatePostContentModal;
