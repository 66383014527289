import React from "react";

const HighRiskCustomerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="153"
      height="111"
      viewBox="0 0 153 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M131.645 61.5536C130.592 57.9418 128.107 54.6405 124.669 53.3363C125.421 56.7562 125.627 61.3525 122.483 62.7024C119.339 64.0522 116.195 60.5046 115.29 57.1263C114.035 52.4353 114.726 47.4615 114.626 42.5975C114.526 37.7335 113.331 32.4011 109.491 29.5611C106.192 27.121 101.811 27.1357 97.8976 26.0149C89.9726 23.7445 84.2058 16.9062 78.9349 10.4073C79.27 13.5141 78.5851 16.647 76.9894 19.3069C75.3044 12.9818 70.8601 7.4937 65.1183 4.64708C59.3765 1.80047 52.436 1.64619 46.5783 4.2299C49.3504 8.80304 52.1701 13.494 53.2787 18.7616C54.3873 24.0292 53.4669 30.0781 49.6374 33.7554C46.9103 36.3737 42.8525 37.742 41.2956 41.23C39.6496 44.9194 41.5028 49.6362 39.495 53.1312C37.8454 56.0032 34.0506 56.916 30.8995 56.1317C27.7484 55.3474 25.0872 53.2439 22.5301 51.1933C19.4621 55.4936 20.8737 61.6442 23.4405 66.2792C26.0072 70.9142 29.6267 75.0521 30.9864 80.2C32.3999 85.5526 31.1689 91.2179 31.1367 96.7645C31.1045 102.311 32.9019 108.653 37.8543 110.843L123.716 111.001C122.373 94.9953 128.598 88.4858 131.736 72.7478C132.472 69.0543 132.698 65.1642 131.645 61.5536Z"
        fill="#EB2701"
        fill-opacity="0.6"
      />
      <g opacity="0.6">
        <path
          d="M131.645 61.5536C130.591 57.9418 128.107 54.6405 124.669 53.3363C125.42 56.7562 125.627 61.3525 122.483 62.7024C119.338 64.0522 116.194 60.5046 115.29 57.1263C114.035 52.4353 114.726 47.4615 114.626 42.5975C114.526 37.7335 113.33 32.4011 109.491 29.5611C106.192 27.121 101.811 27.1357 97.8973 26.0149C89.9724 23.7445 84.2056 16.9062 78.9347 10.4073C79.2697 13.5141 78.5849 16.647 76.9891 19.3069C75.3041 12.9818 70.8598 7.4937 65.118 4.64708C59.3763 1.80047 52.4358 1.64619 46.578 4.2299C49.3502 8.80304 52.1699 13.494 53.2784 18.7616C54.387 24.0292 53.4667 30.0781 49.6371 33.7554C46.9101 36.3737 42.8523 37.742 41.2953 41.23C39.6493 44.9194 41.5025 49.6362 39.4947 53.1312C37.8451 56.0032 34.0503 56.916 30.8992 56.1317C27.7482 55.3474 25.087 53.2439 22.5299 51.1933C19.4618 55.4936 20.8735 61.6442 23.4402 66.2792C26.007 70.9142 29.6264 75.0521 30.9862 80.2C32.3996 85.5526 31.1687 91.2179 31.1365 96.7645C31.1043 102.311 32.9017 108.653 37.8541 110.843L123.716 111.001C122.373 94.9953 128.598 88.4858 131.736 72.7478C132.472 69.0543 132.698 65.1642 131.645 61.5536Z"
          fill="url(#paint0_linear_6213_6255)"
          fill-opacity="0.6"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M40.1042 103.677C39.0268 102.559 38.2589 99.7868 38.276 96.8087C38.2846 95.3297 38.3988 93.7596 38.5204 92.0957C38.8249 87.9121 39.1695 83.1707 37.8763 78.2717C36.6465 73.6172 34.2111 69.8629 32.0619 66.5509C31.3699 65.4831 30.7079 64.4639 30.1182 63.4672C31.5512 63.7368 33.0125 63.811 34.4644 63.6881C39.3112 63.2831 43.3848 60.7951 45.6413 56.8674C47.6963 53.2895 47.6809 49.6041 47.6688 46.9129C47.6652 46.0484 47.6598 44.7552 47.7844 44.3027C48.0967 43.8638 49.3982 42.9845 50.2663 42.398C51.5852 41.5058 53.081 40.4954 54.5087 39.1242C59.8251 34.0198 62.0279 25.6206 60.2566 17.2052C59.7127 14.62 58.8711 12.2071 57.872 9.9375C59.31 10.1694 60.7073 10.616 62.02 11.263C65.8749 13.1744 68.973 16.9997 70.1045 21.2466L74.4253 37.4654L82.1001 24.7608C85.9368 28.3431 90.4942 31.52 95.984 33.0911C97.5302 33.533 99.0589 33.8209 100.408 34.0739C102.445 34.4568 104.37 34.8184 105.324 35.5239C106.606 36.4724 107.416 39.1739 107.489 42.7514C107.514 43.9794 107.48 45.2678 107.445 46.632C107.346 50.4061 107.233 54.6839 108.408 59.0744C109.976 64.9374 115.256 70.8663 121.952 70.3048C123.007 70.2154 124.044 69.9662 125.029 69.5654C124.955 70.1148 124.86 70.6834 124.743 71.2713C123.639 76.8033 121.158 82.2896 118.532 88.0984C115.347 95.1397 117.946 95.0575 116.853 102.985L40.1042 103.677Z"
          fill="url(#paint1_linear_6213_6255)"
          fill-opacity="0.6"
        />
        <path
          d="M46.9172 103.539C46.0372 102.627 45.41 100.365 45.424 97.9344C45.431 96.7274 45.5243 95.4462 45.6236 94.0884C45.8723 90.6745 46.1538 86.8053 45.0975 82.8076C44.093 79.0094 42.1038 75.9458 40.3484 73.2431C39.7831 72.3717 39.2424 71.5401 38.7607 70.7267C39.9312 70.9467 41.1248 71.0073 42.3107 70.907C46.2695 70.5765 49.5968 68.5462 51.4399 65.3411C53.1184 62.4214 53.1058 59.4141 53.0959 57.2179C53.093 56.5124 53.0886 55.4572 53.1903 55.0879C53.4455 54.7298 54.5085 54.0122 55.2175 53.5336C56.2948 52.8056 57.5165 51.9811 58.6827 50.8621C63.025 46.6967 64.8243 39.8428 63.3775 32.9756C62.9333 30.866 62.2458 28.897 61.4298 27.0449C62.6043 27.2342 63.7457 27.5986 64.8178 28.1266C67.9665 29.6863 70.497 32.8079 71.4212 36.2735L74.9504 49.5085L81.2191 39.1412C84.3528 42.0644 88.0752 44.6569 92.5593 45.939C93.8222 46.2995 95.0708 46.5345 96.1727 46.7409C97.8369 47.0534 99.409 47.3485 100.188 47.9242C101.235 48.6982 101.897 50.9027 101.957 53.822C101.977 54.8241 101.949 55.8754 101.921 56.9887C101.84 60.0685 101.748 63.5593 102.707 67.1421C103.988 71.9265 108.3 76.7646 113.769 76.3064C114.631 76.2334 115.478 76.0301 116.283 75.703C116.222 76.1513 116.145 76.6154 116.049 77.0951C115.148 81.6093 113.121 86.0863 110.976 90.8265C108.375 96.5724 110.498 96.5053 109.605 102.974L46.9172 103.539Z"
          fill="url(#paint2_linear_6213_6255)"
          fill-opacity="0.4"
        />
      </g>
      <path
        d="M121.432 41.9692C120.727 42.1217 119.984 42.7561 120.176 43.4704C120.268 43.8088 120.545 44.0533 120.799 44.2871C122.669 46.0103 124.033 48.2384 124.735 50.7186L125.036 50.5827C125.85 48.8252 126.317 46.7817 125.74 44.9274C125.162 43.0731 123.284 41.5693 121.432 41.9692Z"
        fill="#EB2701"
      />
      <g opacity="0.6">
        <path
          d="M121.432 41.9692C120.727 42.1217 119.984 42.7561 120.177 43.4704C120.268 43.8088 120.545 44.0533 120.799 44.2871C122.67 46.0103 124.033 48.2384 124.736 50.7186L125.036 50.5827C125.851 48.8252 126.317 46.7817 125.74 44.9274C125.162 43.0731 123.284 41.5693 121.432 41.9692Z"
          fill="#EB8D01"
        />
      </g>
      <path
        d="M38.0451 31.3237C36.8721 30.4827 35.2719 30.6432 34.4668 32.3042C33.9787 33.6552 34.2081 35.0507 34.6543 36.4167C35.1005 37.7828 36.5268 38.887 37.878 38.5071C39.2052 38.1337 39.8858 36.5526 39.8675 35.1372C39.8493 33.6659 39.2184 32.1651 38.0451 31.3237Z"
        fill="#EB2701"
      />
      <g opacity="0.6">
        <path
          d="M38.0449 31.3256C36.8719 30.4847 35.2716 30.6452 34.4665 32.3062C33.9784 33.6571 34.2078 35.0526 34.654 36.4187C35.1002 37.7847 36.5266 38.889 37.8777 38.509C39.2049 38.1356 39.8855 36.5546 39.8673 35.1392C39.849 33.6678 39.2182 32.167 38.0449 31.3256Z"
          fill="#EB8D01"
        />
      </g>
      <path
        d="M145.499 0.28125L130.653 13.6752L135.865 14.0971L129.783 23.1322L142.066 11.2954L137.486 11.2295L145.499 0.28125Z"
        fill="#EB2701"
      />
      <path
        d="M152.46 23.6152L141.887 24.4025L143.987 26.3205L138.538 28.1622L147.524 27.1876L145.583 25.6326L152.46 23.6152Z"
        fill="#EB2701"
      />
      <path
        d="M120.11 4.39648L120.09 15.3043L122.104 13.2924L123.486 19.0195L123.207 9.72703L121.556 11.603L120.11 4.39648Z"
        fill="#EB2701"
      />
      <path
        d="M8.86133 9.61914L16.8982 21.8053L17.7667 18.0566L23.614 23.4159L16.4213 13.2556L15.8774 16.5808L8.86133 9.61914Z"
        fill="#EB2701"
      />
      <path
        d="M0 31.0421L8.93615 34.6336L7.95855 32.311L13.1149 33.0477L5.40327 30.2324L6.39053 32.2101L0 31.0421Z"
        fill="#EB2701"
      />
      <g opacity="0.5">
        <path
          d="M47.1714 70.3757C47.1878 70.3057 47.204 70.2358 47.2215 70.1654C47.2215 70.1654 47.2122 70.1393 47.1961 70.0918C47.1864 70.1879 47.1789 70.2818 47.1714 70.3757Z"
          fill="white"
        />
      </g>
      <path
        d="M50.6427 90.5518C52.4047 89.5035 53.2306 87.6061 52.5149 87.0387C51.7992 86.4713 49.9542 88.3989 49.4017 88.6253L49.099 88.4556C49.099 88.4556 50.861 85.3674 50.5304 84.8008C50.1997 84.2341 49.2089 84.1207 48.9058 84.4042C48.6027 84.6877 46.8593 86.3307 46.6851 86.3023C46.5108 86.274 46.2099 85.9624 46.2099 85.9624C46.2099 85.9624 47.0082 84.149 47.3664 82.931C47.3664 82.931 47.3306 81.6655 46.0263 81.8164C46.0263 81.8164 46.2832 80.7022 45.3289 80.4379C44.3746 80.1735 43.5669 81.2244 43.1261 82.9936C42.9838 82.3229 42.904 81.6397 42.8878 80.9534C42.8692 79.8712 41.8597 79.5501 41.2725 80.1923C40.6853 80.8344 39.6021 84.6306 41.9882 87.9173C44.3742 91.2039 46.6872 91.3545 46.6872 91.3545C46.6872 91.3545 48.8804 91.6001 50.6427 90.5518Z"
        fill="#F2C5A7"
      />
      <path
        d="M114.821 81.1385C114.821 81.1385 115.133 79.3443 114.233 79.042C113.334 78.7397 113.499 79.3251 113.261 79.8918C113.022 80.4585 112.398 80.5546 112.306 80.2696C112.214 79.9846 112.93 79.3443 112.95 78.4944C112.97 77.6446 112.491 76.3032 111.904 76.3032C111.317 76.3032 110.986 76.8132 111.114 77.3044C111.243 77.7956 111.28 78.0408 111.023 78.2864C110.837 78.4543 110.634 78.6001 110.417 78.7209C110.417 78.7209 110.656 77.2849 110.381 76.8132C110.107 76.3415 109.519 75.3592 109.005 75.3772C108.49 75.3952 107.94 75.8492 108.032 76.1895C108.124 76.5297 108.436 77.606 108.307 77.8136C108.179 78.0213 106.307 79.2865 106.307 79.2301C106.307 79.1738 106.508 77.7757 106.16 77.4358C105.811 77.096 105.132 77.2657 105.132 77.2657C105.132 77.2657 103.829 78.7017 103.772 79.3623C103.715 80.0229 103.964 81.9876 103.964 81.9876C103.964 81.9876 105.974 85.8785 108.764 88.4471L108.507 88.1827C108.507 88.1827 111.401 87.0873 112.955 85.0662C112.955 85.0662 114.583 84.0651 115.041 83.0076C115.5 81.9501 114.821 81.1385 114.821 81.1385Z"
        fill="#F2C5A7"
      />
      <path
        d="M103.964 81.985C103.964 81.985 102.009 84.2513 100.357 83.0804C98.7052 81.9095 96.0991 77.7922 93.2365 76.9233C90.3738 76.0543 86.6327 76.5182 86.6327 76.5182C86.6327 76.5182 81.3933 78.2448 78.3027 78.9808C78.3027 78.9808 68.9529 75.3919 62.0149 76.9785C55.0768 78.5651 55.5445 81.7096 52.3509 82.4747C49.1572 83.2399 47.3677 82.928 47.3677 82.928C47.0099 84.146 46.2112 85.9595 46.2112 85.9595C46.2112 85.9595 46.5139 86.271 46.6885 86.2993C46.8632 86.3277 48.6065 84.6844 48.9092 84.4012C49.212 84.1181 50.2035 84.2311 50.5338 84.7978C50.8641 85.3644 49.1025 88.4526 49.1025 88.4526L49.4052 88.6223C49.9559 88.3959 51.8005 86.4694 52.5183 87.0357C53.2362 87.602 52.4081 89.5005 50.6462 90.5488C48.8842 91.5971 46.6907 91.3515 46.6907 91.3515C46.6907 91.3515 49.1501 93.2025 53.2616 93.127C57.3731 93.0515 63.7765 89.5638 63.7765 89.5638C63.7765 89.5638 63.862 95.6684 64.4127 98.7282C64.9634 101.788 65.6848 104.096 65.6848 104.096C65.6848 104.096 64.5265 107.504 64.7466 110.903C70.4361 110.601 100.768 110.854 106.064 110.953C106.064 110.953 105.206 98.9819 102.086 97.4332C102.086 97.4332 102.287 95.1481 102.159 93.6557C102.159 93.6557 107.005 91.8048 108.767 88.443C105.974 85.8766 103.964 81.985 103.964 81.985Z"
        fill="#02285E"
      />
      <path
        d="M65.5225 103.708C65.5225 103.708 98.6935 97.3833 102.146 96.5324C102.146 96.5324 102.375 94.7606 102.059 92.2461C102.059 92.2461 71.884 97.5873 64.6318 99.7472L65.5225 103.708Z"
        fill="#EB2701"
      />
      <g opacity="0.5">
        <path
          d="M40.5762 83.5903C40.5762 83.5903 41.4013 80.8549 41.8418 80.6473C42.2823 80.4396 42.6126 80.8078 42.6126 80.8078C42.6126 80.8078 42.541 79.9296 41.9886 79.9108C41.4361 79.8921 40.9698 80.2603 40.6946 81.1476C40.4195 82.035 40.5762 83.5903 40.5762 83.5903Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M43.0716 83.5908C43.0716 83.5908 44.402 81.0068 45.0905 81.0539C45.779 81.101 45.7135 82.0724 45.7135 82.0724C45.7135 82.0724 46.0448 81.7528 45.9715 81.3938C45.8981 81.0347 45.6247 80.3061 44.8389 80.4202C44.0531 80.5343 43.3561 81.847 43.3468 81.893C43.3375 81.9391 42.9431 83.2005 43.0716 83.5908Z"
          fill="white"
        />
      </g>
      <g opacity="0.26">
        <path
          d="M72.8896 97.7773C72.8896 97.7773 75.1007 97.9655 77.1933 97.7773C79.2859 97.5892 102.082 93.1696 102.082 93.1696L102.146 92.6802L101.77 92.2461L72.8896 97.7773Z"
          fill="white"
        />
      </g>
      <path
        d="M82.9833 79.3714L85.5143 77.3212L85.7326 76.9408L85.5536 76.8418L80.1987 78.4568L79.3313 78.7966V79.0658L81.4239 79.7598L82.3364 79.9233L82.9833 79.3714Z"
        fill="#F2C5A7"
      />
      <path
        d="M97.9344 92.9379C96.4093 91.2567 71.9432 77.2258 71.7994 77.1952C71.5324 77.1381 78.1352 78.4298 78.4021 78.4865C78.5495 78.5182 97.744 85.9512 101.465 92.3311C101.481 92.3584 100.729 92.4294 99.8799 92.5929C98.9818 92.7671 98.0085 93.02 97.9344 92.9379Z"
        fill="#012551"
      />
      <path
        d="M86.3687 76.0896C86.5995 75.9405 89.5241 76.2369 89.2933 76.3864C87.8022 77.35 84.3194 80.9758 84.2886 81.0015C84.196 81.0825 81.611 79.8859 81.8196 79.7043"
        fill="#012551"
      />
      <path
        d="M93.2343 94.8672C93.2343 94.8672 96.5028 97.8522 95.2546 101.743C94.0065 105.633 89.6084 109.222 89.6084 109.222L87.2886 106.88C87.2886 106.88 93.0257 102.02 91.6255 99.0305"
        fill="#EB2701"
      />
      <path
        d="M87.0851 99.3082C87.0851 99.3082 85.5629 103.141 82.5893 104.878C79.6157 106.616 76.8997 106.578 76.8997 106.578L75.6516 102.943C75.6516 102.943 79.5795 102.234 81.1214 100.799C82.6633 99.3638 84.0642 95.9785 84.0642 95.9785"
        fill="#EB2701"
      />
      <path
        d="M88.4631 94.4349C88.4631 94.4349 88.9952 92.3195 91.4181 92.6406C93.8409 92.9617 93.5203 97.188 92.3041 98.5216C91.0878 99.8553 89.5276 98.8357 89.5276 98.8357C89.5276 98.8357 87.6737 100.007 85.7829 98.8169C83.8921 97.6272 83.452 94.246 85.0122 93.662C86.5723 93.078 87.7471 93.8873 88.4631 94.4349Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M76.9284 102.653C76.9284 102.653 77.8173 104.707 78.6983 104.744C79.5793 104.782 82.7729 103.422 83.9845 101.741C85.1962 100.06 83.4889 97.2261 83.5072 97.189C83.5254 97.1518 83.9316 96.2821 83.9316 96.2821C83.9316 96.2821 84.064 95.8174 84.0643 95.634C84.0647 95.4506 84.0901 95.0508 84.0901 95.0508C84.0901 95.0508 86.0034 97.5631 87.7654 97.858C89.5274 98.1529 89.9611 97.172 89.9611 97.172L89.7464 96.4654C89.7464 96.4654 90.5443 96.9441 91.4121 96.925C92.2798 96.9058 93.032 95.9761 93.032 95.9761C93.032 95.9761 93.5562 96.6458 92.8019 97.7033C92.0476 98.7608 91.358 99.213 90.5891 99.1927C89.8201 99.1725 89.6 98.7642 89.2339 98.9847C88.8679 99.2053 87.8788 99.3746 87.5164 99.3459C87.1539 99.3172 86.9932 99.5175 86.9932 99.5175C86.9932 99.5175 85.2756 103.555 82.39 104.99C79.5045 106.425 76.8061 106.576 76.8061 106.576L75.6499 102.941L76.9284 102.653Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M88.2928 105.835C88.3286 105.911 89.1788 106.937 90.1149 106.786C91.051 106.635 93.2903 103.841 94.1348 101.876C94.9792 99.9105 94.2997 98.8729 93.841 98.835C93.3822 98.797 91.84 99.8472 91.8296 99.7717C91.8192 99.6962 91.684 99.1682 91.684 99.1682L91.9581 98.8339C91.9581 98.8339 92.7224 97.9583 92.9661 97.2635C93.2098 96.5686 93.2341 95.5675 93.2341 95.5675L93.7014 95.3672C93.7014 95.3672 95.3195 96.5119 95.4154 99.6848C95.5113 102.858 92.5492 106.272 92.5492 106.272L89.6933 109.223L87.2891 106.786L88.2928 105.835Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M87.7406 94.7256C88.301 95.1307 88.5547 95.6892 88.4337 95.8671C88.3128 96.0449 87.8633 95.7728 87.303 95.3678C86.7426 94.9628 86.2828 94.5784 86.4037 94.4012C86.5247 94.2241 87.1799 94.3206 87.7406 94.7256Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M91.4141 93.4094C91.9315 93.5688 92.2371 93.9845 92.1777 94.1918C92.1183 94.3991 91.7125 94.3196 91.1955 94.1602C90.6784 94.0007 90.244 93.821 90.3045 93.6137C90.3649 93.4064 90.897 93.2496 91.4141 93.4094Z"
          fill="white"
        />
      </g>
      <path
        d="M54.3879 54.5387C54.3879 54.5387 55.3336 58.2848 51.5055 60.5699C47.6774 62.855 43.991 61.4293 43.991 61.4293L42.6746 55.7821C42.6746 55.7821 46.2565 56.6069 48.1111 56.0222C49.9658 55.4375 53.2721 51.3691 53.2721 51.3691"
        fill="#EB2701"
      />
      <path
        d="M84.3811 78.3309C78.62 79.7739 68.9227 80.165 65.7777 78.6884C61.6022 76.7259 57.6653 72.6955 56.152 69.6604C54.6967 66.7361 50.1046 55.5212 56.4179 45.0681C62.3171 35.3018 79.1936 28.3059 92.4563 43.2849C95.9677 48.0348 98.226 60.4743 95.8102 67.306C94.2436 71.7396 89.9729 76.9292 84.3811 78.3309Z"
        fill="#02285E"
      />
      <path
        d="M63.4689 55.8952C61.479 56.5713 60.1857 58.4491 60.277 60.5034C60.4158 63.6397 62.0662 68.1253 64.9031 69.7881C68.7938 72.068 72.1735 66.3207 79.4597 65.5769C87.0039 64.807 87.7439 66.9161 91.8342 64.73C94.3083 63.4089 93.9097 52.5607 91.1884 51.5607C87.1091 50.0599 85.292 54.1952 78.0505 55.2464C73.2144 55.9479 66.7674 54.7751 63.4689 55.8952Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-miterlimit="10"
      />
      <path
        d="M53.5012 51.6785C51.7238 48.7387 48.9327 49.3783 46.6701 49.7561C44.4076 50.1339 42.3708 47.4434 42.3708 47.4434C41.1581 49.013 42.2491 54.7976 42.2491 54.7976C42.2491 54.7976 47.7218 53.7088 49.1034 53.7857C50.485 53.8627 53.2625 55.5377 53.2625 55.5377L53.484 55.4692"
        fill="#EB2701"
      />
      <path
        d="M94.8519 48.7378C94.8519 48.7378 71.8193 56.0169 52.7726 55.6325C52.7726 55.6325 51.3867 55.6384 51.488 53.1088C51.5892 50.5792 54.5571 50.5785 54.5571 50.5785C54.5571 50.5785 70.5103 49.5913 76.1877 48.6233C81.8651 47.6553 92.9486 44.7023 93.3186 44.7247C94.1194 44.7733 94.8136 47.1461 94.8519 48.7378Z"
        fill="#EB2701"
      />
      <path
        d="M78.1571 60.3202C76.4037 62.4113 73.9486 63.6404 71.3544 63.7225C69.0138 63.6514 67.9138 63.0093 66.6667 60.8659C65.6362 58.4648 65.7972 57.8359 65.7972 57.8359L78.1571 60.3202Z"
        fill="#EB2701"
      />
      <path
        d="M89.2812 58.3304C88.6099 60.0242 87.0723 61.1129 85.3558 61.1052C83.5216 61.2417 81.6911 60.7924 80.1143 59.8187L88.5838 53.0938C88.5838 53.0938 89.6 57.2847 89.2812 58.3304Z"
        fill="#EB2701"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6213_6255"
          x1="76.5738"
          y1="2.39648"
          x2="76.5738"
          y2="111.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.44" stop-color="#EB8D01" />
          <stop offset="1" stop-color="#EB2701" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6213_6255"
          x1="77.5735"
          y1="9.9375"
          x2="77.5735"
          y2="103.677"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.41" stop-color="#FFD495" />
          <stop offset="1" stop-color="#EB8D01" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6213_6255"
          x1="77.5218"
          y1="27.0449"
          x2="77.5218"
          y2="103.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.515" stop-color="#FFF9EF" />
          <stop offset="1" stop-color="#EB8D01" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HighRiskCustomerIcon;
