import React from "react";

const SalesDashboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 18.9194L7.90306 11.0058L12.118 15.2208L19.589 6.82244L21.0748 8.30822L12.118 18.382L7.90306 14.167L1.58061 20.5L0 18.9194ZM1.58061 14.1776L7.90306 7.84457L12.118 12.0595L21.0748 1.98577L19.589 0.5L12.118 8.89831L7.90306 4.68335L0 12.5969L1.58061 14.1776Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default SalesDashboardIcon;
