import React from "react";

import styles from "./InitialContact.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";
import { updateTailoredStrategyforBANTQualification } from "../../../../redux/tailoredStrategyforBANTQualificationSlice";

interface InitialContactProps {
  className?: string;
  cardType: CardType;
}

const InitialContact: React.FC<InitialContactProps> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();

  const { includeData } = useAppSelector(
    (state) => state.tailoredStrategyforBANTQualification
  );

  const handlePinned = (isPinned: boolean) => {
    dispatch(
      updateTailoredStrategyforBANTQualification({
        ...includeData,
        initialContact: isPinned,
      })
    );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Step-by-Step BANT Qualification Process
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Initial Contact
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>
          Introduction
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            {" "}
            <span className={styles["highlight"]}>Script:</span> “Hello [Lead’s
            Name], this is [Your Name] from [Your Company]. I noticed you
            recently [attended our webinar/downloaded our whitepaper] on 5G
            solutions. I’m reaching out to understand your needs better and see
            how we can assist you further.”
          </TextElement>
        </div>
        <TextElement className={styles["content-heading"]}>
          Establish Context
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            <span className={styles["highlight"]}>Script:</span> “I see you’re
            based in [State] and working in the [Industry] sector. Our 5G
            solutions have been particularly beneficial for companies in your
            area and industry. I’d love to learn more about your specific
            requirements.”
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default InitialContact;
