import React from "react";

const EvaluationStageIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="112"
      height="117"
      viewBox="0 0 112 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.67331 89.6077L13.7039 90.4241L13.1828 97.9529C13.0136 102.371 12.8448 109.62 12.1311 116.189L19.1079 116.203C33.0002 116.228 46.8922 116.227 60.7839 116.198C60.1828 113.341 59.3304 108.474 59.1321 108.113C59.0893 108.036 58.7691 102.67 58.7691 102.67C67.49 102.063 75.6816 99.9659 75.6801 99.9659C74.5468 94.972 71.5164 89.4419 67.4267 84.2312C65.8912 82.2729 64.2497 80.4002 62.5096 78.6215C55.7801 71.7331 47.5604 66.0938 40.2242 64.0982C27.2001 60.5581 18.0638 61.9776 11.8895 65.2555C2.98917 69.9763 0.240778 78.5501 0.864787 81.6687C1.99453 87.2932 7.67331 89.6077 7.67331 89.6077Z"
        fill="#02285E"
      />
      <path
        d="M12.7427 103.78C12.7427 103.78 16.1283 112.161 59.0503 107.08L58.8408 103.735C58.8408 103.735 36.6849 111.317 13.0598 98.6777L12.7427 103.78Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M59.1035 107.839C16.1815 112.919 12.7617 103.788 12.7617 103.788L12.9728 101.066C15.7982 102.856 20.7219 110.4 58.9542 105.965L59.1035 107.839Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M13.0317 98.7689C15.0557 99.6761 21.0164 102.401 21.672 102.428C39.6862 92.1239 57.0942 79.7932 60.4931 76.5219L54.6303 71.6465C37.1692 87.1214 19.3096 96.2202 13.0317 98.7689Z"
        fill="#012551"
      />
      <path
        d="M45.6794 116.215L51.0559 116.211C51.4431 115.925 51.8098 115.612 52.153 115.275C52.638 114.803 52.9158 114.157 52.9254 113.48C52.935 112.803 52.6755 112.149 52.2039 111.664C50.0597 109.47 45.8486 105.479 41.4791 102.883L38.4023 103.985C38.4023 103.985 41.2889 111.212 45.6794 116.215Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M51.0563 116.211C51.4434 115.925 51.81 115.612 52.1535 115.275C52.3935 115.042 52.5853 114.763 52.7178 114.455C52.8503 114.148 52.9209 113.817 52.9256 113.482C52.9304 113.147 52.8691 112.814 52.7454 112.503C52.6216 112.191 52.4378 111.907 52.2044 111.667C51.9709 111.43 51.7145 111.171 51.4356 110.895C50.7675 111.43 50.0436 111.892 49.2766 112.272C48.7652 112.523 48.1877 112.604 47.6273 112.504C47.0668 112.405 46.5524 112.13 46.1581 111.719C43.766 109.151 41.7864 105.861 40.4278 103.259L38.4028 103.986C38.4028 103.986 41.2873 111.213 45.6783 116.216L51.0563 116.211Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M24.3495 116.209L28.7339 116.214C31.0744 113.745 35.1091 109.198 37.0657 105.222L35.497 102.883C35.497 102.883 28.6834 105.651 23.3589 110.604C23.0394 110.905 22.8021 111.283 22.6697 111.701C22.5373 112.12 22.5141 112.566 22.6024 112.996C22.7859 113.902 23.2504 115.082 24.3495 116.209Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M24.3484 116.208L28.7328 116.213C31.0718 113.745 35.1079 109.196 37.0641 105.221L35.4995 102.89C33.264 106.299 30.1653 109.696 28.3764 111.561C27.9356 112.017 27.3387 112.291 26.7053 112.325C26.0719 112.36 25.4488 112.154 24.9607 111.749C24.462 111.34 24.0199 110.866 23.6459 110.341C23.549 110.426 23.4552 110.514 23.3583 110.603C23.0382 110.904 22.8005 111.281 22.6678 111.7C22.5352 112.119 22.5121 112.564 22.6007 112.995C22.7863 113.901 23.2497 115.082 24.3484 116.208Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M37.2294 102.989C37.2294 102.989 38.402 100.01 42.2541 100.913C46.1063 101.815 44.9561 107.9 42.7966 109.62C40.637 111.34 38.2817 109.577 38.2817 109.577C38.2817 109.577 35.1209 110.946 32.2593 108.877C29.3977 106.808 29.1928 101.814 31.7928 101.243C34.3928 100.673 36.1604 102.064 37.2294 102.989Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M29.9211 103.604C29.9211 103.604 32.3544 106.985 35.3113 107.353C38.2682 107.721 38.8861 106.408 38.8861 106.408L38.9004 105.907C38.9004 105.907 41.6024 107.868 42.8407 107.336C44.079 106.804 44.338 105.972 44.338 105.972L44.7606 105.262C44.7606 105.262 44.5297 109.331 42.1927 110.013C39.8558 110.695 38.6903 109.828 38.6903 109.828L37.815 109.521C37.815 109.521 34.3707 110.806 31.8048 108.496C29.2389 106.186 29.9211 103.604 29.9211 103.604Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M35.766 103.428C36.6072 104.118 36.9309 104.975 36.7097 105.211C36.4884 105.447 35.8063 104.972 34.9656 104.282C34.1249 103.592 33.4428 102.951 33.6635 102.715C33.8843 102.479 34.9243 102.739 35.766 103.428Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M42.091 102.196C42.8991 102.521 43.3304 103.18 43.2019 103.472C43.0734 103.763 42.4372 103.574 41.6261 103.249C40.815 102.925 40.1476 102.586 40.273 102.293C40.3984 102 41.2809 101.872 42.091 102.196Z"
          fill="white"
        />
      </g>
      <path
        d="M27.3121 89.3458L57.7377 94.8622L89.3516 102.21C89.5069 102.246 89.65 102.322 89.7667 102.431C89.8834 102.539 89.9696 102.677 90.0166 102.829C90.0636 102.982 90.0698 103.144 90.0346 103.299C89.9994 103.455 89.924 103.599 89.816 103.716L87.5463 106.183C87.4587 106.278 87.3517 106.354 87.2326 106.405C87.1135 106.455 86.9849 106.48 86.8555 106.477L56.1185 105.792L25.9377 93.7723C25.424 93.5676 25.0126 93.1672 24.794 92.659C24.5753 92.1507 24.5673 91.5764 24.7718 91.0623C24.8006 90.9895 24.8336 90.9185 24.8707 90.8495L25.1021 90.4163C25.3099 90.0268 25.6358 89.7133 26.033 89.5209C26.4301 89.3285 26.878 89.2672 27.3121 89.3458Z"
        fill="#A6A6A6"
      />
      <path
        d="M111.391 80.9698C111.385 81.5109 111.168 82.0283 110.787 82.4118C105.567 87.6995 93.7674 99.6486 88.8176 104.663C88.1079 105.382 87.2552 105.944 86.3147 106.312C85.3742 106.68 84.3669 106.847 83.3581 106.801C76.3976 106.478 60.2351 105.734 56.7775 105.576C56.3402 105.557 55.9087 105.468 55.4989 105.314C51.6111 103.855 30.5075 95.9447 24.4412 93.67C24.0037 93.5057 23.6469 93.1781 23.4457 92.756C23.2444 92.3339 23.2144 91.8502 23.362 91.4065C23.5098 90.9646 23.6678 90.488 23.8091 90.0553C23.8744 89.8599 23.9777 89.6794 24.1128 89.5239C24.248 89.3684 24.4124 89.2412 24.5967 89.1493C24.781 89.0575 24.9816 89.0029 25.187 88.9887C25.3924 88.9745 25.5986 89.0009 25.7938 89.0665L25.8177 89.0746L53.6769 98.9603C53.6769 98.9603 72.8213 79.7452 79.1582 73.3859C79.769 72.7723 80.53 72.3299 81.3651 72.1028C82.2002 71.8757 83.0803 71.872 83.9173 72.0919L109.825 78.89C110.182 78.9841 110.508 79.1703 110.771 79.43C111.033 79.6896 111.223 80.0137 111.321 80.3697C111.375 80.5649 111.399 80.7673 111.391 80.9698Z"
        fill="#A6A6A6"
      />
      <g opacity="0.6">
        <path
          d="M111.391 80.9684C111.387 81.2355 111.333 81.4996 111.231 81.7465C111.004 81.5704 110.744 81.4406 110.466 81.3643C104.005 79.6682 90.3936 76.097 84.5583 74.5662C83.7213 74.3463 82.8412 74.35 82.0061 74.5771C81.171 74.8042 80.41 75.2466 79.7992 75.8602L55.2681 100.477C54.9836 100.763 54.6238 100.963 54.2304 101.052C53.8371 101.142 53.4264 101.117 53.0464 100.982L26.4562 91.5469C26.0641 91.4087 25.6333 91.432 25.2584 91.6116C24.8835 91.7912 24.5951 92.1124 24.4567 92.5046L24.449 92.5276C24.3471 92.844 24.2354 93.1802 24.1243 93.5109C23.7782 93.2975 23.5143 92.9734 23.3754 92.591C23.2366 92.2086 23.231 91.7905 23.3596 91.4045C23.5074 90.9627 23.6655 90.4861 23.8067 90.0534C23.8721 89.8578 23.9753 89.677 24.1106 89.5213C24.2459 89.3657 24.4106 89.2383 24.5951 89.1465C24.7796 89.0546 24.9805 89.0001 25.1861 88.986C25.3917 88.972 25.5981 88.9986 25.7934 89.0645L25.8174 89.0727L53.6765 98.9583C53.6765 98.9583 72.8209 79.7432 79.1579 73.384C79.7687 72.7704 80.5296 72.3279 81.3648 72.1008C82.1999 71.8738 83.0799 71.87 83.917 72.09L109.825 78.8881C110.182 78.9821 110.508 79.1683 110.77 79.428C111.033 79.6877 111.222 80.0117 111.32 80.3678C111.375 80.5631 111.398 80.7657 111.391 80.9684Z"
          fill="#E9E8F7"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M111.392 80.9704C111.386 81.5115 111.169 82.0288 110.787 82.4123C105.567 87.7001 93.7679 99.6492 88.8181 104.663C88.1084 105.383 87.2557 105.944 86.3152 106.313C85.3747 106.681 84.3674 106.847 83.3585 106.801C76.3981 106.479 60.2355 105.735 56.778 105.577C56.3407 105.557 55.9092 105.469 55.4994 105.314C51.6116 103.855 30.508 95.9452 24.4417 93.6705C24.0042 93.5062 23.6474 93.1787 23.4461 92.7566C23.2449 92.3345 23.2149 91.8508 23.3624 91.4071C23.5103 90.9652 52.0832 101.742 52.0832 101.742L54.1958 102.243C55.0215 102.438 55.8837 102.415 56.6978 102.176C57.5118 101.936 58.2495 101.489 58.8382 100.877L83.3045 75.4668C83.3045 75.4668 99.597 79.4289 106.428 81.2648C109.02 81.9608 110.525 81.6383 111.392 80.9704Z"
          fill="#BAC4D3"
        />
      </g>
      <path
        d="M87.6171 92.8194C89.0911 91.7246 89.7261 90.082 89.0354 89.1506C88.3448 88.2192 86.59 88.3516 85.1161 89.4464C83.6421 90.5412 83.0071 92.1838 83.6978 93.1152C84.3884 94.0467 86.1432 93.9142 87.6171 92.8194Z"
        fill="white"
      />
      <path
        d="M79.7045 102.196C79.7045 102.196 82.7344 98.4289 86.197 99.0871C89.6596 99.7453 88.5518 112.824 77.183 113.152C65.8142 113.479 65.3692 101.409 69.1632 99.0835C73.3237 96.5323 79.7061 102.192 79.7061 102.192L79.7045 102.196Z"
        fill="#F4D2B0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.6523 87.8583C41.2516 90.056 39.5427 85.6576 39.5427 85.6576C39.5427 85.6576 33.9292 91.2296 24.773 86.4893C24.773 86.4893 24.696 86.5603 24.5421 86.6802C23.3374 87.6323 17.4307 91.7398 7.67343 89.6049C7.67343 89.6049 0.675765 87.4756 0.60796 80.8766C0.507527 71.143 7.81312 71.1476 7.81312 71.1476C7.81312 71.1476 20.9907 68.3856 29.9048 71.6237C30.3636 71.792 30.8372 71.8278 31.2644 72.0319C39.0834 75.7776 45.9251 85.7709 43.6523 87.8583Z"
        fill="#012551"
      />
      <path
        d="M31.6719 66.793L40.4891 69.6999L46.2183 78.7665L39.8967 83.8012L31.6719 66.793Z"
        fill="#012551"
      />
      <path
        d="M43.6518 87.8575C41.2511 90.0552 39.5422 85.6568 39.5422 85.6568C39.5422 85.6568 33.9287 91.2287 24.7725 86.4885C24.7725 86.4885 24.6955 86.5594 24.5415 86.6793C24.1627 84.496 22.9239 74.8757 29.8079 71.7371C29.8079 71.7371 32.802 71.5381 36.0051 72.4867C44.3186 74.9481 45.9245 85.7701 43.6518 87.8575Z"
        fill="#F4D2B0"
      />
      <path
        d="M45.0989 77.5662C45.0989 77.5662 43.2427 74.7012 42.2389 72.9092C41.2351 71.1171 40.4897 69.6992 40.4897 69.6992L40.737 69.0996C40.737 69.0996 45.9202 70.4309 47.7958 70.8498C49.6714 71.2687 54.4285 71.8953 54.4285 71.8953L54.3469 72.33C53.0892 73.5933 51.7547 74.7777 50.351 75.8763C48.1201 77.6081 46.1425 78.4786 46.1425 78.4786L45.4798 78.1494L45.0989 77.5662Z"
        fill="#F4D2B0"
      />
      <path
        d="M20.0486 24.7982C20.0486 24.7982 19.7269 30.4778 13.1896 31.9039C6.6523 33.3301 1.99365 29.6272 1.99365 29.6272L2.63601 21.1136C2.63601 21.1136 7.41855 23.9272 10.3489 23.971C13.2793 24.0149 19.8686 19.8467 19.8686 19.8467"
        fill="#EB2701"
      />
      <path
        d="M52.4982 71.9835C43.5623 71.3355 29.4329 67.3912 25.5741 63.8654C20.4479 59.1848 16.5973 51.7214 15.7857 46.7765C15.0047 42.0164 13.4442 24.1938 27.2269 12.4902C40.1068 1.55241 67.5305 -0.419203 79.8766 26.6833C82.7922 34.955 80.4461 53.4093 73.9006 61.8489C69.6513 67.328 61.1747 72.6126 52.4982 71.9835Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M11.1587 46.0684L11.3116 45.8455L11.3172 45.749C11.2642 45.8541 11.2097 45.9603 11.1587 46.0684Z"
          fill="white"
        />
      </g>
      <path
        d="M32.5028 30.9006C29.3359 30.9246 26.6308 32.9539 25.8381 35.8705C24.6268 40.3229 24.9836 47.3644 28.3183 51.0056C32.8918 55.9994 40.3361 49.5202 51.1543 51.8546C62.3543 54.2712 62.4721 57.5633 69.3372 56.402C73.4911 55.6989 77.7939 40.3337 74.3303 37.6757C69.1358 33.6871 64.6622 38.6315 53.7706 36.7476C46.4997 35.4883 37.7523 30.8624 32.5028 30.9006Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M20.0583 20.3868C18.8225 15.446 14.5197 15.0526 11.0968 14.5347C7.67392 14.0168 5.9477 9.30566 5.9477 9.30566C3.49806 10.9385 2.46875 19.5403 2.46875 19.5403C2.46875 19.5403 10.8297 20.5501 12.7812 21.2971C14.7328 22.0441 17.9767 25.6756 17.9767 25.6756L18.3249 25.6817"
        fill="#EB2701"
      />
      <path
        d="M80.8707 35.4206C80.8707 35.4206 44.4636 34.9349 17.229 25.5768C17.229 25.5768 15.2305 24.9426 16.5152 21.4499C17.7999 17.9573 22.0722 19.3339 22.0722 19.3339C22.0722 19.3339 45.4689 25.3416 54.0715 26.6167C62.674 27.8919 79.949 28.8935 80.4695 29.0976C81.6012 29.5359 81.5319 33.1796 80.8707 35.4206Z"
        fill="#EB2701"
      />
      <path
        d="M51.7377 41.0509C48.4505 43.4251 44.4194 44.3165 40.579 43.5246C37.1633 42.6015 35.7654 41.2958 34.6652 37.7929C33.9713 33.9961 34.4225 33.1562 34.4225 33.1562L51.7377 41.0509Z"
        fill="#EB2701"
      />
      <path
        d="M68.7542 45.5134C66.7297 47.284 63.9334 47.6677 61.6234 46.4926C59.0744 45.4451 56.9077 43.6179 55.3936 41.2431L71.2619 38.1592C71.2619 38.1592 69.8758 44.3577 68.7542 45.5134Z"
        fill="#EB2701"
      />
      <path
        opacity="0.2"
        d="M102.407 38.457C106.912 38.457 110.564 34.8018 110.564 30.2929C110.564 25.7841 106.912 22.1289 102.407 22.1289C97.9025 22.1289 94.2505 25.7841 94.2505 30.2929C94.2505 34.8018 97.9025 38.457 102.407 38.457Z"
        fill="#FFA600"
        stroke="#FFA600"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.2"
        d="M102.419 59.874C106.924 59.874 110.576 56.2188 110.576 51.7099C110.576 47.2011 106.924 43.5459 102.419 43.5459C97.9137 43.5459 94.2617 47.2011 94.2617 51.7099C94.2617 56.2188 97.9137 59.874 102.419 59.874Z"
        fill="#38B05A"
        stroke="#38B05A"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.2"
        d="M102.453 17.6083C106.958 17.6083 110.61 13.9532 110.61 9.4443C110.61 4.93543 106.958 1.28027 102.453 1.28027C97.9479 1.28027 94.2959 4.93543 94.2959 9.4443C94.2959 13.9532 97.9479 17.6083 102.453 17.6083Z"
        fill="#EB2701"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M102.486 17.6943C106.991 17.6943 110.643 14.0391 110.643 9.53024C110.643 5.02137 106.991 1.36621 102.486 1.36621C97.9811 1.36621 94.3291 5.02137 94.3291 9.53024C94.3291 14.0391 97.9811 17.6943 102.486 17.6943Z"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M102.407 38.5175C106.912 38.5175 110.564 34.8624 110.564 30.3535C110.564 25.8446 106.912 22.1895 102.407 22.1895C97.9025 22.1895 94.2505 25.8446 94.2505 30.3535C94.2505 34.8624 97.9025 38.5175 102.407 38.5175Z"
        stroke="#FFA600"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M102.453 59.789C106.958 59.789 110.61 56.1338 110.61 51.625C110.61 47.1161 106.958 43.4609 102.453 43.4609C97.9479 43.4609 94.2959 47.1161 94.2959 51.625C94.2959 56.1338 97.9479 59.789 102.453 59.789Z"
        stroke="#38B05A"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default EvaluationStageIcon;
