import React, { useEffect } from "react";

import styles from "./JourneyTreeHeader.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Button, TextElement } from "../../../componenets";

type Props = {
  className?: string;
  defaultCampaignName: string;
};

const JourneyTreeHeader: React.FC<Props> = (props) => {
  const { className, defaultCampaignName } = props;
  const [campaignName, setCampaignName] =
    React.useState<string>(defaultCampaignName);

  const navigate = useNavigate();

  useEffect(() => {
    setCampaignName(defaultCampaignName);
  }, [defaultCampaignName]);

  return (
    <div className={classNames(styles["container"], className)}>
      <button className={styles["back-container"]} onClick={() => navigate(-1)}>
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L0 6L6 0L7.4 1.45L3.85 5H16V7H3.85L7.4 10.55L6 12Z"
            fill="#02285E"
          />
        </svg>
        <TextElement>Back</TextElement>
      </button>
      <input
        value={campaignName}
        className={styles["campaign-name-input"]}
        disabled={true}
      />
      <Button className={styles["submit-button"]} disabled={true}>
        Submit for approval
      </Button>
    </div>
  );
};

export default JourneyTreeHeader;
