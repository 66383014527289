import React, { InputHTMLAttributes } from "react";

import styles from "./Input.module.scss";
import classNames from "classnames";
import Send from "../../../../icons/Send";
import Propensity from "../../../../icons/Propensity";
import { GraphIcon, KPIIcon } from "../../../../icons/index copy";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onSubmit: () => void;

  className?: string;
}

const Input: React.FC<InputProps> = ({
  onChange,
  onInput,
  onSubmit,
  className,
  ...rest
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (onInput) {
      onInput(e);
    }
  };

  const handleIconClick = () => {
    onSubmit();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Perform actions when the Enter key is pressed
      // For example, you can submit the form
      // or perform any other desired action
      onSubmit();
    }
  };

  return (
    <div className={classNames(styles["container"], className)}>
      <input
        onChange={handleInputChange}
        onInput={handleInput}
        onKeyPress={handleKeyPress}
        {...rest}
      />
      <Send onClick={handleIconClick} className={styles["action-icon"]} />
    </div>
  );
};

export default Input;
