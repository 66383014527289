import React, { useEffect, useState } from "react";
import styles from "./CustomerCampaignOffers.module.scss";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { Button, TextElement } from "../../componenets";
import classNames from "classnames";
import RetentionCampaignHeader from "./components/CampaignHeader/RetentionCampaignHeader";
import { useLocation, useNavigate } from "react-router-dom";
import useJourneyManagement, {
  Journey,
} from "../JourneyManagment/useJourneyManagment";
import DottedLoader from "../../componenets/DottedLoader/DottedLoader";
import GeneratedActiveOffers from "../../componenets/GeneratedActiveOffers/GeneratedActiveOffers";
import DisclosureComponent from "../../componenets/Disclosure/Disclosure";
import StagesAndActions from "../../componenets/VisualizeUserJourney/StagesAndActions";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Card from "../../componenets/Card/Card";

type OfferType = "onboarding" | "retention" | "upsell-and-cross-sell";

const CustomerCampaignOffers: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState( 
    "Generating Retention Strategies..."
  );  

  const [displayedGenneratedOffers, setDisplayedGeneratedOffers] =
    useState(false);

  const [selectedOffer, setSelectedOffer] = useState<null | Journey>(null);
  
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const campaignType = query.get("type") as OfferType;
  const { fetchJourneys, journeys } = useJourneyManagement();
  const navigate = useNavigate();
  useEffect(() => {
    if (campaignType) fetchJourneys(campaignType);
  }, [campaignType]);

  useEffect(() => {
    setLoading(true);
    setDisplayedGeneratedOffers(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getHeading = () => {
    switch (campaignType) {
      case "onboarding":
        return "Onboarding Campaign";
      case "retention":
        return "Retention Campaign";
      case "upsell-and-cross-sell":
        return "Upsell and Cross-sell Campaign";
    }
  };

  return (
    <div className={styles["container"]}>
      <RetentionCampaignHeader
        defaultCampaignName={selectedOffer?.title || `Untitled Campaign`}
        className={styles["header"]}
      />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={[
            {
              id: 1,
              tabIcon: <img src="/gifs/ninja.gif" />,
              content: (
                <div className={classNames(styles["campaign-query-chat"])}>
                  <CampaignQueryChat payload="" />
                </div>
              ),
            },
          ]}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
          defaultActiveTab={null}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>
            Your {getHeading()}
          </TextElement>
          <div className={styles["plan"]}>
            <div className={styles["customer-campaign"]}>
              {loading && (
                <div className={styles["loading-container"]}>
                  <TextElement>{loadingText}</TextElement>
                  <DottedLoader />
                </div>
              )}
              {displayedGenneratedOffers && (
                <GeneratedActiveOffers
                  offers={journeys?.map((j) => {
                    return {
                      heading: j.title,
                      text: j.description,
                      onClick: () => {
                        setSelectedOffer(j);
                        setDisplayedGeneratedOffers(false);
                      },
                    };
                  })}
                  loading={loading}
                  description="We’ve generated these targeted retention offers to help keep your high-risk customers engaged. Pick the one you want to use for your campaign. "
                  className={styles["generated-offers"]}
                />
              )}

              {selectedOffer && (
                <div>
                  <DisclosureComponent
                    header={
                      <div className={styles["selected-offer"]}>
                        <TextElement>{selectedOffer?.title}</TextElement>
                        <Button
                          onClick={() => {
                            setDisplayedGeneratedOffers(true);
                          }}
                          className={styles["change-offer-button"]}
                        >
                          Change Strategy
                        </Button>
                      </div>
                    }
                    content={
                      <Card type="pinable" className={styles["overview-card"]}>
                        <TextElement className={styles["title"]}>
                          Offer Overview
                        </TextElement>
                        <TextElement className={styles["description"]}>
                          {selectedOffer?.description}
                        </TextElement>
                      </Card>
                    }
                    headerClassName={styles["closure-header"]}
                    contentClassName={styles["closure-content"]}
                    openHeaderClassName={styles["open-closure-header"]}
                    defaultOpen
                  />
                  <DisclosureComponent
                    header={<TextElement>Stages and Actions</TextElement>}
                    content={
                      <StagesAndActions blueprint={selectedOffer?.blueprint} />
                    }
                    headerClassName={styles["closure-header"]}
                    contentClassName={styles["closure-content"]}
                    openHeaderClassName={styles["open-closure-header"]}
                    defaultOpen
                  />
                  <Button
                    onClick={() => {
                      navigate(
                        `${ROUTE_DEFINITIONS.CAMPAIGN_TREE_VIEW.path}?id=${selectedOffer?.id}`
                      );
                    }}
                    className={styles["build-journey-button"]}
                  >
                    Build Nurture Journey
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCampaignOffers;
