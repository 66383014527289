import React from "react";

interface TwoStarIconProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const TwoStarIcon: React.FC<TwoStarIconProps> = ({
  fill = "#EB2701",
  ...props
}) => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2 22.2222L7.7 14.5833L0 11.1111L7.7 7.63889L11.2 0L14.7 7.63889L22.4 11.1111L14.7 14.5833L11.2 22.2222ZM22.4 25L20.65 21.1806L16.8 19.4444L20.65 17.7083L22.4 13.8889L24.15 17.7083L28 19.4444L24.15 21.1806L22.4 25Z"
        fill={fill}
      />
    </svg>
  );
};

export default TwoStarIcon;
