import React from "react";

const PipelineConfigurationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 12.5H0V10H20V12.5ZM20 15H0V17.5H20V15ZM13.75 7.5L20 3.0625V0L13.75 4.4375L7.5 0L0 4.575V7.5L7.4 2.9875L13.75 7.5Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default PipelineConfigurationIcon;
