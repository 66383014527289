// components/LogList.tsx
import React, { useEffect, useState } from "react";
import styles from "./LogList.module.scss";
import { useFetchLogs } from "./useFetchLogs";
import { Button } from "../../componenets";
import { JSONTree } from "react-json-tree";
import classNames from "classnames";

const LogList: React.FC = () => {
  const { logs, loading, error, log, fetchLogById } =
    useFetchLogs("market_sentiments");
  const [selectedLog, setSelectedLog] = useState<string | null>(null);

  // Check for ID in the URL query parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    if (id) {
      // If there's an ID, fetch the specific log
      fetchLogById(id);
      setSelectedLog(id);
    }
  }, [fetchLogById]);

  const handleLogClick = (id: string) => {
    // Update the URL with the log ID as a query parameter
    const newUrl = `${window.location.pathname}?id=${id}`;
    window.history.pushState({}, "", newUrl);

    // Fetch the specific log by ID
    fetchLogById(id);
    setSelectedLog(id);
  };

  if (loading) return <p>Loading logs...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Logs</h2>
      <ul className={styles.logList}>
        {selectedLog && log ? (
          <li className={classNames(styles.logItem, styles["log-details"])}>
            <Button
              onClick={() => {
                setSelectedLog(null);
                window.history.back();
              }}
            >
              Go Back
            </Button>
            <h3>Log Details</h3>
            <JSONTree shouldExpandNodeInitially={() => true} data={log} />
          </li>
        ) : (
          logs.map((log) => (
            <li key={log.id} className={styles.logItem}>
              <button onClick={() => handleLogClick(log.id)}>
                <span>{log.datetime}</span> - <span>{log.id}</span>
              </button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default LogList;
